import { Component, OnInit } from '@angular/core';
import {LeadsService} from '../../services/leads.service'

@Component({
  selector: 'app-leaddilog',
  templateUrl: './leaddilog.component.html',
  styleUrls: ['./leaddilog.component.css']
})
export class LeaddilogComponent implements OnInit {

  constructor( public leadsService : LeadsService) { 
    console.log(leadsService.leadsData);
  }

  ngOnInit(): void {
  }

  savaleadData(){     
    console.log(this.leadsService.leadsData);   
  }

}
