import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {


  atertmessage: any = [];
  param = { limit: 10, offset: 0 };

  otp = '';
  newPassword = '';
  youremail = '';

  confirmPassword = '';
  _passwordType = 'password';
  _confirmPasswordType = 'password';

  resetPasswordAPI = 'reset-password';
  resetPasswordURL = `${this.commonService.configurl}${this.resetPasswordAPI}`;


  otpValidAPI = 'validate-otp';
  otpValidURL = `${this.commonService.configurl}${this.otpValidAPI}`;

  showPassword = false;
  showConfirmPassword = false;

  constructor(private commonService: CommonService, public data: DataService, private router: Router, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {

    window.scroll(0,0);
  }

  gotoHome() {
    this.router.navigate(['/login']);
  }

  resetPassword() {

    this.atertmessage = [];

    this.commonService.addLoader();
    const formData = new FormData();

    formData.append('email', this.youremail);
    formData.append('password', this.newPassword);
    formData.append('otp_number', this.otp);

    if (this.newPassword === '') {
      this.atertmessage.push('Password');
    }
    if (this.otp === '') {
      this.atertmessage.push(' OTP');
    }
    if (this.youremail === '') {
      this.atertmessage.push(' Email');
    }

    if (this.newPassword === '' || this.otp === '' || this.youremail === '') {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;

      }
      this.data.showError(msg);
    } else {

      const regexpEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      const regEmailtest = regexpEmail.test(this.youremail);
      const errorEamil = 'Plaese Enter Valid Email Address!'
      if (regEmailtest) {

        const error = "Paaaword Must be Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
        const regexpForRegPass = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
        const testForRegPass = regexpForRegPass.test(this.newPassword);
        const testForConfirmRegPass = regexpForRegPass.test(this.confirmPassword);

        if (testForRegPass) {
          if (testForConfirmRegPass) {
            if (this.confirmPassword === this.newPassword) {

              // this.validOtp();
              
              this.data.datatoPost(this.resetPasswordURL, formData, this.data.setHeaders(this.param)).subscribe(
                (res: any) => {
                  console.log(`resetPassword:`, res.data);

                  this.commonService.removeLoader();

                  this.router.navigate(['/login']);
                  this.data.SuccessToastr('Password change successful.');

                });

            }
            else {
              this.data.showError(`Passwords do not match!`);
            }
          }
          else {
            this.data.showError(error);
          }
        }
        else {
          this.data.showError(error);
        }

      }
      else {
        this.data.showError(errorEamil);
      }
    }


  }


  validOtp(){
    const formData = new FormData();

    formData.append('email', this.youremail);
    formData.append('otp', this.otp);

    this.data.datatoPostOtp(this.resetPasswordURL, formData, this.data.setHeaders(this.param)).subscribe(
      (res: any) => {

      });
  }
}
