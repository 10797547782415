import { Injectable,EventEmitter, Output } from '@angular/core';
import { DataService } from '../services/data.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {

  param = { limit: 10, offset: 0 };
  leadsDataresponse: any = [];
  
  getLeadsApi='admin/get-leads';
  getLeadsUrl=`${this.commonservice.configurl}${this.getLeadsApi}`;
  @Output() change: EventEmitter<any> = new EventEmitter();
  emitObject = { dataToEmit: '', selectedObjectName: '' };

  leadsData = {
    date : '',
    frist_name : '',
    last_name : '',
    gender :'',
    sport :'',
    nationality :'',
    profile_link : '',
    email :'',
    marketing :'',
    status :''
  }
  constructor(private commonservice: CommonService, public dataservices: DataService) { }

  resetleaddata(){
    this.leadsData = {
      date : '',
      frist_name : '',
      last_name : '',
      gender :'',
      sport :'',
      nationality :'',
      profile_link : '',
      email :'',
      marketing :'',
      status :''
    }
  }

  leadsDataLoad() {
    this.dataservices.dataJsonLoad(this.getLeadsUrl, this.dataservices.setHeaders(this.param)).subscribe(
      (res: any) => {
      
        console.log(`leadsDataresponse:`, res);
        this.leadsDataresponse = res.data;
       
          this.emitObject.dataToEmit = 'leadsDataresponse';
          this.emitObject.selectedObjectName = '';
          this.change.emit(this.emitObject);
        

        this.commonservice.removeLoader();
      }
    );
  }
 
}
