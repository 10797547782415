<div class="content-wrapper">
  <div class="container-fluid top">
    <ol style="padding: .75rem 1rem;margin-bottom: 1rem;list-style: none;background-color: #E9ECEF;border-radius: .25rem;">
      <li class="breadcrumb-item">
        <div style="display: flex; justify-content: space-between; align-items: center">
          <div>
            <a class="poppins-light">Interested Student Athletes ></a>
          </div>

        </div>
      </li>
    </ol>
    <div class="card mb-3">
      <div class="card-body" style="padding: 3rem;">
        <div class="header_box">
          <div class="row" style="margin-left: 0px;margin-bottom: 15px;">

            <div>
              <div>
                <select id="mounth" name="orderby" class="selectbox" [(ngModel)]="selectedGender"
                  (change)="selectGenderChange($event)" style=" border: 2px solid #e4e4e4;">
                  <option value="" disabled>Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>

                <select name="orderby" class="selectbox" [(ngModel)]="selectedSport"
                  (change)="selectSportChange($event)"
                  style="margin-left: 8px;                              border: 2px solid #e4e4e4;">
                  <option value="" disabled>Select Sport</option>
                  <option *ngFor="let sport of allsports; index as i;" value={{sport}}>{{sport}}
                  </option>

                </select>

                <select name="orderby" class="selectbox" [(ngModel)]="selectedStatus"
                  (change)="selectStatusChange($event)" style="margin-left: 8px;
                                border: 2px solid #e4e4e4;">
                  <option value="" disabled>Select Status</option>
                  <option value="Considering">Considering</option>
                  <option value="Applied">Applied</option>
                  <option value="Accepted">Accepted</option>
                  <option value="Denied">Denied</option>
                </select>
                <button class="btn btn-success btn-width" style="    background: #6C63FF;
                                font-family: Poppins SemiBold; width: 133px;  height: 39px;
                                margin-top: -3px; color: white; cursor: pointer; margin-left: 8px;"
                  (click)="resetFilter()">Reset</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-9 col-sm-8 col-12 " style="margin-top: 1rem;">
            <h3 class="poppins-medium ">
              Search
              <small>
                <a>
                  By Name
                </a>
              </small>
            </h3>
            <input type="text" style="padding: 8px;margin: 35px auto;width: 30%;margin-top: -10px;"
              placeholder="Type Name here..." (keyup)="updateFilter($event)" />
          </div>
          <div class="col-xl-3 col-sm-4 col-12 displyflexCSVBtn" style="margin-top: 1rem;">
            <div style=" color:#6C63FF; cursor: pointer;" *ngIf="clubService.universitieDataresponse.length !== 0"
              (click)="downloadCsv()">
              <mat-icon style="font-size: 17px;top: 3px;position: relative;">get_app</mat-icon>
              <span style="font-size: 16px;" class="poppins-light">Download CSV</span>
            </div>
          </div>
        </div>

        <div id=ngxdataTable>
          <ngx-datatable #table class='material striped' [rows]="rows" [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" [rowHeight]="45" [rowClass]="getRowClass" [scrollbarV]="false" [selected]="selected"
            (activate)="onActivate($event)" [limit]="10">
            <ngx-datatable-column name="User Name" prop="users.first_name" [width]='110'>
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span class="poppinsregular firstlatterCapital" (click)="userDetails(row.users.id)"
                  style="cursor: pointer !important">{{ row.users?.first_name }} {{ row.users?.last_name
                  }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Gender" prop="users.gender" [width]='50'>
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span class="poppinsregular datatabel firstlatterCapital">{{ row.users?.gender }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Email" [width]='100' prop="users.email">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span class="poppinsregular">{{ row.users?.email }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Sport" [width]='100' prop="users.sport.name">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span class="poppinsregular datatabel">{{ row.users?.sport?.name }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Planned Entry Date" [width]='100' prop="users.planned_entry_date">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span class="poppinsregular datatabel">{{ row.users?.planned_entry_date }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" [width]='100' prop="status">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span class="Montserrat-regular status-margin  {{row?.status.toLowerCase()  }}">
                  <ul>
                    <li> {{ row.status }}</li>
                  </ul>
                </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
      <div class="card-footer small text-muted"></div>
    </div>
  </div>
</div>
