<div id="login">

    <div class="flexBlock">
        <div class="width1">
            <!-- <aside> -->
            <figure>
                <span>
                    <i class="fa fa-arrow-left backArrow" aria-hidden="true" (click)="gotoBack()"></i>
                </span>
                <a (click)="gotoHome()" routerLink="/login" [routerLinkActive]="['activeheader']">
                    <img src="assets/img/SportfolioLoginlogo.svg" width="149" height="42" alt="">
                </a>
            </figure>

            <div class="step-text1 poppins-medium" style="margin-top: 2.5rem;">Step 3 of 4</div>
            <div class="step-text2 Montserrat-regular">Tell us a bit about yourself!</div>

            <div class="step-text2 poppins-medium" style="margin-top: 1rem; margin-bottom: 2.5rem;">Join as a</div>


            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Country*</div>
            <div id="ngselecetvalue" style="margin-top: 1rem">
                <ng-select [items]="universitylist.listOfContrys" bindLabel="name" bindValue="id" [(ngModel)]="authService.registerAccountDetails.nationality"
                    placeholder="Select Country">
                </ng-select>
            </div>

            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Location*</div>
            <div id="ngselecetvalue">
                <ng-select [items]="universitylist.listOfLocation" bindLabel="name" bindValue="id" [(ngModel)]="authService.registerAccountDetails.location_id"
                    placeholder="Select Location">
                </ng-select>
            </div>

            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Zip Code</div>
            <input type="number" id="zip_code" name="zip_code" class="form-control fontBlack style-input required" placeholder="Zip Code"
                [(ngModel)]="authService.registerAccountDetails.zip_code" />

            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Video URL</div>
            <div id="inputWithIcon">
                <input type="text" id="video" name="video" class="form-control fontBlack style-input required" placeholder="Video URL" [(ngModel)]="authService.registerAccountDetails.video">
                <i class="fa fa-youtube-play" aria-hidden="true" style=" top: 5px;"></i>
            </div>


            <div style="margin-top: 1rem !important;" id="descriptionData">
                <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Description : </div>
                <angular-editor [(ngModel)]="authService.registerAccountDetails.short_note" [config]="config" #ctrl="ngModel" class="editDescription">
                </angular-editor>
            </div>


            <button type="submit" class="btn_1 rounded full-width add_top_60" style="margin-top:3rem;" (click)="onNext()">Next</button>

        </div>

        <app-login-slider></app-login-slider>

    </div>