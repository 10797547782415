import {
  Component,
  OnInit,
  ChangeDetectorRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UniversityService } from '../services/university.service';
import { UniversitydetailsService } from '../services/universitydetails.service';
import { AllsportService } from '../services/allsport.service';
import { ActivatedRoute, Router } from '@angular/router';

import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import {
  LyImageCropper,
  ImgCropperConfig,
  ImgResolution,
} from '@alyle/ui/image-cropper';
import { StyleRenderer, lyl } from '@alyle/ui';

import { CommonService } from '../services/common.service';
import { YeardllService } from '../services/yeardll.service';
import {
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from '@angular/cdk/scrolling';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { filter } from 'rxjs/operators';
import { NgSelectConfig } from '@ng-select/ng-select';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;

const styles = () => {
  return {
    actions: lyl`{
      display: flex
    }`,
    cropper: lyl`{
      max-width: 1064px
      height: 315px
    }`,
    flex: lyl`{
      flex: 1
    }`,
  };
};

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-sportdilog',
  templateUrl: './sportdilog.component.html',
  styleUrls: ['./sportdilog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StyleRenderer],
})
export class SportdilogComponent implements OnInit {
  toppings = new FormControl();
  toppingList: any[] = [];
  selected: any = [];

  classes = this.sRenderer.renderSheet(styles);
  croppedImage?: string;
  ready: boolean;

  @ViewChild(LyImageCropper, { static: true }) readonly cropper: LyImageCropper;

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  multiSelectControl = new FormControl();

  @ViewChildren(MatOption)
  options: QueryList<MatOption>;

  result: string;
  myConfig: ImgCropperConfig = {
    width: 1064, // Default `250`
    height: 315, // Default `200`,
    responsiveArea: true,
  };

  @ViewChild('d1') d1: any;
  date: any;

  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];

  addClublogopic: any;
  addCommities_imagePic: any;
  addRecommendation_imagePic: any;
  Clubgallery: any;

  addcoachPic: any;
  addNotable_AluminiPic: any;
  selectedCar: number;

  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  ////////////////////////////////////////
  /////// Text editor

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // tags: Tag[] = [];
  name = 'Angular 6';
  htmlContent = '';
  value = `www.wreljndkf.com/dfalkjdflkjalkdjflkasdflkjadlk`;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  ///////////////////////////

  constructor(
    public universityService: UniversityService,
    public universitydetailsService: UniversitydetailsService,
    private changeDetector: ChangeDetectorRef,
    public sportService: AllsportService,
    private router: Router,
    readonly sRenderer: StyleRenderer,
    private commonservice: CommonService,
    private cd: ChangeDetectorRef,
    readonly sd: ScrollDispatcher,
    public yeardllService: YeardllService
  ) {
    for (let i = 0; i < 100000; i++) {
      this.toppingList.push({ id: i, viewValue: 'option-' + i });
    }
    // this.config.notFoundText = 'Custom not found';
    // this.config.appendTo = 'body';
    // this.config.bindValue = 'value';
  }

  ngOnInit(): void {
    if (!this.universityService.addSportData) {
      this.router.navigate(['/university_Profile']);
    }

    this.yeardllService.yergen();

    window.scroll(0, 0);
    // this.universitydetailsService.contyList();
    // this.sportService.dataLoad();
  }

  chosenYearHandlerAdd(
    normalizedYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.universityService.universitySportClub.Notable_year =
      this.changeDateFormate(normalizedYear);
    this.d1.nativeElement.value =
      this.universityService.universitySportClub.Notable_year;
    datepicker.close();
  }

  changeDateFormate(date: any) {
    // let d = `${date.getFullYear()}-${this.prependZero(date.getMonth())}-${this.prependZero(date.getDate())}`;
    let d = `${date.getFullYear()}`;
    return d;
  }

  saveAddedSportData() {
    if (this.cropper.isLoaded) {
      console.log('hnerenre');

      this.cropper.crop();
      this.universityService.addClubSportdata();
    } else {
      console.log('heree');

      this.universityService.addClubSportdata();
    }
  }

  deleteAddedsport() {
    this.universityService.addSportData = false;
    this.universityService.CancelSportClubData();
    this.router.navigate(['/university_Profile']);
  }

  selectprofilePhoto(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.universityService.addclubProfilePhoto = e.target.result;
      };
      reader.readAsDataURL(_file);
    }

    this.universityService.addclubProfilePhoto = event.target.files[0];
  }

  selectLogo(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    this.universityService.addClublogo = event.target.files[0];

    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.addClublogopic = e.target.result;
      };
      reader.readAsDataURL(_file);
    }
  }

  select_Gallery(event: any) {
    this.Clubgallery = [];

    let files = event.target.files;

    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.Clubgallery.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
      this.universityService.addClubgallery = files;
    }
  }

  onCropped(e) {
    this.croppedImage = e.dataURL;
    this.universityService.abcd = this.croppedImage;
  }

  selectClub(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.addCommities_image = event.target.files[0];

    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.addCommities_imagePic = e.target.result;
      };
      reader.readAsDataURL(_file);
    }
  }

  selectRecommendations(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.addRecommendation_image = event.target.files[0];

    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.addRecommendation_imagePic = e.target.result;
      };
      reader.readAsDataURL(_file);
    }
  }

  selectcoach(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.coachaddProfile = event.target.files[0];

    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.addcoachPic = e.target.result;
      };
      reader.readAsDataURL(_file);
    }
  }

  selectNotable(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.addNotableAlumini_image = event.target.files[0];

    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.addNotable_AluminiPic = e.target.result;
      };
      reader.readAsDataURL(_file);
    }
  }

  ngAfterViewInit(): void {
    this.sd
      .scrolled()
      .pipe(
        filter((scrollable) => this.cdkVirtualScrollViewPort === scrollable)
      )
      .subscribe(() => {
        let needUpdate = false;

        this.options.forEach((option) => {
          const selected = this.selected.includes(option.value);

          if (selected && !option.selected) {
            option.select();
            needUpdate = true;
          } else if (!selected && option.selected) {
            option.deselect();
            needUpdate = true;
          }
        });

        if (needUpdate) {
          this.cd.detectChanges();
        }
      });
  }

  foropen() {
    this.cdkVirtualScrollViewPort.scrollToIndex(5);
  }

  openChange($event: boolean) {
    if ($event) {
      this.foropen();
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    }
  }

  onSelectionChange(change): void {
    if (!change.isUserInput) {
      return;
    }
    console.log(change.source.value);
    const value = change.source.value;
    const idx = this.selected.indexOf(change.source.value);

    if (idx > -1) {
      this.selected.splice(idx, 1);
    } else {
      this.selected.push(value);
    }
  }
}
