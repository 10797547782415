import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { UniversitydetailsService } from '../../services/universitydetails.service';
import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-signup3',
  templateUrl: './signup3.component.html',
  styleUrls: ['./signup3.component.scss'],
})

export class Signup3Component implements OnInit {
  @ViewChild('bday') bday: any
  birthdate: NgbDateStruct | any;
  date: any;
  atertmessage: any = [];
  param = { limit: 10, offset: 0 };

  linked_inList = [{ value: "Male" }, { value: "Female" }, { value: "Other" }];

  // signUpForm: FormGroup;

  maxDate = new Date();


  /////// Text editor

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // tags: Tag[] = [];
  name = 'Angular 6';
  htmlContent = '';
  value = `www.wreljndkf.com/dfalkjdflkjalkdjflkasdflkjadlk`;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  ///////////////////////////

  constructor(private formBuilder: FormBuilder, public authService: AuthService, private router: Router, public universitylist: UniversitydetailsService, private common: CommonService, public data: DataService) { }

  ngOnInit(): void {

    if (this.universitylist.listOfContrys.length === 0) {
      this.universitylist.contyList();
    }
    if (this.universitylist.listOfRegion.length === 0) {
      this.universitylist.regionList();
    }
  }

  gotoHome() {
    // this.authService.logout();
    this.common.currentUser = '';
    this.router.navigate(['/login']);
  }

  onNext(): void {

    this.atertmessage = [];

    // if (this.authService.registerAccountDetails.zip_code === '') {
    //   this.atertmessage.push(' Zip Code');
    // }

    // if (this.authService.registerAccountDetails.scholarship_description === '') {
    //   this.atertmessage.push('Scholarship Description');
    // }

    // if (this.authService.registerAccountDetails.short_note === '') {
    //   this.atertmessage.push('Short Note');
    // }

    // if (this.authService.registerAccountDetails.video === '') {
    //   this.atertmessage.push('Video');
    // }
    if (this.authService.registerAccountDetails.nationality === '') {
      this.atertmessage.push(' Nationality');
    }
    if (this.authService.registerAccountDetails.location_id === '') {
      this.atertmessage.push(' Location');
    }


    if (this.authService.registerAccountDetails.video) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = this.authService.registerAccountDetails.video.match(regExp);

      if (!(match && match[2].length == 11)) {
        this.atertmessage.push('Upload Only Youtube Link');
      }

    }

    if (this.atertmessage.length > 0) {

      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;

      }
      this.data.showError(msg);
    }
    else {
      this.router.navigate(['/signup4']);
    }
  }

  onChange(sport: any) {
    // this.universitylist.searchbySport(sport);
  }

  changeDateFormate(date: any) {
    let d = `${date.getFullYear()}-${this.prependZero(date.getMonth())}-${this.prependZero(date.getDate())}`;
    return d;
  }

  prependZero(number: any) {
    if (number < 10)
      return "0" + number;
    else
      return number;
  }

  editbirthday() {
    this.birthdate = this.changeDateFormate(this.date);

  }

  gotoBack() {
    this.router.navigate(['/signup2']);
  }

}
