import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClubService } from '../../services/club.service';
import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';


@Component({
  selector: 'app-inter-univer-diolog',
  templateUrl: './inter-univer-diolog.component.html',
  styleUrls: ['./inter-univer-diolog.component.css']
})
export class InterUniverDiologComponent implements OnInit {

  dataInterUniver: any = [];


  param = { limit: 10, offset: 0 };


  constructor(public clubService: ClubService, public dataService: DataService, public commonService: CommonService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InterUniverDiologComponent>) { }

  ngOnInit(): void {
    console.log(this.data.user);
    // this.commonService.addLoader();
    this.clubService.profileDataLoad(this.data.user);
  }

  onNavigate(site: any) {
    window.open(site, "_blank");
  }

  youtubeVideoThumbnail(url: string) {
    let thumImg = '';
    if (!url.includes("watch?v=")) {
      let u1 = url.replace("https://youtu.be/", "");
      let u2 = u1.trim();
      thumImg = `https://i.ytimg.com/vi/${u2}/hqdefault.jpg`;
    }
    else {
      thumImg = `https://i.ytimg.com/vi/${url.replace("https://www.youtube.com/watch?v=", "")}/hqdefault.jpg`;
    }
    return thumImg;
  }
}
