import { Injectable } from '@angular/core';
import { NgbCalendar, NgbDateStruct, NgbInputDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class YeardllService {

  ngbToday = new Date();
  today = this.calendar.getToday();
  nbgYears: any = [];
  nbgAllYears: any = [];

  constructor(  private calendar: NgbCalendar ) { }

  yergen() {
    let y = Number(this.ngbToday.getFullYear());
    let x = y - 100;
    for (let i = 0; i < 100; i++) {
      this.nbgYears.push(`${y + i}`);
    }

    for (let j = 0; j < 200; j++) {
      this.nbgAllYears.push(`${x + j}`);
    }

  }
}
