<!-- <app-header></app-header> -->

<div class="content-wrapper">
  <div style="margin-top: 2.8rem">
    <section
      id="hero_in"
      class="courses start_bg_zoom"
    >
      <div *ngIf="!universityService.editBackground">
        <div class="relative">
          <div
            class="absoluteBackground"
            style="position: absolute; right: 23px; top: 31px"
          >
            <div
              style="margin: 6.5px"
              (click)="editProfileimage()"
            >
              <mat-icon
                style="font-size: 16px"
                class="iconbtn"
              >edit </mat-icon>
            </div>
          </div>
        </div>
        <div>
          <div
            class="wrapper"
            *ngIf="
              !universitydetailsService.universityDetails.university_banner
            "
            style="background: url({{
              this.universitydetailsService.webConfigDetailsData[6].config_value
            }});"
          ></div>
          <div *ngIf="
              universitydetailsService.universityDetails.university_banner &&
              isImgYes
            ">
            <!-- <div
              class="wrapper"
              style="background: url({{
                universitydetailsService.universityDetails.university_banner.url
              }});"
            ></div> -->
            <img
              style="height: 400px;width: 100%;"
              [src]="universitydetailsService.universityDetails.university_banner.url"
            />
          </div>
        </div>
      </div>
      <div *ngIf="universityService.editBackground">
        <app-edit-universityProfile></app-edit-universityProfile>
      </div>
    </section>

    <div class="bg_color_1">
      <nav class="secondary_nav sticky_horizontal">
        <div class="container">
          <ul class="clearfix">
            <li>
              <!-- href="#description1"  -->
              <a
                (click)="commonService.gotoScroll('description1')"
                class="active"
                style="font-family: 'Poppins SemiBold' !important; cursor: pointer;"
              >Description
              </a>
            </li>
            <li>
              <!-- href="#sport1"  -->
              <a
                (click)="commonService.gotoScroll('sport1')"
                style="font-family: 'Poppins SemiBold' !important; cursor: pointer;"
              >Sport Clubs</a>
            </li>
            <li>
              <!-- href="#Course"  -->
              <a
                (click)="commonService.gotoScroll('Course')"
                style="font-family: 'Poppins SemiBold' !important; cursor: pointer;"
              >Courses</a>
            </li>
            <li>
              <!-- href="#Scholarship"  -->
              <a
                (click)="commonService.gotoScroll('Scholarship')"
                style="font-family: 'Poppins SemiBold' !important; cursor: pointer;"
              >Scholarships</a>
            </li>
            <li>
              <!-- href="#Notable"  -->
              <a
                (click)="commonService.gotoScroll('Notable')"
                style="font-family: 'Poppins SemiBold' !important; cursor: pointer;"
              >Notable Alumni</a>
            </li>
            <li>
              <!-- href="#reviews"  -->
              <a
                (click)="commonService.gotoScroll('reviews')"
                style="font-family: 'Poppins SemiBold' !important; cursor: pointer;"
              >Reviews</a>
            </li>
          </ul>
        </div>
      </nav>
      <div class="container margin_60_35">
        <div class="row">
          <!-- <div class="col-lg-8"> -->
          <div class="col-lg-12">
            <!-- description1 -->
            <section id="description1">
              <div style="display: flex">
                <div *ngIf="!universityService.editlogo">
                  <div class="relative">
                    <div style="position: absolute; right: -12px; top: 0px">
                      <div
                        style="margin: 6.5px"
                        (click)="editProfilelogo(this.universitycopyProfile)"
                      >
                        <mat-icon
                          style="font-size: 16px"
                          class="iconbtn"
                        >edit
                        </mat-icon>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="
                      universitydetailsService.universityDetails
                        .profile_picture !== null
                    ">
                    <img
                      class="logo"
                      src="{{
                        universitydetailsService.universityDetails
                          .profile_picture
                      }}"
                    />
                  </div>

                  <div *ngIf="
                      universitydetailsService.universityDetails
                        .profile_picture === null
                    ">
                    <img
                      class="logo"
                      src="{{
                        this.universitydetailsService.webConfigDetailsData[7]
                          .config_value
                      }}"
                    />
                  </div>
                </div>

                <div *ngIf="universityService.editlogo">
                  <div class="relative">
                    <div style="
                        display: flex;
                        position: absolute;
                        top: 33px;
                        right: 6px;
                        background: white;
                      ">
                      <button
                        class="iconbtn"
                        (click)="saveEditedBackground()"
                        style="width: 24px"
                      >
                        <mat-icon style="
                            font-size: 18px !important;
                            margin-left: -4px !important;
                          ">save</mat-icon>
                      </button>

                      <button
                        class="iconbtn"
                        (click)="deleteEditedBackground()"
                        style="width: 24px"
                      >
                        <mat-icon style="
                            font-size: 18px !important;
                            margin-left: -4px !important;
                          ">close</mat-icon>
                      </button>
                    </div>
                  </div>

                  <button
                    type="button"
                    class="logouni"
                    style="background: url({{ universitycopyProfile }})"
                  >
                    <label
                      for="upload-logo"
                      class="upload_btn"
                      style="
                        margin-bottom: 0px;
                        margin-left: -5px;
                        margin-top: 5px;
                      "
                    >
                      <mat-icon
                        class="iconbtn"
                        style="font-size: 29px !important; color: #312e2ecc"
                      >camera_alt</mat-icon>
                    </label>
                    <input
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      name="photo"
                      id="upload-logo"
                      (change)="selectuniversitylogo($event)"
                      hidden
                    />
                  </button>
                </div>

                <div style="margin-left: 1rem">
                  <h2
                    class="poppins-medium firstlatterCapital"
                    *ngIf="
                      universitydetailsService.universityDetails
                        .university_details
                    "
                  >
                    {{
                    universitydetailsService.universityDetails
                    .university_details.correct_name
                    }}
                  </h2>
                  <div
                    class="text3 poppinsemibold"
                    *ngIf="universitydetailsService.universityDetails"
                  >
                    BUCS Ranking -
                    <span *ngIf="
                        universitydetailsService.universityDetails
                          .university_details.bucs_overall_rating
                      ">
                      {{
                      universitydetailsService.universityDetails
                      .university_details.bucs_overall_rating
                      }}</span>
                    <span *ngIf="
                        !universitydetailsService.universityDetails
                          .university_details.bucs_overall_rating
                      ">
                      0</span>
                    , University Ranking -
                    <span *ngIf="
                        universitydetailsService.universityDetails
                          .university_ranking
                      ">{{
                      universitydetailsService.universityDetails
                      .university_ranking.institution_ranking
                      }}</span>
                    <span *ngIf="
                        !universitydetailsService.universityDetails
                          .university_ranking
                      ">0</span>
                  </div>
                </div>
              </div>
            </section>

            <app-edit-universitydetails></app-edit-universitydetails>
            <!-- sport -->
            <section
              id="sport1"
              class="border_shadow"
            >
              <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
                <div class="d-flex">
                  <h2 class="poppins-medium">
                    Sport Clubs
                    <!-- <span>
                      <i
                        class="material-icons icon-class"
                        (click)="addsport()"
                      >add</i>
                    </span> -->
                    <small style="font-size: 14px;">
                      Add Sports Club <span
                        matTooltip="To add sports club, Email info@sportfolio.co.uk with the Club name and sport and this will be added within 48hrs of request. Thanks"
                      >
                        (i)
                      </span>
                    </small>
                  </h2>
                </div>

                <div
                  class="poppins-medium"
                  style="margin-top: -15px"
                  *ngIf="
                    universitydetailsService.universityDetails.university_club
                  "
                >
                  {{
                  universitydetailsService.universityDetails.university_club
                  .length
                  }}
                  Sport Clubs
                </div>
              </div>

              <div *ngIf="
                  universitydetailsService.universityDetails.university_details
                ">
                <div
                  id="accordion_lessons"
                  role="tablist"
                  style="margin-top: 1rem"
                  *ngIf="
                    universitydetailsService.universityDetails.university_club
                      .length !== 0
                  "
                >
                  <!-- <div id="accordion_lessons" role="tablist" class="add_bottom_45"> -->

                  <!-- <div class="card"> -->
                  <div *ngFor="
                      let clubs of universitydetailsService.universityDetails
                        .university_club;
                      index as i
                    ">
                    <div
                      class="card-header"
                      role="tab"
                      id="headingOne"
                      *ngIf="clubs.club"
                    >
                      <h5 class="mb-0 d-flex justify-content-between">
                        <div class="Bflex">
                          <a
                            class="collapsed sporttext"
                            data-toggle="collapse"
                            href="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <span
                              class="poppinsregular firstlatterCapital"
                              (click)="details(clubs.club.id)"
                            >{{ clubs.club.name }}</span>
                          </a>
                          <div
                            *ngIf="clubs.club.is_performance_sport"
                            class="text3 poppinsemibold"
                            style="color: #21287f; font-size: 14px"
                          >
                            Performance Sport
                            <span
                              matTooltip="A Performance Sport Team is aimed at high performing athletes. These sports are taken seriously and typically receive more resources, funding and support."
                            >
                              (i)
                            </span>
                          </div>
                        </div>
                        <div style="display: flex">
                          <!-- <button class="btn btn-danger btn-width cursorPointer"
                                                          style="  background: #6C63FF;  font-family: 'Poppins SemiBold'; "
                                                          (click)="details(clubs.club.id)">Details</button> -->
                          <div
                            style="margin-left: 10px; margin-top: 10px"
                            (click)="editSport(clubs.club.id)"
                          >
                            <mat-icon
                              style="font-size: 16px"
                              class="iconbtn"
                            >edit
                            </mat-icon>
                          </div>
                          <button
                            class="iconbtn"
                            (click)="deleteEditedSport(i)"
                          >
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Courses -->
            <section
              id="Course"
              class="border_shadow"
            >
              <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
                <div class="d-flex">
                  <h2 class="poppins-medium">Courses</h2>
                  <span
                    *ngIf="!universityService.addCourseData"
                    style="margin-top: 5px"
                  >
                    <i
                      class="material-icons icon-class"
                      (click)="universityService.addCourseData = true"
                    >add</i>
                  </span>
                  <span style="margin-top: 5px">
                    <i
                      class="material-icons icon-class"
                      (click)="openDialog()"
                    >upload</i>
                  </span>
                  <!-- <button type="button" class="  uploadbtn" (click)="universityService.courseUploadFile = true">
                                        <label style="margin-bottom: 0rem;" (click)="openDialog()">
                                            <i class="fa fa-upload" style="margin-left: 3px"></i>
                                        </label>

                                    </button> -->

                  <div
                    *ngIf="universityService.addCourseData"
                    class="addEditSaveBtn"
                  >
                    <button
                      class="iconbtn"
                      (click)="saveAddedCourseData()"
                    >
                      <mat-icon>save</mat-icon>
                    </button>
                    <button
                      class="iconbtn"
                      (click)="deleteAddedCourse()"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
                <div *ngIf="
                    universitydetailsService.universityDetails
                      .university_details
                  ">
                  <div
                    *ngIf="!universityService.addCourseData"
                    class="poppins-medium"
                    style="margin-top: -15px"
                  >
                    <span class="select1"><a
                        target="_blank"
                        [href]="environment.universityCourseFileUrl"
                      >
                        Template
                      </a>
                    </span>
                    {{ universityService.coureseDataresponse.length }} Type
                  </div>
                </div>
              </div>
              <!-- <div style="display: flex; justify-content: space-between; align-items: center;     margin: 15px 0; " *ngIf="!universityService.addCourseData ">
                                <div>
                                    <button type="button" class="  uploadbtn" (click)="universityService.courseUploadFile = true">
                                        <label for="upload-photo" class="" style="margin-bottom: 0rem;" (click)="openDialog()">
                                            <i class="fa fa-upload" style="margin-left: 3px"></i>
                                        </label>
                                        <input type="file" name="photo" id="upload-photo" accept="application/vnd.ms-excel,application/msexcel,application/.xls,.xlsx"
                                            (change)="selectuniversityCourse($event)" style="display: none;">
                                    </button>
                                    <span class="file-name graycolorfront">&nbsp;{{universityService.selectedFileName}}</span>
                                </div>
                                <div *ngIf="universityService.courseUploadFile" class="addEditSaveBtn">
                                    <button class="iconbtn" (click)="universityService.saveCourseUploadFile()">
                                        <mat-icon>save</mat-icon>
                                    </button>
                                    <button class="iconbtn" (click)="universityService.deleteCourseUploadcancelFile()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </div> -->

              <div
                *ngIf="universityService.addCourseData"
                class="margin1rem"
              >
                <div class="textlabel">
                  Course Name
                  <span>*</span>
                </div>
                <input
                  class="universitydatainput"
                  placeholder="Course Name"
                  [(ngModel)]="universityService.universityCourse.course_name"
                />

                <!-- <input class="universitydatainput" placeholder="Course Type" [(ngModel)]="universityService.universityCourse.course_type"> -->
                <div class="textlabel">
                  Select Course Type
                  <span>*</span>
                </div>
                <select
                  class="universitydatainput"
                  style="color: #757272 !important"
                  [(ngModel)]="universityService.universityCourse.course_type"
                >
                  <option
                    value=""
                    disabled
                    selected
                    class="poppinsemibold"
                  >
                    Select Course Type
                  </option>
                  <option value="Foundation">Foundation</option>
                  <option value="Research">Research</option>
                  <option value="Postgraduate">Postgraduate</option>
                  <option value="Undergraduate">Undergraduate</option>
                  <option value="Apprenticeship">Apprenticeship</option>
                  <option value="Other">Other</option>
                </select>

                <div class="textlabel">
                  Duration
                  <span>*</span>
                </div>
                <input
                  class="universitydatainput"
                  placeholder="Duration"
                  [(ngModel)]="universityService.universityCourse.duration"
                />

                <!-- <div id="yearcal" style="
                                    margin-top: -9px;">

                                    <mat-form-field appearance="fill" style="width: 100%;">
                                        <mat-label disabled selected> </mat-label>
                                        <input matInput [matDatepicker]=" picker1" readonly [max]="maxDate" [(ngModel)]="date1" (ngModelChange)="addcourseTime()">
                                        <mat-datepicker-toggle matSuffix [for]="picker1">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div> -->
              </div>

              <div *ngIf="
                  universitydetailsService.universityDetails.university_details
                ">
                <div
                  id="accordion_lessons2"
                  role="tablist"
                  *ngIf="universityService.coureseDataresponse.length !== 0"
                >
                  <div
                    class="card"
                    *ngFor="
                      let coursedata of universityService.coureseDataresponse;
                      index as i
                    "
                  >
                    <div
                      class="card-header"
                      role="tab"
                      id="headingOne"
                    >
                      <h5 class="mb-0">
                        <div
                          class="Bflex"
                          *ngIf="CourseId !== coursedata.course_id"
                        >
                          <a
                            data-toggle="collapse"
                            href="#collapse1"
                            aria-expanded="true"
                            style="width: 97%"
                            aria-controls="collapse1"
                          >
                            <span class="poppinsregular firstlatterCapital">{{
                              coursedata.course.name
                              }}</span>,
                            <span class="poppinsregular firstlatterCapital">{{
                              coursedata.course.type
                              }}</span>
                            <div>
                              <span class="poppinsregular firstlatterCapital">{{
                                coursedata.duration
                                }}</span>
                            </div>
                          </a>
                          <!-- <span
                                                    class="glyphicon" style="color:#6C63FF;">&#xe025;</span> -->
                          <div style="width: 3%">
                            <span style="margin-left: 10px">
                              <mat-icon
                                (click)="editCourse(coursedata)"
                                class="iconbtn"
                                style="font-size: 16px"
                              >edit
                              </mat-icon>
                            </span>
                          </div>
                        </div>

                        <div
                          *ngIf="CourseId === coursedata.course_id"
                          class="Bflex"
                        >
                          <div style="width: 100%">
                            <div class="textlabel">
                              Course Name
                              <span>*</span>
                            </div>
                            <input
                              [(ngModel)]="
                                universityService.copycoureseDataresponse[i]
                                  .course.name
                              "
                              class="universitydatainput"
                              placeholder="Course Name"
                            />

                            <div class="textlabel">
                              Select Course Type
                              <span>*</span>
                            </div>
                            <select
                              class="universitydatainput"
                              style="color: #000000"
                              [(ngModel)]="
                                universityService.copycoureseDataresponse[i]
                                  .course.type
                              "
                            >
                              <option
                                value=""
                                disabled
                                selected
                              >
                                Course type
                              </option>
                              <option value="Foundation">Foundation</option>
                              <option value="Research">Research</option>
                              <option value="Postgraduate">Postgraduate</option>
                              <option value="Undergraduate">
                                Undergraduate
                              </option>
                              <option value="Apprenticeship">
                                Apprenticeship
                              </option>
                              <option value="Other">Other</option>
                            </select>

                            <!-- <input [(ngModel)]="universityService.copycoureseDataresponse[i].course.type" class="universitydatainput" placeholder="Course Type"> -->

                            <div class="textlabel">
                              Duration
                              <span>*</span>
                            </div>
                            <input
                              [(ngModel)]="
                                universityService.copycoureseDataresponse[i]
                                  .duration
                              "
                              class="universitydatainput"
                              placeholder="Duration"
                            />

                            <!-- <div id="yearcal" style="
                                                    margin-top: -9px;">

                                                    <mat-form-field appearance="fill"  style="width: 100%;">
                                                        <mat-label disabled selected> </mat-label>
                                                        <input matInput [matDatepicker]=" picker2" readonly [max]="maxDate" [(ngModel)]="date2"  (ngModelChange)="editcourseTime(i)">
                                                        <mat-datepicker-toggle matSuffix [for]="picker2">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker2></mat-datepicker>
                                                    </mat-form-field>
                                                    </div> -->
                          </div>
                          <!-- </span>
                                                </a> -->
                          <div style="display: flex">
                            <button
                              class="iconbtn"
                              (click)="
                                saveEditedCourse(
                                  universityService.copycoureseDataresponse[i]
                                )
                              "
                            >
                              <mat-icon>save</mat-icon>
                            </button>

                            <button
                              class="iconbtn"
                              (click)="deleteEditedCourse(i)"
                            >
                              <mat-icon>delete</mat-icon>
                            </button>

                            <button
                              class="iconbtn"
                              (click)="editCancelCourseData(i)"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                          </div>
                        </div>
                      </h5>
                    </div>

                    <!-- <div id="collapse1" class=" collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion_lessons2" style="height: 8vh !important;">
                                        <div class="card-body">
                                            <div class="list_lessons">
                                                <div  class="poppinsregular">{{coursedata.Duration}}</div>
                                            </div>
                                        </div>
                                    </div> -->
                  </div>
                </div>
              </div>
              <!-- /accordion -->
            </section>

            <!-- Scholarships -->
            <section
              id="Scholarship"
              class="border_shadow"
            >
              <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: baseline;
                ">
                <div
                  style="
                    color: #333 !important;
                    font-size: 22px;
                    margin-bottom: 1.7rem;
                  "
                  class="poppins-medium"
                >
                  Scholarships

                  <span *ngIf="!universityService.addScholarshipData">
                    <i
                      class="material-icons icon-class"
                      (click)="universityService.addScholarshipData = true"
                    >add</i>
                  </span>
                </div>

                <div
                  *ngIf="universityService.addScholarshipData"
                  class="addEditSaveBtn"
                  style="display: flex"
                >
                  <button
                    class="iconbtn"
                    (click)="saveAddedScholarshipData()"
                  >
                    <mat-icon>save</mat-icon>
                  </button>
                  <button
                    class="iconbtn"
                    (click)="deleteAddedScholarship()"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>

              <div
                *ngIf="universityService.addScholarshipData"
                class="margin1rem"
              >
                <div class="textlabel">
                  Title
                  <span>*</span>
                </div>
                <input
                  class="universitydatainput"
                  placeholder=" Scholarship Title"
                  [(ngModel)]="
                    universityService.universityScholarship.scholarship_name
                  "
                />

                <div class="textlabel">
                  Scholarship Apply Link
                  <span>*</span>
                </div>
                <div id="inputWithIcon">
                  <input
                    class="universitydatainput"
                    placeholder=" Scholarship Apply Link"
                    [(ngModel)]="
                      universityService.universityScholarship
                        .scholarship_apply_link
                    "
                    style="padding-left: 1.3rem"
                  />
                  <mat-icon>link</mat-icon>
                </div>

                <div
                  class="textlabel"
                  style="margin-bottom: 8px"
                >
                  Description
                  <span>*</span>
                </div>
                <angular-editor
                  [(ngModel)]="
                    universityService.universityScholarship.scholarship_details
                  "
                  [config]="config"
                  #ctrl="ngModel"
                  class="editDescription"
                >
                </angular-editor>

                <!-- <textarea class="universitydatainput" placeholder=" Scholarship Details" [(ngModel)]="universityService.universityScholarship.scholarship_details">
                                </textarea> -->
              </div>

              <div *ngIf="
                  universitydetailsService.universityDetails.university_details
                ">
                <div
                  *ngIf="universityService.scholarshipDataresponse.length !== 0"
                  style="margin-top: 5px"
                >
                  <div *ngFor="
                      let scholarshipData of universityService.scholarshipDataresponse;
                      index as i
                    ">
                    <div *ngIf="ScholarshipId !== scholarshipData.id">
                      <div
                        class="row"
                        style="margin-top: 1rem"
                      >
                        <div class="col-lg-11 col-md-11 col-sm-11 col-11">
                          <div style="
                              display: flex;
                              justify-content: space-between;
                              align-items: baseline;
                              width: 100%;
                            ">
                            <div class="select1 poppinsemibold firstlatterCapital redirect">
                              {{ scholarshipData.name }}
                            </div>
                            <div class="select1 poppinsemibold">
                              {{
                              scholarshipData.updated_at | date : "shortDate"
                              }}
                            </div>
                          </div>

                          <div
                            class="textdatalink poppinsemibold"
                            (click)="onNavigate(scholarshipData.apply_link)"
                          >
                            {{ scholarshipData.apply_link }}
                          </div>
                          <div id="descriptionData">
                            <div
                              class="reviewdata poppinsregular"
                              innerHtml="{{ scholarshipData.details }}"
                            ></div>
                          </div>
                          <!-- <p class="reviewdata poppinsregular">{{scholarshipData.details}}
                                                </p> -->
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-1">
                          <span style="margin-left: 10px">
                            <mat-icon
                              (click)="editScholarship(scholarshipData)"
                              class="iconbtn"
                              style="font-size: 16px"
                            >edit
                            </mat-icon>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="ScholarshipId === scholarshipData.id"
                      class="Bflex"
                    >
                      <div style="width: 100%">
                        <div class="textlabel">
                          Title
                          <span>*</span>
                        </div>
                        <input
                          class="universitydatainput"
                          placeholder=" Scholarship Title"
                          [(ngModel)]="
                            universityService.copyscholarshipDataresponse[i]
                              .name
                          "
                        />

                        <div class="textlabel">
                          Scholarship Apply Link
                          <span>*</span>
                        </div>
                        <div id="inputWithIcon">
                          <input
                            class="universitydatainput"
                            placeholder=" Scholarship Apply Link"
                            [(ngModel)]="
                              universityService.copyscholarshipDataresponse[i]
                                .apply_link
                            "
                            style="padding-left: 1.3rem"
                          />
                          <mat-icon>link</mat-icon>
                        </div>

                        <div
                          class="textlabel"
                          style="margin-bottom: 8px"
                        >
                          Description
                          <span>*</span>
                        </div>
                        <angular-editor
                          [(ngModel)]="
                            universityService.copyscholarshipDataresponse[i]
                              .details
                          "
                          [config]="config"
                          #ctrl="ngModel"
                          class="editDescription"
                        >
                        </angular-editor>

                        <!-- <textarea class="universitydatainput" placeholder=" Scholarship Details" [(ngModel)]="universityService.copyscholarshipDataresponse[i].details"></textarea> -->
                      </div>
                      <div style="display: flex">
                        <button
                          class="iconbtn"
                          (click)="
                            saveEditedScholarship(
                              universityService.copyscholarshipDataresponse[i]
                            )
                          "
                        >
                          <mat-icon>save</mat-icon>
                        </button>

                        <button
                          class="iconbtn"
                          (click)="deleteEditedScholarship(i)"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>

                        <button
                          class="iconbtn"
                          (click)="editCancelScholarshipData(i)"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Notable Alumni -->
            <section
              id="Notable"
              class="border_shadow"
            >
              <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
                <div class="d-flex">
                  <h2 class="poppins-medium">Notable Alumni</h2>
                  <span
                    *ngIf="!universityService.addNotableData"
                    style="margin-top: 5px"
                  >
                    <i
                      class="material-icons icon-class"
                      (click)="addNewNotableData()"
                    >add</i>
                  </span>

                  <div
                    *ngIf="universityService.addNotableData"
                    class="addEditSaveBtn"
                  >
                    <button
                      class="iconbtn"
                      (click)="saveAddedNotableData()"
                    >
                      <mat-icon>save</mat-icon>
                    </button>
                    <button
                      class="iconbtn"
                      (click)="deleteAddedNotable()"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>

                <div>
                  <div
                    *ngIf="!universityService.addNotableData"
                    class="poppins-medium"
                    style="margin-top: -15px"
                  >
                    Total ({{ universityService.notableDataresponse.length }})
                  </div>
                </div>
              </div>

              <div
                *ngIf="universityService.addNotableData"
                class="margin1rem"
              >
                <div class="row">
                  <div class="col-lg-2 col-md-2 col-sm-2 col-12">
                    <button
                      type="button"
                      class="logouni"
                      style="background: url({{
                        this.addnotable_aluminiPhoto
                      }})"
                      (click)="imageRef.click()"
                    >
                      <label
                        class="upload_btn"
                        style="
                          margin-bottom: 0px;
                          margin-left: -5px;
                          margin-top: 5px;
                        "
                      >
                        <mat-icon
                          class="iconbtn"
                          style="font-size: 29px !important; color: #312e2ecc"
                        >camera_alt</mat-icon>
                      </label>
                      <input
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        name="photo"
                        id="upload-addnotable"
                        (change)="selectAddNotable($event)"
                        #imageRef
                        hidden
                      />
                    </button>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5 col-12">
                    <div class="textlabel">
                      First Name
                      <span>*</span>
                    </div>
                    <input
                      class="universitydatainput"
                      placeholder=" First Name"
                      [(ngModel)]="
                        universityService.universityNotableAlumini
                          .notable_alumini_first_name
                      "
                    />
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5 col-12">
                    <div class="textlabel">
                      Last Name
                      <span>*</span>
                    </div>
                    <input
                      class="universitydatainput"
                      placeholder=" Last Name"
                      [(ngModel)]="
                        universityService.universityNotableAlumini
                          .notable_alumini_last_name
                      "
                    />
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col-lg-6 col-md-6 col-sm-6 col-12"
                    id="backgroudwhite"
                  >
                    <!-- <select class="universitydatainput" style="
                                        color: #7d7b7b;" [(ngModel)]="universityService.universityNotableAlumini.sport_id">
                                            <option value="" disabled selected class="poppinsemibold">Select Sport
                                            </option>
                                            <option *ngFor="let sport of sportService.listOfSports; index as i;" [value]="sport.id" class="poppinsemibold">{{sport.name}}</option>
                                        </select> -->

                    <!-- <mat-select placeholder="Select Sport" class="universitydatainput" [(ngModel)]="universityService.universityNotableAlumini.sport_id">
                                            <cdk-virtual-scroll-viewport itemSize="48" [style.height.px]=5*48>
                                                <mat-option *cdkVirtualFor="let sport of sportService.listOfSports; index as i;" [value]="sport.id" class="poppinsemibold">{{sport.name}}</mat-option>
                                            </cdk-virtual-scroll-viewport>
                                        </mat-select> -->

                    <ng-select
                      [items]="sportService.listOfSports"
                      bindLabel="name"
                      bindValue="id"
                      [(ngModel)]="
                        universityService.universityNotableAlumini.sport_id
                      "
                      placeholder="Select Sport*"
                      loadingText="Loading..."
                    >
                    </ng-select>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-6 col-12"
                    id="backgroudwhite"
                  >
                    <div>
                      <div class="textlabel">
                        Achievement
                        <span>*</span>
                      </div>
                      <input
                        class="universitydatainput"
                        placeholder=" Achievement"
                        [(ngModel)]="
                      universityService.universityNotableAlumini
                        .notable_alumini_achievement
                    "
                      />
                    </div>
                  </div>
                  <!-- <div
                    class="col-lg-6 col-md-6 col-sm-6 col-12"
                    id="backgroudwhite"
                  > -->
                  <!-- <select class="universitydatainput" [(ngModel)]="universityService.universityNotableAlumini.course_id">
                                            <option value="" disabled selected class="poppinsemibold">Select Degree
                                            </option>
                                            <option *ngFor="let degree of universityService.courseData; index as i;" [value]="degree.id" class="poppinsemibold">{{degree.name}}
                                            </option>
                                        </select> -->

                  <!-- <mat-select placeholder="Select Course" class="universitydatainput" [(ngModel)]="universityService.universityNotableAlumini.course_id">
                                            <cdk-virtual-scroll-viewport itemSize="48" [style.height.px]=5*48>
                                                <mat-option *cdkVirtualFor="let degree of universityService.courseData; index as i;;" [value]="degree.id" class="poppinsemibold">{{degree.name}}</mat-option>
                                            </cdk-virtual-scroll-viewport>
                                        </mat-select> -->

                  <!-- <ng-select
                      [items]="universityService.courseData"
                      [virtualScroll]="true"
                      bindLabel="name"
                      bindValue="id"
                      [(ngModel)]="
                        universityService.universityNotableAlumini.course_id
                      "
                      placeholder="Select Course"
                      loadingText="Loading..."
                    >
                    </ng-select> -->
                  <!-- </div> -->
                </div>

                <!-- <div class="row"> -->
                <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="textlabel">LinkedIn Url</div>
                    <div id="inputWithIcon">
                      <input
                        class="universitydatainput"
                        placeholder="LinkedIn Url"
                        [(ngModel)]="
                          universityService.universityNotableAlumini
                            .notable_alumini_linked_in
                        "
                        style="padding-left: 1.3rem; margin-top: 9px"
                      />
                      <i
                        class="fa fa-linkedin-square"
                        aria-hidden="true"
                        style="top: 8px"
                      ></i>
                    </div>
                  </div> -->

                <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div
                      id="backgroudwhite"
                      style="margin-top: 10px"
                    > -->
                <!-- <ng-select
                        [items]="yeardllService.nbgAllYears"
                        [virtualScroll]="true"
                        placeholder="Select Year"
                        [(ngModel)]="
                          universityService.universityNotableAlumini
                            .notable_alumini_year
                        "
                      >
                      </ng-select> -->
                <!-- <mat-form-field style=" width: 100%; ">
                                                <input matInput #d2 [matDatepicker]="dp" [formControl]="date" placeholder="Year" readonly [(ngModel)]="date">
                                                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                                <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandlerAdd($event, dp)" panelClass="example-month-picker">
                                                </mat-datepicker>
                                            </mat-form-field> -->
                <!-- </div>
                  </div>
                </div> -->


              </div>

              <div
                class="row add_top_20"
                *ngIf="universityService.notableDataresponse.length !== 0"
              >
                <div
                  class="col-lg-6"
                  *ngFor="
                    let notableData of universityService.notableDataresponse;
                    index as i
                  "
                >
                  <ul class="list_teachers">
                    <li>
                      <div
                        *ngIf="NotableId !== notableData.id"
                        class="Bflex"
                      >
                        <a>
                          <figure *ngIf="notableData.profile_image === null">
                            <img
                              src="this.universitydetailsService.webConfigDetailsData[5].config_value"
                              alt=""
                            />
                          </figure>
                          <figure *ngIf="notableData.profile_image !== null">
                            <img
                              src="{{ notableData.profile_image }}"
                              alt=""
                            />
                          </figure>
                          <div>
                            <span class="poppins-medium containWrite firstlatterCapital">
                              {{ notableData.first_name }}
                            </span>
                            <span class="poppins-medium containWrite firstlatterCapital">
                              {{ notableData.last_name }}
                            </span>
                          </div>

                          <!-- <h5 *ngIf="notableData.sport !== null" class="poppinsregular">{{notableData.sport.name}}</h5> -->
                          <div class="select1 poppinsemibold">
                            {{ notableData.achievement }} {{ notableData.year }}
                          </div>
                        </a>
                        <div>
                          <span style="margin-left: 10px">
                            <mat-icon
                              (click)="editNotable(notableData, i)"
                              class="iconbtn"
                              style="font-size: 16px"
                            >edit
                            </mat-icon>
                          </span>
                        </div>
                      </div>
                      <div *ngIf="NotableId === notableData.id">
                        <div style="
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                          ">
                          <div>
                            <button
                              type="button"
                              class="logouni"
                              style="background: url({{
                                notableData.profile_image
                              }})"
                              (click)="editTmageRef.click()"
                            >
                              <label
                                class="upload_btn"
                                style="margin-bottom: 0px; margin-left: -5px; margin-top: 5px;"
                              >
                                <mat-icon
                                  class="iconbtn"
                                  style="font-size: 29px !important; color: #312e2ecc"
                                >camera_alt</mat-icon>
                              </label>
                              <input
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                name="photo"
                                id="upload-editnotable"
                                (change)="selectEditNotable($event,notableData.id)"
                                #editTmageRef
                                hidden
                              />
                            </button>
                          </div>
                          <div style="display: flex">
                            <button
                              class="iconbtn"
                              (click)="
                                saveEditedNotable(
                                  universityService.copynotableDataresponse[i]
                                )
                              "
                            >
                              <mat-icon>save</mat-icon>
                            </button>

                            <button
                              class="iconbtn"
                              (click)="deleteEditedNotable(i)"
                            >
                              <mat-icon>delete</mat-icon>
                            </button>

                            <button
                              class="iconbtn"
                              (click)="editCancelNotableData(i,notableData.id)"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                          </div>
                        </div>

                        <div>
                          <div class="textlabel">
                            First Name
                            <span>*</span>
                          </div>
                          <input
                            class="universitydatainput"
                            placeholder=" First Name"
                            [(ngModel)]="
                              universityService.copynotableDataresponse[i]
                                .first_name
                            "
                          />

                          <div class="textlabel">
                            Last Name
                            <span>*</span>
                          </div>
                          <input
                            class="universitydatainput"
                            placeholder=" Last Name"
                            [(ngModel)]="
                              universityService.copynotableDataresponse[i]
                                .last_name
                            "
                          />

                          <div class="textlabel">
                            Achievement
                            <span>*</span>
                          </div>
                          <input
                            class="universitydatainput"
                            placeholder=" Achievement"
                            [(ngModel)]="
                              universityService.copynotableDataresponse[i]
                                .achievement
                            "
                          />

                          <!-- <div class="textlabel">LinkedIn Url</div>
                          <div id="inputWithIcon">
                            <input
                              class="universitydatainput"
                              placeholder="LinkedIn Url"
                              [(ngModel)]="
                                universityService.copynotableDataresponse[i]
                                  .linked_in
                              "
                              style="padding-left: 1.3rem"
                            />
                            <i
                              class="fa fa-linkedin-square"
                              aria-hidden="true"
                            ></i>
                          </div> -->

                          <!-- <select class="universitydatainput" style="
                                                    color: #7d7b7b;" [(ngModel)]="universityService.copynotableDataresponse[i].sport_id">
                                                            <option value="" disabled selected style="color:#7d7979 ;" class="poppinsemibold">Select Sport
                                                            </option>
                                                            <option *ngFor="let sport of sportService.listOfSports; index as i;" [value]="sport.id" class="poppinsemibold">{{sport.name}}</option>
                                                        </select> -->

                          <!-- <select class="universitydatainput" [(ngModel)]="universityService.copynotableDataresponse[i].course_id">
                                                            <option value="" disabled selected class="poppinsemibold">Select Degree
                                                            </option>
                                                            <option *ngFor="let degree of universityService.courseData; index as i;" [value]="degree.id" class="poppinsemibold">{{degree.name}}
                                                            </option>
                                                        </select> -->

                          <div id="backgroudwhite">
                            <ng-select
                              [items]="sportService.listOfSports"
                              bindLabel="name"
                              bindValue="id"
                              [(ngModel)]="
                                universityService.copynotableDataresponse[i]
                                  .sport_id
                              "
                              placeholder="Select Sport*"
                              loadingText="Loading..."
                            >
                            </ng-select>
                          </div>

                          <!-- <div id="backgroudwhite">
                            <ng-select
                              [items]="universityService.courseData"
                              [virtualScroll]="true"
                              bindLabel="name"
                              bindValue="id"
                              [(ngModel)]="
                                universityService.copynotableDataresponse[i]
                                  .course_id
                              "
                              placeholder="Select Course"
                              loadingText="Loading..."
                            >
                            </ng-select>
                          </div>

                          <div id="backgroudwhite">
                            <ng-select
                              [items]="yeardllService.nbgAllYears"
                              [virtualScroll]="true"
                              placeholder="Select Year"
                              [(ngModel)]="
                                universityService.copynotableDataresponse[i]
                                  .year
                              "
                            >
                            </ng-select>
                          </div> -->

                          <!-- <div id="yearcal">
                                                        <mat-form-field style=" width: 100%; ">
                                                            <input matInput #d1 [matDatepicker]="dp" [formControl]="date" placeholder="Year" readonly [(ngModel)]="date">
                                                            <mat-datepicker-toggle matSuffix [for]="dp">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandlerEdit($event, dp ,i)" panelClass="example-month-picker">
                                                            </mat-datepicker>
                                                        </mat-form-field>
                                                    </div> -->
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            <!-- reviews -->
            <section
              id="reviews"
              style="margin-top: 2rem; border: none"
            >
              <h2 class="poppins-medium">Reviews</h2>

              <div class="reviews-container">
                <div class="row">
                  <div class="col-lg-3">
                    <div id="review_summary">
                      <strong class="poppins-medium">{{ totalrating }}</strong>
                      <div class="rating">
                        <ngb-rating
                          [(rate)]="totalrating"
                          [max]="5"
                          [readonly]="true"
                        >
                          <ng-template let-fill="fill">
                            <span
                              class="star"
                              [ngClass]="'color' + reviewRating"
                              [class.filled]="fill === 100"
                            >&#9733;</span>
                          </ng-template>
                        </ngb-rating>
                        <!-- <i class="icon_star voted"></i>
                                                <i class="icon_star voted"></i>
                                                <i class="icon_star voted"></i>
                                                <i class="icon_star voted"></i>
                                                <i class="icon_star"></i> -->
                      </div>

                      <small
                        class="poppins-medium"
                        *ngIf="
                          universitydetailsService.universityDetails.review
                        "
                      >Based on
                        {{
                        universitydetailsService.universityDetails.review
                        .length
                        }}
                        reviews</small>
                    </div>
                  </div>
                  <div class="col-lg-9 rating_top">
                    <div class="row">
                      <div class="col-lg-10 col-9">
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            [style.width.%]="startByRating.fiveStar"
                            [attr.aria-valuenow]="startByRating.fiveStar"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-3">
                        <small class="poppins-medium">
                          <strong>5 stars</strong>
                        </small>
                      </div>
                    </div>
                    <!-- /row -->
                    <div class="row">
                      <div class="col-lg-10 col-9">
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            [style.width.%]="startByRating.fourStar"
                            [attr.aria-valuenow]="startByRating.fourStar"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-3">
                        <small class="poppins-medium">
                          <strong>4 stars</strong>
                        </small>
                      </div>
                    </div>
                    <!-- /row -->
                    <div class="row">
                      <div class="col-lg-10 col-9">
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            [style.width.%]="startByRating.threeStart"
                            [attr.aria-valuenow]="startByRating.threeStart"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-3">
                        <small class="poppins-medium">
                          <strong>3 stars</strong>
                        </small>
                      </div>
                    </div>
                    <!-- /row -->
                    <div class="row">
                      <div class="col-lg-10 col-9">
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            [style.width.%]="startByRating.twoStar"
                            [attr.aria-valuenow]="startByRating.twoStar"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-3">
                        <small class="poppins-medium">
                          <strong>2 stars</strong>
                        </small>
                      </div>
                    </div>
                    <!-- /row -->
                    <div class="row">
                      <div class="col-lg-10 col-9">
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            [style.width.%]="startByRating.oneStar"
                            [attr.aria-valuenow]="startByRating.oneStar"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-3">
                        <small class="poppins-medium">
                          <strong>1 stars</strong>
                        </small>
                      </div>
                    </div>
                    <!-- /row -->
                  </div>
                </div>
                <!-- /row -->
              </div>
              <hr />

              <div class="reviews-container">
                <div *ngFor="
                    let review of universitydetailsService.universityDetails
                      .review;
                    index as i
                  ">
                  <div
                    class="review-box clearfix"
                    *ngIf="review.is_rating === 1"
                    style="margin-bottom: 9px !important"
                  >
                    <figure
                      class="rev-thumb"
                      *ngIf="review.is_rating === 1"
                    >
                      <img
                        *ngIf="review.from_review_user.profile_picture"
                        src="{{ review.from_review_user.profile_picture }}"
                        alt=""
                      />
                      <img
                        *ngIf="!review.from_review_user.profile_picture"
                        src="{{
                          this.universitydetailsService.webConfigDetailsData[5]
                            .config_value
                        }}"
                        alt=""
                      />
                    </figure>

                    <!-- <figure class="rev-thumb" *ngIf="review.is_rating === 1 ">
                                            <img src="{{review.from_review_user.profile_picture}}" alt="">
                                        </figure> -->
                    <div
                      class="rev-content"
                      *ngIf="review.is_rating === 1"
                    >
                      <div class="text3 poppins-medium firstlatterCapital">
                        {{ review.from_review_user.first_name }}
                        {{ review.from_review_user.last_name }}
                      </div>
                      <div class="rating">
                        <!-- <i class="material-icons voted">&#xe838;</i>
                                            <i class="material-icons voted">&#xe838;</i>
                                            <i class="material-icons voted">&#xe838;</i>
                                            <i class="material-icons voted">&#xe838;</i>
                                            <i class="material-icons voted">&#xe838;</i> -->
                        <ngb-rating
                          [max]="5"
                          [(rate)]="review.rating"
                          [readonly]="true"
                        >
                          <ng-template let-fill="fill">
                            <span
                              class="star"
                              [class.filled]="fill === 100"
                            >&#9733;</span>
                          </ng-template>
                        </ngb-rating>
                      </div>
                      <div class="rev-info poppinsregular">
                        Date :
                        {{
                        review.from_review_user.updated_at
                        | date : "mediumDate"
                        }}
                      </div>
                      <div id="descriptionReview">
                        <div
                          class="reviewdata"
                          innerHtml="{{ review.description }}"
                        ></div>
                      </div>
                      <!--
                                                <p class="reviewdata poppins-italic ">
                                                    {{review.description}}
                                                </p> -->

                      <div *ngIf="review.replies.length === 0">
                        <div
                          style="margin-bottom: 1rem"
                          *ngIf="replyId !== review.id"
                        >
                          <a
                            class="btn_1 rounded"
                            style="padding: 3px 13px"
                            (click)="reviewReplay(review.id)"
                          >Reply
                          </a>
                        </div>
                        <div
                          class="row"
                          *ngIf="replyId === review.id"
                          style="margin-top: 3rem"
                        >
                          <div class="col-lg-10 col-md-10 col-sm-10 col-10">
                            <div
                              class="textlabel"
                              style="margin-bottom: 10px"
                            >
                              Description
                            </div>
                            <angular-editor
                              [(ngModel)]="
                                universityService.reviewReply.replyDescription
                              "
                              [config]="config"
                              #ctrl="ngModel"
                              class="editDescription"
                            >
                            </angular-editor>

                            <!-- <textarea class="universitydatainput lbl3 poppins-light" placeholder="Reply" [(ngModel)]="universityService.reviewReply.replyDescription"></textarea> -->
                          </div>
                          <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                            <div style="display: flex">
                              <button
                                class="iconbtn"
                                (click)="
                                  savereplayData(review.id, review.to_id)
                                "
                              >
                                <mat-icon style="font-size: 18px !important">save</mat-icon>
                              </button>

                              <button
                                class="iconbtn"
                                (click)="noReply(review.id)"
                              >
                                <mat-icon style="font-size: 19px !important">close</mat-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="review.replies">
                    <div *ngIf="review.replies[0]">
                      <div *ngIf="review.replies[0].is_rating === 0">
                        <div style="
                            display: flex;
                            margin-left: 8rem;
                            margin-bottom: 2rem;
                          ">
                          <div style="
                              transform: rotate(180deg);
                              color: #4529bde3;
                              font-size: 15px;
                              display: flex;
                              justify-content: center;
                              align-items: flex-end;
                              margin-top: 1rem;
                            ">
                            <mat-icon style="font-size: 30px">reply</mat-icon>
                          </div>
                          <div style="
                              padding: 20px;
                              border: 1px solid #6c757d2b;
                              border-radius: 4px;
                              width: 100%;
                            ">
                            <div
                              class="reviewdata poppins-italic"
                              style="font-size: 15px"
                            >
                              <div class="text3 poppins-medium firstlatterCapital">
                                {{
                                universitydetailsService.universityDetails
                                .university_details.correct_name
                                }}
                              </div>
                              <div class="rev-info poppinsregular">
                                Date :
                                {{
                                review.replies[0].updated_at
                                | date : "mediumDate"
                                }}
                              </div>
                              <div id="descriptionReview">
                                <div
                                  class="reviewdata"
                                  innerHtml="{{
                                    review.replies[0].description
                                  }}"
                                ></div>
                              </div>
                              <!-- <div class="reviewdata poppins-italic ">
                                                                {{review.replies[0].description}}
                                                            </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
      </div>
      <!-- /container -->
    </div>
  </div>
</div>