import { Component, OnInit ,ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { AuthService } from '../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { UniversitydetailsService } from '../services/universitydetails.service';
import { UniversityService } from '../services/university.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../alert/alert.component';
import { ClubService } from '../services/club.service';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-university-admin',
  templateUrl: './university-admin.component.html',
  styleUrls: ['./university-admin.component.css']
})
export class UniversityAdminComponent implements OnInit {

  ifreviewdata = false;
  ifCoursedata = false;

  noticeBoardId = '';


////////////////////////////////////////
  /////// Text editor

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // tags: Tag[] = [];
  name = 'Angular 6';
  htmlContent = '';
  value = `www.wreljndkf.com/dfalkjdflkjalkdjflkasdflkjadlk`;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  
  ///////////////////////////
  
  constructor(private http: HttpClient, public commonservice: CommonService, private Activatedroute: ActivatedRoute, private authService: AuthService,
    private router: Router, public data: DataService ,public universitydetailsService:UniversitydetailsService ,public universityService: UniversityService, private changeDetector: ChangeDetectorRef , public dialog: MatDialog ,public clubService :ClubService) { }

  ngOnInit(): void {
    this.commonservice.removePreLoader();

    this.universitydetailsService.userDetails();

    this.universityService.getUniversityNoticeBoardData();
    this.clubService.universityDataLoad();

    window.scroll(0,0);

  }

  // NoticeBoard
  saveAddNoticeBoard() {
    this.universityService.addUniversityNoticeBoardData();
    // this.changeDetector.detectChanges();
  }

  saveCancelNoticeBoard() {
    this.universityService.adduniversityNoticeBoard = false;
    this.universityService.CanceladduniversityNOticeBoard();
    this.changeDetector.detectChanges();
  }

  editNoticeBoardData(data: any) {
    this.universityService.copyuniversityNoticeBoardResponse = JSON.parse(JSON.stringify(this.universityService.UniversityNoticeBoardData));
    this.noticeBoardId = data.id;
    this.universityService.edituniversityNoticeBoard = true;
  }

  saveEditNoticeBoard(data: any) {
    this.universityService.editUniversityNoticeBoardData(data);
    this.noticeBoardId = '';
    this.changeDetector.detectChanges();
  }

  saveDeleteNoticeBoard(i: any) {
    this.universityService.edituniversityNoticeBoard = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: "deleteUniversityNoticeBoard" }
    });
    this.noticeBoardId = '';
    this.changeDetector.detectChanges();
  }

  editCancleNoticeBoard(i: any) {
    this.universityService.edituniversityNoticeBoard = true;
    this.noticeBoardId = i;
    this.changeDetector.detectChanges();
  }

  
}
