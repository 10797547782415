import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, RouterOutlet } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../services/data.service';

export interface User {
  email: string;
  password: string;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  param = { limit: 10, offset: 0 };

  authApi = this.commonService.configurl + 'admin/login';
  registerApi = this.commonService.configurl + 'admin/register';
  parameter = { email: '', password: '', user_type: '' };

  logoutApi = this.commonService.configurl + 'admin/logout';

  userpassword = '';


  registerAccountDetails = {
    email: "",
    location_id: "",
    linked_in: "",
    password: "",
    user_type: "university",
    nationality: '',
    correct_name: "",
    url: "",
    zip_code: "",
    short_note: "",
    video: "",
    facebook: "",
    instagram: "",
    average_rating: "",
    scholarship_description: "",
    female_students: "",
    male_students: "",
    other_students: "",
    total_students: "",
    bucs_men_rating: "",
    bucs_women_rating: "",
    bucs_overall_rating: ""
  };

  constructor(private http: HttpClient, private commonService: CommonService, private toastr: ToastrService, public data: DataService, private router: Router) { }

  public signIn(userData: User, user_type: string): void {

    this.parameter.email = userData.email;
    this.parameter.password = userData.password;
    this.parameter.user_type = user_type;

    this.commonService.addLoader();

    this.http.post<any>(this.authApi, this.parameter).subscribe({
      next: data => {
        console.log('user_data: ', data);
        localStorage.setItem('USER_DETAILS', JSON.stringify(data.data));
        localStorage.setItem('ACCESS_TOKEN', data.access_token);

        this.commonService.currentUser = data.data;
        console.log('successfully login');
        this.toastr.success('Login Successfully');
        this.commonService.removeLoader();
        // this.router.navigate(['profile']);
        this.router.navigate(['admin']);

      },
      error: (error: HttpErrorResponse) => {
        console.log(error)
        this.toastr.warning(error.error.error.message)
        // let blockedmsg = 'User is blocked';
        // if ((error.error.message).toLowerCase() == blockedmsg.toLowerCase()) {
        // this.toastr.warning(blockedmsg);

        // } else  if (error.message == blockedmsg.toLowerCase()) {
        // this.toastr.warning(blockedmsg);

        // } else {
        // this.toastr.warning('Oops something went wrong, Please try again');
        // }
        // console.error('There was an error!', error);
        this.commonService.removeLoader();

      }
      // error: error => {
      //   let blockedmsg = 'User is blocked';
      //   if ((error.error.message).toLowerCase() == blockedmsg.toLowerCase()) {
      //   this.toastr.warning(blockedmsg);

      //   } else  if (error.message == blockedmsg.toLowerCase()) {
      //   this.toastr.warning(blockedmsg);

      //   } else {
      //   this.toastr.warning('Oops something went wrong, Please try again');
      //   }
      //   // console.error('There was an error!', error);
      //   this.commonService.removeLoader();
      // }
    });
  }

  public isLoggedIn(): any {
    return localStorage.getItem('ACCESS_TOKEN') !== null;
  }

  public logout(): void {

    this.data.dataJsonLoad(this.logoutApi, this.data.setHeaders(this.param)).subscribe(
      (res: any) => {
      }
    );

    this.commonService.afterresponse();
    localStorage.removeItem('USER_DETAILS');
    localStorage.removeItem('ACCESS_TOKEN');
    this.commonService.currentUser = '';
    this.router.navigate(['/login']);
    this.data.SuccessToastr('Logged Out ');

  }

  public registerUser(): void {
    this.commonService.addLoader();

    let param = {
      "email": this.registerAccountDetails.email,
      "location_id": this.registerAccountDetails.location_id,
      "linked_in": this.registerAccountDetails.linked_in,
      "password": this.registerAccountDetails.password,
      "user_type": this.registerAccountDetails.user_type,
      "nationality": this.registerAccountDetails.nationality,
      "correct_name": this.registerAccountDetails.correct_name,
      "url": this.registerAccountDetails.url,
      "zip_code": this.registerAccountDetails.zip_code,
      "short_note": this.registerAccountDetails.short_note,
      "video": this.registerAccountDetails.video,
      "facebook": this.registerAccountDetails.facebook,
      "instagram": this.registerAccountDetails.instagram,
      "average_rating": this.registerAccountDetails.average_rating,
      "scholarship_description": this.registerAccountDetails.short_note,
      "female_students": this.registerAccountDetails.female_students,
      "male_students": this.registerAccountDetails.male_students,
      "other_students": this.registerAccountDetails.other_students,
      "total_students": this.registerAccountDetails.total_students,
      "bucs_men_rating": this.registerAccountDetails.bucs_men_rating,
      "bucs_women_rating": this.registerAccountDetails.bucs_women_rating,
      "bucs_overall_rating": this.registerAccountDetails.bucs_overall_rating,
    }
    this.commonService.removeLoader();

    this.http.post<any>(this.registerApi, param).subscribe({
      next: data => {
        console.log(data);
        console.log('Register Successful!');
        this.toastr.success('Admin user registered successfully');
        this.commonService.removeLoader();
        this.router.navigate(['/login']);
      },
      error: error => {

        // this.toastr.warning('Something went wrong!');
        if (error.error.email[0]) {
          this.toastr.warning(error.error.email[0]);
        }
        this.toastr.warning(error.error.error.message);
        // this.toastr.warning('Something went wrong!');
        // console.error('There was an error!', error);
        this.commonService.removeLoader();
      }
    });
  }

  public GoogleSignIn(userDetail: any, accessToken: string): void {

    localStorage.setItem('USER_DETAILS', JSON.stringify(userDetail));
    localStorage.setItem('ACCESS_TOKEN', accessToken);
    this.commonService.currentUser = userDetail;

    console.log(JSON.stringify(userDetail));
    console.log(accessToken);

    console.log('successfully login');
    this.toastr.success('Login Successfully');
    this.commonService.removeLoader();
    // this.router.navigate(['profile']);
    this.router.navigate(['admin']);

  }
}
