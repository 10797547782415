<nav id="menu" class="fake_menu"></nav>

<div id="preloader">
    <div data-loader="circle-side"></div>
</div>
<!-- End Preload -->

<div id="login">

    <div class="flexBlock">
        <div style="padding: 4rem;">
            <!-- <aside> -->
            <figure style="    margin: 0 0 4rem !important;">

                <a (click)="gotoHome()" routerLink="/login" [routerLinkActive]="['activeheader']">
                    <img src="assets/img/SportfolioLoginlogo.svg" width="149" height="42" alt="">
                </a>
            </figure>


            <small style="margin-top:3.5rem;">
                <a>
                    <span class="textb">Reset password?</span>
                </a>
            </small>

            <input class="poppins-medium fontBlack universitydatainput required" id="otp" placeholder="OTP" [(ngModel)]=" this.otp" style="margin-top:3rem;">

            <input class="poppins-medium fontBlack universitydatainput required" id="resetemail" placeholder="Email" [(ngModel)]=" this.youremail" style="margin-top:2rem;">
            
            <div class="row linepassword">
                <div class="col-lg-11 co-sm-11 col-md-11 col-11" style=" padding: 0">
                    <input [type]="_passwordType" class="poppins-medium fontBlack inputpassword required" placeholder="New Password" id="newPassword"
                        [(ngModel)]=" this.newPassword">
                </div>
                <div class="col-lg-1 co-sm-1 col-md-1 col-1  " style=" padding: 0; ">
                    <div *ngIf="showPassword">
                        <i class="fa fa-eye fontBlack" (click)="showPassword = false; _passwordType ='password' "></i>
                    </div>
                    <div *ngIf="!showPassword">
                        <i class="fa fa-eye-slash fontBlack" (click)="showPassword = true; _passwordType ='text' "></i>
                    </div>
                </div>
            </div>

            <div class="row linepassword">
                <div class="col-lg-11 co-sm-11 col-md-11 col-11" style=" padding: 0">

                    <input [type]="_confirmPasswordType" class="poppins-medium fontBlack inputpassword required" placeholder="Confirm Password"
                        [(ngModel)]=" this.confirmPassword" id="confirmPassword">

                </div>
                <div class="col-lg-1 co-sm-1 col-md-1 col-1  " style=" padding: 0; ">
                    <div *ngIf="showConfirmPassword">
                        <i class="fa fa-eye fontBlack" (click)="showConfirmPassword = false; _confirmPasswordType ='password' "></i>
                    </div>
                    <div *ngIf="!showConfirmPassword">
                        <i class="fa fa-eye-slash fontBlack" (click)="showConfirmPassword = true; _confirmPasswordType ='text' "></i>
                    </div>
                </div>
            </div>

            <button type="submit" class="btn_1 rounded full-width add_top_60" (click)="resetPassword();" style="   margin-top: 2rem;">
                Change Password</button>

        </div>
        <app-login-slider></app-login-slider>
    </div>
</div>

<!-- /login -->

<!-- COMMON SCRIPTS -->
<script src="js/jquery-3.5.1.min.js"></script>
<script src="js/common_scripts.js"></script>
<script src="js/main.js"></script>
<script src="assets/validate.js"></script>