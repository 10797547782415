import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AllsportService {

  param = { limit: 10, offset: 0 };
  url = 'get-all-sports';
  dataurl =`${this.commonservice.configurl}${this.url}`;
  listOfSports: any = [];

  constructor(private commonservice: CommonService, public data: DataService) {
  }

  dataLoad() {

    this.commonservice.addLoader();

    this.data.dataJsonLoad(this.dataurl, this.data.setHeaders(this.param)).subscribe(
      (res: any) => {
        // console.log(`listofallSports:`, res);
        this.listOfSports = res.data;
        // return  this.listOfSports;

        this.commonservice.removeLoader();
      }
    );
  }

}