import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../services/common.service';
import { AllsportService } from '../services/allsport.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UniversitydetailsService } from '../services/universitydetails.service';
import { UniversityService } from '../services/university.service';
import { AlertComponent } from '../alert/alert.component';
import { DataService } from '../services/data.service';

import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import {
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperErrorEvent,
  ImgCropperEvent,
  ImgCropperLoaderConfig,
} from '@alyle/ui/image-cropper';
import { StyleRenderer, lyl } from '@alyle/ui';
import { Platform } from '@angular/cdk/platform';

const styles = () => {
  return {
    actions: lyl`{
      display: flex
    }`,
    cropper: lyl`{
      max-width: 400px
      height: 300px
    }`,
    flex: lyl`{
      flex: 1
    }`,
  };
};

@Component({
  selector: 'app-sportdata',
  templateUrl: './sportdata.component.html',
  styleUrls: ['./sportdata.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StyleRenderer],
})
export class SportdataComponent implements OnInit, AfterViewInit {
  classes = this.sRenderer.renderSheet(styles);
  croppedImage?: string;
  ready: boolean;
  @ViewChild(LyImageCropper, { static: true }) readonly cropper: LyImageCropper;
  result: string;
  myConfig: ImgCropperConfig = {
    width: 150, // Default `250`
    height: 150, // Default `200`,
    output: {
      width: 40,
      height: 40,
    },
  };

  param = { limit: 10, offset: 0 };
  url = 'club-details/';
  dataurl = `${this.commonService.configurl}${this.url}`;

  universityurl = 'university-details/';
  universitydataurl = `${this.commonService.configurl}${this.universityurl}`;
  universityDetails: any = [];

  //gallery
  deleteGalleryApi = 'admin/club/delete-image';
  deleteGalleryUrl = `${this.commonService.configurl}${this.deleteGalleryApi}`;

  cards = [
    {
      title: 'Card Title 1',
      description:
        'This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg',
    },
    {
      title: 'Card Title 2',
      description:
        'This card has supporting text below as a natural lead-in to additional content.',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg',
    },
    {
      title: 'Card Title 3',
      description:
        'This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action. This text is much longer so that you can see a significant difference between the text in  previous tabs.',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg',
    },
    {
      title: 'Card Title 4',
      description:
        'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg',
    },
    {
      title: 'Card Title 5',
      description:
        'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg',
    },
    {
      title: 'Card Title 6',
      description:
        'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Button',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg',
    },
  ];
  slides: any = [[]];

  constructor(
    public universityService: UniversityService,
    public universitydetailsService: UniversitydetailsService,
    private Activatedroute: ActivatedRoute,
    public commonService: CommonService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    public data: DataService,
    public sportService: AllsportService,
    private router: Router,
    readonly sRenderer: StyleRenderer,
    private _platform: Platform
  ) {}

  ngOnInit(): void {
    if (this.universityService.sportClubId) {
      this.router.navigate(['/university_Profile']);
    }

    this.commonService.change.subscribe((emitObject) => {
      if (emitObject.dataToEmit === 'afterresponse') {
        this.changeDetector.detectChanges();
      }
    });

    this.slides = this.chunk(this.cards, 3);

    console.log(this.Activatedroute.snapshot.queryParamMap.get('id') || 0);
    this.universityService.ClubData(
      this.Activatedroute.snapshot.queryParamMap.get('id')
    );

    window.scroll(0, 0);

    console.log(
      'this.universityService.clubDetails',
      this.universityService.clubDetails
    );
  }

  //delete gallery
  deleteGallery(id: any) {
    const formData = new FormData();
    formData.append('image_id', id);

    this.data
      .datatoPost(
        this.deleteGalleryUrl,
        formData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`Gallerydata:`, res.data);
        this.data.SuccessToastr(' Deleted Successfully');
        this.universityService.ClubData(this.universityService.clubDetails.id);
      });
  }

  youtubeVideoThumbnail(url: string) {
    let thumImg = '';
    if (!url.includes('watch?v=')) {
      let u1 = url.replace('https://youtu.be/', '');
      let u2 = u1.trim();
      thumImg = `https://i.ytimg.com/vi/${u2}/hqdefault.jpg`;
    } else {
      thumImg = `https://i.ytimg.com/vi/${url.replace(
        'https://www.youtube.com/watch?v=',
        ''
      )}/hqdefault.jpg`;
    }
    return thumImg;
  }

  onNavigate(site: any) {
    window.open(site, '_blank');
  }

  onCropped(e) {
    this.croppedImage = e.dataURL;
    this.universityService.profileEdit = this.croppedImage;
    console.log(e);
  }

  ngAfterViewInit() {
    // if (this._platform.isBrowser) {
    //   const config: ImgCropperLoaderConfig = {
    //     scale: 0.745864772531767,
    //     xOrigin: 642.380608078103,
    //     yOrigin: 236.26357452128866,
    //     rotation: 0,
    //     originalDataURL: 'https://firebasestorage.googleapis.com/v0/b/alyle-ui.appspot.com/o/img%2Flarm-rmah-47685-unsplash-1.png?alt=media&token=96a29be5-e3ef-4f71-8437-76ac8013372c'
    //   };
    //   this.cropper.loadImage(config);
    // }
  }

  chunk(arr: any, chunkSize: any) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
}
