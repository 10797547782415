import { Component, OnInit } from '@angular/core';
import { UniversityService } from 'src/app/services/university.service';

@Component({
  selector: 'app-upload-course',
  templateUrl: './upload-course.component.html',
  styleUrls: ['./upload-course.component.css']
})
export class UploadCourseComponent implements OnInit {

  constructor(public universityService: UniversityService) { 
  }

  ngOnInit(): void {
  }

  selectuniversityCourse(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.selectedFileName = event.target.files[0].name;
    this.universityService.file = event.target.files[0];
  }

}
