import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LeaddilogComponent } from '../dialog/leaddilog/leaddilog.component';
import { LeadsService } from '../services/leads.service';
import { DataService } from '../services/data.service';
import { CommonService } from '../services/common.service';


@Component({
    selector: 'app-leads',
    templateUrl: './leads.component.html',
    styleUrls: ['./leads.component.css'],
    encapsulation: ViewEncapsulation.None,

})

export class LeadsComponent implements OnInit, AfterViewInit {
    @ViewChild('table') table: any;
    isrentaldata = true;


    name = 'Angular';
    temp = [];
    rows = [];
    editing = {};
    customers = [];
    artists = [];
    appliedArtists = [];
    selected = [];
    // Columns = [{ name: 'telephone_no' }, { name: 'name' }, { name: 'Status' }, { name: 'sport_name' },{ name: 'Email' },{ name: 'Telephone_no.' },{ name: 'Email' }];

    data = [];
    rowIndex: any;
    quantite: any;


    constructor(public dialog: MatDialog, private changeDetector: ChangeDetectorRef, public leadsService: LeadsService, private commonservice: CommonService, public dataservices: DataService) { }

    ngOnInit(): void {

        this.leadsService.change.subscribe(emitObject => {
            if (emitObject.dataToEmit === 'leadsDataresponse') {
                this.temp = [... this.leadsService.leadsDataresponse];
                this.rows = this.leadsService.leadsDataresponse;
            }

        });
        this.leadsDataLoad();

        window.scroll(0, 0);
    }

    leadsDataLoad() {
        if (this.leadsService.leadsDataresponse.length == 0) {
            this.leadsService.leadsDataLoad();
        } else {
            this.temp = [... this.leadsService.leadsDataresponse];
            this.rows = this.leadsService.leadsDataresponse;
        }
    }


    getRowClass(row): boolean {
        row.rowIndex = this.rowIndex;
        // row.checked = false;
        return true;
    }

    setPage(pageInfo): void {
    }

    ngAfterViewInit(): void {
        // setTimeout(() => {
        //   this.expandAll();
        // });
    }


    onActivate(event): void {
        console.log(`activated :${event}`);
    }


    openDialog() {
        const dialogRef = this.dialog.open(LeaddilogComponent);

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    removerendData() {
        this.isrentaldata = true;
    }


    buttonInRowClick(event: any): void {
        event.stopPropagation();
        console.log('Button in the row clicked.');
    }

    wholeRowClick(): void {
        console.log('Whole row clicked.');
    }

    nextButtonClickEvent(): void {
        //do next particular records like  101 - 200 rows.
        //we are calling to api

        console.log('next clicked')
    }
    previousButtonClickEvent(): void {
        //do previous particular the records like  0 - 100 rows.
        //we are calling to API
    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.rows = temp;
        this.table.offset = 0;
    }

}

