<div class="relative">
    <div style="display: flex; display: flex;
    position: absolute; top: 30px; right: 23px;  background: white; border-radius: 8px; z-index: 1">
        <button class="iconbtn" (click)="saveEditedBackground()">
            <mat-icon>save</mat-icon>
        </button>

        <button class="iconbtn" (click)="deleteEditedBackground()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<!-- <div class=" backgroudphoto " style="display: flex; justify-content: center; text-align: center; background-color: transparent;     margin-top: 46px;">
    <div style="overflow-x: scroll;overflow-y: hidden;">
        <div style="background: transparent; width: 1064px; height: 315px; background-repeat: no-repeat !important; background-size: cover !important;"
            [style.background-image]="profileImage">

            <ly-img-cropper (ready)="ready = true" [config]="myConfig" (cleaned)="ready = false" [withClass]="classes.cropper" (cropped)="onCropped($event)"
                style="width: 1064;  height: 315px;   color: #6C63FF; 
                            font-family: 'Poppins Medium'; font-size: 23px; cursor: pointer;  background: transparent !important; ">
                <span style="cursor: pointer !important;
                            border: 1px solid;
                            padding: 10px;
                            border-radius: 11px;">Select Profile Photo</span>
            </ly-img-cropper>

            <div style="display: none">
                <img *ngIf="cropper.isCropped" class=" logouni" [src]="croppedImage">
            </div>

        </div>

        <div *ngIf="cropper.isLoaded" style="margin-top: -57px; ">
            <button class="ly-btn1" (click)="cropper.zoomIn()" ly-button appearance="icon">
                <ly-icon>zoom_in</ly-icon>
            </button>
            <button class="ly-btn1" (click)="cropper.zoomOut()" ly-button appearance="icon">
                <ly-icon>zoom_out</ly-icon>
            </button>
            <button class="ly-btn1" (click)="cropper.center()" ly-button appearance="icon">
                <ly-icon>filter_center_focus</ly-icon>
            </button>
            <button class="ly-btn1" (click)="cropper.rotate(-90)" ly-button appearance="icon">
                <ly-icon>rotate_90_degrees_ccw</ly-icon>
            </button>
            <button class="ly-btn1" (click)="cropper.fit()" style=" font-family: 'Poppins Medium';" ly-button>Fit</button>
            <button class="ly-btn1" (click)="cropper.fitToScreen()" style=" font-family: 'Poppins Medium';" ly-button>Fit to screen</button>
            <button class="ly-btn1" (click)="cropper.setScale(1)" style=" font-family: 'Poppins Medium';" ly-button>1:1</button>
            <button class="ly-btn1" (click)="cropper.clean()" style=" font-family: 'Poppins Medium';" ly-button>Clean</button>
        </div>
    </div>
</div> -->

<div class=" backgroudphoto " style="display: flex; justify-content: center; text-align: center; background-color: transparent;">
    <div style="overflow-x: scroll;overflow-y: hidden;">
        <div style="background: transparent;width: 1064px;height: 315px; background-repeat: no-repeat !important; background-size: cover !important; cursor: pointer !important;" [style.background-image]="profileImage">

            <ly-img-cropper [withClass]="classes.cropper" [config]="myConfig" (cropped)="onCropped($event)" (ready)="ready = true" (cleaned)="ready = false" style="width: 1064;  height: 315px;   color: #6C63FF; 
                font-family: 'Poppins Medium'; font-size: 23px; cursor: pointer; background: transparent !important;">
                <span style="cursor: pointer !important;
                border: 1px solid;
                padding: 10px;
                border-radius: 11px;">Select Profile Photo</span>
            </ly-img-cropper>

            <div style="display: none">
                <img *ngIf="cropper.isCropped" class=" logouni" [src]="croppedImage">
            </div>

        </div>

        <div *ngIf="cropper.isLoaded" style="margin-top: -57px; ">
            <button class="ly-btn1" (click)="cropper.zoomIn()" ly-button appearance="icon">
                <ly-icon>zoom_in</ly-icon>
            </button>
            <button class="ly-btn1" (click)="cropper.zoomOut()" ly-button appearance="icon">
                <ly-icon>zoom_out</ly-icon>
            </button>
            <button class="ly-btn1" (click)="cropper.center()" ly-button appearance="icon">
                <ly-icon>filter_center_focus</ly-icon>
            </button>
            <button class="ly-btn1" (click)="cropper.rotate(-90)" ly-button appearance="icon">
                <ly-icon>rotate_90_degrees_ccw</ly-icon>
            </button>
            <button class="ly-btn1" (click)="cropper.fit()" style=" font-family: 'Poppins Medium'; top:-7px; position: relative;" ly-button>Fit</button>
            <button class="ly-btn1" (click)="cropper.fitToScreen()" style=" font-family: 'Poppins Medium'; top:-7px; position: relative;" ly-button>Fit to screen</button>
            <!-- <button class="ly-btn1" (click)="cropper.setScale(1)" style=" font-family: 'Poppins Medium'; top:-7px; position: relative;" ly-button>1:1</button> -->
            <button class="ly-btn1" (click)="cropper.clean()" style=" font-family: 'Poppins Medium'; top:-7px; position: relative;" ly-button>Clean</button>
        </div>
    </div>
</div>