<div class="content-wrapper">
  <div class="border_shadow" style="margin: 100px 60px 60px 60px">
    <div style="width: 100%; text-align: center; margin-bottom: 2rem">
      <h1 class="poppins-medium">Add Sports Club</h1>
    </div>
    <section
      class="add_top_20 backgroudphoto"
      style="
        display: flex;
        justify-content: center;
        text-align: center;
        height: 315px;
        background-color: transparent;
      "
    >
      <div style="overflow-x: scroll; overflow-y: hidden">
        <div
          style="
            background: transparent;
            width: 1064px;
            height: 315px;
            cursor: pointer !important;
          "
        >
          <ly-img-cropper
            [withClass]="classes.cropper"
            [config]="myConfig"
            (cropped)="onCropped($event)"
            (ready)="ready = true"
            (cleaned)="ready = false"
            style="
              width: 1064;
              height: 315px;
              color: #6c63ff;
              font-family: 'Poppins Medium';
              font-size: 23px;
              cursor: pointer;
            "
          >
            <span
              style="
                cursor: pointer !important;
                border: 1px solid;
                padding: 10px;
                border-radius: 11px;
              "
              >Select Profile Photo</span
            >
          </ly-img-cropper>

          <!-- <div style="display: flex; justify-content: flex-end; align-items: flex-end; margin-top: 1rem; ">
                        <button *ngIf="cropper.isLoaded" color="accent" (click)="cropper.crop()" class="doneProfile" ly-button>
                            <ly-icon>crop</ly-icon>SAVE
                        </button>
                    </div> -->
          <div style="display: none">
            <img
              *ngIf="cropper.isCropped"
              class="logouni"
              [src]="croppedImage"
            />
          </div>
        </div>

        <div *ngIf="cropper.isLoaded" style="margin-top: -72px">
          <button
            class="ly-btn1"
            (click)="cropper.zoomIn()"
            ly-button
            appearance="icon"
          >
            <ly-icon>zoom_in</ly-icon>
          </button>
          <button
            class="ly-btn1"
            (click)="cropper.zoomOut()"
            ly-button
            appearance="icon"
          >
            <ly-icon>zoom_out</ly-icon>
          </button>
          <button
            class="ly-btn1"
            (click)="cropper.center()"
            ly-button
            appearance="icon"
          >
            <ly-icon>filter_center_focus</ly-icon>
          </button>
          <button
            class="ly-btn1"
            (click)="cropper.rotate(-90)"
            ly-button
            appearance="icon"
          >
            <ly-icon>rotate_90_degrees_ccw</ly-icon>
          </button>
          <button
            class="ly-btn1"
            (click)="cropper.fit()"
            style="font-family: 'Poppins Medium'; top: -7px; position: relative"
            ly-button
          >
            Fit
          </button>
          <button
            class="ly-btn1"
            (click)="cropper.fitToScreen()"
            style="font-family: 'Poppins Medium'; top: -7px; position: relative"
            ly-button
          >
            Fit to screen
          </button>
          <!-- <button class="ly-btn1" (click)="cropper.setScale(1)" style=" font-family: 'Poppins Medium'; top:-7px; position: relative;" ly-button>1:1</button> -->
          <button
            class="ly-btn1"
            (click)="cropper.clean()"
            style="font-family: 'Poppins Medium'; top: -7px; position: relative"
            ly-button
          >
            Clean
          </button>
        </div>
      </div>
    </section>

    <div class="paddindDIV">
      <section class="add_top_20 seperate-data">
        <div style="display: flex">
          <button
            type="button"
            class="logouni"
            style="background: url({{ this.addClublogopic }})"
          >
            <label
              for="upload-logo"
              class="upload_btn"
              style="margin-bottom: 0px; margin-left: -7px; margin-top: 5px"
            >
              <mat-icon
                class="iconbtn"
                style="font-size: 29px !important; color: rgb(0 0 0 / 66%)"
                >camera_alt</mat-icon
              >
            </label>
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              name="photo"
              id="upload-logo"
              (change)="selectLogo($event)"
              accept="image/*"
              style="display: none"
            />
          </button>
          <div style="margin-left: 2rem; width: 100%">
            <h2 class="poppins-medium" style="font-size: 32px !important">
              <div class="textlabel">Club Name*</div>
              <input
                class="universitydatainput"
                placeholder="Club Name "
                [(ngModel)]="universityService.universitySportClub.clubName"
              />
            </h2>
          </div>
        </div>

        <div class="row">
          <!-- <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="margin-top: 10px;">
                        <select class="universitydatainput" [(ngModel)]="universityService.universitySportClub.sport_name">
                            <option value="" disabled selected class="poppinsemibold">Select Sport
                            </option>
                            <option *ngFor="let sport of sportService.listOfSports; index as i;" [value]="sport.id" class="poppinsemibold">{{sport.name}}</option>
                        </select>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="margin-top: 10px;">
                        <select class="universitydatainput" [(ngModel)]="universityService.universitySportClub.nationality">
                            <option value="" disabled selected class="poppinsemibold">Select Nationality
                            </option>
                            <option *ngFor="let contry of universitydetailsService.listOfContrys; index as i;" [value]="contry.id" class="poppinsemibold">{{contry.name}}</option>
                        </select>
                    </div> -->
          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Sport*</div>
            <ng-select
              [items]="sportService.listOfSports"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="universityService.universitySportClub.sport_name"
              placeholder="Select Sport"
            >
            </ng-select>
          </div>

          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Email</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder="Email"
                [(ngModel)]="universityService.universitySportClub.email"
                style="padding-left: 1.3rem"
              />
              <mat-icon>email</mat-icon>
            </div>
          </div>
          <!-- <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="margin-top: 10px;">
                            <div class="textlabel">Nationality*</div>
                        <ng-select [items]="universitydetailsService.listOfContrys" bindLabel="nationality" bindValue="id" [(ngModel)]="universityService.universitySportClub.nationality"
                            placeholder="Select Nationality">
                        </ng-select>

                    </div> -->

          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Club URL</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder="Club URL"
                [(ngModel)]="universityService.universitySportClub.web_url"
                style="padding-left: 1.3rem"
              />
              <mat-icon>link</mat-icon>
            </div>
          </div>

          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Video URL</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder="Video URL"
                [(ngModel)]="universityService.universitySportClub.video"
                style="padding-left: 1.3rem"
              />
              <i class="fa fa-youtube-play" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <!-- <div class="textDesc add_top_20">
                    <textarea class="universitydatainput" placeholder=" Description" [(ngModel)]="universityService.universitySportClub.description"></textarea>
                </div> -->
      </section>

      <section class="add_top_20 seperate-data">
        <div class="poppins-medium lbl2">Social Links</div>

        <div class="row">
          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Facebook</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder=" Facebook"
                [(ngModel)]="universityService.universitySportClub.facebook"
                style="padding-left: 1.3rem"
              />
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </div>
          </div>
          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Twitter</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder=" Twitter"
                [(ngModel)]="universityService.universitySportClub.twitter"
                style="padding-left: 1.3rem"
              />
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </div>
          </div>

          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Instagram</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder=" Instagram"
                [(ngModel)]="universityService.universitySportClub.instagram"
                style="padding-left: 1.3rem"
              />
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </div>
          </div>

          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <label class="textlabel"> Is this a performance sport? </label>
            <div id="inputWithIcon">
              <mat-slide-toggle
                color="primary"
                [(ngModel)]="
                  universityService.universitySportClub.is_performance_sport
                "
              >
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </section>

      <!-- Overview -->
      <section class="add_top_20 seperate-data">
        <div class="poppins-medium lbl2">Overview</div>

        <div class="textDesc add_top_20">
          <div class="textlabel" style="margin-bottom: 8px">Description</div>
          <angular-editor
            [(ngModel)]="universityService.universitySportClub.description"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
          >
          </angular-editor>

          <!-- <textarea class="universitydatainput" placeholder=" Description" [(ngModel)]="universityService.universitySportClub.description"></textarea> -->
        </div>
      </section>

      <!-- Basic Information -->
      <section class="add_top_20 seperate-data">
        <div class="poppins-medium lbl2">Basic Information</div>

        <div class="textDesc add_top_20">
          <div class="textlabel" style="margin-bottom: 8px">Description</div>
          <angular-editor
            [(ngModel)]="universityService.universitySportClub.basicInformation"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
          >
          </angular-editor>

          <!-- <textarea class="universitydatainput" placeholder=" Description" [(ngModel)]="universityService.universitySportClub.basicInformation"></textarea> -->
        </div>
      </section>

      <!-- Coaching Team  -->
      <section class="add_top_20 seperate-data">
        <div class="poppins-medium lbl2">Coaching Team</div>

        <div class="textDesc add_top_20">
          <div class="row">
            <div
              class="col-xl-2 col-md-2 col-sm-2 col-12"
              style="margin-top: 10px"
            >
              <button
                type="button"
                class="logouni"
                style="background: url({{ this.addcoachPic }})"
              >
                <label
                  for="upload-coach"
                  class="upload_btn"
                  style="margin-bottom: 0px; margin-left: -7px; margin-top: 5px"
                >
                  <mat-icon
                    class="iconbtn"
                    style="font-size: 29px !important; color: rgb(0 0 0 / 66%)"
                    >camera_alt</mat-icon
                  >
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  name="photo"
                  id="upload-coach"
                  (change)="selectcoach($event)"
                  accept="image/*"
                  style="display: none"
                />
              </button>
            </div>
            <div
              class="col-xl-10 col-md-10 col-sm-10 col-12"
              style="margin-top: 10px"
            >
              <div class="textlabel">Coach Name</div>
              <input
                class="universitydatainput"
                placeholder=" Coach Name"
                [(ngModel)]="universityService.universitySportClub.coach_name"
              />

              <div class="textlabel">Coach Type</div>
              <input
                class="universitydatainput"
                placeholder="  Coach Type"
                [(ngModel)]="universityService.universitySportClub.coach_type"
              />
            </div>
          </div>

          <div class="textlabel" style="margin-bottom: 8px">Description</div>
          <angular-editor
            [(ngModel)]="universityService.universitySportClub.coachingTeam"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
          >
          </angular-editor>
          <!-- <textarea class="universitydatainput" placeholder="Description" [(ngModel)]="universityService.universitySportClub.coachingTeam"></textarea>-->
        </div>
      </section>

      <!-- Training Times -->
      <section class="add_top_20 seperate-data">
        <div class="poppins-medium lbl2">Training Times</div>

        <div class="textDesc add_top_20">
          <div class="textlabel" style="margin-bottom: 8px">Description</div>
          <angular-editor
            [(ngModel)]="universityService.universitySportClub.trainingTimes"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
          >
          </angular-editor>

          <!-- <textarea class="universitydatainput" placeholder=" Description" [(ngModel)]="universityService.universitySportClub.trainingTimes"></textarea> -->
        </div>
      </section>

      <!-- Performance Overview -->
      <section class="add_top_20 seperate-data">
        <div class="poppins-medium lbl2">Performance Overview</div>

        <div class="textDesc add_top_20">
          <div class="textlabel" style="margin-bottom: 8px">Description</div>
          <angular-editor
            [(ngModel)]="
              universityService.universitySportClub.performanceOverview
            "
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
          >
          </angular-editor>

          <!-- <textarea class="universitydatainput" placeholder=" Performance Overview" [(ngModel)]="universityService.universitySportClub.performanceOverview"></textarea> -->
        </div>
      </section>

      <!--Notable Alumni-->
      <section class="seperate-data">
        <div class="">
          <div class="poppins-medium lbl2">Notable Alumni</div>

          <div class="add_top_20">
            <div class="row">
              <div
                class="col-xl-2 col-md-2 col-sm-2 col-12"
                style="margin-top: 10px"
              >
                <button
                  type="button"
                  class="logouni"
                  style="background: url({{ this.addNotable_AluminiPic }})"
                >
                  <label
                    for="upload-notable"
                    class="upload_btn"
                    style="
                      margin-bottom: 0px;
                      margin-left: -7px;
                      margin-top: 5px;
                    "
                  >
                    <mat-icon
                      class="iconbtn"
                      style="
                        font-size: 29px !important;
                        color: rgb(0 0 0 / 66%);
                      "
                      >camera_alt</mat-icon
                    >
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    name="photo"
                    id="upload-notable"
                    (change)="selectNotable($event)"
                    accept="image/*"
                    style="display: none"
                  />
                </button>
              </div>
              <div
                class="col-xl-5 col-md-5 col-sm-5 col-12"
                style="margin-top: 10px"
              >
                <div class="textlabel">First Name</div>
                <input
                  class="universitydatainput"
                  placeholder=" First Name"
                  [(ngModel)]="
                    universityService.universitySportClub.Notable_fname
                  "
                />
              </div>
              <div
                class="col-xl-5 col-md-5 col-sm-5 col-12"
                style="margin-top: 10px"
              >
                <div class="textlabel">Last Name</div>
                <input
                  class="universitydatainput"
                  placeholder=" Last Name"
                  [(ngModel)]="
                    universityService.universitySportClub.Notable_lname
                  "
                />
              </div>
            </div>

            <div class="row">
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12"
                style="margin-top: 10px"
              >
                <ng-select
                  [items]="universityService.courseData"
                  [virtualScroll]="true"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="
                    universityService.universitySportClub.Notable_courseid
                  "
                  placeholder="Select Course"
                  loadingText="Loading..."
                >
                </ng-select>
              </div>
              <!-- <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="margin-top: 10px;">
                                <ng-select [items]="yeardllService.nbgAllYears" [virtualScroll]="true" placeholder="Select Year" [(ngModel)]="universityService.universitySportClub.Notable_year">
                                </ng-select>
                            </div> -->
            </div>

            <!-- <div class="textlabel">LinkedIn</div>
                        <div id="inputWithIcon">
                            <input class="universitydatainput" placeholder=" LinkedIn " [(ngModel)]="universityService.universitySportClub.Notable_linkdin" style="margin-top: 10px; padding-left:  1.3rem;">
                            <i class="fa fa-linkedin-square" aria-hidden="true" style="
                            top: 8px !important;"></i>
                        </div> -->

            <div class="textlabel">Achievement</div>
            <input
              class="universitydatainput"
              placeholder="Achievement"
              [(ngModel)]="
                universityService.universitySportClub.Notable_description
              "
              style="margin-top: 10px"
            />
          </div>
        </div>
      </section>

      <!-- Notice Board -->
      <section class="add_top_20 seperate-data">
        <div class="">
          <div class="poppins-medium lbl2">Notice Board</div>

          <div class="textDesc add_top_20">
            <div class="textlabel">Notice Title</div>
            <input
              class="universitydatainput"
              placeholder=" Notice Title"
              [(ngModel)]="universityService.universitySportClub.notice_title"
            />

            <div class="textlabel" style="margin-bottom: 8px">Description</div>
            <angular-editor
              [(ngModel)]="universityService.universitySportClub.notice_board"
              [config]="config"
              #ctrl="ngModel"
              class="editDescription"
            >
            </angular-editor>

            <!-- <textarea class="universitydatainput" placeholder="Notice Board" [(ngModel)]="universityService.universitySportClub.notice_board"></textarea> -->
          </div>
        </div>
      </section>

      <!-- Club Commitee -->
      <section class="seperate-data">
        <div class="">
          <div class="poppins-medium lbl2">Club Commitee</div>

          <div class="add_top_20">
            <div class="row">
              <div
                class="col-xl-2 col-md-2 col-sm-2 col-12"
                style="margin-top: 10px"
              >
                <button
                  type="button"
                  class="logouni"
                  style="background: url({{ this.addCommities_imagePic }})"
                >
                  <label
                    for="upload-Club"
                    class="upload_btn"
                    style="
                      margin-bottom: 0px;
                      margin-left: -7px;
                      margin-top: 5px;
                    "
                  >
                    <mat-icon
                      class="iconbtn"
                      style="
                        font-size: 29px !important;
                        color: rgb(0 0 0 / 66%);
                      "
                      >camera_alt</mat-icon
                    >
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    name="photo"
                    id="upload-Club"
                    (change)="selectClub($event)"
                    accept="image/*"
                    style="display: none"
                  />
                </button>
              </div>
              <div
                class="col-xl-5 col-md-5 col-sm-5 col-12"
                style="margin-top: 10px"
              >
                <div class="textlabel">First Name</div>
                <input
                  class="universitydatainput"
                  placeholder=" First Name"
                  [(ngModel)]="
                    universityService.universitySportClub.commities_fname
                  "
                />
              </div>
              <div
                class="col-xl-5 col-md-5 col-sm-5 col-12"
                style="margin-top: 10px"
              >
                <div class="textlabel">Last Name</div>
                <input
                  class="universitydatainput"
                  placeholder=" Last Name"
                  [(ngModel)]="
                    universityService.universitySportClub.commities_lname
                  "
                />
              </div>
            </div>

            <div class="textlabel">Email</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder=" Email"
                [(ngModel)]="
                  universityService.universitySportClub.commities_email
                "
                style="margin-top: 10px; padding-left: 1.3rem"
              />
              <mat-icon style="top: 8px !important">email</mat-icon>
            </div>

            <div class="textlabel" style="margin-bottom: 8px">Description</div>
            <angular-editor
              [(ngModel)]="
                universityService.universitySportClub.commities_description
              "
              [config]="config"
              #ctrl="ngModel"
              class="editDescription"
            >
            </angular-editor>

            <!-- <textarea class="universitydatainput" placeholder="Description" [(ngModel)]="universityService.universitySportClub.commities_description"
                            style="margin-top: 10px;"></textarea> -->
          </div>
        </div>
      </section>

      <!-- Recommendations -->
      <!-- <section class="seperate-data">
                <div class="">

                    <div class="poppins-medium lbl2">Recommendations</div>

                    <div class="add_top_20">
                        <div class="row ">
                            <div class="col-xl-2 col-md-2 col-sm-2 col-12" style="margin-top: 10px;">
                                <button type="button" class=" logouni" style="background: url({{this.addRecommendation_imagePic}})">
                                    <label for="upload-recommendation" class="upload_btn" style="margin-bottom: 0px;
                                                    margin-left: -7px; margin-top: 5px;">
                                        <mat-icon class="iconbtn" style="font-size: 29px !important;  color: rgb(0 0 0 / 66%);">camera_alt</mat-icon>
                                    </label>
                                    <input type="file"  accept="image/png, image/jpg, image/jpeg"name="photo" id="upload-recommendation" (change)="selectRecommendations($event)" accept="image/*" style="display: none;">
                                </button>
                            </div>
                            <div class="col-xl-10 col-md-10 col-sm-10 col-12" style="margin-top: 10px;">
                                <input class="universitydatainput" placeholder=" Name" [(ngModel)]="universityService.universitySportClub.recommendation_name">
                            </div>
                        </div>


                        <input class="universitydatainput" placeholder=" Title" [(ngModel)]="universityService.universitySportClub.recommendation_title ">

                        <textarea class="universitydatainput" placeholder="Description" [(ngModel)]="universityService.universitySportClub.recommendation_description"></textarea>
                    </div>
                </div>
            </section> -->

      <!-- Membership Price -->
      <section class="add_top_20 seperate-data">
        <div class="poppins-medium lbl2">Membership Price</div>

        <div class="textDesc add_top_20">
          <div class="textlabel">Membership Price</div>
          <input
            class="universitydatainput"
            placeholder=" Membership Price"
            [(ngModel)]="universityService.universitySportClub.membershipPrice"
          />
        </div>
      </section>

      <!-- Gallery -->
      <section class="seperate-data">
        <div class="marginbottom">
          <div class="poppins-medium lbl2">Gallery</div>
          <div class="textDesc add_top_20">
            <button type="button " class="gallery">
              <label
                for="upload-photo"
                class="upload_btn"
                style="margin-top: 5px"
                >Add Image
              </label>
              <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                name="photo"
                id="upload-photo"
                multiple
                (change)="select_Gallery($event)"
                style="display: none"
              />
            </button>
          </div>
          <div class="row">
            <div
              class="col-xl-3 col-md-3 col-sm-3 col-12"
              style="margin-top: 1rem"
              *ngFor="let gallery of this.Clubgallery; index as i"
            >
              <img
                style="width: 6rem; height: 6rem"
                class="Galleryimg"
                [src]="gallery"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- Sportfolio News -->
      <!-- <section class="seperate-data">
                <div class="">

                    <div class="poppins-medium lbl2">Sportfolio News</div>

                    <div class="add_top_20">
                        <textarea class="universitydatainput" placeholder="Description" [(ngModel)]="universityService.universitySportClub.news_description"></textarea>
                    </div>
                </div>
            </section> -->

      <!-- faq -->
      <section class="add_top_20 seperate-data">
        <div class="poppins-medium lbl2">FAQ's</div>

        <div class="textDesc add_top_20">
          <div class="textlabel">Question</div>
          <input
            class="universitydatainput"
            placeholder=" Question"
            [(ngModel)]="universityService.universitySportClub.faqTitle"
          />

          <div class="textlabel" style="margin-bottom: 8px">Description</div>
          <angular-editor
            [(ngModel)]="universityService.universitySportClub.faqdescription"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
          >
          </angular-editor>

          <!-- <textarea class="universitydatainput" placeholder=" Description" [(ngModel)]="universityService.universitySportClub.faqdescription"></textarea> -->
        </div>
      </section>

      <div
        style="
          margin-bottom: 6px;
          display: flex;
          justify-content: flex-end;
          align-items: end;
        "
      >
        <button
          class="btn btn-success btn-width"
          mat-button
          mat-dialog-close
          style="
            background: #6c63ff;
            font-family: 'Poppins SemiBold';
            color: white;
          "
          (click)="saveAddedSportData()"
        >
          SAVE
        </button>

        <button
          class="btn btn-danger btn-width"
          mat-button
          mat-dialog-close
          style="
            background: #ffc107;
            font-family: 'Poppins SemiBold';
            margin-left: 8px;
            color: black;
          "
          (click)="deleteAddedsport()"
        >
          CANCEL
        </button>
      </div>
    </div>
  </div>
</div>
