<nav id="menu" class="fake_menu"></nav>

<div id="preloader">
    <div data-loader="circle-side"></div>
</div>
<!-- End Preload -->

<div id="login">

    <div class="flexBlock">
        <div class="width1">
            <!-- <aside> -->
            <figure>

                <a>
                    <img src="assets/img/SportfolioLoginlogo.svg" width="149" height="42" alt="">
                </a>
            </figure>

            <form [formGroup]="authForm" (ngSubmit)="signIn()">
                <div class="form-group" style="margin-top: 4rem;" *ngIf="!forgetpasswordData">
                    <input type="email" id="loginEmail" name="email" class="poppins-medium style-input fontBlack required" placeholder="Email"
                        formControlName="email">
                </div>
                <div class="form-group" *ngIf="!forgetpasswordData">
                    <div class="row  linepassword">
                        <div class="col-lg-11 co-sm-11 col-md-11 col-11" style="padding: 0px;">

                            <input *ngIf="!showPassword" id="loginPassword" type="password" name="password" class="poppins-medium fontBlack inputpassword required"
                                placeholder="Password" formControlName="password">

                            <input *ngIf="showPassword" id="loginPassword" type="text" name="password" class="poppins-medium fontBlack inputpassword  required"
                                placeholder="Password" formControlName="password">

                        </div>
                        <div class="col-lg-1 co-sm-1 col-md-1 col-1  " style=" padding: 0; ">
                            <div *ngIf="showPassword">
                                <i class="fa fa-eye fontBlack" (click)="showPassword = false "></i>
                            </div>
                            <div *ngIf="!showPassword">
                                <i class="fa fa-eye-slash fontBlack" (click)="showPassword = true "></i>
                            </div>
                        </div>

                    </div>

                </div>

                <small *ngIf="!forgetpasswordData">
                    <a (click)="!forgetpasswordData = true" style="cursor: pointer">
                        <span class="textb">Forgot password?</span>
                    </a>
                </small>

                <!-- <a class="btn_1 rounded full-width add_top_60" style="margin-top: 2rem;">Log In</a> -->

                <button type="submit" *ngIf="!forgetpasswordData" class="btn_1 rounded full-width add_top_60" style="   margin-top: 2rem;">
                    Submit</button>
                <!-- <div *ngIf="!forgetpasswordData" class="text-center add_top_10" style="
                margin-top: 1rem;">
                    <span class="lbl poppins-medium">Don't have an account?</span>
                    <a routerLink="/signup1">
                        <span class="textb poppins-medium"> Sign up!</span>
                    </a>
                </div> -->
            </form>

            <div *ngIf="forgetpasswordData">

                <small style="margin-top:2rem;">
                    <a>
                        <span class="textb">Forgot password?</span>
                    </a>
                </small>

                <input class="universitydatainput fontBlack" id="sendOtpEmail" placeholder="Email" [(ngModel)]="this.forgetUseEmail " style="margin-top:3rem;">

                <button type="submit" class="btn_1 rounded full-width add_top_60" (click)="forgetPassword();" style="   margin-top: 2rem;">
                    Send OTP</button>
            </div>

            <!-- <div class="divider"><span class="lineOr poppinsregular">Or</span></div>

            <ul class="social_teacher">
                <li><a href="#"><i style="font-size:20px; color: #1976D2;" class="fa">&#xf09a;</i></a></li>
                <li><a href="#"><img src="../assets/img/g.png" style="width: 20px; height: 20px;"></a></li>
                <li><a href="#"><i style="font-size:22px; color: #1976D2;" class="fa">&#xf0e1;</i></a></li>
            </ul>

            <div class="divider"><span class="lineOr poppinsregular">Or</span></div>

            <div style="display: flex; justify-content: center;align-items: center; margin-top: 2rem;">
                <a href="#" class="textb poppins-medium">Login as University!</a>
            </div> -->
            <!-- </aside> -->
        </div>
        <app-login-slider></app-login-slider>
    </div>


</div>
<!-- /login -->

<!-- COMMON SCRIPTS -->
<script src="js/jquery-3.5.1.min.js"></script>
<script src="js/common_scripts.js"></script>
<script src="js/main.js"></script>
<script src="assets/validate.js"></script>