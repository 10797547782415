<div id="login">

    <div class="flexBlock">
        <div class="width1">
            <!-- <aside> -->
            <figure>
                <span>
                    <i class="fa fa-arrow-left backArrow" aria-hidden="true" (click)="gotoBack()"></i>
                </span>
                <a (click)="gotoHome()" routerLink="/login" [routerLinkActive]="['activeheader']">
                    <img src="assets/img/SportfolioLoginlogo.svg" width="149" height="42" alt="">
                </a>
            </figure>

            <div class="step-text1 poppins-medium" style="margin-top: 2.5rem;">Step 5 of 5</div>
            <div class="step-text2 poppinsregular">Tell us a bit about Rating</div>


            <div class="fontHadColor poppinsregular" style="margin-top: 2.5rem; margin-bottom: 8px;">Bucs Ranking</div>
            <input type="number" id="bucs_overall_rating" name="bucs_overall_rating" class="form-control fontBlack  style-input required" placeholder="Average Rating"
                style="margin-top: 2.5rem;" [(ngModel)]="authService.registerAccountDetails.bucs_overall_rating" />

            <div class="fontHadColor poppinsregular" style="margin-top: 2.5rem; margin-bottom: 8px;">University Ranking</div>
            <input type="number" id="average_rating" name="average_rating" class="form-control fontBlack  style-input required" placeholder="Average Rating"
                style="margin-top: 2.5rem;" [(ngModel)]="authService.registerAccountDetails.average_rating" />

            <!-- <div class="fontHadColor poppinsregular" style="margin-top: 2.5rem; margin-bottom: 8px;">Average Rating</div>
            <ngb-rating [(rate)]="authService.registerAccountDetails.average_rating" [max]="5">
                <ng-template let-fill="fill">
                    <span class="star" [ngClass]="'color'+reviewRating" [class.filled]="fill === 100">&#9733;</span>
                </ng-template>
            </ngb-rating> -->

            <!-- <input type="number" id="average_rating" name="average_rating" class="form-control fontBlack  style-input required" placeholder="Average Rating"
                style="margin-top: 2.5rem;" [(ngModel)]="authService.registerAccountDetails.average_rating" /> -->
            <!-- <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Bucs Men Rating</div>
            <ngb-rating [(rate)]="authService.registerAccountDetails.bucs_men_rating" [max]="5">
                <ng-template let-fill="fill">
                    <span class="star" [ngClass]="'color'+reviewRating" [class.filled]="fill === 100">&#9733;</span>
                </ng-template>
            </ngb-rating> -->
            <!-- <input type="number" id="bucs_men_rating" name="bucs_men_rating" class="form-control fontBlack  style-input required" placeholder="Bucs Men Rating"
                style="margin-top: 1.5rem;" [(ngModel)]="authService.registerAccountDetails.bucs_men_rating" /> -->

            <!-- <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Bucs Women Rating</div>
            <ngb-rating [(rate)]="authService.registerAccountDetails.bucs_women_rating" [max]="5">
                <ng-template let-fill="fill">
                    <span class="star" [ngClass]="'color'+reviewRating" [class.filled]="fill === 100">&#9733;</span>
                </ng-template>
            </ngb-rating> -->
            <!-- <input type="number" id="bucs_women_rating" name="bucs_women_rating" class="form-control  fontBlack style-input required"
                placeholder="Bucs Women Rating" style="margin-top: 1.5rem;" [(ngModel)]="authService.registerAccountDetails.bucs_women_rating"
            /> -->

            <!-- <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Bucs Overall Rating</div>
            <ngb-rating [(rate)]="authService.registerAccountDetails.bucs_overall_rating" [max]="5">
                <ng-template let-fill="fill">
                    <span class="star" [ngClass]="'color'+reviewRating" [class.filled]="fill === 100">&#9733;</span>
                </ng-template>
            </ngb-rating> -->

            <!-- <input type="number" id="bucs_overall_rating" name="bucs_overall_rating" class="form-control  fontBlack style-input required"
                placeholder="Bucs Overall Rating" style="margin-top: 1.5rem;" [(ngModel)]="authService.registerAccountDetails.bucs_overall_rating"
            /> -->





            <button type="submit" class="btn_1 rounded full-width add_top_60" style=" margin-bottom: 3rem;  margin-top: 3rem;" (click)="onSubmit()">Create Account</button>



        </div>
        <app-login-slider></app-login-slider>
    </div>


</div>