<mat-dialog-content>

    <div class="row topd">
        <div class="col-xl-6 col-md-6 col-sm-6 col-12">
            <div>
                <label>Date</label>
            </div>
            <div>
                <!-- <mat-form-field >
                <input matInput [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field> -->
            </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-6 col-12">
            <div><label>Frist Name</label></div>
            <input type="text" class="inputdata" placeholder="Frist Name" [(ngModel)]="leadsService.leadsData.frist_name" >
        </div>
    </div>
    <div class="row topd">
        <div class="col-xl-6 col-md-6 col-sm-6 col-12">
            <div><label> Last Name</label></div>
            <input type="text" class="inputdata" placeholder="Last Name" [(ngModel)]="leadsService.leadsData.last_name">
        </div>
        <div class="col-xl-6 col-md-6 col-sm-6 col-12">
            <div><label> Gender</label></div>
            <input type="text" class="inputdata" placeholder="Gender" [(ngModel)]="leadsService.leadsData.gender">
        </div>
    </div>
    <div class="row topd">
        <div class="col-xl-6 col-md-6 col-sm-6 col-12">
            <div><label>Sport</label></div>
            <input type="text" class="inputdata" placeholder="Sport" [(ngModel)]="leadsService.leadsData.sport">
        </div>
        <div class="col-xl-6 col-md-6 col-sm-6 col-12">
            <div><label>Nationality</label></div>
            <input type="text" class="inputdata" placeholder="Nationality" [(ngModel)]="leadsService.leadsData.nationality">
        </div>
    </div>
    <div class="row topd">
        <div class="col-xl-6 col-md-6 col-sm-6 col-12">
            <div><label>Profile Link</label></div>
            <input type="text" class="inputdata" placeholder="Profile Link" [(ngModel)]="leadsService.leadsData.profile_link">
        </div>
        <div class="col-xl-6 col-md-6 col-sm-6 col-12">
            <div><label>Email</label></div>
            <input type="text" class="inputdata" placeholder="Email" [(ngModel)]="leadsService.leadsData.email">
        </div>
    </div>

    <div class="row topd">
        <div class="col-xl-6 col-md-6 col-sm-6 col-12">
            <div><label>Marketing</label></div>
            <input type="text" class="inputdata" placeholder="Marketing" [(ngModel)]="leadsService.leadsData.marketing">
        </div>
        <div class="col-xl-6 col-md-6 col-sm-6 col-12">
            <div><label>Status</label></div>
            <input type="text" class="inputdata" placeholder="Status" [(ngModel)]="leadsService.leadsData.status">
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="align-items: flex-end;">
    <div style=" display: flex; margin-left: 4rem;">
        <div>
            <button mat-button [mat-dialog-close]="true" class=" btnAA " (onclick)="savaleadData()" cdkFocusInitial>save</button>
        </div>
        <div style="margin-left: 8px;">
            <button class="btnBb" mat-button mat-dialog-close>Cancel</button>
        </div>
    </div>
</mat-dialog-actions>