import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UniversitydetailsService } from '../../services/universitydetails.service';
import { Router } from '@angular/router';

import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';
import { AllsportService } from '../../services/allsport.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { FormControl } from '@angular/forms';
// import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';


@Component({
  selector: 'app-signup4',
  templateUrl: './signup4.component.html',
  styleUrls: ['./signup4.component.css']
})
export class Signup4Component implements OnInit {

  @ViewChild('d1') d1: any

  date: any;
  birthdate: NgbDateStruct | any;
  param = { limit: 10, offset: 0 };

  signUpForm: FormGroup;
  atertmessage: any = [];

  constructor(private formBuilder: FormBuilder, public sportService: AllsportService, private router: Router, public universitylist: UniversitydetailsService, public authService: AuthService, private common: CommonService, public data: DataService) {
  }

  ngOnInit(): void {

  }

  onSubmit(): void {

    // this.authService.registerUser();

    this.atertmessage = [];
    this.authService.registerAccountDetails.user_type = 'university';

    // if (this.authService.registerAccountDetails.female_students === '') {
    //   this.atertmessage.push(' Female Students');
    // }
    // if (this.authService.registerAccountDetails.male_students === '') {
    //   this.atertmessage.push(' Male Students');
    // }
    // if (this.authService.registerAccountDetails.other_students === '') {
    //   this.atertmessage.push(' Other Students');
    // }
    // if (this.authService.registerAccountDetails.total_students === '') {
    //   this.atertmessage.push(' Total Students');
    // }


    // if (this.authService.registerAccountDetails.female_students === '' || this.authService.registerAccountDetails.male_students === '' || this.authService.registerAccountDetails.other_students === '' || this.authService.registerAccountDetails.total_students === '') {

    //   var msg = '';

    //   if (this.atertmessage.length == 1) {
    //     msg = `${this.atertmessage} Required!`;
    //   } else {
    //     msg = `${this.atertmessage.join()} are Required`;

    //   }
    //   this.data.showError(msg);
    // } else {

      // this.router.navigate(['/signup5']);

    this.authService.registerUser();

    // }

  }


  onKey(e: any) {
    this.authService.registerAccountDetails.total_students = this.authService.registerAccountDetails.male_students + this.authService.registerAccountDetails.female_students + this.authService.registerAccountDetails.other_students
  }

  gotoHome() {
    // this.authService.logout();
    this.common.currentUser = '';
    this.router.navigate(['/login']);
  }
  gotoBack() {
    this.router.navigate(['/signup3']);
  }

}
