import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClubService } from '../services/club.service';
import { DataService } from '../services/data.service';
import { CommonService } from '../services/common.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { LeadsService } from '../services/leads.service';
import { InterUniverDiologComponent } from '../dialog/inter-univer-diolog/inter-univer-diolog.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-university-profiles',
  templateUrl: './university-profiles.component.html',
  styleUrls: ['./university-profiles.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UniversityProfilesComponent implements OnInit {
  @ViewChild('table') table: any;
  isrentaldata = true;

  name = 'Angular';
  editing = {};
  customers = [];
  artists = [];
  appliedArtists = [];
  selected = [];
  rows = [];
  temp = [];
  Columns = [
    { name: 'first_name' },
    { name: 'gender' },
    { name: 'email' },
    { name: 'phone' },
  ];

  data = [];
  rowIndex: any;
  quantite: any;

  data1 = [
    {
      name: 'Test 1',
      age: 13,
      average: 8.2,
      approved: true,
      description: "using 'Content here, content here' ",
    },
    {
      name: 'Test 2',
      age: 11,
      average: 8.2,
      approved: true,
      description: "using 'Content here, content here' ",
    },
    {
      name: 'Test 4',
      age: 10,
      average: 8.2,
      approved: true,
      description: "using 'Content here, content here' ",
    },
  ];

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'User Interested Universities',
    useBom: true,
    noDownload: false,
    headers: ['Name', 'Age', 'Average', 'approved', 'description'],
  };
  expoerteddata: any[];

  allsports = [];
  selectedGender = '';
  selectedSport = '';
  selectedStatus = '';

  constructor(
    public dialog: MatDialog,
    public clubService: ClubService,
    private commonservice: CommonService,
    public leadsService: LeadsService,
    public dataservices: DataService
  ) {}

  ngOnInit(): void {
    this.clubService.change.subscribe((emitObject) => {
      if (emitObject.dataToEmit === 'universitieDataresponse') {
        this.rows = this.clubService.universitieDataresponse;
        this.temp = [...this.clubService.universitieDataresponse];
        this.allsports = _.uniq(_.map(this.rows, 'users.sport.name'));
        console.log(this.allsports);
      }
    });
    this.universitiDataLoad();

    window.scroll(0, 0);
  }

  universitiDataLoad() {
    if (this.clubService.universitieDataresponse.length == 0) {
      this.clubService.universityDataLoad();
    } else {
      this.rows = this.clubService.universitieDataresponse;
      this.temp = [...this.clubService.universitieDataresponse];
      this.allsports = _.uniq(_.map(this.rows, 'users.sport.name'));
      console.log(this.allsports);
    }
    console.log('>>>>>>>>>>>>>>>>>>', this.rows);
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(ProfiledilogComponent);

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }

  userDetails(id) {
    this.dialog.open(InterUniverDiologComponent, {
      height: 'auto',
      width: '63%',
      data: { user: id },
    });
  }

  getRowClass(row): boolean {
    row.rowIndex = this.rowIndex;
    // row.checked = false;
    return true;
  }

  setPage(pageInfo): void {}

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.expandAll();
    // });
  }

  onSelect({ selected }): void {
    console.log(`selected : ${selected}`);
  }

  onActivate(event): void {
    console.log(`activated :${event}`);
  }

  buttonInRowClick(event: any): void {
    event.stopPropagation();
    console.log('Button in the row clicked.');
  }

  wholeRowClick(): void {
    console.log('Whole row clicked.');
  }

  nextButtonClickEvent(): void {
    console.log('next clicked');
  }

  previousButtonClickEvent(): void {}

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
      let str = `${d.users.first_name} ${d.users.last_name}`;
      return str.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  downloadCsv() {
    this.options.headers = [
      'ID',
      'FIRST NAME',
      'LAST NAME',
      'GENDER',
      'DATE OF BIRTH',
      'EMAIL',
      'GRADUATION STATUS',
      'MARKETING PREFERENCE',
      'PHONE',
      'PLANNED ENTRY DATE',
      'CONTRY NAME',
      'NATIONALITY',
      'SCHOOL ATTENDED',
      'SPORT',
      'SUBJECT NAME',
      'STUDY AREA',
    ];
    this.expoerteddata = [];
    // this.clubService.universitieDataresponse.forEach(university => {

    this.rows.forEach((university) => {
      let user = university.users;
      this.expoerteddata.push({
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        gender: user.gender,
        date_of_birth: user.date_of_birth,
        email: user.email,
        graduation_status: user.graduation_status,
        marketing_preference: user.marketing_preference,
        phone: user.phone,
        planned_entry_date: user.planned_entry_date,
        contry_name: user.country.name,
        contry_nationality: user.country.nationality,
        school_attended: user.school_attended,
        sport: user.sport ? user.sport.name : '',
        subject_name: user.subject_name,
        study_area: user.study_area,
      });
    });

    new ngxCsv(this.expoerteddata, 'Athletes_Student_List', this.options);
  }

  resetFilter() {
    this.selectedGender = '';
    this.selectedSport = '';
    this.selectedStatus = '';
    this.rows = this.clubService.universitieDataresponse;
    this.temp = [...this.clubService.universitieDataresponse];
  }

  selectGenderChange(e: any) {
    this.makeFilter();
  }

  selectSportChange(e: any) {
    this.makeFilter();
  }

  selectStatusChange(e: any) {
    this.makeFilter();
  }

  makeFilter() {
    this.rows = this.clubService.universitieDataresponse;
    this.temp = [...this.clubService.universitieDataresponse];
    let valueobj = {};
    // let valueobj =  { 'city': "valsad", 'active': true, 'salary': 40000 };

    if (this.selectedGender) {
      valueobj['users'] = {};
      valueobj['users']['gender'] = this.selectedGender;
    }

    if (this.selectedSport) {
      if (valueobj['users']) {
        valueobj['users']['sport'] = {};
        valueobj['users']['sport']['name'] = this.selectedSport;
      } else {
        valueobj['users'] = {};
        valueobj['users']['sport'] = {};
        valueobj['users']['sport']['name'] = this.selectedSport;
      }
    }
    if (this.selectedStatus) {
      valueobj['status'] = this.selectedStatus;
    }

    let filterdata = _.filter(this.rows, valueobj);
    this.rows = filterdata;
    this.temp = filterdata;
  }
}
