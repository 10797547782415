import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { CommonService } from './common.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UniversitydetailsService } from './universitydetails.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { NUMBER_TYPE } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root',
})
export class UniversityService {
  atertmessage: any = [];

  selectedFileName: any;

  setIndex: any;

  param = { limit: 10, offset: 0 };
  add_club_details: any;
  abcd: any;
  profileEdit: any;
  file: any;

  Clubgallery: any;
  courseData: any = [];

  addClublogo: any = [];
  addClubgallery: any = [];
  addclubProfilePhoto: any = [];

  editRecommendation_image: any;
  addRecommendation_image: any;

  editCommities_image: any;
  addCommities_image: any;

  addnotable_alumini_photo: any;
  profile_image: any;

  profile_logo: any;
  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required]),
  });

  clubDetails: any = [];
  copyClubDetails: any = [];

  edit_uni_logo: any;
  editProfile_photo: any = [];
  editClublogo: any = [];
  editClubgallery: any = [];
  editclubProfilePhoto: any = [];

  addNotableAlumini_image: any = [];
  addNotable_image: any;
  editNotable_image: any = [];

  sportDataresponse: any = [];
  coureseDataresponse: any = [];
  scholarshipDataresponse: any = [];
  UniversityNoticeBoardData: any = [];
  notableDataresponse: any = [];

  copyClubDataresponse: any = [];
  copycoureseDataresponse: any = [];
  copyscholarshipDataresponse: any = [];
  copynotableDataresponse: any = [];
  copyuniversityNoticeBoardResponse: any = [];

  copyClubNoticeBoardResponse: any = [];
  copyClubRecommendationResponse: any = [];
  copyClubCommittieResponse: any = [];
  copyClubNotableAluminiResponse: any = [];
  copyClubFaqResponse: any = [];
  copyClubCoachResponse: any = [];
  copyClubCoachDetails: any = [];

  ClubNotableAluminieDetails: any = [];
  ClubFaqDetails: any = [];
  ClubCoachDetails: any = [];
  coachaddProfile: any;
  sportClubId = true;

  addSportData = false;
  addCourseData = false;
  addScholarshipData = false;
  addNotableData = false;
  addCoaching = false;
  adduniversityNoticeBoard = false;

  replayreview = false;

  edituniversityNoticeBoard = false;
  editCoach = false;
  editlogo = false;
  editBackground = false;
  editSportData = false;
  editCourseData = false;
  editScholarshipData = false;
  editNotableData = false;

  //university Data
  edituniversity = false;

  editsportClubId = false;

  courseUploadFile = false;

  //club
  addRecommendation = false;
  addCommite = false;
  addNoticeBoard = false;
  addNotableAlumini = false;
  addFaq = false;
  addSportNews = false;

  editSportNews = false;
  editRecommendation = false;
  editCommitee = false;
  editNoticeBoard = false;
  editNotableAlumini = false;
  editFaq = false;

  addNotable_club = false;
  //course
  courseApi = 'get-all-courses';
  courseUrl = `${this.commonservice.configurl}${this.courseApi}`;

  //user
  backgrounApi = 'admin/update-banner-photo';
  backgrounUrl = `${this.commonservice.configurl}${this.backgrounApi}`;

  logoApi = 'admin/update-profile-photo';
  logoUrl = `${this.commonservice.configurl}${this.logoApi}`;

  editUniversityAdminApi = 'admin/update-profile';
  editUniversityAdminUrl = `${this.commonservice.configurl}${this.editUniversityAdminApi}`;

  //sport
  getSportApi = 'admin/get-all-sports';
  getSportUrl = `${this.commonservice.configurl}${this.getSportApi}`;

  addSportApi = 'admin/add-sport';
  addSportUrl = `${this.commonservice.configurl}${this.addSportApi}`;
  editSportApi = 'admin/edit-sport';
  editSportUrl = `${this.commonservice.configurl}${this.editSportApi}`;
  deleteSportApi = 'admin/delete-sport';
  deleteSportUrl = `${this.commonservice.configurl}${this.deleteSportApi}`;

  // club data

  addclubSportApi = 'admin/sport/add-club';
  addclubSportUrl = `${this.commonservice.configurl}${this.addclubSportApi}`;
  editclubSportApi = 'admin/sport/edit-club';
  editclubSportUrl = `${this.commonservice.configurl}${this.editclubSportApi}`;

  deleteUniversitySportClubApi = 'admin/sport/delete-club/';
  deleteUniversitySportClubUrl = `${this.commonservice.configurl}${this.deleteUniversitySportClubApi}`;
  //Courses

  getCoursesApi = 'admin/get-all-courses';
  getCoursesUrl = `${this.commonservice.configurl}${this.getCoursesApi}`;

  addCoursesApi = 'admin/add-course';
  addCoursesUrl = `${this.commonservice.configurl}${this.addCoursesApi}`;
  editCoursesApi = 'admin/edit-course';
  editCoursesUrl = `${this.commonservice.configurl}${this.editCoursesApi}`;
  deleteCoursesApi = 'admin/delete-course';
  deleteCoursesUrl = `${this.commonservice.configurl}${this.deleteCoursesApi}`;

  coutseUploadFileApi = 'admin/import-courses';
  coutseUploadFileUrl = `${this.commonservice.configurl}${this.coutseUploadFileApi}`;
  //Scholarships

  getScholarshipstApi = 'admin/get-all-scholarship';
  getScholarshipstUrl = `${this.commonservice.configurl}${this.getScholarshipstApi}`;

  addScholarshipsApi = 'admin/add-scholarship';
  addScholarshipsUrl = `${this.commonservice.configurl}${this.addScholarshipsApi}`;
  editScholarshipsApi = 'admin/edit-scholarship';
  editScholarshipsUrl = `${this.commonservice.configurl}${this.editScholarshipsApi}`;
  deleteScholarshipsApi = 'admin/delete-scholarship';
  deleteScholarshipsUrl = `${this.commonservice.configurl}${this.deleteScholarshipsApi}`;

  //University Notice Board
  getUniversityNoticeBoardApi = 'admin/noticeboard';
  getUniversityNoticeBoardUrl = `${this.commonservice.configurl}${this.getUniversityNoticeBoardApi}`;

  addUniversityNoticeBoardApi = 'admin/add-noticeboard';
  addUniversityNoticeBoardUrl = `${this.commonservice.configurl}${this.addUniversityNoticeBoardApi}`;
  editUniversityNoticeBoardApi = 'admin/add-noticeboard/';
  editUniversityNoticeBoardUrl = `${this.commonservice.configurl}${this.editUniversityNoticeBoardApi}`;
  deleteUniversityNoticeBoardApi = 'admin/delete-noticeboard/';
  deleteUniversityNoticeBoardUrl = `${this.commonservice.configurl}${this.deleteUniversityNoticeBoardApi}`;

  //Notable Alumini
  getNotableAluminiApi = 'admin/get-all-notable-alumini';
  getNotableAluminiUrl = `${this.commonservice.configurl}${this.getNotableAluminiApi}`;

  addNotableAluminiApi = 'admin/add-notable-alumini';
  addNotableAluminiUrl = `${this.commonservice.configurl}${this.addNotableAluminiApi}`;
  editNotableAluminiApi = 'admin/edit-notable-alumini';
  editNotableAluminiUrl = `${this.commonservice.configurl}${this.editNotableAluminiApi}`;
  deleteNotableAluminiApi = 'admin/delete-notable-alumini';
  deleteNotableAluminiUrl = `${this.commonservice.configurl}${this.deleteNotableAluminiApi}`;

  //club details
  cluburl = 'club-details/';
  clubDataApi = `${this.commonservice.configurl}${this.cluburl}`;

  //club  Recommendation
  addRecommendationApi = 'admin/sport/add-recommendation';
  addRecommendationUrl = `${this.commonservice.configurl}${this.addRecommendationApi}`;
  editRecommendationApi = 'admin/sport/edit-recommendation';
  editRecommendationUrl = `${this.commonservice.configurl}${this.editRecommendationApi}`;
  deleteRecommendationApi = 'admin/sport/delete-recommendation';
  deleteRecommendationUrl = `${this.commonservice.configurl}${this.deleteRecommendationApi}`;

  //club Committed
  addCommittedApi = 'admin/sport/add-club-commitee';
  addCommittedUrl = `${this.commonservice.configurl}${this.addCommittedApi}`;
  editCommittedApi = 'admin/sport/edit-club-commitee';
  editCommittedUrl = `${this.commonservice.configurl}${this.editCommittedApi}`;
  deleteCommittedApi = 'admin/sport/delete-club-commitee';
  deleteCommittedUrl = `${this.commonservice.configurl}${this.deleteCommittedApi}`;

  //club Notice Board
  addNoticeBoardApi = 'admin/sport/add-club-noticeboard';
  addNoticeBoardUrl = `${this.commonservice.configurl}${this.addNoticeBoardApi}`;
  editNoticeBoardApi = 'admin/sport/edit-club-noticeboard';
  editNoticeBoardUrl = `${this.commonservice.configurl}${this.editNoticeBoardApi}`;
  deleteNoticeBoardApi = 'admin/sport/delete-club-noticeboard';
  deleteNoticeBoardUrl = `${this.commonservice.configurl}${this.deleteNoticeBoardApi}`;

  //Club Notable alumini
  getClubNotableApi = 'admin/get-club-notable-alumini/';
  getClubNotableUrl = `${this.commonservice.configurl}${this.getClubNotableApi}`;

  addClubNotableApi = 'admin/add-club-notable-alumini';
  addClubNotableUrl = `${this.commonservice.configurl}${this.addClubNotableApi}`;
  editClubNotableApi = 'admin/edit-club-notable-alumini';
  editClubNotableUrl = `${this.commonservice.configurl}${this.editClubNotableApi}`;
  deleteClubNotableApi = 'admin/delete-club-notable-alumini';
  deleteClubNotableUrl = `${this.commonservice.configurl}${this.deleteClubNotableApi}`;

  //Club FAQ's
  getClubFAQApi = 'admin/get-club-faq/';
  getClubFAQUrl = `${this.commonservice.configurl}${this.getClubFAQApi}`;

  addClubFAQApi = 'admin/club-faq';
  addClubFAQUrl = `${this.commonservice.configurl}${this.addClubFAQApi}`;
  editClubFAQApi = 'admin/club-faq/';
  editClubFAQUrl = `${this.commonservice.configurl}${this.editClubFAQApi}`;
  deleteClubFAQApi = 'admin/club-faq-delete';
  deleteClubFAQUrl = `${this.commonservice.configurl}${this.deleteClubFAQApi}`;

  //Club Sportfolio News
  getClubSportNewsApi = '';
  getClubSportNewsUrl = `${this.commonservice.configurl}${this.getClubSportNewsApi}`;

  addClubSportNewsApi = '';
  addClubSportNewsUrl = `${this.commonservice.configurl}${this.addClubSportNewsApi}`;
  editClubSportNewsApi = '';
  editClubSportNewsUrl = `${this.commonservice.configurl}${this.editClubSportNewsApi}`;
  deleteClubSportNewsApi = '';
  deleteClubSportNewsUrl = `${this.commonservice.configurl}${this.deleteClubSportNewsApi}`;

  //Club Coach News
  getClubCoachApi = 'admin/club-coach/';
  getClubCoachUrl = `${this.commonservice.configurl}${this.getClubCoachApi}`;

  addClubCoachApi = 'admin/add-club-coach';
  addClubCoachUrl = `${this.commonservice.configurl}${this.addClubCoachApi}`;
  editClubCoachApi = 'admin/add-club-coach/';
  editClubCoachUrl = `${this.commonservice.configurl}${this.editClubCoachApi}`;
  deleteClubCoachApi = 'admin/delete-club-coach';
  deleteClubCoachUrl = `${this.commonservice.configurl}${this.deleteClubCoachApi}`;

  //review reply
  reviewReplyApi = 'reply-review';
  reviewReplyUrl = `${this.commonservice.configurl}${this.reviewReplyApi}`;

  reviewReply = {
    replyDescription: '',
  };
  universitySportClub = {
    sport_name: '',
    category: '',
    position: '',
    league_predicted_points: '',
    cup_minimum_points: '',
    indiv: '',
    total: '',
    clubName: '',
    nationality: '',
    description: '',
    twitter: '',
    instagram: '',
    facebook: '',
    notice_board: '',
    club_Commitee: '',
    recommendations: '',
    video: '',
    notice_title: '',
    university_id: '',
    email: '',
    web_url: '',
    recommendation_name: '',
    recommendation_title: '',
    recommendation_description: '',
    recommendation_image: '',
    commities_fname: '',
    commities_lname: '',
    commities_description: '',
    commities_email: '',
    commities_image: '',
    Notable_fname: '',
    Notable_lname: '',
    Notable_description: '',
    Notable_linkdin: '',
    Notable_image: '',
    Notable_year: '',
    Notable_courseid: '',
    basicInformation: '',
    coachingTeam: '',
    coach_type: '',
    coach_name: '',
    trainingTimes: '',
    performanceOverview: '',
    faqTitle: '',
    faqdescription: '',
    club_id: '',
    news_description: '',
    membershipPrice: '',
    is_performance_sport: 0,
  };

  universitySport = {
    sport_name: '',
    category: '',
    position: '',
    league_predicted_points: '',
    cup_minimum_points: '',
    indiv: '',
    total: '',
    clubName: '',
    nationality: '',
    description: '',
    twitter: '',
    instagram: '',
    facebook: '',
    notice_board: '',
    club_Commitee: '',
    recommendations: '',
    video: '',
    notice_title: '',
    university_id: '',
    email: '',
    web_url: '',
    recommendation_name: '',
    recommendation_title: '',
    recommendation_description: '',
    recommendation_image: '',
    commities_fname: '',
    commities_lname: '',
    commities_description: '',
    commities_email: '',
    commities_image: '',
    club_id: '',
    Notable_fname: '',
    Notable_lname: '',
    Notable_email: '',
    Notable_description: '',
    faqTitle: '',
    faqdescription: '',
    sportNewsdescription: '',
    sportNewsTitle: '',
    coach_name: '',
    coach_type: '',
    coach_information: '',
  };

  universityCourse = {
    course_name: '',
    course_type: '',
    duration: '',
  };

  universityScholarship = {
    scholarship_name: '',
    scholarship_apply_link: '',
    scholarship_details: '',
  };

  universityNotableAlumini = {
    sport_id: '',
    course_id: '',
    notable_alumini_first_name: '',
    notable_alumini_last_name: '',
    notable_alumini_achievement: '',
    notable_alumini_year: '',
    notable_alumini_linked_in: '',
  };

  course: any = [];
  scholarship: any = [];
  notableAlumini: any = [];

  clubNotableAlumini = {
    course_id: '',
    notable_alumini_first_name: '',
    notable_alumini_last_name: '',
    notable_alumini_achievement: '',
    notable_alumini_year: '',
    notable_alumini_linked_in: '',
  };

  universityNoticeBoard = {
    notice_title: '',
    notice_board: '',
  };
  constructor(
    private commonservice: CommonService,
    private router: Router,
    public data: DataService,
    private toastr: ToastrService,
    private universityDetailsservice: UniversitydetailsService
  ) {}

  ClubData(id: string | null) {
    this.data
      .dataJsonLoad(
        `${this.clubDataApi}${id}`,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`clubDetails:`, res);
        this.clubDetails = res.data;
        this.commonservice.afterresponse();
        this.commonservice.removeLoader();
      });
  }

  //course ID
  courserId() {
    this.data
      .dataJsonLoad(this.courseUrl, this.data.setHeaders(this.param))
      .subscribe((res: any) => {
        console.log(`listofcourseData :`, res.data);
        this.courseData = res.data;
        this.commonservice.afterresponse();
      });
  }

  //background
  editbackground() {
    this.commonservice.addLoader();

    const formData = new FormData();
    if (this.editProfile_photo.length === 0) {
      this.commonservice.removeLoader();
      this.data.showError('Profile Photo Required');
    } else {
      const file = this.DataURIToBlob(this.editProfile_photo);
      formData.append('file', file, 'image.jpg');

      this.data
        .datatoPost(
          this.backgrounUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`PhotoData:`, res.data);
          this.data.SuccessToastr('Admin profile updated successfully');
          this.editBackground = false;
          this.commonservice.removeLoader();
          this.universityDetailsservice.dataLoad(
            this.universityDetailsservice.universityDetails.slug
          );
        });
    }

    // if (!this.editProfile_photo) {
    // }
    // else {

    // }
  }

  editUniversityLogoImage() {
    this.atertmessage = [];

    this.commonservice.addLoader();

    const formData = new FormData();

    // if (this.edit_uni_logo) {
    formData.append('file', this.edit_uni_logo);
    // }

    if (!this.edit_uni_logo) {
      this.atertmessage.push('Select Logo');
    }

    if (!this.edit_uni_logo) {
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(this.logoUrl, formData, this.data.setHeaders(this.param))
        .subscribe((res: any) => {
          console.log(`PhotoData:`, res.data);
          this.data.SuccessToastr('Logo has been updated successfully.');
          this.editlogo = false;
          this.commonservice.removeLoader();
          this.universityDetailsservice.dataLoad(
            this.universityDetailsservice.universityDetails.slug
          );
        });
    }
  }
  edituniversitydata(data: any) {
    let telephoneNumber = '';
    this.atertmessage = [];

    let location = '';

    let uniraking: '';
    if (data.phone === null) {
      telephoneNumber = '';
    } else {
      telephoneNumber = data.phone.internationalNumber;
    }

    if (!data.locations) {
      location = '';
    } else {
      location = data.locations.id;
    }

    if (data.university_ranking) {
      uniraking = data.university_ranking.institution_ranking;
    } else {
      uniraking = '';
    }

    const formData = new FormData();
    this.commonservice.addLoader();

    // if (this.universityDetailsservice.universityDetails.university_ranking) {
    // formData.append('institution_ranking', uniraking);
    // }

    formData.append('first_name', data.first_name);
    formData.append('email', data.email);
    formData.append('location_id', data.location_id);

    formData.append('phone', telephoneNumber);

    if (data.address) {
      formData.append('address', data.address);
    }
    if (data.address_line_1) {
      formData.append('address_line_1', data.address_line_1);
    }
    if (data.address_line_2) {
      formData.append('address_line_2', data.address_line_2);
    }
    if (data.university_details.zip_code) {
      formData.append('zip_code', data.university_details.zip_code);
    }
    if (data.university_details.female_students) {
      formData.append(
        'female_students',
        data.university_details.female_students
      );
    }
    if (data.university_details.male_students) {
      formData.append('male_students', data.university_details.male_students);
    }
    if (data.university_details.other_students) {
      formData.append('other_students', data.university_details.other_students);
    }
    if (data.university_details.total_students) {
      formData.append('total_students', data.university_details.total_students);
    }
    if (data.university_details.linked_in) {
      formData.append('linked_in', data.university_details.linked_in);
    }
    if (data.university_details.video) {
      formData.append('vedio_link', data.university_details.video);
    }
    // if (data.university_details.bucs_overall_rating) {
    //   formData.append('bucs_overall_rating', data.university_details.bucs_overall_rating);
    // }
    if (
      this.universityDetailsservice.universityDetails.university_details
        .short_note
    ) {
      formData.append(
        'short_note',
        this.universityDetailsservice.universityDetails.university_details
          .short_note
      );
    }
    if (data.university_details.average_rating) {
      formData.append('average_rating', data.university_details.average_rating);
    }
    if (data.university_details.bucs_men_rating) {
      formData.append(
        'bucs_men_rating',
        data.university_details.bucs_men_rating
      );
    }
    if (data.university_details.bucs_overall_rating) {
      formData.append(
        'bucs_women_rating',
        data.university_details.bucs_overall_rating
      );
    }
    // if (data.university_details.bucs_women_rating) {
    //   formData.append('bucs_overall_rating', data.university_details.bucs_women_rating);
    // }
    if (data.university_details.url) {
      formData.append('university_website', data.university_details.url);
    }
    if (data.country) {
      formData.append('nationality', data.country.id);
    }
    if (data.university_details.facebook) {
      formData.append('facebook', data.university_details.facebook);
    }
    if (data.university_details.instagram) {
      formData.append('instagram', data.university_details.instagram);
    }

    formData.append('sportfolio_tag', '');

    if (data.first_name === '') {
      this.atertmessage.push('University Name');
    }
    if (data.email === '') {
      this.atertmessage.push('Email');
    }
    if (data.country.id === '') {
      this.atertmessage.push('Nationality');
    }
    if (data.email) {
      const regexpEmail = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const regEmailtest = regexpEmail.test(data.email);
      if (!regEmailtest) {
        this.atertmessage.push('Plaese Enter Valid Email Address!');
      }
    }

    if (data.university_details.facebook) {
      const regFecbook = new RegExp(
        /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/
      );
      const regFecbookTest = regFecbook.test(data.university_details.facebook);
      if (!regFecbookTest) {
        this.atertmessage.push('Plaese Enter Valid Facebook Address!');
      }
    }
    if (data.university_details.linked_in) {
      const regLinkedIn = new RegExp(
        /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/
      );
      const regLinkedInTest = regLinkedIn.test(
        data.university_details.linked_in
      );
      if (!regLinkedInTest) {
        this.atertmessage.push(' Plaese Enter Valid LinkedIn Address');
      }
    }
    if (data.university_details.instagram) {
      const regInstagram = new RegExp(
        '(https?)?:?(www)?instagram.com/[a-z].{3}'
      );
      const regInstagramTest = regInstagram.test(
        data.university_details.instagram
      );
      if (!regInstagramTest) {
        this.atertmessage.push(' Plaese Enter Valid Instagram Address');
      }
    }
    if (data.university_details.url) {
      const regWebUrl = new RegExp(
        '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
      );
      const regWebUrlTest = regWebUrl.test(data.university_details.url);
      if (!regWebUrlTest) {
        this.atertmessage.push(' Plaese Enter Valid University Url');
      }
    }

    if (data.university_details.video) {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = data.university_details.video.match(regExp);
      if (!(match && match[2].length == 11)) {
        this.atertmessage.push(' Plaese Enter Valid Video Url');
      }
    }

    if (this.atertmessage.length > 0) {
      var msg = '';
      if (this.atertmessage.length == 1) {
        this.commonservice.removeLoader();
        msg = `${this.atertmessage} Required!`;
      } else {
        this.commonservice.removeLoader();
        msg = `${this.atertmessage.join()} are Required`;
      }
      this.data.showError(msg);
    } else {
      if (!this.atertmessage.length) {
        this.data
          .datatoPost(
            this.editUniversityAdminUrl,
            formData,
            this.data.setHeaders(this.param)
          )
          .subscribe((res: any) => {
            this.data.SuccessToastr('Admin profile updated successfully');
            this.commonservice.removeLoader();
            this.edituniversity = false;
            this.universityDetailsservice.dataLoad(
              this.universityDetailsservice.universityDetails.slug
            );
          });
      }
    }
  }
  //sport

  addClubSportdata() {
    this.atertmessage = [];

    // this.commonservice.addLoader();

    const formData = new FormData();
    formData.append('sport_id', this.universitySportClub.sport_name);
    formData.append(
      'university_id',
      this.universityDetailsservice.universityDetails.id
    );
    formData.append('club_name', this.universitySportClub.clubName);
    if (this.universitySportClub.email) {
      formData.append('email', this.universitySportClub.email);
    }
    formData.append('web_url', this.universitySportClub.web_url);
    formData.append('facebook_url', this.universitySportClub.facebook);
    formData.append('twitter_url', this.universitySportClub.twitter);
    formData.append('instagram_url', this.universitySportClub.instagram);
    formData.append('country_id', this.universitySportClub.nationality);
    formData.append('organisation_type', 'Sports club');
    formData.append('club_description', this.universitySportClub.description);

    if (this.addClublogo) {
      formData.append('club_logo', this.addClublogo);
    }

    for (var i = 0; i < this.addClubgallery.length; i++) {
      formData.append('club_gallery_photos[]', this.addClubgallery[i]);
    }

    if (this.abcd) {
      const file = this.DataURIToBlob(this.abcd);
      formData.append('club_profile_photo', file, 'image.jpg');
    }

    formData.append('notice_title', this.universitySportClub.notice_title);
    formData.append(
      'notice_description',
      this.universitySportClub.notice_board
    );

    formData.append(
      'recommendation_name',
      this.universitySportClub.recommendation_name
    );
    formData.append(
      'recommendation_title',
      this.universitySportClub.recommendation_title
    );
    formData.append(
      'recommendation_description',
      this.universitySportClub.recommendation_description
    );
    formData.append('recommendation_image', '');
    // this.addRecommendation_image
    formData.append(
      'commities_fname',
      this.universitySportClub.commities_fname
    );
    formData.append(
      'commities_lname',
      this.universitySportClub.commities_lname
    );
    formData.append(
      'commities_description',
      this.universitySportClub.commities_description
    );
    formData.append(
      'commities_email',
      this.universitySportClub.commities_email
    );
    if (this.addCommities_image) {
      formData.append('commities_image', this.addCommities_image);
    }

    if (
      this.universityDetailsservice.universityDetails.university_details.web_url
    ) {
      formData.append(
        'university_website',
        this.universityDetailsservice.universityDetails.university_details
          .web_url
      );
    }

    formData.append('overview', this.universitySportClub.description);
    formData.append('video_link', this.universitySportClub.video);
    formData.append(
      'membership_price',
      this.universitySportClub.membershipPrice
    );
    formData.append(
      'basic_information',
      this.universitySportClub.basicInformation
    );
    formData.append(
      'performance_information',
      this.universitySportClub.performanceOverview
    );
    formData.append('training_times', this.universitySportClub.trainingTimes);

    formData.append('notable_alumini_photo', this.addNotableAlumini_image);
    formData.append(
      'notable_alumini_first_name',
      this.universitySportClub.Notable_fname
    );
    formData.append(
      'notable_alumini_last_name',
      this.universitySportClub.Notable_lname
    );
    formData.append(
      'notable_alumini_linked_in',
      this.universitySportClub.Notable_linkdin
    );
    formData.append(
      'notable_alumini_year',
      this.universitySportClub.Notable_year
    );
    formData.append(
      'notable_alumini_achievement',
      this.universitySportClub.Notable_description
    );
    formData.append(
      'notable_alumini_course_id',
      this.universitySportClub.Notable_courseid
    );

    formData.append('faq_question', this.universitySportClub.faqTitle);
    formData.append('faq_answer', this.universitySportClub.faqdescription);

    formData.append('coach_name', this.universitySportClub.coach_name);
    formData.append('coach_type', this.universitySportClub.coach_type);
    formData.append('coach_information', this.universitySportClub.coachingTeam);

    if (this.coachaddProfile) {
      formData.append('coach_profile_image', this.coachaddProfile);
    }

    formData.append(
      'is_performance_sport',
      this.universitySportClub.is_performance_sport == 1 ? String(1) : String(0)
    );

    if (this.universitySportClub.sport_name === '') {
      this.atertmessage.push(' Sport Name');
    }
    if (this.universitySportClub.clubName === '') {
      this.atertmessage.push(' Club Name');
    }
    // if (this.universitySportClub.email === '') {
    //   this.atertmessage.push(' Email');
    // }

    // if (this.universitySportClub.nationality === '') {
    //   this.atertmessage.push(' Nationality');
    // }
    // if (this.universitySportClub.web_url === '') {
    //   this.atertmessage.push(' University URL');
    // }
    // if (this.universitySportClub.facebook === '') {
    //   this.atertmessage.push(' Facebook');
    // }
    // if (this.universitySportClub.twitter === '') {
    //   this.atertmessage.push(' Twitter');
    // }
    // if (this.universitySportClub.instagram === '') {
    //   this.atertmessage.push(' Instagram');
    // }

    // if (this.universitySportClub.description === '') {
    //   this.atertmessage.push(' Description');
    // }
    // if (this.universitySportClub.notice_title === '') {
    //   this.atertmessage.push(' Notice Title');
    // }
    // if (this.universitySportClub.notice_board === '') {
    //   this.atertmessage.push(' Notice Board');
    // }

    // if (this.addClublogo === '') {
    //   this.atertmessage.push(' Add Club logo');
    // }

    // if (this.universitySportClub.Notable_fname === '') {
    //   this.atertmessage.push(' Notable Alumini First Name');
    // }
    // if (this.universitySportClub.Notable_lname === '') {
    //   this.atertmessage.push(' Notable Alumini Last Name');
    // }
    // if (this.universitySportClub.Notable_linkdin === '') {
    //   this.atertmessage.push(' Notable Alumini LinkedIn  ');
    // }
    // if (this.universitySportClub.Notable_year === '') {
    //   this.atertmessage.push(' Notable Alumini Year');
    // }
    // if (this.universitySportClub.Notable_description === '') {
    //   this.atertmessage.push(' Notable Alumini Achievement');
    // }

    // if (this.universitySportClub.coach_name === '') {
    //   this.atertmessage.push(' Coach Name');
    // }
    // if (this.universitySportClub.coach_type === '') {
    //   this.atertmessage.push(' Coach Type');
    // }
    // if (this.universitySportClub.coachingTeam === '') {
    //   this.atertmessage.push(' Coach Information');
    // }
    // if (this.universitySportClub.commities_fname === '') {
    //   this.atertmessage.push(' commities First Name');
    // }
    // if (this.universitySportClub.commities_lname === '') {
    //   this.atertmessage.push(' commities Last Name');
    // }
    // if (this.universitySportClub.commities_description === '') {
    //   this.atertmessage.push(' Commities Description');
    // }
    // if (this.universitySportClub.commities_email === '') {
    //   this.atertmessage.push(' Commities Email');
    // }
    // if (this.addCommities_image === '') {
    //   this.atertmessage.push(' AddCommities Image');
    // }

    // if (this.universitySportClub.faqTitle === '') {
    //   this.atertmessage.push(' Question');
    // }
    // if (this.universitySportClub.faqdescription === '') {
    //   this.atertmessage.push(' Answer');
    // }
    if (this.universitySportClub.email) {
      const regexpEmail = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const regEmailtest = regexpEmail.test(this.universitySportClub.email);
      if (!regEmailtest) {
        this.atertmessage.push('Plaese Enter Valid Email Address!');
      }
    }

    if (this.universitySportClub.commities_email) {
      const regexpEmail = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const regCommitiesEmailtest = regexpEmail.test(
        this.universitySportClub.commities_email
      );
      if (!regCommitiesEmailtest) {
        this.atertmessage.push('Plaese Enter Valid Commities Email Address!');
      }
    }
    if (this.universitySportClub.facebook) {
      const regFecbook = new RegExp(
        /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/
      );
      const regFecbookTest = regFecbook.test(this.universitySportClub.facebook);

      if (!regFecbookTest) {
        this.atertmessage.push('Plaese Enter Valid Facebook Address!');
      }
    }
    if (this.universitySportClub.twitter) {
      const regTwitter = new RegExp(
        /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
      );
      const regTwitterTest = regTwitter.test(this.universitySportClub.twitter);

      if (!regTwitterTest) {
        this.atertmessage.push(' Plaese Enter Valid Twitter Address');
      }
    }
    if (this.universitySportClub.instagram) {
      const regInstagram = new RegExp(
        '(https?)?:?(www)?instagram.com/[a-z].{3}'
      );
      const regInstagramTest = regInstagram.test(
        this.universitySportClub.instagram
      );

      if (!regInstagramTest) {
        this.atertmessage.push(' Plaese Enter Valid Instagram Address');
      }
    }
    if (this.universitySportClub.web_url) {
      const regWebUrl = new RegExp(
        '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
      );
      const regWebUrlTest = regWebUrl.test(this.universitySportClub.web_url);

      if (!regWebUrlTest) {
        this.atertmessage.push(' Plaese Enter Valid Web Url');
      }
    }
    if (this.universitySportClub.Notable_linkdin) {
      const regLinkedIn = new RegExp(
        /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/
      );
      const regLinkedInTest = regLinkedIn.test(
        this.universitySportClub.Notable_linkdin
      );
      if (!regLinkedInTest) {
        this.atertmessage.push('Plaese Enter Valid LinkedIn Address');
      }
    }

    if (this.universitySportClub.video) {
      formData.append('video_link', this.universitySportClub.video);

      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = this.universitySportClub.video.match(regExp);
      if (!(match && match[2].length == 11)) {
        this.atertmessage.push(' Plaese Enter Valid Video Url');
      }
    }
    if (this.atertmessage.length > 0) {
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
        this.commonservice.removeLoader();
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }

      this.commonservice.removeLoader();
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.addclubSportUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          this.data.SuccessToastr('Club created successfully');
          this.commonservice.removeLoader();
          this.addSportData = false;
          this.universityDetailsservice.dataLoad(
            this.universityDetailsservice.universityDetails.slug
          );
          this.router.navigate(['/university_Profile']);
        });
    }
  }

  editSaveClubSportdata(clubData: any) {
    // this.commonservice.addLoader();

    this.atertmessage = [];

    const formData = new FormData();
    formData.append('club_id', clubData.id);
    formData.append('sport_id', clubData.sport_id);
    formData.append(
      'university_id',
      this.universityDetailsservice.universityDetails.id
    );
    formData.append('club_name', clubData.name);
    if (clubData.email) {
      formData.append('email', clubData.email);
    }
    if (clubData.country) {
      formData.append('country_id', clubData.country.id);
    }
    formData.append('organisation_type', '');
    if (clubData.description) {
      formData.append('club_description', clubData.description);
    }
    if (this.editClublogo) {
      formData.append('club_logo', this.editClublogo);
    }

    for (var i = 0; i < this.editClubgallery.length; i++) {
      formData.append('club_gallery_photos[]', this.editClubgallery[i]);
    }

    // formData.append('notice_title', clubData.notice_board[0].title);
    // formData.append('notice_description', clubData.notice_board[0].description);

    if (this.profileEdit) {
      const file = this.DataURIToBlob(this.profileEdit);
      formData.append('club_profile_photo', file, 'image.jpg');
    }

    // formData.append('commities_fname', clubData.commities[0].first_name);
    // formData.append('commities_lname', clubData.commities[0].last_name);
    // formData.append('commities_description', clubData.commities[0].description);
    // formData.append('commities_email', clubData.commities[0].email);

    // if (this.editCommities_image) {
    //   formData.append('commities_image', this.editCommities_image);
    // }

    // formData.append('commities_id', clubData.commities[0].id);

    if (clubData.description) {
      formData.append('overview', clubData.description);
    }
    if (clubData.basic_information) {
      formData.append('basic_information', clubData.basic_information);
    }
    if (clubData.training_times) {
      formData.append('training_times', clubData.training_times);
    }
    if (clubData.performance_information) {
      formData.append(
        'performance_information',
        clubData.performance_information
      );
    }
    if (clubData.membership_price) {
      formData.append('membership_price', clubData.membership_price);
    }

    if (clubData.sport_id === '') {
      this.atertmessage.push(' Sport Name');
    }
    if (clubData.name === '') {
      this.atertmessage.push(' Club Name');
    }
    // if (clubData.email === '' || clubData.email == null) {
    //   this.atertmessage.push(' Email');
    // }
    // if (clubData.email === '') {
    //   this.atertmessage.push(' Email');
    // }
    // if (clubData.country === '') {
    //   this.atertmessage.push(' Nationality');
    // }

    if (clubData.video_link) {
      formData.append('video_link', clubData.video_link);

      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = clubData.video_link.match(regExp);
      if (!(match && match[2].length == 11)) {
        this.atertmessage.push(' Plaese Enter Valid Video Url');
      }
    }

    if (clubData.email) {
      const regexpEmail = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const regEmailtest = regexpEmail.test(clubData.email);

      if (!regEmailtest) {
        this.atertmessage.push('Plaese Enter Valid Email Address!');
      }
    }

    if (clubData.facebook) {
      formData.append('facebook_url', clubData.facebook);

      const regFecbook = new RegExp(
        /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/
      );
      const regFecbookTest = regFecbook.test(clubData.facebook);

      if (!regFecbookTest) {
        this.atertmessage.push('Plaese Enter Valid Facebook Address!');
      }
    }

    if (clubData.twitter) {
      formData.append('twitter_url', clubData.twitter);
      const regTwitter = new RegExp(
        /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
      );
      const regTwitterTest = regTwitter.test(clubData.twitter);

      if (!regTwitterTest) {
        this.atertmessage.push(' Plaese Enter Valid Twitter Address');
      }
    }

    if (clubData.instagram) {
      formData.append('instagram_url', clubData.instagram);
      const regInstagram = new RegExp(
        '(https?)?:?(www)?instagram.com/[a-z].{3}'
      );
      const regInstagramTest = regInstagram.test(clubData.instagram);

      if (!regInstagramTest) {
        this.atertmessage.push(' Plaese Enter Valid Instagram Address');
      }
    }

    if (clubData.website) {
      formData.append('web_url', clubData.website);

      const regWebUrl = new RegExp(
        '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
      );
      const regWebUrlTest = regWebUrl.test(clubData.website);

      if (!regWebUrlTest) {
        this.atertmessage.push(' Plaese Enter Valid University Url');
      }
    }

    formData.append(
      'is_performance_sport',
      clubData.is_performance_sport == 1 ||
        clubData.is_performance_sport == true
        ? String(1)
        : String(0)
    );

    if (this.atertmessage.length > 0) {
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.editclubSportUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          this.commonservice.afterresponse();
          this.ClubData(clubData.id);
          this.Clubgallery = [];
          this.universityDetailsservice.dataLoad(
            this.universityDetailsservice.universityDetails.slug
          );
          this.commonservice.removeLoader();
          this.data.SuccessToastr('Club update successfully.');
          // this.sportClubId = false;
          // this.router.navigate(['/sportdata'], { queryParams: { id: clubData.id } })
        });
    }
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  deleteSportdata() {
    this.data
      .dataJsonLoad(
        `${this.deleteUniversitySportClubUrl}${
          this.universityDetailsservice.universityDetails.university_club[
            this.setIndex
          ].club_id
        }`,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        this.data.SuccessToastr('Sport deleted successfully');
        this.universityDetailsservice.dataLoad(
          this.universityDetailsservice.universityDetails.slug
        );

        this.commonservice.removeLoader();
      });
  }

  CancelSportClubData() {
    this.universitySportClub = {
      sport_name: '',
      category: '',
      position: '',
      league_predicted_points: '',
      cup_minimum_points: '',
      indiv: '',
      total: '',
      clubName: '',
      nationality: '',
      description: '',
      twitter: '',
      instagram: '',
      facebook: '',
      notice_board: '',
      club_Commitee: '',
      recommendations: '',
      video: '',
      notice_title: '',
      university_id: '',
      email: '',
      web_url: '',
      recommendation_name: '',
      recommendation_title: '',
      recommendation_description: '',
      recommendation_image: '',
      commities_fname: '',
      commities_lname: '',
      commities_description: '',
      commities_email: '',
      commities_image: '',
      Notable_fname: '',
      Notable_lname: '',
      Notable_description: '',
      Notable_linkdin: '',
      Notable_image: '',
      Notable_year: '',
      Notable_courseid: '',
      basicInformation: '',
      coachingTeam: '',
      coach_type: '',
      coach_name: '',
      trainingTimes: '',
      performanceOverview: '',
      faqTitle: '',
      faqdescription: '',
      club_id: '',
      news_description: '',
      membershipPrice: '',
      is_performance_sport: 0,
    };
  }

  CancelSportData() {
    this.universitySport = {
      sport_name: '',
      category: '',
      position: '',
      league_predicted_points: '',
      cup_minimum_points: '',
      indiv: '',
      total: '',
      clubName: '',
      nationality: '',
      description: '',
      twitter: '',
      instagram: '',
      facebook: '',
      notice_board: '',
      club_Commitee: '',
      recommendations: '',
      video: '',
      notice_title: '',
      university_id: '',
      email: '',
      web_url: '',
      recommendation_name: '',
      recommendation_title: '',
      recommendation_description: '',
      recommendation_image: '',
      commities_fname: '',
      commities_lname: '',
      commities_description: '',
      commities_email: '',
      commities_image: '',
      club_id: '',
      Notable_fname: '',
      Notable_lname: '',
      Notable_email: '',
      Notable_description: '',
      faqTitle: '',
      faqdescription: '',
      sportNewsdescription: '',
      sportNewsTitle: '',
      coach_name: '',
      coach_type: '',
      coach_information: '',
    };
  }

  //Course
  getCoursedata() {
    this.data
      .dataJsonLoad(this.getCoursesUrl, this.data.setHeaders(this.param))
      .subscribe((res: any) => {
        console.log(`coureseDataresponse:`, res);
        this.coureseDataresponse = res.data;
        this.course = res.data;

        this.commonservice.afterresponse();
      });
  }

  addsaveCoursedata() {
    this.atertmessage = [];
    this.commonservice.addLoader();

    let addCoursedata = {
      course_name: this.universityCourse.course_name,
      course_type: this.universityCourse.course_type,
      duration: this.universityCourse.duration,
    };

    console.log(this.universityCourse);
    if (this.universityCourse.course_type === '') {
      this.atertmessage.push('Course Type');
    }

    if (this.universityCourse.course_name === '') {
      this.atertmessage.push(' Course Name');
    }

    if (this.universityCourse.duration === '') {
      this.atertmessage.push(' Duration');
    }

    if (
      this.universityCourse.course_type === '' ||
      this.universityCourse.course_name === '' ||
      this.universityCourse.duration === ''
    ) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.addCoursesUrl,
          addCoursedata,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`coursedata:`, res.data);
          this.data.SuccessToastr('Data Added');
          this.addCourseData = false;
          this.CancelCourseData();
          this.commonservice.removeLoader();
          this.getCoursedata();
          this.universityDetailsservice.dataLoad(
            this.universityDetailsservice.universityDetails.slug
          );
        });
    }
  }

  editCoursedata(data: any) {
    this.commonservice.addLoader();

    this.atertmessage = [];
    this.course = {
      course_name: data.course.name,
      course_type: data.course.type,
      course_id: data.course_id,
      duration: data.duration,
      // duration : data.course.duration,
    };

    if (data.course.name === '') {
      this.atertmessage.push('Course Name');
    }
    if (data.course.type === '') {
      this.atertmessage.push(' Course Type');
    }
    if (data.duration === '') {
      this.atertmessage.push(' Duration');
    }

    if (
      data.course.name === '' ||
      data.course.type === '' ||
      data.duration === ''
    ) {
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.editCoursesUrl,
          this.course,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          this.data.SuccessToastr('Course created successfully. ');
          this.commonservice.editsaveCoursedata();
          this.commonservice.removeLoader();
          this.editCourseData = false;
          this.getCoursedata();
        });
    }
  }

  deleteCourseData() {
    let deletecourseData = {
      course_id: Number(this.course[this.setIndex].course_id),
    };
    this.data
      .datatoPost(
        this.deleteCoursesUrl,
        deletecourseData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`Sportdata:`, res.data);
        this.data.SuccessToastr(' Course deleted successfully.');
        this.getCoursedata();
      });
  }

  CancelCourseData() {
    this.universityCourse = {
      course_name: '',
      course_type: '',
      duration: '',
    };
  }

  //Scholarship data
  getScholarshipdata() {
    this.data
      .dataJsonLoad(this.getScholarshipstUrl, this.data.setHeaders(this.param))
      .subscribe((res: any) => {
        console.log(`scholarshipDataresponse:`, res);
        this.scholarshipDataresponse = res.data;
        this.commonservice.afterresponse();
      });
  }

  addScholarship() {
    this.commonservice.addLoader();
    this.atertmessage = [];
    let addScholarshipdata = {
      scholarship_name: this.universityScholarship.scholarship_name,
      scholarship_apply_link: this.universityScholarship.scholarship_apply_link,
      scholarship_details: this.universityScholarship.scholarship_details,
    };

    console.log(this.universityScholarship);
    if (this.universityScholarship.scholarship_name === '') {
      this.atertmessage.push(' Scholarship Title');
    }
    if (this.universityScholarship.scholarship_apply_link === '') {
      this.atertmessage.push(' Scholarship Apply Link');
    }
    if (this.universityScholarship.scholarship_details === '') {
      this.atertmessage.push(' Scholarship Details');
    }

    if (
      this.universityScholarship.scholarship_name === '' ||
      this.universityScholarship.scholarship_apply_link === '' ||
      this.universityScholarship.scholarship_details === ''
    ) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      const regWebUrl = new RegExp(
        '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
      );
      const regWebUrlTest = regWebUrl.test(
        this.universityScholarship.scholarship_apply_link
      );

      if (regWebUrlTest) {
        this.data
          .datatoPost(
            this.addScholarshipsUrl,
            addScholarshipdata,
            this.data.setHeaders(this.param)
          )
          .subscribe((res: any) => {
            console.log(`ScholarshipData:`, res.data);
            this.data.SuccessToastr('Scholarship Data Added.');
            this.addScholarshipData = false;
            this.CancelScholarshipData();
            this.commonservice.removeLoader();
            this.addScholarshipData = false;
            this.getScholarshipdata();
          });
      } else {
        this.data.showError('Plaese Enter Valid Web Url');
      }
    }
  }

  editScholarship(data: any) {
    this.commonservice.addLoader();

    this.atertmessage = [];

    this.scholarship = {
      scholarship_id: data.id,
      scholarship_name: data.name,
      scholarship_apply_link: data.apply_link,
      scholarship_details: data.details,
    };

    if (data.name === '') {
      this.atertmessage.push('Scholarship Title');
    }
    if (data.apply_link === '') {
      this.atertmessage.push(' Scholarship  Apply Link');
    }
    if (data.details === '') {
      this.atertmessage.push(' Scholarship Details ');
    }

    if (data.name === '' || data.apply_link === '' || data.details === '') {
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;

        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      const regWebUrl = new RegExp(
        '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
      );
      const regWebUrlTest = regWebUrl.test(data.apply_link);

      if (regWebUrlTest) {
        this.data
          .datatoPost(
            this.editScholarshipsUrl,
            this.scholarship,
            this.data.setHeaders(this.param)
          )
          .subscribe((res: any) => {
            console.log(`ScholarshipData:`, res.data);

            this.data.SuccessToastr('Edit Successful.');
            this.commonservice.editScholarshipadded();
            this.commonservice.removeLoader();
            this.getScholarshipdata();
          });
      } else {
        this.data.showError('Plaese Enter Valid Web Url');
      }
    }
  }

  deleteScholarshipData() {
    let deleteScholarshipData = {
      scholarship_id: Number(this.scholarshipDataresponse[this.setIndex].id),
    };
    this.data
      .datatoPost(
        this.deleteScholarshipsUrl,
        deleteScholarshipData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`Sportdata:`, res.data);
        this.data.SuccessToastr(' Scholarship deleted successfully.');
        this.editScholarshipData = false;
        this.getScholarshipdata();
      });
  }

  CancelScholarshipData() {
    this.universityScholarship = {
      scholarship_name: '',
      scholarship_apply_link: '',
      scholarship_details: '',
    };
  }

  //Notable_AluminiData
  getNotableAluminiData() {
    this.data
      .dataJsonLoad(this.getNotableAluminiUrl, this.data.setHeaders(this.param))
      .subscribe((res: any) => {
        console.log(`notableDataresponse:`, res);
        this.notableDataresponse = res.data;
        this.commonservice.afterresponse();
      });
  }

  addNotableAluminidata() {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();
    formData.append('sport_id', this.universityNotableAlumini.sport_id);
    // formData.append('course_id', this.universityNotableAlumini.course_id);
    formData.append(
      'notable_alumini_first_name',
      this.universityNotableAlumini.notable_alumini_first_name
    );
    formData.append(
      'notable_alumini_last_name',
      this.universityNotableAlumini.notable_alumini_last_name
    );
    formData.append(
      'notable_alumini_achievement',
      this.universityNotableAlumini.notable_alumini_achievement
    );
    // formData.append(
    //   'notable_alumini_year',
    //   this.universityNotableAlumini.notable_alumini_year
    // );
    // formData.append(
    //   'notable_alumini_linked_in',
    //   this.universityNotableAlumini.notable_alumini_linked_in
    // );
    formData.append('notable_alumini_photo', this.addnotable_alumini_photo);

    if (this.universityNotableAlumini.notable_alumini_first_name === '') {
      this.atertmessage.push(' First Name ');
    }
    if (this.universityNotableAlumini.notable_alumini_last_name === '') {
      this.atertmessage.push(' Last Name');
    }
    if (this.universityNotableAlumini.notable_alumini_achievement === '') {
      this.atertmessage.push(' Achievement');
    }
    // if (this.universityNotableAlumini.notable_alumini_year === '') {
    //   this.atertmessage.push(' Year');
    // }
    // if (this.universityNotableAlumini.notable_alumini_linked_in === '') {
    //   this.atertmessage.push(' LinkedIn ');
    // }
    if (!this.addnotable_alumini_photo) {
      this.atertmessage.push(' Image');
    }

    if (this.universityNotableAlumini.notable_alumini_linked_in) {
      const regLinkedIn = new RegExp(
        /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/
      );
      const regLinkedInTest = regLinkedIn.test(
        this.universityNotableAlumini.notable_alumini_linked_in
      );
      if (!regLinkedInTest) {
        this.atertmessage.push('Plaese Enter Valid LinkedIn Address');
      }
    }

    if (this.atertmessage.length > 0) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.addNotableAluminiUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`NotableAlumini:`, res.data);
          this.data.SuccessToastr('Notable Alumni created successfully.');
          this.CancelNotableAluminiData();
          this.commonservice.removeLoader();
          this.addnotable_alumini_photo = '';
          this.addNotableData = false;
          this.getNotableAluminiData();
        });
    }
  }

  editNotableAluminidata(data: any) {
    this.atertmessage = [];
    this.commonservice.addLoader();
    const formData = new FormData();
    formData.append('sport_id', data.sport_id);
    // formData.append('course_id', data.course_id);
    formData.append('notable_alumini_first_name', data.first_name);
    formData.append('notable_alumini_last_name', data.last_name);
    formData.append('notable_alumini_achievement', data.achievement);
    // formData.append('notable_alumini_year', data.year);
    // formData.append('notable_alumini_linked_in', data.linked_in);
    formData.append('notable_alumini_id', data.id);

    if (this.profile_image) {
      formData.append('notable_alumini_photo', this.profile_image);
    }

    if (data.first_name === '') {
      this.atertmessage.push('  First Name ');
    }
    if (data.last_name === '') {
      this.atertmessage.push(' Last Name');
    }
    if (data.achievement === '') {
      this.atertmessage.push(' Achievement');
    }
    // if (data.linked_in) {
    //   const regLinkedIn = new RegExp(
    //     /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/
    //   );
    //   const regLinkedInTest = regLinkedIn.test(data.linked_in);

    //   if (!regLinkedInTest) {
    //     this.atertmessage.push('Plaese Enter Valid LinkedIn Address');
    //   }
    // }

    if (
      data.first_name === '' ||
      data.last_name === '' ||
      data.achievement === ''
    ) {
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.editNotableAluminiUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`NotableAlumini:`, res.data);

          this.data.SuccessToastr('Notable Alumni edit successful.');
          this.commonservice.editaddedNotableUnni();
          this.commonservice.removeLoader();
          this.editNotableData = false;
          this.getNotableAluminiData();
        });
    }
  }

  deleteNotableAluminiData() {
    let deleteNotableAluminiData = {
      notable_alumini_id: Number(this.notableDataresponse[this.setIndex].id),
    };
    this.data
      .datatoPost(
        this.deleteNotableAluminiUrl,
        deleteNotableAluminiData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`Sportdata:`, res.data);
        this.data.SuccessToastr('Notable Alumni deleted successfully.');
        this.getNotableAluminiData();
      });
  }

  CancelNotableAluminiData() {
    this.universityNotableAlumini = {
      sport_id: '',
      course_id: '',
      notable_alumini_first_name: '',
      notable_alumini_last_name: '',
      notable_alumini_achievement: '',
      notable_alumini_year: '',
      notable_alumini_linked_in: '',
    };
    this.addnotable_alumini_photo = null;
  }

  get f() {
    return this.myForm.controls;
  }

  //University Notice Board
  getUniversityNoticeBoardData() {
    this.data
      .dataJsonLoad(
        this.getUniversityNoticeBoardUrl,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`UniversityNoticeBoardresponse:`, res);
        this.UniversityNoticeBoardData = res.data;
        this.commonservice.afterresponse();
      });
  }

  addUniversityNoticeBoardData() {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();
    formData.append('title', this.universityNoticeBoard.notice_title);
    formData.append('description', this.universityNoticeBoard.notice_board);

    if (this.universityNoticeBoard.notice_title === '') {
      this.atertmessage.push(' Notice Title');
    }
    if (this.universityNoticeBoard.notice_board === '') {
      this.atertmessage.push(' Notice Description');
    }
    if (
      this.universityNoticeBoard.notice_title === '' ||
      this.universityNoticeBoard.notice_board === ''
    ) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.addUniversityNoticeBoardUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`UniversityNoticeBoard:`, res.data);
          this.data.SuccessToastr('Task Complete');
          this.adduniversityNoticeBoard = false;
          this.getUniversityNoticeBoardData();
          this.CanceladduniversityNOticeBoard();
          this.commonservice.removeLoader();
        });
    }
  }

  editUniversityNoticeBoardData(data: any) {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();

    formData.append('title', data.title);
    formData.append('description', data.description);

    if (data.title === '') {
      this.atertmessage.push(' Notice Title');
    }
    if (data.description === '') {
      this.atertmessage.push(' Notice Description');
    }
    if (data.title === '' || data.description === '') {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          `${this.editUniversityNoticeBoardUrl}${data.id}`,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`UniversityNoticeBoardData:`, res.data);
          this.data.SuccessToastr('Notice board updated ');
          this.edituniversityNoticeBoard = false;
          this.getUniversityNoticeBoardData();
          this.commonservice.removeLoader();
        });
    }
  }

  deleteUniversityNoticeBoardData() {
    this.data
      .dataJsonLoad(
        `${this.deleteUniversityNoticeBoardUrl}${
          this.UniversityNoticeBoardData[this.setIndex].id
        }`,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        this.data.SuccessToastr(' Task Complete');
        this.getUniversityNoticeBoardData();
        this.commonservice.removeLoader();
      });
  }

  CanceladduniversityNOticeBoard() {
    this.universityNoticeBoard = {
      notice_title: '',
      notice_board: '',
    };
  }

  addData_false() {
    this.addCourseData = false;
    this.addScholarshipData = false;
    this.addNotableData = false;
  }

  //Commities
  addCommiteData(club_id: any) {
    this.atertmessage = [];

    this.commonservice.addLoader();
    const formData = new FormData();

    formData.append('club_id', club_id);
    formData.append('commities_fname', this.universitySport.commities_fname);
    formData.append('commities_lname', this.universitySport.commities_lname);
    formData.append(
      'commities_description',
      this.universitySport.commities_description
    );
    formData.append('commities_email', this.universitySport.commities_email);
    if (this.addCommities_image) {
      formData.append('commities_image', this.addCommities_image);
    }

    if (this.universitySport.commities_fname === '') {
      this.atertmessage.push(' First Name');
    }
    if (this.universitySport.commities_lname === '') {
      this.atertmessage.push(' Last Name');
    }
    if (this.universitySport.commities_description === '') {
      this.atertmessage.push(' Description');
    }
    if (this.universitySport.commities_email === '') {
      this.atertmessage.push(' Email');
    }
    // if (!this.addCommities_image) {
    //   this.atertmessage.push(' Image');
    // }
    if (this.atertmessage.length > 0) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      const regexpEmail = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const regEmailtest = regexpEmail.test(
        this.universitySport.commities_email
      );

      if (regEmailtest) {
        this.data
          .datatoPost(
            this.addCommittedUrl,
            formData,
            this.data.setHeaders(this.param)
          )
          .subscribe((res: any) => {
            console.log(`Commitiesdata:`, res.data);
            this.data.SuccessToastr('Committee member added successfully');
            this.CancelSportData();
            this.addCommite = false;
            this.commonservice.removeLoader();
            this.ClubData(club_id);
          });
      } else {
        this.data.showError('Plaese Enter Valid Email Address');
      }
    }
  }

  editCommiteeData(clubData: any) {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();

    formData.append('commities_fname', clubData.first_name);
    formData.append('commities_lname', clubData.last_name);
    formData.append('commities_description', clubData.description);
    formData.append('commities_email', clubData.email);
    if (this.editCommities_image) {
      formData.append('commities_image', this.editCommities_image);
    }

    formData.append('club_commitee_id', clubData.id);
    formData.append('club_id', clubData.club_id);

    if (clubData.first_name === '') {
      this.atertmessage.push(' First Name');
    }
    if (clubData.last_name === '') {
      this.atertmessage.push(' Last Name');
    }
    if (clubData.description === '') {
      this.atertmessage.push(' Description');
    }
    if (clubData.email === '') {
      this.atertmessage.push(' Email');
    }

    if (
      clubData.first_name === '' ||
      clubData.last_name === '' ||
      clubData.description === '' ||
      clubData.email === ''
    ) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      const regexpEmail = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const regEmailtest = regexpEmail.test(clubData.email);

      if (regEmailtest) {
        this.data
          .datatoPost(
            this.editCommittedUrl,
            formData,
            this.data.setHeaders(this.param)
          )
          .subscribe((res: any) => {
            console.log(`commitiesData:`, res.data);
            this.data.SuccessToastr('Committee member edited successfully ');

            this.editCommitee = false;
            this.commonservice.removeLoader();
            this.commonservice.editclubCommitee();
            this.ClubData(clubData.club_id);
          });
      } else {
        this.data.showError('Plaese Enter Valid Email Address');
      }
    }
  }

  deleteCommiteData() {
    const formData = new FormData();

    formData.append(
      'club_committee_id',
      this.clubDetails.commities[this.setIndex].id
    );

    this.data
      .datatoPost(
        this.deleteCommittedUrl,
        formData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`Sportdata:`, res.data);
        this.data.SuccessToastr('Task Complete');
        this.commonservice.removeLoader();
        this.ClubData(this.clubDetails.commities[this.setIndex].club_id);
      });
  }

  //Club Recommendation
  addRecommendationData(id: any) {
    this.atertmessage = [];

    this.commonservice.addLoader();
    const formData = new FormData();

    formData.append('club_id', id);
    formData.append(
      'recommendation_name',
      this.universitySport.recommendation_name
    );
    formData.append(
      'recommendation_title',
      this.universitySport.recommendation_title
    );
    formData.append(
      'recommendation_description',
      this.universitySport.recommendation_description
    );
    formData.append('recommendation_image', this.addRecommendation_image);

    if (this.universitySport.recommendation_name === '') {
      this.atertmessage.push(' Recommendation Name');
    }
    if (this.universitySport.recommendation_title === '') {
      this.atertmessage.push(' Recommendation Title');
    }
    if (this.universitySport.recommendation_description === '') {
      this.atertmessage.push(' Recommendation Description');
    }

    if (
      this.universitySport.recommendation_name === '' ||
      this.universitySport.recommendation_title === '' ||
      this.universitySport.recommendation_description === ''
    ) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.addRecommendationUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`RecommendationData:`, res.data);
          this.data.SuccessToastr('Task Complete ');
          this.addRecommendation = false;
          this.CancelSportData();
          this.commonservice.removeLoader();
          this.ClubData(id);
        });
    }
  }

  editRecommendationData(clubData: any) {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();

    formData.append('club_id', clubData.club_id);
    formData.append('recommendation_id', clubData.id);
    formData.append('recommendation_name', clubData.name);
    formData.append('recommendation_title', clubData.title);
    formData.append('recommendation_description', clubData.description);
    if (this.editRecommendation_image) {
      formData.append('recommendation_image', this.editRecommendation_image);
    }

    if (clubData.name === '') {
      this.atertmessage.push(' Recommendation Name');
    }
    if (clubData.title === '') {
      this.atertmessage.push(' Recommendation Title');
    }
    if (clubData.description === '') {
      this.atertmessage.push(' Recommendation Description');
    }

    if (
      clubData.name === '' ||
      clubData.title === '' ||
      clubData.description === ''
    ) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.editRecommendationUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`Recommendationdata:`, res.data);
          this.data.SuccessToastr('Edit Successfully ');
          this.editRecommendation = false;
          this.commonservice.removeLoader();
          this.ClubData(clubData.club_id);
        });
    }
  }

  deleteRecommendationData() {
    const formData = new FormData();

    formData.append(
      'recommendation_id',
      this.clubDetails.recommendation[this.setIndex].id
    );

    this.data
      .datatoPost(
        this.deleteRecommendationUrl,
        formData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        this.data.SuccessToastr(' Task Complete');
        this.commonservice.removeLoader();
        this.ClubData(this.clubDetails.commities[this.setIndex].club_id);
      });
  }

  //Club Notice Board
  addNoticeBoardData(id: any) {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();

    formData.append('club_id', id);
    formData.append('notice_title', this.universitySport.notice_title);
    formData.append('notice_description', this.universitySport.notice_board);

    if (this.universitySport.notice_title === '') {
      this.atertmessage.push(' Notice Title');
    }
    if (this.universitySport.notice_board === '') {
      this.atertmessage.push(' Notice Description');
    }
    if (
      this.universitySport.notice_title === '' ||
      this.universitySport.notice_board === ''
    ) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.addNoticeBoardUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`NoticeBoardD10ata:`, res.data);
          this.data.SuccessToastr('Task Complete');
          this.addNoticeBoard = false;
          this.CancelSportData();
          this.commonservice.removeLoader();
          this.ClubData(id);
        });
    }
  }

  editClubNoticeBoardData(clubData: any) {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();

    formData.append('club_id', clubData.club_id);
    formData.append('club_notice_board_id', clubData.id);
    formData.append('notice_title', clubData.title);
    formData.append('notice_description', clubData.description);

    if (clubData.title === '') {
      this.atertmessage.push(' Notice Title');
    }
    if (clubData.description === '') {
      this.atertmessage.push(' Notice Description');
    }
    if (this.atertmessage.length > 0) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.editNoticeBoardUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`NoticeBoardData:`, res.data);
          this.data.SuccessToastr('Notice board updated ');
          this.editNoticeBoard = false;
          this.commonservice.editclubNoticBoard();
          this.commonservice.removeLoader();
          this.ClubData(clubData.club_id);
        });
    }
  }

  deleteNoticeBoardData() {
    const formData = new FormData();

    formData.append(
      'club_notice_board_id',
      this.clubDetails.notice_board[this.setIndex].id
    );

    this.data
      .datatoPost(
        this.deleteNoticeBoardUrl,
        formData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        this.data.SuccessToastr('Task Complete');
        this.commonservice.removeLoader();
        this.ClubData(this.clubDetails.commities[this.setIndex].club_id);
      });
  }

  //Club Notable Alumini
  getClubNotableAluminieData(id: string | null) {
    this.data
      .dataJsonLoad(
        `${this.getClubNotableUrl}${id}`,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`ClubNotableAluminieDetails:`, res);
        this.ClubNotableAluminieDetails = res.data;
        this.commonservice.afterresponse();
      });
  }

  addClubNotableAluminiData(club_id: any) {
    this.atertmessage = [];

    this.commonservice.addLoader();
    const formData = new FormData();

    formData.append('club_id', club_id);
    // formData.append('course_id', this.clubNotableAlumini.course_id);
    formData.append(
      'notable_alumini_first_name',
      this.clubNotableAlumini.notable_alumini_first_name
    );
    formData.append(
      'notable_alumini_last_name',
      this.clubNotableAlumini.notable_alumini_last_name
    );
    formData.append(
      'notable_alumini_achievement',
      this.clubNotableAlumini.notable_alumini_achievement
    );
    // formData.append(
    //   'notable_alumini_year',
    //   this.clubNotableAlumini.notable_alumini_year
    // );
    // formData.append(
    //   'notable_alumini_linked_in',
    //   this.clubNotableAlumini.notable_alumini_linked_in
    // );
    if (this.addNotable_image) {
      formData.append('notable_alumini_photo', this.addNotable_image);
    }

    if (this.clubNotableAlumini.notable_alumini_first_name === '') {
      this.atertmessage.push(' First Name');
    }
    if (this.clubNotableAlumini.notable_alumini_last_name === '') {
      this.atertmessage.push(' Last Name');
    }
    if (this.clubNotableAlumini.notable_alumini_achievement === '') {
      this.atertmessage.push(' Achievement');
    }

    // if (!this.addNotable_image) {
    //   this.atertmessage.push(' Image');
    // }
    // if (this.clubNotableAlumini.notable_alumini_year === '') {
    //   this.atertmessage.push(' Year');
    // }
    // if (this.clubNotableAlumini.notable_alumini_linked_in === '') {
    //   this.atertmessage.push(' LinkedIn ');
    // }
    if (this.atertmessage.length > 0) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        this.commonservice.removeLoader();
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      // const regLinkedIn = new RegExp(/((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/);
      // const regLinkedInTest = regLinkedIn.test(this.clubNotableAlumini.notable_alumini_linked_in);

      // if (regLinkedInTest) {

      this.data
        .datatoPost(
          this.addClubNotableUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          this.data.SuccessToastr('Notable Alumni created successfully ');
          this.commonservice.removeLoader();
          this.addNotableAlumini = false;
          this.addNotable_image = null;
          this.cancelClubNotableAluminiData();
          this.ClubData(club_id);
          this.getClubNotableAluminieData(club_id);
        });
      // }
    }
  }

  editClubNotableAluminieData(clubData: any) {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();
    formData.append('club_id', clubData.club_id);
    // formData.append('course_id', clubData.course_id);
    formData.append('notable_alumini_first_name', clubData.first_name);
    formData.append('notable_alumini_last_name', clubData.last_name);
    formData.append('notable_alumini_achievement', clubData.achievement);
    // formData.append('notable_alumini_year', clubData.year);
    // formData.append('notable_alumini_linked_in', clubData.linked_in);
    formData.append('notable_alumini_id', clubData.id);

    if (this.editNotable_image) {
      formData.append('notable_alumini_photo', this.editNotable_image);
    }

    if (clubData.first_name === '') {
      this.atertmessage.push('  First Name ');
    }
    if (clubData.last_name === '') {
      this.atertmessage.push(' Last Name');
    }
    if (clubData.achievement === '') {
      this.atertmessage.push(' Achievement');
    }

    if (this.atertmessage.length > 0) {
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      // const regLinkedIn = new RegExp(/((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/);
      // const regLinkedInTest = regLinkedIn.test(clubData.linked_in);

      // if (regLinkedInTest) {

      this.data
        .datatoPost(
          this.editClubNotableUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          this.data.SuccessToastr('Notable Alumni edited successfully');
          this.commonservice.editclubNotableUnni();
          this.commonservice.removeLoader();

          this.editNotableAlumini = false;
          this.ClubData(clubData.club_id);
          this.getClubNotableAluminieData(clubData.club_id);
        });
      // }
    }
  }

  deleteClubNotableAluminiData() {
    const formData = new FormData();

    formData.append(
      'notable_alumini_id',
      this.ClubNotableAluminieDetails[this.setIndex].id
    );

    this.data
      .datatoPost(
        this.deleteClubNotableUrl,
        formData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        this.data.SuccessToastr(' Notable Alumini deleted successfully');
        this.commonservice.removeLoader();
        this.getClubNotableAluminieData(
          this.ClubNotableAluminieDetails[this.setIndex].club_id
        );
      });
  }

  cancelClubNotableAluminiData() {
    this.clubNotableAlumini = {
      course_id: '',
      notable_alumini_first_name: '',
      notable_alumini_last_name: '',
      notable_alumini_achievement: '',
      notable_alumini_year: '',
      notable_alumini_linked_in: '',
    };
    this.addNotable_image = '';
  }

  //FAQ's
  getFAQData(id: string | null) {
    this.data
      .dataJsonLoad(
        `${this.getClubFAQUrl}${id}`,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`ClubFaqDetails:`, res);
        this.ClubFaqDetails = res.data;
        this.commonservice.afterresponse();
        this.commonservice.removeLoader();
      });
  }

  addFAQData(club_id: any) {
    this.atertmessage = [];

    this.commonservice.addLoader();
    const formData = new FormData();

    formData.append('club_id', club_id);
    formData.append('question', this.universitySport.faqTitle);
    formData.append('answer', this.universitySport.faqdescription);

    if (this.universitySport.faqTitle === '') {
      this.atertmessage.push(' Question');
    }
    if (this.universitySport.faqdescription === '') {
      this.atertmessage.push(' Answer');
    }

    if (
      this.universitySport.faqTitle === '' ||
      this.universitySport.faqdescription === ''
    ) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.addClubFAQUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`Faqdata:`, res.data);
          this.data.SuccessToastr('FAQ created successfully ');
          this.addFaq = false;
          this.CancelSportData();
          this.commonservice.removeLoader();
          this.ClubData(club_id);
          this.getFAQData(club_id);
        });
    }
  }

  editFAQeData(clubData: any) {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();

    formData.append('question', clubData.question);
    formData.append('answer', clubData.answer);

    formData.append('faq_id', clubData.id);
    formData.append('club_id', clubData.club_id);

    if (clubData.title === '') {
      this.atertmessage.push(' Question');
    }
    if (clubData.description === '') {
      this.atertmessage.push(' Answer');
    }

    if (clubData.title === '' || clubData.description === '') {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          `${this.editClubFAQUrl}${clubData.id}`,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`faqData:`, res.data);
          this.data.SuccessToastr(' FAQ updated successfully ');
          this.commonservice.editclubfaq();
          this.commonservice.removeLoader();
          // this.ClubData(clubData.club_id);

          this.editFaq = false;
          this.getFAQData(clubData.club_id);
        });
    }
  }

  deleteFAQData() {
    const formData = new FormData();

    formData.append('faq_id', this.ClubFaqDetails[this.setIndex].id);

    this.data
      .datatoPost(
        this.deleteClubFAQUrl,
        formData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        this.data.SuccessToastr(' FAQ deleted successfully');
        this.commonservice.removeLoader();
        // this.ClubData(this.ClubFaqDetails[this.setIndex].club_id);
        this.getFAQData(this.ClubFaqDetails[this.setIndex].club_id);
      });
  }

  //club sport news
  AddClubSportNewseData(club_id: any) {
    this.atertmessage = [];

    this.commonservice.addLoader();
    const formData = new FormData();

    formData.append('club_id', club_id);
    // formData.append('commities_fname', this.universitySport.commities_fname);
    formData.append(
      'Notable_description',
      this.universitySport.Notable_description
    );

    // if (this.universitySport.commities_fname === '') {
    //   this.atertmessage.push(' First Name');
    // }
    if (this.universitySport.Notable_description === '') {
      this.atertmessage.push(' Description');
    }

    if (this.universitySport.Notable_description === '') {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.addClubSportNewsUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          this.data.SuccessToastr('Add Successfully ');

          this.CancelSportData();
          this.commonservice.removeLoader();
          this.ClubData(club_id);
        });
    }
  }

  editClubSportNewseData(clubData) {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();

    formData.append('faq_titlt', clubData.title);
    formData.append('faq_description', clubData.description);

    formData.append('club_FAQe_id', clubData.id);
    formData.append('club_id', clubData.club_id);

    if (clubData.title === '') {
      this.atertmessage.push(' Title');
    }
    if (clubData.description === '') {
      this.atertmessage.push(' Description');
    }

    if (clubData.title === '' || clubData.description === '') {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.editClubSportNewsUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`faqData:`, res.data);
          this.data.SuccessToastr('Edit Successfully ');

          this.commonservice.removeLoader();
          this.ClubData(clubData.club_id);
        });
    }
  }

  deleteClubSportNewseData() {
    const formData = new FormData();

    formData.append(
      'club_faq_id',
      this.clubDetails.commities[this.setIndex].id
    );

    this.data
      .datatoPost(
        this.deleteClubSportNewsUrl,
        formData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        this.data.SuccessToastr(' Delete Successfully');
        this.commonservice.removeLoader();
        this.ClubData(this.clubDetails.commities[this.setIndex].club_id);
      });
  }

  //club Coach
  getCoachData(id: string | null) {
    this.data
      .dataJsonLoad(
        `${this.getClubCoachUrl}${id}`,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`ClubCoachDetails:`, res);
        this.ClubCoachDetails = res.data;
        this.commonservice.afterresponse();
        this.commonservice.removeLoader();
      });
  }

  AddClubCoachData(club_id: any) {
    this.atertmessage = [];

    this.commonservice.addLoader();
    const formData = new FormData();

    formData.append('club_id', club_id);
    formData.append('coach_name', this.universitySport.coach_name);
    formData.append('coach_type', this.universitySport.coach_type);
    formData.append(
      'coach_information',
      this.universitySport.coach_information
    );

    if (this.universitySport.coach_name === '') {
      this.atertmessage.push(' Coach Name');
    }
    if (this.universitySport.coach_type === '') {
      this.atertmessage.push(' Coach Type');
    }
    if (this.universitySport.coach_information === '') {
      this.atertmessage.push(' Coach Information');
    }

    if (
      this.universitySport.coach_name === '' ||
      this.universitySport.coach_type === '' ||
      this.universitySport.coach_information === ''
    ) {
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.addClubCoachUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          this.data.SuccessToastr('Club Coach created');
          this.addCoaching = false;
          this.CancelSportData();
          this.commonservice.removeLoader();
          this.getCoachData(club_id);
        });
    }
  }

  editClubCoachData(clubData) {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();

    formData.append('coach_name', clubData.coach_name);
    formData.append('coach_type', clubData.coach_type);
    formData.append('coach_information', clubData.coach_information);

    formData.append('club_id', clubData.club_id);

    if (clubData.coach_name === '') {
      this.atertmessage.push(' Coach Name');
    }
    if (clubData.coach_type === '') {
      this.atertmessage.push(' Coach Type');
    }
    if (clubData.coach_information === '') {
      this.atertmessage.push(' Coach Information');
    }

    if (
      clubData.coach_name === '' ||
      clubData.coach_type === '' ||
      clubData.coach_information === ''
    ) {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          `${this.editClubCoachUrl}${clubData.id}`,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          console.log(`faqData:`, res.data);
          this.data.SuccessToastr('Club Coach edited ');

          this.editCoach = false;
          this.commonservice.removeLoader();
          this.commonservice.editclubCoachingTeam();
          this.getCoachData(clubData.club_id);
        });
    }
  }

  deleteClubCoachData() {
    const formData = new FormData();

    formData.append('coach_id', this.ClubCoachDetails[this.setIndex].id);

    this.data
      .datatoPost(
        this.deleteClubCoachUrl,
        formData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        this.data.SuccessToastr(' Club coach deleted');
        this.commonservice.removeLoader();
        this.getCoachData(this.ClubCoachDetails[this.setIndex].club_id);
      });
  }

  //universities reply
  reviewReplyData(reviewId, universityId) {
    this.atertmessage = [];
    this.commonservice.addLoader();

    const formData = new FormData();

    formData.append('reply_to', reviewId);
    formData.append('to_id', universityId);

    formData.append('description', this.reviewReply.replyDescription);

    if (this.reviewReply.replyDescription === '') {
      this.atertmessage.push('Reply Description');
    }

    if (this.reviewReply.replyDescription === '') {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;
        this.commonservice.removeLoader();
      }
      this.data.showError(msg);
    } else {
      this.data
        .datatoPost(
          this.reviewReplyUrl,
          formData,
          this.data.setHeaders(this.param)
        )
        .subscribe((res: any) => {
          this.data.SuccessToastr('Review Submitted');
          this.replayreview = false;
          this.universityDetailsservice.dataLoad(
            this.universityDetailsservice.universityDetails.slug
          );
          this.reviewReplycancelData();
        });
    }
  }

  reviewReplycancelData() {
    this.reviewReply = {
      replyDescription: '',
    };
    this.replayreview = false;
  }

  //

  saveCourseUploadFile() {
    this.atertmessage = [];
    this.commonservice.addLoader();
    const formData = new FormData();
    formData.append('file', this.file);

    if (!this.file) {
      this.data.showError('File Required!');
    }

    this.data
      .datatoPost(
        this.coutseUploadFileUrl,
        formData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        this.data.SuccessToastr('File Upload Successfully');
        this.courseUploadFile = false;
        this.file = '';
        this.selectedFileName = '';
        this.commonservice.closeCloseDialog();
        this.getCoursedata();
        this.reviewReplycancelData();
      });
  }

  deleteCourseUploadcancelFile() {
    this.courseUploadFile = false;
    this.file = '';
    this.selectedFileName = '';
  }
}
