import { Injectable, EventEmitter, Output } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { tap, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    public toastr: ToastrService,
    private http: HttpClient,
    private router: Router,
    private commonservice: CommonService
  ) {}
  dataJsonLoad(
    path: string,
    headers: {
      headers?:
        | HttpHeaders
        | { [header: string]: string | string[] }
        | undefined;
      observe?: 'body' | undefined;
      params?: HttpParams | { [param: string]: string | string[] } | undefined;
      reportProgress?: boolean | undefined;
      responseType: 'arraybuffer';
      withCredentials?: boolean | undefined;
    }
  ): any {
    // this.commonservice.addLoader();
    return this.http.get(path, headers).pipe(
      tap(
        (res: any) => {
          // console.log(res);
        },
        (error) => {
          console.log(error);
          if (error.statusText === 'Unauthorized' || error.status == 422) {
            this.commonservice.removeLoader();
            this.toastr.error('Unauthorized Tokan!');
            localStorage.removeItem('USER_DETAILS');
            localStorage.removeItem('ACCESS_TOKEN');
            // this.commonservice.isuserlogin = false;
            this.router.navigate(['login']);
          } else {
            this.commonservice.removeLoader();
            if (!navigator.onLine) {
              this.noInternetConnection();
            } else {
              this.toastr.error('Oops something went wrong, Please try again');
            }
          }
        }
      )
    );
  }

  setHeaders(params: {
    [x: string]: any;
    limit?: number;
    offset?: number;
  }): any {
    // const accessToken = localStorage.getItem('ACCESS_TOKEN');
    // const reqData = {
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   },

    // };

    let accessToken = localStorage.getItem('ACCESS_TOKEN');
    if (!accessToken) {
      accessToken =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9zZWN1cmUtb3BlbmFwaS10b2tlbiIsImlhdCI6MTYyMjEwOTUwMiwiZXhwIjoxNjUzNjQ1NTAyLCJuYmYiOjE2MjIxMDk1MDIsImp0aSI6ImRXaFpOa1pzWUNUbGVYWkMiLCJzdWIiOjEsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.atFq64Q9hDMiMDUF8rNIERX9a0NgogU79aa1z3hCZqc';
    }
    const reqData = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    // if (params) {
    //   const reqParams = {};
    //   Object.keys(params).map(k => {
    //     reqParams[k] = params[k];
    //   });

    //   reqData['params'] = reqParams;
    // }
    return reqData;
  }

  deleteObject(
    path: string,
    body: any,
    header: {
      headers?:
        | HttpHeaders
        | { [header: string]: string | string[] }
        | undefined;
      observe: 'events';
      params?: HttpParams | { [param: string]: string | string[] } | undefined;
      reportProgress?: boolean | undefined;
      responseType?: 'json' | undefined;
      withCredentials?: boolean | undefined;
    }
  ): any {
    this.commonservice.addLoader();
    return this.http.delete<any>(path, header).pipe(
      tap(
        (res: any) => {
          console.log(res);
          this.commonservice.removeLoader();
          // this.toastr.success('deleted successfully');
        },
        (error) => {
          console.log(error);
          this.commonservice.removeLoader();
          if (!navigator.onLine) {
            this.noInternetConnection();
          } else {
            this.toastr.error('Oops something went wrong, Please try again');
          }
        }
      )
    );
  }

  updataObject(
    path: string,
    body: any,
    header: {
      headers?:
        | HttpHeaders
        | { [header: string]: string | string[] }
        | undefined;
      observe: 'events';
      params?: HttpParams | { [param: string]: string | string[] } | undefined;
      reportProgress?: boolean | undefined;
      responseType?: 'json' | undefined;
      withCredentials?: boolean | undefined;
    }
  ): any {
    // this.commonservice.addLoader();

    return this.http.put<any>(path, body, header).pipe(
      tap(
        (res: any) => {
          console.log(res);
          this.commonservice.removeLoader();

          // this.toastr.success('changed successfully');
        },
        (error) => {
          console.log(error);
          this.commonservice.removeLoader();
          if (!navigator.onLine) {
            this.noInternetConnection();
          } else {
            this.toastr.error('Oops something went wrong, Please try again');
          }
        }
      )
    );
  }

  datatoPost(
    path: string,
    body: any,
    header: {
      headers?:
        | HttpHeaders
        | { [header: string]: string | string[] }
        | undefined;
      observe: 'events';
      params?: HttpParams | { [param: string]: string | string[] } | undefined;
      reportProgress?: boolean | undefined;
      responseType?: 'json' | undefined;
      withCredentials?: boolean | undefined;
    }
  ) {
    //  this.commonservice.addLoader();

    return this.http.post<any>(path, body, header).pipe(
      tap(
        (res: any) => {
          console.log(res);
          this.commonservice.removeLoader();
          // this.toastr.success(' done');
        },
        (error) => {
          // console.clear();
          console.log(error);
          console.log(path);
          this.commonservice.removeLoader();
          if (error.error.file) {
            this.toastr.error(error.error.file[0]);
          } else if (error.error.notable_alumini_photo) {
            this.toastr.error(error.error.notable_alumini_photo[0]);
          } else if (!navigator.onLine) {
            this.noInternetConnection();
          } else {
            this.toastr.error('Oops something went wrong, Please try again');
          }

          // this.toastr.success(' Add Successfully');
        }
      )
    );
  }

  //delete
  dataDeletetoPost(
    path: string,
    header: {
      headers?:
        | HttpHeaders
        | { [header: string]: string | string[] }
        | undefined;
      observe: 'events';
      params?: HttpParams | { [param: string]: string | string[] } | undefined;
      reportProgress?: boolean | undefined;
      responseType?: 'json' | undefined;
      withCredentials?: boolean | undefined;
    }
  ) {
    //  this.commonservice.addLoader();

    return this.http.post<any>(path, header).pipe(
      tap(
        (res: any) => {
          console.log(res);
          this.commonservice.removeLoader();
          // this.toastr.success(' done');
        },
        (error) => {
          console.log(error);
          this.commonservice.removeLoader();
          if (!navigator.onLine) {
            this.noInternetConnection();
          } else {
            this.toastr.error('Oops something went wrong, Please try again');
          }
        }
      )
    );
  }

  datatoPostEmail(
    path: string,
    body: any,
    header: {
      headers?:
        | HttpHeaders
        | { [header: string]: string | string[] }
        | undefined;
      observe: 'events';
      params?: HttpParams | { [param: string]: string | string[] } | undefined;
      reportProgress?: boolean | undefined;
      responseType?: 'json' | undefined;
      withCredentials?: boolean | undefined;
    }
  ): any {
    //  this.commonservice.addLoader();
    return this.http.post<any>(path, body, header).pipe(
      tap(
        (res: any) => {
          console.log(res);
          this.commonservice.removeLoader();
          // this.toastr.success(' done');
        },
        (error) => {
          console.log(error);
          this.commonservice.removeLoader();
          if (!navigator.onLine) {
            this.noInternetConnection();
          } else {
            this.toastr.error('The email does not exists');
          }
        }
      )
    );
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }

  showError(message: string) {
    // this.toastr.success(`<i>gfgfgdfgdfg</i>`);

    this.commonservice.removeLoader();
    this.toastr.error(message);
  }

  SuccessToastr(message: string) {
    this.commonservice.removeLoader();
    this.toastr.success(message);
    // this.toastr.success(' Successfully Delete');
  }

  loginNotify() {
    this.toastr.warning('Please login ');
  }

  datatoPostOtp(
    path: string,
    body: any,
    header: {
      headers?:
        | HttpHeaders
        | { [header: string]: string | string[] }
        | undefined;
      observe: 'events';
      params?: HttpParams | { [param: string]: string | string[] } | undefined;
      reportProgress?: boolean | undefined;
      responseType?: 'json' | undefined;
      withCredentials?: boolean | undefined;
    }
  ) {
    //  this.commonservice.addLoader();

    return this.http.post<any>(path, body, header).pipe(
      tap(
        (res: any) => {
          console.log(res);
          this.commonservice.removeLoader();
          // this.toastr.success(' done');
        },
        (error) => {
          console.log(error);
          this.commonservice.removeLoader();
          if (!navigator.onLine) {
            this.noInternetConnection();
          } else {
            this.toastr.error('Please enter valid OTP');
          }
        }
      )
    );
  }

  datatoPostvalidemail(
    path: string,
    body: any,
    header: {
      headers?:
        | HttpHeaders
        | { [header: string]: string | string[] }
        | undefined;
      observe: 'events';
      params?: HttpParams | { [param: string]: string | string[] } | undefined;
      reportProgress?: boolean | undefined;
      responseType?: 'json' | undefined;
      withCredentials?: boolean | undefined;
    }
  ) {
    //  this.commonservice.addLoader();

    return this.http.post<any>(path, body, header).pipe(
      tap(
        (res: any) => {
          console.log(res);
          this.commonservice.removeLoader();
          // this.toastr.success(' done');
        },
        (error) => {
          this.commonservice.removeLoader();
          if (!navigator.onLine) {
            this.noInternetConnection();
          } else {
            this.toastr.error('Email already exist');
          }
          // console.log(error);
        }
      )
    );
  }
  noInternetConnection() {
    this.toastr.error('No internet connection!');
  }
}
