import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { MatSelectModule } from '@angular/material/select';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { UniversityAdminComponent } from './university-admin/university-admin.component';
import { UniversityProfilesComponent } from './university-profiles/university-profiles.component';
import { ProfielDetailComponent } from './profiel-detail/profiel-detail.component';
import { LeadsComponent } from './leads/leads.component';
import { LoginComponent } from './login/login.component';
import { LoginSliderComponent } from './login/login-slider/login-slider.component';
import { Signup1Component } from './register/signup1/signup1.component';
import { Signup2Component } from './register/signup2/signup2.component';
import { Signup3Component } from './register/signup3/signup3.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeaddilogComponent } from './dialog/leaddilog/leaddilog.component';
import { AlertComponent } from './alert/alert.component';
import { VideoPopupComponent } from './dialog/video-popup/video-popup.component';
import { SportdataComponent } from './sportdata/sportdata.component';
import { SportdilogComponent } from './sportdilog/sportdilog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { Signup4Component } from './register/signup4/signup4.component';
import { Signup5Component } from './register/signup5/signup5.component';

import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LySliderModule } from '@alyle/ui/slider';
// import { ImageCropperExample01Component } from './image-cropper-example-01.component';
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';


import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

/** Alyle UI */
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LY_THEME_GLOBAL_VARIABLES,
  LyHammerGestureConfig
} from '@alyle/ui';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { color } from '@alyle/ui/color';
import { EditSportComponent } from './edit-sport/edit-sport.component';
import { EditUniversityDetailsComponent } from './edit-universityDetails/edit-universityDetails.component';
import { EditUniversityProfileComponent } from './edit-universityProfile/edit-universityProfile..component';
import { ResetpasswordComponent } from './login/resetpassword/resetpassword.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InterUniverDiologComponent } from './dialog/inter-univer-diolog/inter-univer-diolog.component';
import { UploadCourseComponent } from './dialog/upload-course/upload-course.component';

export class GlobalVariables {
  testVal = color(0x00bcd4);
  Quepal = {
    default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
    contrast: color(0xffffff),
    shadow: color(0x11998e)
  };
  SublimeLight = {
    default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
    contrast: color(0xffffff),
    shadow: color(0xB36FBC)
  };
  Amber = {
    default: color(0xffc107),
    contrast: color(0, 0, 0, 0.87)
  };
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UniversityAdminComponent,
    UniversityProfilesComponent,
    ProfielDetailComponent,
    LeadsComponent,
    LoginComponent,
    LoginSliderComponent,
    Signup1Component,
    Signup2Component,
    Signup3Component,
    LeaddilogComponent,
    AlertComponent,
    VideoPopupComponent,
    SportdataComponent,
    SportdilogComponent,
    Signup4Component,
    Signup5Component,
    EditSportComponent,
    EditUniversityDetailsComponent,
    EditUniversityProfileComponent,
    ResetpasswordComponent,
    InterUniverDiologComponent,
    UploadCourseComponent
  ],
  imports: [
    // SocialLoginModule,
    AngularEditorModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ScrollingModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    // MatDialogModule,
    // MatDatepickerModule,
    // MatTableModule,
    // MatPaginatorModule,
    DataTablesModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxDatatableModule,
    HammerModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    ToastrModule.forRoot(),
    ToastNoAnimationModule.forRoot(),
    BlockUIModule,
    BlockUIModule.forRoot(),
    ReactiveFormsModule,
    NgSelectModule,
    NgxIntlTelInputModule,
    NgbModule,
  ],
  // providers: [

  // ],
  providers: [[LyTheme2],
  [StyleRenderer],
  { provide: LY_THEME_NAME, useValue: 'minima-light' },
  {
    provide: LY_THEME,
    useClass: MinimaLight,
    multi: true
  },
  {
    provide: LY_THEME,
    useClass: MinimaDark,
    multi: true
  },
  {
    provide: LY_THEME_GLOBAL_VARIABLES,
    useClass: GlobalVariables
  }, // global variables
  // Gestures
  { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
  // {
  //   provide: 'SocialAuthServiceConfig',
  //   useValue: {
  //     autoLogin: false,
  //     providers: [
  //       {
  //         id: GoogleLoginProvider.PROVIDER_ID,
  //         provider: new GoogleLoginProvider(
  //           '420458759350-abjrqa386duhuc01r80a9th7vr4cnt1i.apps.googleusercontent.com'
  //         )
  //       },
  //       {
  //         id: FacebookLoginProvider.PROVIDER_ID,
  //         provider: new FacebookLoginProvider('228315472420055')
  //       }
  //     ],
  //     onError: (err) => {
  //       console.error(err);
  //     }
  //   } as SocialAuthServiceConfig,
  // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
