import { Injectable, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { VideoPopupComponent } from '../dialog/video-popup/video-popup.component';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  @Output() change: EventEmitter<any> = new EventEmitter();
  emitObject = { dataToEmit: '', selectedObjectName: '' };

  currentUser: any;

  isUserLoggedIn = false;


  singupOneComplated = false;
  singupTwoComplated = false;

  // configurl = 'https://staging-admin.sportfolio.co.uk/api/';
  configurl = 'https://services.sportfolio.co.uk/api/';

  constructor(private router: Router ,public dialog: MatDialog ) {
    let user = localStorage.getItem('USER_DETAILS') as string;
    this.currentUser = JSON.parse(user);
  }

  loadScripts() {
    const dynamicScripts = [
      // './assets/vendor/chart.js/Chart.js',
      // './assets/js/admin-charts.js',
      './assets/js/scroll.js',
      // './assets/validate.js',
      // './assets/js/mobile.js'

    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
      // $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
    }
  }
  removeLoader() {
    // window.scrollTo(0, 0);
    $('#preloader').delay(350).fadeOut('slow');
    this.emitObject.dataToEmit = 'stopLoader';
    this.emitObject.selectedObjectName = '';
    this.change.emit(this.emitObject);
  }
  login() {
    this.emitObject.dataToEmit = 'login';
    this.emitObject.selectedObjectName = '';
    this.change.emit(this.emitObject);
  }
  signup1() {
    this.emitObject.dataToEmit = 'signup1';
    this.emitObject.selectedObjectName = '';
    this.change.emit(this.emitObject);
  }

  addLoader(): void {
    this.emitObject.dataToEmit = 'startLoader';
    this.emitObject.selectedObjectName = '';
    this.change.emit(this.emitObject);
  }

  stopLoader(): void {
    this.emitObject.dataToEmit = 'stopLoader';
    this.emitObject.selectedObjectName = '';
    this.change.emit(this.emitObject);
  }
  afterresponse(): void {
    this.emitObject.dataToEmit = 'afterresponse';
    this.emitObject.selectedObjectName = '';
    this.change.emit(this.emitObject);
  }

  loadedUniData(): void {
    this.emitObject.dataToEmit = 'loadedUniData';
    this.emitObject.selectedObjectName = '';
    this.change.emit(this.emitObject);
  }

  editaddedNotableUnni() {
    this.emitObject.dataToEmit = 'editaddedNotableUnni';
    this.change.emit(this.emitObject);
   }

   editsaveCoursedata() {
    this.emitObject.dataToEmit = 'editsaveCoursedata';
    this.change.emit(this.emitObject);
  }
  editScholarshipadded() {
    this.emitObject.dataToEmit = 'editScholarshipadded';
    this.change.emit(this.emitObject);
  }

  //club
  editclubNotableUnni() {
    this.emitObject.dataToEmit = 'editclubNotableUnni';
    this.change.emit(this.emitObject);
   }

   editclubCommitee() {
    this.emitObject.dataToEmit = 'editclubCommitee ';
    this.change.emit(this.emitObject);
  }
  editclubfaq() {
    this.emitObject.dataToEmit = 'editclubfaq';
    this.change.emit(this.emitObject);
  }
  editclubNoticBoard() {
    this.emitObject.dataToEmit = 'editclubNoticBoard';
    this.change.emit(this.emitObject);
  }
  editclubCoachingTeam() {
    this.emitObject.dataToEmit = 'editclubCoachingTeam';
    this.change.emit(this.emitObject);
  }
  closeCloseDialog() {
    this.emitObject.dataToEmit = 'closeCloseDialog';
    this.change.emit(this.emitObject);
  }
  gotoScroll(el){
    setTimeout(() => {
      $('html, body').animate({
        scrollTop: $(`#${el}`).offset().top-70
      }, 80, function(){
        // event.preventDefault();
      });
    }, 800);
  }

  removePreLoader() {
    // window.scrollTo(0, 0);
    $('#preloader').delay(350).fadeOut('slow');
  }

  //video
  loadScriptsvideo() {
    const dynamicScripts = [
      './assets/js/jquery-3.5.1.min.js',
      './assets/js/common_scripts.js',
      './assets/js/main.js',
      './assets/validate.js',
      './assets/js/mobile.js'
    ];
    this.scripLoader(dynamicScripts);

  }

  loadspecialscript() {
    const dynamicScripts = [
      './assets/js/modernizr.js',
      './assets/js/jquery-3.5.1.min.js',
      './assets/js/common_scripts.js',
      './assets/js/main.js',
      './assets/validate.js',
      './assets/js/mobile.js',
      './assets/js/video_header.js',
      './assets/js/vodeorunner.js'
    ];
  this.scripLoader(dynamicScripts);
  }

  scripLoader(dynamicScripts: string | any[]) {
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
      // $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
    }
  }


  openVideo(url: any){

    this.dialog.open(VideoPopupComponent, {
      height: '79vh',
      width: '67%',
      data: {
        url: url
      }
    });
  }

}
