<div class="content-wrapper">
  <div *ngIf="!universityService.sportClubId">
    <section class="add_top_20 backgroudphoto">
      <div>
        <div
          *ngIf="
            universityService.clubDetails.banner === null &&
            universitydetailsService.universityDetails.university_banner
          "
        >
          <img
            style="height: 350px; width: 100%"
            src="{{
              universitydetailsService.universityDetails.university_banner.url
            }}"
          />
          <!-- <div class="wrapper" style="background: url({{universitydetailsService.universityDetails.university_banner.url}});">
                    </div> -->
        </div>

        <img
          *ngIf="
            universityService.clubDetails.banner === null &&
            universitydetailsService.universityDetails.university_banner ===
              null
          "
          style="height: 350px; width: 100%; border-bottom: 2px solid black"
          src="{{
            this.universitydetailsService.webConfigDetailsData[6].config_value
          }}"
        />

        <div *ngIf="universityService.clubDetails.banner">
          <img
            *ngIf="universityService.clubDetails.banner.url !== null"
            style="height: 350px; width: 100%"
            src="{{ universityService.clubDetails.banner.url }}"
          />
        </div>
      </div>
    </section>

    <div class="paddingDIV">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-sm-12 col-12">
          <section class="add_top_20">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
              "
            >
              <div style="display: flex">
                <div *ngIf="universityService.clubDetails.logo">
                  <img
                    *ngIf="universityService.clubDetails.logo === null"
                    src="../assets/img/MaskGroup.png"
                    style="height: 65px; width: 65px"
                  />

                  <img
                    *ngIf="universityService.clubDetails.logo.url"
                    src="{{ universityService.clubDetails.logo.url }}"
                    style="height: 65px; width: 65px"
                  />
                </div>

                <div
                  *ngIf="universityService.clubDetails"
                  style="margin-left: 1rem"
                >
                  <h2
                    class="poppins-medium firstlatterCapital"
                    style="font-size: 24px !important"
                    *ngIf="universityService.clubDetails"
                  >
                    {{ universityService.clubDetails.name }}
                  </h2>
                  <div
                    class="text3 poppinsemibold firstlatterCapital"
                    *ngIf="universityService.clubDetails.sport"
                  >
                    {{ universityService.clubDetails.sport.name }}
                    <span
                      *ngIf="
                        universityService.clubDetails.country &&
                        universityService.clubDetails.country.name !== null
                      "
                      >, {{ universityService.clubDetails.country.name }}</span
                    >
                  </div>
                </div>
              </div>

              <div>
                <ul class="social_teacher">
                <li *ngIf="universityService.clubDetails.facebook !== null">
                  <a
                    target="_blank"
                    href="{{ universityService.clubDetails.facebook }}"
                  >
                    <i class="fa fa-facebook iconbtn" aria-hidden="true"></i>
                  </a>
                </li>
                <li *ngIf="universityService.clubDetails.twitter !== null">
                  <a
                    target="_blank"
                    href="{{ universityService.clubDetails.twitter }}"
                  >
                    <i class="fa fa-twitter iconbtn" aria-hidden="true"></i>
                  </a>
                </li>
                <li *ngIf="universityService.clubDetails.instagram !== null">
                  <a
                    target="_blank"
                    href="{{ universityService.clubDetails.instagram }}"
                  >
                    <i class="fa fa-instagram iconbtn" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
              <div
                *ngIf="universityService.clubDetails.is_performance_sport"
                class="text3 poppinsemibold"
                style="color: #21287f"
              >
                Performance Sport
                <span
                  matTooltip="A Performance Sport Team is aimed at high performing athletes. These sports are taken seriously and typically receive more resources, funding and support."
                >
                  (i)
                </span>
              </div>
              </div>
            </div>
            <!-- <div *ngIf="universityService.clubDetails.description !== null" class="textDesc add_top_20">
                        {{universityService.clubDetails.description}}
                        </div> -->
          </section>

          <!-- <section>
                        <div class="border_shadow" *ngIf="universityService.clubDetails.email !== null || universityService.clubDetails.website !== null ">

                            <div style="display: flex; " *ngIf="universityService.clubDetails.email !== null">
                                <div>
                                    <mat-icon class="emailtext "> email</mat-icon>
                                </div>
                                <div class="emailtext poppins-medium " style="margin-left: 7px;
                                      word-wrap: break-word; width: 75%;">
                                    {{universityService.clubDetails.email}}
                                </div>
                            </div>

                            <div style="display: flex; " *ngIf="universityService.clubDetails.website !== null" class="websitecolor">
                                <div>
                                    <mat-icon>link</mat-icon>
                                </div>
                                <div class=" poppins-medium" (click)="onNavigate(universityService.clubDetails.website)" style="margin-left: 7px; cursor: pointer;  word-wrap: break-word; width: 75%;">{{universityService.clubDetails.website}} </div>
                            </div>
                        </div>
                    </section> -->

          <!-- Overview -->
          <section *ngIf="universityService.clubDetails.description !== null">
            <div class="border_shadow">
              <div class="marginbottom">
                <div class="poppins-medium lbl2">Overview</div>
                <div class="textDesc add_top_20">
                  <div id="descriptionData">
                    <div
                      class="text3 poppinsregular"
                      innerHtml="{{
                        universityService.clubDetails.description
                      }}"
                    ></div>
                  </div>

                  <!-- <div class="text3 poppinsregular"> {{universityService.clubDetails.description}}</div> -->
                </div>
              </div>
            </div>
          </section>

          <!-- Club Commitee -->
          <section *ngIf="universityService.clubDetails.commities">
            <div
              class="border_shadow"
              *ngIf="universityService.clubDetails.commities.length !== 0"
            >
              <div class="marginbottom">
                <div class="poppins-medium lbl2">Club Commitee</div>
                <div
                  class="row add_top_20 add_bottom_30"
                  style="padding: 0 15px"
                >
                  <div
                    *ngFor="
                      let clubcommities of universityService.clubDetails
                        .commities;
                      index as i
                    "
                  >
                    <ul class="list_teachers">
                      <li>
                        <a>
                          <!-- <figure style="height: 65px; width: 65px; border-radius: 0px">
                                                        <img src={{clubcommities.image}} style="height: 65px; width: 65px;" alt="">
                                                    </figure> -->
                          <h5 class="text3 poppins-medium firstlatterCapital">
                            {{ clubcommities.first_name }}
                            {{ clubcommities.last_name }}
                          </h5>
                          <h5 class="text3 poppins-medium">
                            {{ clubcommities.email }}
                          </h5>
                          <div id="descriptionData">
                            <div
                              class="text3 poppinsregular"
                              innerHtml="{{ clubcommities.description }}"
                            ></div>
                          </div>

                          <!-- <div class="text3 poppinsregular">{{clubcommities.description}}</div> -->
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Basic Information -->
          <section
            *ngIf="universityService.clubDetails.basic_information !== null"
          >
            <div class="border_shadow">
              <div class="marginbottom">
                <div class="poppins-medium lbl2">Basic Information</div>
                <div class="textDesc add_top_20">
                  <div id="descriptionData">
                    <div
                      class="text3 poppinsregular"
                      innerHtml="{{
                        universityService.clubDetails.basic_information
                      }}"
                    ></div>
                  </div>
                  <!-- <div class="text3 poppinsregular">{{universityService.clubDetails.basic_information}}</div> -->
                </div>
              </div>
            </div>
          </section>

          <!-- Coaching Team -->
          <section *ngIf="universityService.clubDetails.coaching_team">
            <div
              class="border_shadow"
              *ngIf="universityService.clubDetails.coaching_team.length !== 0"
            >
              <div class="marginbottom">
                <div class="poppins-medium lbl2">Coaching Team</div>
                <div class="textDesc add_top_20">
                  <div
                    *ngFor="
                      let coach of universityService.clubDetails.coaching_team;
                      index as i
                    "
                    style="margin-top: 1rem"
                  >
                    <div class="select1 poppins-medium firstlatterCapital">
                      {{ coach.coach_name }}
                    </div>
                    <div class="select1 poppins-medium firstlatterCapital">
                      {{ coach.coach_type }}
                    </div>
                    <!-- <div class="text3 poppinsregular">{{coach.coach_information}}</div> -->
                    <div id="descriptionData">
                      <div
                        class="text3 poppinsregular"
                        innerHtml="{{ coach.coach_information }}"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Training Times -->
          <section
            *ngIf="universityService.clubDetails.training_times !== null"
          >
            <div class="border_shadow">
              <div class="marginbottom">
                <div class="poppins-medium lbl2">Training Times</div>
                <div class="textDesc add_top_20">
                  <div id="descriptionData">
                    <div
                      class="text3 poppinsregular"
                      innerHtml="{{
                        universityService.clubDetails.training_times
                      }}"
                    ></div>
                  </div>
                  <!-- <div class="text3 poppinsregular">
                                        {{universityService.clubDetails.training_times}}
                                    </div> -->
                </div>
              </div>
            </div>
          </section>

          <!-- Performance Overview -->
          <section
            *ngIf="
              universityService.clubDetails.performance_information !== null
            "
          >
            <div class="border_shadow">
              <div class="marginbottom">
                <div class="poppins-medium lbl2">Performance Overview</div>
                <div class="textDesc add_top_20">
                  <div id="descriptionData">
                    <div
                      class="text3 poppinsregular"
                      innerHtml="{{
                        universityService.clubDetails.performance_information
                      }}"
                    ></div>
                  </div>
                  <!-- <div class="text3 poppinsregular"> {{universityService.clubDetails.performance_information}}</div> -->
                </div>
              </div>
            </div>
          </section>

          <!-- Notable Alumni-->
          <section *ngIf="universityService.clubDetails.club_notable_alumni">
            <div
              class="border_shadow"
              *ngIf="
                universityService.clubDetails.club_notable_alumni.length !== 0
              "
            >
              <div class="marginbottom">
                <div class="poppins-medium lbl2">Notable Alumni</div>
                <div class="row add_top_20 add_bottom_30">
                  <div
                    class="col-lg-6"
                    *ngFor="
                      let notableData of universityService.clubDetails
                        .club_notable_alumni;
                      index as i
                    "
                  >
                    <ul class="list_teachers">
                      <li>
                        <a>
                          <figure
                            style="
                              height: 65px;
                              width: 65px;
                              border-radius: 0px;
                            "
                            *ngIf="notableData.profile_image"
                          >
                            <img
                              src="{{ notableData.profile_image }}"
                              style="height: 65px; width: 65px"
                              alt=""
                            />
                          </figure>
                          <figure
                            style="
                              height: 65px;
                              width: 65px;
                              border-radius: 0px;
                            "
                            *ngIf="notableData.profile_image === null"
                          >
                            <img
                              src="{{
                                this.universitydetailsService
                                  .webConfigDetailsData[5].config_value
                              }}"
                              alt=""
                            />
                          </figure>
                          <h5
                            class="text3 poppins-medium firstlatterCapital"
                            style="color: #979797 !important"
                          >
                            {{ notableData.first_name }}
                            {{ notableData.last_name }}
                          </h5>
                          <h5
                            class="textdatalink poppins-medium"
                            (click)="onNavigate(notableData.linked_in)"
                          >
                            {{ notableData.linked_in }}
                          </h5>
                          <div
                            class="text3 poppinsemibold"
                            style="color: #979797 !important"
                          >
                            {{ notableData.achievement }} {{ notableData.year }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Notice Board -->
          <section *ngIf="universityService.clubDetails.notice_board">
            <div
              class="border_shadow"
              *ngIf="universityService.clubDetails.notice_board.length !== 0"
            >
              <div class="marginbottom">
                <div class="poppins-medium lbl2">Notice Board</div>
                <div class="textDesc add_top_20">
                  <div
                    *ngFor="
                      let club of universityService.clubDetails.notice_board;
                      index as i
                    "
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                    >
                      <div>
                        <div class="select1 poppins-medium firstlatterCapital">
                          {{ club.title }}
                        </div>
                      </div>
                      <div class="select1 poppins-Bold">
                        {{ club.updated_at | date : "shortDate" }}
                      </div>
                    </div>
                    <div id="descriptionData">
                      <div
                        class="text3 poppinsregular"
                        innerHtml="{{ club.description }}"
                      ></div>
                    </div>
                    <!-- <div class="text3 poppinsregular">{{club.description}}</div> -->
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Recommendations -->
          <!-- <section>
                        <div class="border_shadow " *ngIf="universityService.clubDetails.recommendation.length !== 0">
                            <div class="marginbottom">
                                <div class="poppins-medium lbl2">Recommendations</div>
                             
                                <div class="row add_top_20 add_bottom_30">
                                    <div *ngFor="let clubrecommen of universityService.clubDetails.recommendation; index as i;">
                                        <ul class="list_teachers">
                                            <li>
                                                <a>
                                                    <figure style="height: 65px; width: 65px; border-radius: 0px">
                                                        <img src={{clubrecommen.image}} style="height: 65px; width: 65px;" alt="">
                                                    </figure>
                                                    <h5 class="poppins-medium">{{clubrecommen.title}}
                                                    </h5>
                                                    <h5 class="poppins-medium">{{clubrecommen.name}}</h5>
                                                    <div class="lbl1 poppinsemibold"> {{clubrecommen.description}}</div>
                                                </a>
                                            </li>


                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> -->

          <!-- Gallery -->
          <section *ngIf="universityService.clubDetails.gallery">
            <div
              class="border_shadow"
              *ngIf="universityService.clubDetails.gallery.length !== 0"
            >
              <div class="marginbottom">
                <div class="poppins-medium lbl2">Gallery</div>
                <div class="row add_top_20">
                  <div
                    class="col-lg-4 col-sm-4 col-12 mb-4"
                    *ngFor="
                      let clubgallary of universityService.clubDetails.gallery;
                      index as i
                    "
                  >
                    <div style="position: relative">
                      <img class="imgA" src="{{ clubgallary.url }}" alt="" />
                      <!-- <div style=" position: absolute; top: 0; right: 0; ">
                                        <div style="height: 26px; width: 26px; border: 1px solid black; background: white;">
                                            <button class="iconbtn" (click)="deleteGallery(clubgallary.id)" style="margin-left: -5px;">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>

                                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Sportfolio News -->
          <!-- <section>
                        <div class="border_shadow ">
                            <div class="marginbottom">
                                <div class="poppins-medium lbl2">Sportfolio News</div>
                                <div class="textDesc add_top_20">
                                    <div class="text3 poppinsregular">
                                        The team is led by experienced coaching talent including a Great Britain Defensive Lineman and a Great Britain Defensive
                                        Backs Coach. We also have opportunities for experienced players to develop into coaching
                                        roles.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> -->

          <!--FAQ's -->
          <section *ngIf="universityService.clubDetails.faq">
            <div
              class="border_shadow"
              *ngIf="universityService.clubDetails.faq.length !== 0"
            >
              <div class="marginbottom">
                <div class="poppins-medium lbl2">FAQ's</div>
                <div class="textDesc add_top_20">
                  <div
                    role="tablist"
                    class="add_bottom_45 accordion_2"
                    id="booking"
                  >
                    <div
                      class="card"
                      *ngFor="
                        let faq of universityService.clubDetails.faq;
                        index as i
                      "
                    >
                      <div class="card-header" role="tab">
                        <h5 class="mb-0 poppins-medium">
                          <a
                            data-toggle="collapse"
                            href="#{{ faq.id }}"
                            aria-expanded="true"
                          >
                            <i class="indicator ti-plus"></i
                            >{{ faq.question }}</a
                          >
                        </h5>
                      </div>

                      <div
                        id="{{ faq.id }}"
                        class="collapse"
                        role="tabpanel"
                        data-parent="#booking"
                      >
                        <div class="card-body">
                          <div id="descriptionData">
                            <div
                              class="text3 poppinsregular"
                              innerHtml="{{ faq.answer }}"
                            ></div>
                          </div>

                          <!-- <p class="text3 poppinsregular">{{faq.answer}}</p> -->
                        </div>
                      </div>
                    </div>

                    <!-- <div class="card">
                                            <div class="card-header" role="tab">
                                                <h5 class="mb-0  poppins-medium">
                                                    <a class="collapsed" data-toggle="collapse" href="#collapseTwo_booking" aria-expanded="false">
                                                        <i class="indicator ti-plus"></i>Generative Modeling Review
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapseTwo_booking" class="collapse" role="tabpanel" data-parent="#booking">
                                                <div class="card-body">
                                                    <p class="text3 poppinsregular">Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
                                                        sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                                                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
                                                        you probably haven't heard of them accusamus labore sustainable VHS.</p>
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!--  -->

          <section>
            <div
              class="border_shadow"
              style="min-height: 8rem"
              *ngIf="
                universityService.clubDetails.description === null &&
                universityService.clubDetails.basic_information === null &&
                universityService.clubDetails.training_times === null &&
                universityService.clubDetails.performance_information ===
                  null &&
                universityService.clubDetails.coaching_team.length === 0 &&
                universityService.clubDetails.club_notable_alumni.length ===
                  0 &&
                universityService.clubDetails.notice_board.length === 0 &&
                universityService.clubDetails.commities.length === 0 &&
                universityService.clubDetails.gallery.length === 0 &&
                universityService.clubDetails.faq.length === 0
              "
            >
              <div class="" style="height: 5rem">
                <div
                  class="poppinsemibold"
                  style="
                    font-family: 'Poppins Medium' !important;
                    font-size: 19px;
                    margin-top: 1.9rem;
                    margin-bottom: 0.8rem;
                  "
                >
                  Details Are Not Available For This Club
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-12 col-12">
          <section class="border_shadow">
            <div class="marginbottom">
              <div
                *ngIf="universityService.clubDetails.video_link === null"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <figure style="overflow: hidden">
                  <a class="video" style="position: relative">
                    <img
                      class="img-fluid"
                      src="../assets/img/videonotavailable.jpg"
                      alt=""
                    />
                  </a>
                </figure>
              </div>

              <div
                *ngIf="universityService.clubDetails.video_link !== null"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <figure class="figurHeight" style="overflow: hidden">
                  <a
                    class="video"
                    *ngIf="universityService.clubDetails.video_link"
                    style="position: relative"
                  >
                    <mat-icon
                      class="iconplay"
                      (click)="
                        commonService.openVideo(
                          universityService.clubDetails.video_link
                        )
                      "
                    >
                      play_arrow
                    </mat-icon>
                    <img
                      [src]="
                        youtubeVideoThumbnail(
                          universityService.clubDetails.video_link
                        )
                      "
                      alt=""
                      class="img-fluid"
                      style="margin-top: -42px; height: 220px"
                    />
                  </a>
                </figure>
              </div>

              <div
                style="display: flex; margin-top: 1rem"
                *ngIf="universityService.clubDetails.email !== null"
              >
                <div>
                  <mat-icon class="emailtext"> email</mat-icon>
                </div>
                <div
                  class="emailtext poppins-medium"
                  style="margin-left: 7px; word-wrap: break-word; width: 75%"
                >
                  {{ universityService.clubDetails.email }}
                </div>
              </div>

              <div
                *ngIf="universityService.clubDetails.website !== null"
                style="display: flex; margin-top: 1rem"
                class="websitecolor"
              >
                <div *ngIf="universityService.clubDetails.website !== null">
                  <mat-icon>link</mat-icon>
                </div>
                <div
                  class="poppins-medium"
                  (click)="onNavigate(universityService.clubDetails.website)"
                  style="
                    margin-left: 7px;
                    cursor: pointer;
                    word-wrap: break-word;
                    width: 75%;
                  "
                >
                  {{ universityService.clubDetails.website }}
                </div>
              </div>
            </div>
          </section>

          <section
            class="border_shadow"
            *ngIf="universityService.clubDetails.membership_price !== null"
          >
            <div class="marginbottom">
              <div
                class="poppins-medium"
                style="
                  font-size: 17px;
                  font-family: 'Poppins Bold' !important;
                  color: #151515;
                "
              >
                Membership Price
              </div>

              <div
                class="emailtext poppins-medium"
                style="
                  margin-top: 1rem;
                  font-size: 27px;
                  font-family: 'Poppins Bold' !important;
                "
              >
                ${{ universityService.clubDetails.membership_price }}
              </div>
            </div>
          </section>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: flex-end;
          align-items: end;
          margin: 5rem 0 1rem;
        "
      >
        <button
          class="btn btn-success btn-width"
          style="
            background: #6c63ff;
            font-family: 'Poppins SemiBold';
            width: 90px;
            color: white;
            cursor: pointer;
          "
          routerLink="/university_Profile"
        >
          BACK
        </button>
      </div>
    </div>
  </div>
</div>
