import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  signal,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../services/common.service';
import { AllsportService } from '../services/allsport.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UniversitydetailsService } from '../services/universitydetails.service';
import { UniversityService } from '../services/university.service';
import { AlertComponent } from '../alert/alert.component';
import { DataService } from '../services/data.service';
import { YeardllService } from '../services/yeardll.service';

import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import {
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperErrorEvent,
  ImgCropperEvent,
  ImgCropperLoaderConfig,
} from '@alyle/ui/image-cropper';
import { StyleRenderer, lyl } from '@alyle/ui';
import { Platform } from '@angular/cdk/platform';
import { DomSanitizer } from '@angular/platform-browser';
import { identifierName } from '@angular/compiler';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;
const styles = () => {
  return {
    actions: lyl`{
      display: flex
    }`,
    cropper: lyl`{
      max-width: 1064px
      height: 315px
    }`,
    flex: lyl`{
      flex: 1
    }`,
  };
};

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-edit-sport',
  templateUrl: './edit-sport.component.html',
  styleUrls: ['./edit-sport.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StyleRenderer],
})
export class EditSportComponent implements OnInit, AfterViewInit {
  classes = this.sRenderer.renderSheet(styles);
  croppedImage?: string;
  ready: boolean;
  @ViewChild(LyImageCropper, { static: true }) readonly cropper: LyImageCropper;
  result: string;
  myConfig: ImgCropperConfig = {
    width: 1064, // Default `250`
    height: 315, // Default `200`,
    responsiveArea: true,
  };

  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];

  @ViewChild('d1') d1: any;
  @ViewChild('d2') d2: any;
  date: any;
  @ViewChild('uploadphoto') fileUploader: ElementRef;

  editClublogopic: any;
  addCommities_imagePic: any;
  addRecommendation_imagePic: any;
  editCommities_imagePic: any;
  editRecommendation_imagePic: any;
  updateclublogoimage: any;
  addNotable_imagePic: any;

  CommiteeId: any;
  recommendationId: '';
  noticeBoardId: any;

  Notable_clubeId: any;
  faq_clubeId: any;
  SportNewsclubeId: '';
  coachingId: '';

  param = { limit: 10, offset: 0 };
  url = 'club-details/';
  dataurl = `${this.commonService.configurl}${this.url}`;

  universityurl = 'university-details/';
  universitydataurl = `${this.commonService.configurl}${this.universityurl}`;
  universityDetails: any = [];

  //gallery

  deleteGalleryApi = 'admin/club/delete-image';
  deleteGalleryUrl = `${this.commonService.configurl}${this.deleteGalleryApi}`;
  profileImage: any;

  ////////////////////////////////////////
  /////// Text editor

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // tags: Tag[] = [];
  name = 'Angular 6';
  htmlContent = '';
  value = `www.wreljndkf.com/dfalkjdflkjalkdjflkasdflkjadlk`;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  isLoading = signal(false);

  ///////////////////////////

  constructor(
    private sanitizer: DomSanitizer,
    public universityService: UniversityService,
    public universitydetailsService: UniversitydetailsService,
    private Activatedroute: ActivatedRoute,
    public commonService: CommonService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    public data: DataService,
    public sportService: AllsportService,
    private router: Router,
    readonly sRenderer: StyleRenderer,
    private _platform: Platform,
    public yeardllService: YeardllService
  ) {}

  ngOnInit(): void {
    if (!this.universityService.editsportClubId) {
      this.router.navigate(['/university_Profile']);
    }

    this.commonService.change.subscribe((emitObject) => {
      if (emitObject.dataToEmit === 'afterresponse') {
        // console.log('aaa');
        this.pforileImage();
        this.changeDetector.detectChanges();
      }

      if (emitObject.dataToEmit === 'editclubNotableUnni') {
        this.Notable_clubeId = '';
      }
      if (emitObject.dataToEmit === 'editclubCommitee') {
        this.CommiteeId = '';
      }
      if (emitObject.dataToEmit === 'editclubfaq') {
        this.faq_clubeId = '';
      }
      if (emitObject.dataToEmit === 'editclubNoticBoard') {
        this.noticeBoardId = '';
      }
      if (emitObject.dataToEmit === 'editclubCoachingTeam') {
        this.coachingId = '';
      }
    });

    console.log(this.Activatedroute.snapshot.queryParamMap.get('id') || 0);
    this.dataLoad(this.Activatedroute.snapshot.queryParamMap.get('id'));

    // this.universitydetailsService.contyList();
    // this.sportService.dataLoad();

    this.universityService.getClubNotableAluminieData(
      this.Activatedroute.snapshot.queryParamMap.get('id')
    );
    this.universityService.getFAQData(
      this.Activatedroute.snapshot.queryParamMap.get('id')
    );
    this.universityService.getCoachData(
      this.Activatedroute.snapshot.queryParamMap.get('id')
    );

    this.yeardllService.yergen();

    window.scroll(0, 0);
    this.universitydetailsService.webConfigDetails();
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const text = event.clipboardData?.getData('text/plain');

    document.execCommand(
      'insertText',
      false,
      text.normalize('NFKD').replace(/[\u0300-\u036f]/g, '')
    );
  }

  dataLoad(id: string | null) {
    this.isLoading.set(true);
    console.log('Loading...');

    this.commonService.addLoader();
    this.data
      .dataJsonLoad(`${this.dataurl}${id}`, this.data.setHeaders(this.param))
      .subscribe(
        (res: any) => {
          console.log(`clubDetails:`, res);
          this.universityService.clubDetails = res.data;

          this.pforileImage();

          console.log('Loading completed...', this.isLoading);
          this.isLoading.set(false);

          this.commonService.removeLoader();

          // this.university();
        },
        (error) => {
          console.log('Error in data load :', error);
        }
      );
  }
  pforileImage() {
    if (
      this.universityService.clubDetails.banner === null &&
      this.universitydetailsService.universityDetails.university_banner === null
    ) {
      this.profileImage = this.sanitizer.bypassSecurityTrustStyle(
        `url({{this.universitydetailsService.webConfigDetailsData[6].config_value}}) !important`
      );
      // console.log(`profileImage:`, this.profileImage);
    }

    if (
      this.universityService.clubDetails.banner === null &&
      this.universitydetailsService.universityDetails.university_banner
    ) {
      this.profileImage = this.sanitizer.bypassSecurityTrustStyle(
        `url(${this.universitydetailsService.universityDetails.university_banner.url}) !important`
      );
      console.log(`profileImage:`, this.profileImage);
    }

    if (this.universityService.clubDetails.banner) {
      if (this.universityService.clubDetails.banner.url !== null) {
        this.profileImage = this.sanitizer.bypassSecurityTrustStyle(
          `url(${this.universityService.clubDetails.banner.url}) !important`
        );
        // console.log(`profileImage:`, this.profileImage);
      }
    }
  }

  chosenYearHandlerAdd(
    normalizedYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.universityService.clubNotableAlumini.notable_alumini_year =
      this.changeDateFormate(normalizedYear);
    this.d2.nativeElement.value =
      this.universityService.clubNotableAlumini.notable_alumini_year;
    datepicker.close();
  }

  chosenYearHandlerEdit(
    normalizedYear: Moment,
    datepicker: MatDatepicker<Moment>,
    i: any
  ) {
    this.universityService.copyClubNotableAluminiResponse[i].year =
      this.changeDateFormate(normalizedYear);
    this.d1.nativeElement.value =
      this.universityService.copyClubNotableAluminiResponse[i].year;
    datepicker.close();
  }

  changeDateFormate(date: any) {
    // let d = `${date.getFullYear()}-${this.prependZero(date.getMonth())}-${this.prependZero(date.getDate())}`;
    let d = `${date.getFullYear()}`;
    return d;
  }

  prependZero(number: any) {
    if (number < 10) return '0' + number;
    else return number;
  }

  //delete gallery
  deleteGallery(id: any) {
    const formData = new FormData();
    formData.append('image_id', id);

    this.data
      .datatoPost(
        this.deleteGalleryUrl,
        formData,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`Gallerydata:`, res.data);
        this.data.SuccessToastr(' Deleted Successfully');
        this.universityService.ClubData(this.universityService.clubDetails.id);
      });
  }

  youtubeVideoThumbnail(url: string) {
    let thumImg = '';
    if (!url.includes('watch?v=')) {
      let u1 = url.replace('https://youtu.be/', '');
      let u2 = u1.trim();
      thumImg = `https://i.ytimg.com/vi/${u2}/hqdefault.jpg`;
    } else {
      thumImg = `https://i.ytimg.com/vi/${url.replace(
        'https://www.youtube.com/watch?v=',
        ''
      )}/hqdefault.jpg`;
    }
    return thumImg;
  }

  onNavigate(site: any) {
    window.open(site, '_blank');
  }

  selectLogo(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.editClublogo = event.target.files[0];
    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.updateclublogoimage = e.target.result;
        this.universityService.clubDetails.logo.url = e.target.result;
      };
      reader.readAsDataURL(_file);
    }
  }

  selecteditGallery(event: any) {
    this.universityService.Clubgallery = [];

    let files = event.target.files;
    console.log('Before', files);

    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.universityService.Clubgallery.push(e.target.result);
          this.commonService.afterresponse();
          this.changeDetector.detectChanges();
        };
        reader.readAsDataURL(file);
      }
      console.log('After', files);
      this.universityService.editClubgallery = files;
    }
  }

  saveEditedSport(data: any) {
    data.sport_id = this.universityService.clubDetails.sport.id;

    // if (this.cropper.isLoaded) {
    //   this.cropper.crop();
    //   this.universityService.editSaveClubSportdata(data);
    // } else {
    //   this.universityService.editSaveClubSportdata(data);
    //   // this.changeDetector.detectChanges();
    // }
    this.universityService.editSaveClubSportdata(data);
    this.fileUploader.nativeElement.value = null;
  }

  editCancelSportdata() {
    this.router.navigate(['/university_Profile']);
  }

  onCropped(e) {
    this.croppedImage = e.dataURL;
    this.universityService.profileEdit = this.croppedImage;
    console.log(e);
  }

  selectClub(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.editCommities_image = event.target.files[0];
    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.editCommities_imagePic = e.target.result;
      };
      reader.readAsDataURL(_file);
    }
  }

  selectRecommendations(event: any, i) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.editRecommendation_image = event.target.files[0];
    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.universityService.clubDetails.commities[i].image = e.target.result;
      };
      reader.readAsDataURL(_file);
    }
  }

  selectAddClub(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.addCommities_image = event.target.files[0];
    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.addCommities_imagePic = e.target.result;
      };
      reader.readAsDataURL(_file);
    }
  }

  selectAddRecommendations(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.addRecommendation_image = event.target.files[0];

    let _file = event.target.files[0];
    if (_file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.addRecommendation_imagePic = e.target.result;
      };
      reader.readAsDataURL(_file);
    }
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
    // demo: Load image from URL and update position, scale & rotate
    // this is supported only for browsers
    // if (this._platform.isBrowser) {
    //   const config: ImgCropperLoaderConfig = {
    //     scale: 0.745864772531767,
    //     xOrigin: 642.380608078103,
    //     yOrigin: 236.26357452128866,
    //     // areaWidth: 100,
    //     // areaHeight: 100,
    //     rotation: 0,
    //     originalDataURL: 'https://firebasestorage.googleapis.com/v0/b/alyle-ui.appspot.com/o/img%2Flarm-rmah-47685-unsplash-1.png?alt=media&token=96a29be5-e3ef-4f71-8437-76ac8013372c'
    //   };
    //   this.cropper.loadImage(config);
    // }
  }

  onLoaded(e: ImgCropperEvent) {
    console.log('img loaded', e);
  }

  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
  }

  // Commite
  saveAddCommite(id: any) {
    this.universityService.addCommiteData(id);
    this.changeDetector.detectChanges();
  }

  saveCancelCommite() {
    this.universityService.addCommite = false;
    this.universityService.CancelSportData();
    this.changeDetector.detectChanges();
  }

  editCommiteeData(data: any) {
    this.universityService.copyClubCommittieResponse = JSON.parse(
      JSON.stringify(this.universityService.clubDetails.commities)
    );
    this.CommiteeId = data.id;
    this.universityService.editCommitee = true;
    this.changeDetector.detectChanges();
  }

  saveEditCommitee(data: any) {
    this.CommiteeId = '';
    this.universityService.editCommiteeData(data);
    // this.changeDetector.detectChanges();
  }

  saveDeleteCommitee(i: any) {
    this.universityService.editCommitee = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deleteCommitee' },
    });
    this.CommiteeId = '';
    this.changeDetector.detectChanges();
  }

  saveEditCancleCommitee(i: any) {
    this.universityService.editCommitee = false;
    this.CommiteeId = i;
    this.changeDetector.detectChanges();
  }

  // Recommendation
  saveAddRecommendation(id: any) {
    this.universityService.addRecommendationData(id);
  }

  saveCancelRecommendation() {
    this.universityService.addRecommendation = false;
    this.universityService.CancelSportData();
    this.changeDetector.detectChanges();
  }

  editRecommendationData(data: any) {
    this.universityService.copyClubRecommendationResponse = JSON.parse(
      JSON.stringify(this.universityService.clubDetails.recommendation)
    );
    this.recommendationId = data.id;
    this.universityService.editRecommendation = true;
    this.changeDetector.detectChanges();
  }

  saveEditRecommendation(data: any) {
    this.recommendationId = '';
    this.universityService.editRecommendationData(data);
    // this.changeDetector.detectChanges();
  }

  saveDeleteRecommendation(i: any) {
    this.universityService.editRecommendation = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deleteRecommendation' },
    });
    this.recommendationId = '';
    this.changeDetector.detectChanges();
  }

  editCancleRecommendation(i: any) {
    this.universityService.editRecommendation = false;
    this.recommendationId = '';
    this.changeDetector.detectChanges();
  }

  // NoticeBoard
  saveAddNoticeBoard(id: any) {
    this.universityService.addNoticeBoardData(id);
    // this.changeDetector.detectChanges();
  }

  saveCancelNoticeBoard() {
    this.universityService.addNoticeBoard = false;
    this.universityService.CancelSportData();
    this.changeDetector.detectChanges();
  }

  editNoticeBoardData(data: any) {
    this.universityService.copyClubNoticeBoardResponse = JSON.parse(
      JSON.stringify(this.universityService.clubDetails.notice_board)
    );
    this.noticeBoardId = data.id;
    this.universityService.editNoticeBoard = true;
  }

  saveEditNoticeBoard(data: any) {
    this.universityService.editClubNoticeBoardData(data);
    this.noticeBoardId = '';
    // this.changeDetector.detectChanges();
  }

  saveDeleteNoticeBoard(i: any) {
    this.universityService.editNoticeBoard = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deleteNoticeBoard' },
    });
    this.noticeBoardId = '';
    this.changeDetector.detectChanges();
  }

  editCancleNoticeBoard(i: any) {
    this.universityService.editNoticeBoard = true;
    this.noticeBoardId = i;
    this.changeDetector.detectChanges();
  }

  //NotableAlumini
  saveNotable_club(id: any) {
    this.universityService.addClubNotableAluminiData(id);
    URL.revokeObjectURL(this.addNotable_imagePic);
    this.addNotable_imagePic = '';
  }

  saveCancelNotable_club() {
    this.universityService.addNotableAlumini = false;
    this.universityService.cancelClubNotableAluminiData();
    this.changeDetector.detectChanges();
    URL.revokeObjectURL(this.addNotable_imagePic);
    this.addNotable_imagePic = '';
  }

  editNotable_clubeData(data, i) {
    this.universityService.editNotableAlumini = true;
    this.universityService.copyClubNotableAluminiResponse = JSON.parse(
      JSON.stringify(this.universityService.ClubNotableAluminieDetails)
    );
    this.Notable_clubeId = data.id;

    let ref = this;
    setTimeout(function () {
      if (ref.d1) {
        ref.d1.nativeElement.value =
          ref.universityService.copyClubNotableAluminiResponse[
            i
          ].year.toString();
      }
    }, 100);
    this.changeDetector.detectChanges();
  }

  saveEditNotable_clube(data) {
    this.universityService.editClubNotableAluminieData(data);
    this.Notable_clubeId = '';
    // this.changeDetector.detectChanges();
  }

  saveDeleteNotable_clube(i) {
    this.universityService.editNotableAlumini = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deleteClubNotable' },
    });
    this.Notable_clubeId = '';
    this.changeDetector.detectChanges();
  }

  saveEditCancleNotable_clube(i, id: number) {
    this.universityService.editNotableAlumini = false;
    this.Notable_clubeId = i;

    const oldAlumniDetails =
      this.universityService.copyClubNotableAluminiResponse.find(
        (al) => al.id === id
      );

    if (oldAlumniDetails) {
      const index = this.universityService.ClubNotableAluminieDetails.findIndex(
        (n) => n.id === id
      );
      if (index > -1) {
        this.universityService.ClubNotableAluminieDetails[index].profile_image =
          oldAlumniDetails.profile_image;
      }
    }

    this.changeDetector.detectChanges();
  }

  selectAddNotable(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.addNotable_image = event.target.files[0];
    let _file = event.target.files[0];

    this.addNotable_imagePic = URL.createObjectURL(_file);
    // if (_file) {
    //   let reader = new FileReader();
    //   reader.onload = (e: any) => {
    //     this.addNotable_imagePic = e.target.result;
    //   };
    //   reader.readAsDataURL(_file);
    // }
  }

  selecteditNotable(event: any, id: number) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.editNotable_image = event.target.files[0];
    let _file = event.target.files[0];
    // if (_file) {
    //   let reader = new FileReader();
    //   reader.onload = (e: any) => {
    //     this.universityService.clubDetails.commities[i].image = e.target.result;
    //   };
    //   reader.readAsDataURL(_file);
    // }

    const index = this.universityService.ClubNotableAluminieDetails.findIndex(
      (n) => n.id === id
    );
    if (index > -1) {
      this.universityService.ClubNotableAluminieDetails[index].profile_image =
        URL.createObjectURL(_file);
    }
  }

  //Faq
  saveAddFaq(id: any) {
    this.universityService.addFAQData(id);
  }

  saveCancelFaq() {
    this.universityService.addFaq = false;
    this.universityService.CancelSportData();
    this.changeDetector.detectChanges();
  }

  editFaqData(data) {
    this.universityService.editFaq = true;
    this.universityService.copyClubFaqResponse = JSON.parse(
      JSON.stringify(this.universityService.ClubFaqDetails)
    );
    this.faq_clubeId = data.id;
    this.changeDetector.detectChanges();
  }

  saveEditFaq(data: any) {
    this.universityService.editFAQeData(data);
    this.faq_clubeId = '';
    // this.changeDetector.detectChanges();
  }

  saveDeleteFaq(i: any) {
    this.universityService.editFaq = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deleteFaq' },
    });
    this.faq_clubeId = '';
    this.changeDetector.detectChanges();
  }

  editCancleFaq(i: any) {
    this.universityService.editFaq = false;
    this.faq_clubeId = i;
    this.changeDetector.detectChanges();
  }

  //  Coaching

  saveAddCoach(id: any) {
    this.universityService.AddClubCoachData(id);
  }

  saveCancelCoach() {
    this.universityService.addCoaching = false;
    this.universityService.CancelSportData();
    this.changeDetector.detectChanges();
  }

  editCoachData(data) {
    this.universityService.editCoach = true;
    this.universityService.copyClubCoachDetails = JSON.parse(
      JSON.stringify(this.universityService.ClubCoachDetails)
    );
    this.coachingId = data.id;
    this.changeDetector.detectChanges();
  }

  saveEditCoach(data: any) {
    this.universityService.editClubCoachData(data);
    this.coachingId = '';
    // this.changeDetector.detectChanges();
  }

  saveDeleteCoach(i: any) {
    this.universityService.editCoach = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deleteCoach' },
    });
    this.coachingId = '';
    this.changeDetector.detectChanges();
  }

  editCancleCoach(i: any) {
    this.universityService.editCoach = false;
    this.coachingId = i;
    this.changeDetector.detectChanges();
  }

  //sportfolio news

  saveAddSportNews() {
    this.universityService.addSportNews = false;
    // this.universityService.AddClubSportNewseData();
  }

  saveCancelSportNews() {
    this.universityService.addSportNews = false;
  }

  editSportNewsData() {
    this.universityService.editSportNews = true;
    // this.universityService.copyClubSportNewsResponse = JSON.parse(JSON.stringify(this.universityService.clubDetails.notice_board));
    // this.SportNews_clubeId = data.id;
  }

  saveEditSportNews(data: any) {
    this.universityService.editSportNews = false;
    // this.universityService.editClubSportNewseData(data);
    this.SportNewsclubeId = '';
  }

  saveDeleteSportNews(i: any) {
    this.universityService.editSportNews = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deleteSportNews' },
    });
    this.SportNewsclubeId = '';
    this.changeDetector.detectChanges();
  }

  editCancleSportNews(i: any) {
    this.universityService.editFaq = false;
    this.faq_clubeId = i;
    this.changeDetector.detectChanges();
  }
}
