<!-- <app-header></app-header> -->

<div class="content-wrapper">
    <div class="container-fluid top">
        <!-- Breadcrumbs-->
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a href="#"><span class="sb poppins-light">Leads ></span></a>
            </li>
            <!-- <li class="breadcrumb-item active">Tables</li> -->
        </ol>
        <div class="card mb-3">
            <div class="card-header">
                <div class="fx">
                    <div class="displayflex">
                        <div class="texth poppins-medium "> Students Interested</div>
                    </div>
                </div>

            </div>
            <div class="card-body" style="padding: 3rem;">


                <h3 class="poppins-medium">
                    Search
                    <small>
                      <a>
                        By Name
                      </a>
                    </small>
                </h3>
                <input type="text" style="padding:8px;margin:15px auto;width:30%;" placeholder="Type Name here..." (keyup)="updateFilter($event)" />

                <div id=ngxdataTable>

                    <ngx-datatable #table class='material striped' [rows]="rows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="45" [rowClass]="getRowClass" [scrollbarV]="false" [selected]="selected" (activate)="onActivate($event)" [limit]="10">
                    
                        <ngx-datatable-column class="poppinsemibold" name="Date" prop="created_at"  [width]='80'>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                <span class="poppinsregular">{{ row.created_at | date:'shortDate' }}</span>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column  name="First Name" prop="users.first_name"  [width]='100'>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                <span class="Montserrat-regular">{{ row.users.first_name }} </span>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Last Name" prop="users.last_name" [width]='110'>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                <span class="poppinsregular">{{ row.users.last_name }}</span>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Gender" prop="users.gender" [width]='80'>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                <span class="poppinsregular">{{ row.users.gender }}</span>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Sport" prop="sport_name"  [width]='100'>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                <span class="Montserrat-regular">{{ row.sport_name }}</span>
                            </ng-template>
                        </ngx-datatable-column>

                     
                        <ngx-datatable-column  name="Nationality" prop="users.preferred_location" [width]='100'>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                <span class="Montserrat-regular">{{ row.users.preferred_location }}</span>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Telephone No." prop="users.phone" [width]='100'>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                <span class="Montserrat-regular">{{  row.telephone_no }}</span>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Email" [width]='100'>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                <span class="datatabel Montserrat-regular">{{ row.email }}</span>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Status" [width]='100'>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                <span class="Montserrat-regular status-margin  {{row.status.toLowerCase()  }}">  <ul>
                                    <li> {{ row.status }}</li>
                                  </ul></span>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- <ngx-datatable-column name="Nationality" [width]='100'>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                                <span class="Montserrat-regular">{{ row.users.location_id }}</span>
                            </ng-template>
                        </ngx-datatable-column> -->

                        <!-- <ngx-datatable-column name="Action" [width]='60'>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <div>
                                <button class="btnBg" mat-button (click)="openDialog()">
                                    <i class="material-icons">create</i>
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action" [width]='60'>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <div style="margin-left: 6px;">
                                <button class="btnBg">
                                    <i class="material-icons">&#xe872;</i>
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column> -->
                    </ngx-datatable>

                </div>

            </div>
        </div>




    </div>
</div>
