import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UniversitydetailsService } from '../../services/universitydetails.service';
import { Router } from '@angular/router';

import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';
import { AllsportService } from '../../services/allsport.service';
// import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

// import {FormControl} from '@angular/forms';
// import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
// import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// import {MatDatepicker} from '@angular/material/datepicker';
// import * as _moment from 'moment';
// import { Moment} from 'moment';

// const moment = _moment;

// export const YEAR_MODE_FORMATS = {
//   parse: {
//     dateInput: 'YYYY',
//   },
//   display: {
//     dateInput: 'YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };

@Component({
  selector: 'app-signup5',
  templateUrl: './signup5.component.html',
  styleUrls: ['./signup5.component.css']
})
export class Signup5Component implements OnInit {


  @ViewChild('d1') d1: any

  // date : any;
  // birthdate: NgbDateStruct | any;
  param = { limit: 10, offset: 0 };

  signUpForm: FormGroup;
  atertmessage: any = [];

  constructor(private formBuilder: FormBuilder, public sportService: AllsportService, private router: Router, public universitylist: UniversitydetailsService, public authService: AuthService, private common: CommonService, public data: DataService) {

  }

  ngOnInit(): void {

  }

  onSubmit(): void {

    // this.authService.registerUser();

    this.atertmessage = [];
    this.authService.registerAccountDetails.user_type = 'university';


    // if (this.authService.registerAccountDetails.average_rating === '') {
    //   this.atertmessage.push('Average Rating');
    // }

    // if (this.authService.registerAccountDetails.bucs_men_rating === '') {
    //   this.atertmessage.push('Bucs Men Rating');
    // }

    // if (this.authService.registerAccountDetails.bucs_women_rating === '') {
    //   this.atertmessage.push('Bucs Women Rating');
    // }

    // if (this.authService.registerAccountDetails.bucs_overall_rating === '') {
    //   this.atertmessage.push('Bucs Overall Rating');
    // }



    // if ( this.authService.registerAccountDetails.average_rating === '' || this.authService.registerAccountDetails.bucs_men_rating === '' || this.authService.registerAccountDetails.bucs_women_rating === '' || this.authService.registerAccountDetails.bucs_overall_rating === '') {

    //   var msg = '';

    //   if (this.atertmessage.length == 1) {
    //     msg = `${this.atertmessage} Required!`;
    //   } else {
    //     msg = `${this.atertmessage.join()} are Required`;

    //   }
    //   this.data.showError(msg);
    // } else {
    this.authService.registerUser();

    // }

  }



  gotoHome() {
    // this.authService.logout();
    this.common.currentUser = '';
    this.router.navigate(['/login']);
  }

  gotoBack() {
    this.router.navigate(['/signup4']);
  }
}
