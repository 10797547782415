import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from "@angular/platform-browser";

export interface DialogData {
  url: any;
  animal: 'panda' | 'unicorn' | 'lion';
}

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.css']
})

export class VideoPopupComponent implements OnInit {


  videoUrl: any;
  isVideo = true;
  constructor(@Inject(MAT_DIALOG_DATA) data: DialogData, private sanitizer: DomSanitizer) {
    // console.log(data)

    // https://youtu.be/VJOq2TsLvuw
    // https://www.youtube.com/watch?v=VJOq2TsLvuw
    if (data.url) {
      this.isVideo = true;
      if (!data.url.includes("watch?v=")) {
       let u1 = data.url.replace("https://youtu.be/", "");
       let u2 = u1.trim();
       data.url = `https://www.youtube.com/watch?v=${u2}`;
      }
      var res = data.url.replace("watch?v=", "embed/");
      this.videoUrl = sanitizer.bypassSecurityTrustResourceUrl(res);
    }else{
      this.isVideo = false;
    }
  
  }

  ngOnInit(): void {
  }

}
