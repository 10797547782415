import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { UniversityService } from '../services/university.service';
import { AlertComponent } from '../alert/alert.component';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../services/common.service';
import { AllsportService } from '../services/allsport.service';

import { YeardllService } from '../services/yeardll.service';

import { ActivatedRoute, Router } from '@angular/router';
import { UniversitydetailsService } from '../services/universitydetails.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { SportdilogComponent } from '../sportdilog/sportdilog.component';

// import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;

import { ChangeDetectionStrategy } from '@angular/core';
import {
  LyImageCropper,
  ImgCropperConfig,
  ImgResolution,
} from '@alyle/ui/image-cropper';
import { StyleRenderer, lyl } from '@alyle/ui';

import {
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from '@angular/cdk/scrolling';
import { MatOption } from '@angular/material/core';
import { filter } from 'rxjs/operators';
import { NgSelectConfig } from '@ng-select/ng-select';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UploadCourseComponent } from '../dialog/upload-course/upload-course.component';
import { environment } from 'src/environments/environment';

// import { MatDialogRef } from '@angular/material/dialog';

const styles = () => {
  return {
    actions: lyl`{
      display: flex
    }`,
    cropper: lyl`{
      max-width: 1064px
      height: 315px
    }`,
    flex: lyl`{
      flex: 1
    }`,
  };
};

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-profiel-detail',
  templateUrl: './profiel-detail.component.html',
  styleUrls: ['./profiel-detail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StyleRenderer],
})
export class ProfielDetailComponent implements OnInit, AfterViewInit {
  toppings = new FormControl();
  toppingList: any[] = [];
  selected: any = [];
  environment: any;

  classes = this.sRenderer.renderSheet(styles);
  croppedImage?: string;
  ready: boolean;
  @ViewChild(LyImageCropper, { static: true }) readonly cropper: LyImageCropper;

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  multiSelectControl = new FormControl();

  @ViewChildren(MatOption)
  options: QueryList<MatOption>;

  result: string;
  myConfig: ImgCropperConfig = {
    width: 1064, // Default `250`
    height: 315, // Default `200`,
    responsiveArea: true,
  };

  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];

  @ViewChild('d1') d1: any;
  @ViewChild('d2') d2: any;
  date: any;
  birthdate: NgbDateStruct | any;
  param = { limit: 10, offset: 0 };

  i: any;

  CourseId: any;
  ScholarshipId: any;
  NotableId: any;

  date1 = '';
  date2 = '';
  editnotableimage: any = [];
  addnotable_aluminiPhoto: any = [];

  universitycopyProfile: any = [];

  replyId = '';
  totalrating: any = 0;
  startByRating = {
    oneStar: 0,
    twoStar: 0,
    threeStart: 0,
    fourStar: 0,
    fiveStar: 0,
  };

  /////// Text editor
  isImgYes = true;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // tags: Tag[] = [];
  name = 'Angular 6';
  htmlContent = '';
  value = `www.wreljndkf.com/dfalkjdflkjalkdjflkasdflkjadlk`;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  path = '';
  ///////////////////////////

  constructor(
    public universityService: UniversityService,
    public universitydetailsService: UniversitydetailsService,
    private Activatedroute: ActivatedRoute,
    public commonService: CommonService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    public sportService: AllsportService,
    private router: Router,
    private cd: ChangeDetectorRef,
    readonly sRenderer: StyleRenderer,
    readonly sd: ScrollDispatcher,
    public yeardllService: YeardllService
  ) {
    // , public dialogRef: MatDialogRef<UploadCourseComponent>
    for (let i = 0; i < 100000; i++) {
      this.toppingList.push({ id: i, viewValue: 'option-' + i });
    }
    // this.config.notFoundText = 'Custom not found';
    // this.config.appendTo = 'body';
    // this.config.bindValue = 'value';
  }

  ngOnInit(): void {
    this.environment = environment;
    this.commonService.change.subscribe((emitObject) => {
      if (emitObject.dataToEmit === 'afterresponse') {
        this.changeDetector.detectChanges();
      }
      if (emitObject.dataToEmit === 'loadedUniData') {
        this.reviewCOunting();
      }
      if (emitObject.dataToEmit === 'editaddedNotableUnni') {
        this.NotableId = '';
      }
      if (emitObject.dataToEmit === 'editsaveCoursedata') {
        this.CourseId = '';
      }
      if (emitObject.dataToEmit === 'editScholarshipadded') {
        this.ScholarshipId = '';
      }
      if (emitObject.dataToEmit === 'closeCloseDialog') {
        this.closeDialog();
      }
    });

    this.universitydetailsService.userDetails();
    this.universityService.getCoursedata();
    this.universityService.getScholarshipdata();
    this.universityService.getNotableAluminiData();

    this.universityService.addData_false();

    this.commonService.removePreLoader();

    this.yeardllService.yergen();
  }

  chosenYearHandlerAdd(
    normalizedYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.universityService.universityNotableAlumini.notable_alumini_year =
      this.changeDateFormate(normalizedYear);
    this.d2.nativeElement.value =
      this.universityService.universityNotableAlumini.notable_alumini_year;
    datepicker.close();
  }

  chosenYearHandlerEdit(
    normalizedYear: Moment,
    datepicker: MatDatepicker<Moment>,
    i: any
  ) {
    this.universityService.copynotableDataresponse[i].year =
      this.changeDateFormate(normalizedYear);
    this.d1.nativeElement.value =
      this.universityService.copynotableDataresponse[i].year;
    datepicker.close();
  }

  // chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  //   const ctrlValue = this.date.value;
  //   ctrlValue.month(normalizedMonth.month());
  //   this.date.setValue(ctrlValue);
  //   datepicker.close();
  // }

  changeDateFormate(date: any) {
    // let d = `${date.getFullYear()}-${this.prependZero(date.getMonth())}-${this.prependZero(date.getDate())}`;
    let d = `${date.getFullYear()}`;
    return d;
  }

  prependZero(number: any) {
    if (number < 10) return '0' + number;
    else return number;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.commonService.loadScripts();
    }, 1);
  }

  //background

  editProfileimage() {
    this.universityService.editBackground = true;
  }

  editProfilelogo(data) {
    this.universitycopyProfile = JSON.parse(
      JSON.stringify(
        this.universitydetailsService.universityDetails.profile_picture
      )
    );
    this.universityService.editlogo = true;
  }

  saveEditedBackground() {
    this.universityService.editUniversityLogoImage();
    URL.revokeObjectURL(this.universitycopyProfile);
  }

  deleteEditedBackground() {
    this.universityService.editlogo = false;
    this.universityService.edit_uni_logo = '';
    URL.revokeObjectURL(this.universitycopyProfile);
  }

  selectuniversitylogo(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    this.universityService.edit_uni_logo = event.target.files[0];

    let _file = event.target.files[0];
    // if (_file) {
    //   let reader = new FileReader();
    //   reader.onload = (e: any) => {
    //     this.universitycopyProfile = e.target.result;
    //   };
    //   reader.readAsDataURL(_file);
    // }
    this.universitycopyProfile = URL.createObjectURL(_file);
  }

  //sport
  editSport(club_id) {
    this.commonService.addLoader();
    // this.universityService.copyClubDetails = JSON.parse(JSON.stringify(this.universityService.clubDetails));
    // this.universityService.editSportData = true;
    this.universityService.editsportClubId = true;
    this.router.navigate(['/editSport'], { queryParams: { id: club_id } });
    this.commonService.removeLoader();
    this.changeDetector.detectChanges();
  }

  deleteEditedSport(i: any) {
    this.universityService.editSportData = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deletesport' },
    });
    this.changeDetector.detectChanges();
  }

  addsport() {
    this.commonService.addLoader();
    this.universityService.addSportData = true;
    this.router.navigate(['/addSportData']);
    this.commonService.removeLoader();
  }

  details(club_id: any) {
    this.universityService.sportClubId = false;
    this.router.navigate(['/sportdata'], { queryParams: { id: club_id } });
  }

  //course
  editCourse(data: any) {
    this.universityService.copycoureseDataresponse = JSON.parse(
      JSON.stringify(this.universityService.coureseDataresponse)
    );
    this.universityService.editCourseData = true;
    this.CourseId = data.course_id;
    this.changeDetector.detectChanges();
  }

  addcourseTime() {
    this.universityService.universityCourse.duration = this.changeDateFormate(
      this.date1
    );
  }

  editcourseTime(i: any) {
    this.universityService.copycoureseDataresponse.courses[i].duration =
      this.changeDateFormate(this.date2);
  }

  saveEditedCourse(data: any) {
    this.universityService.editCoursedata(data);
  }

  deleteEditedCourse(i) {
    this.universityService.editCourseData = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deleteCourse' },
    });
    this.CourseId = '';
    this.changeDetector.detectChanges();
  }

  saveAddedCourseData() {
    this.universityService.addsaveCoursedata();
  }

  deleteAddedCourse() {
    this.universityService.addCourseData = false;
    this.universityService.CancelCourseData();
  }

  editCancelCourseData(i: any) {
    this.universityService.editCourseData = true;
    this.CourseId = i;
    this.changeDetector.detectChanges();
  }

  //Scholarship

  editScholarship(data: any) {
    this.universityService.copyscholarshipDataresponse = JSON.parse(
      JSON.stringify(this.universityService.scholarshipDataresponse)
    );
    this.universityService.editScholarshipData = true;
    this.ScholarshipId = data.id;
    this.changeDetector.detectChanges();
  }

  saveEditedScholarship(data: any) {
    this.universityService.editScholarshipData = false;
    this.universityService.editScholarship(data);
  }

  deleteEditedScholarship(i) {
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deleteScholarship' },
    });
    this.ScholarshipId = '';
    this.changeDetector.detectChanges();
  }

  saveAddedScholarshipData() {
    this.universityService.addScholarship();
  }

  deleteAddedScholarship() {
    this.universityService.addScholarshipData = false;
    this.universityService.CancelScholarshipData();
  }

  editCancelScholarshipData(i) {
    this.universityService.editScholarshipData = false;
    this.ScholarshipId = i;
    this.changeDetector.detectChanges();
  }

  //Notable
  editNotable(data: any, i: any) {
    this.universityService.copynotableDataresponse = JSON.parse(
      JSON.stringify(this.universityService.notableDataresponse)
    );
    // this.date = this.universityService.copynotableDataresponse[i].year;
    this.NotableId = data.id;
    let ref = this;
    setTimeout(function () {
      if (ref.d1) {
        ref.d1.nativeElement.value =
          ref.universityService.copynotableDataresponse[i].year.toString();
      }
    }, 100);

    this.universityService.editNotableData = true;
    this.changeDetector.detectChanges();
  }

  saveEditedNotable(data: any) {
    this.universityService.editNotableAluminidata(data);
    this.changeDetector.detectChanges();
  }

  deleteEditedNotable(i) {
    this.universityService.editNotableData = false;
    this.universityService.setIndex = i;
    const dialogRef = this.dialog.open(AlertComponent, {
      data: { page: 'deleteNotableAlumini' },
    });
    this.NotableId = '';
    this.changeDetector.detectChanges();
  }

  editCancelNotableData(i, id: number) {
    this.universityService.editNotableData = false;
    this.NotableId = i;

    const oldAlumniDetails =
      this.universityService.copynotableDataresponse.find((al) => al.id === id);

    if (oldAlumniDetails) {
      const index = this.universityService.notableDataresponse.findIndex(
        (n) => n.id === id
      );
      if (index > -1) {
        this.universityService.notableDataresponse[index].profile_image =
          oldAlumniDetails.profile_image;
      }
    }

    this.changeDetector.detectChanges();
  }

  addNewNotableData() {
    this.universityService.addNotableData = true;
    this.changeDetector.detectChanges();
  }

  saveAddedNotableData() {
    this.universityService.addNotableAluminidata();

    URL.revokeObjectURL(this.addnotable_aluminiPhoto);
    this.addnotable_aluminiPhoto = [];
    this.changeDetector.detectChanges();
  }

  deleteAddedNotable() {
    this.universityService.addNotableData = false;
    this.universityService.CancelNotableAluminiData();
    URL.revokeObjectURL(this.addnotable_aluminiPhoto);
    this.addnotable_aluminiPhoto = [];
    this.changeDetector.detectChanges();
  }

  selectAddNotable(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    this.universityService.addnotable_alumini_photo = event.target.files[0];
    let _file = event.target.files[0];
    // if (_file) {
    //   let reader = new FileReader();
    //   reader.onload = (e: any) => {
    //     this.addnotable_aluminiPhoto = e.target.result;
    //   };
    //   reader.readAsDataURL(_file);
    // }
    this.addnotable_aluminiPhoto = URL.createObjectURL(_file);
  }

  selectEditNotable(event: any, id: number) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    this.universityService.profile_image = event.target.files[0];

    let _file = event.target.files[0];
    // if (_file) {
    //   let reader = new FileReader();
    //   reader.onload = (e: any) => {
    //     this.editnotableimage = e.target.result;
    //   };
    //   reader.readAsDataURL(_file);
    // }

    const index = this.universityService.notableDataresponse.findIndex(
      (n) => n.id === id
    );
    if (index > -1) {
      this.universityService.notableDataresponse[index].profile_image =
        URL.createObjectURL(_file);
    }

    this.editnotableimage = URL.createObjectURL(_file);
  }

  onNavigate(site: any) {
    window.open(site, '_blank');
  }

  reviewReplay(id: any) {
    this.universityService.replayreview = true;
    this.replyId = id;
  }

  savereplayData(reviewId, universityId) {
    this.universityService.reviewReplyData(reviewId, universityId);
  }

  noReply(id) {
    this.replyId = '';
    this.universityService.replayreview = false;
    this.universityService.reviewReplycancelData();
    this.changeDetector.detectChanges();
  }

  reviewCOunting() {
    if (this.universitydetailsService.universityDetails.review) {
      if (this.universitydetailsService.universityDetails.review.length > 0) {
        for (
          let index = 0;
          index < this.universitydetailsService.universityDetails.review.length;
          index++
        ) {
          this.totalrating =
            this.totalrating +
            Number(
              this.universitydetailsService.universityDetails.review[index]
                .rating
            );
          switch (
            Math.round(
              Number(
                this.universitydetailsService.universityDetails.review[index]
                  .rating
              )
            )
          ) {
            case 1:
              this.startByRating.oneStar = this.startByRating.oneStar + 1;
              break;
            case 2:
              this.startByRating.twoStar = this.startByRating.twoStar + 1;
              break;
            case 3:
              this.startByRating.threeStart = this.startByRating.threeStart + 1;
              break;
            case 4:
              this.startByRating.fourStar = this.startByRating.fourStar + 1;
              break;
            case 5:
              this.startByRating.fiveStar = this.startByRating.fiveStar + 1;
              break;
            default:
              break;
          }
        }
        this.totalrating =
          this.totalrating /
          this.universitydetailsService.universityDetails.review.length;
        this.totalrating = Number.parseFloat(this.totalrating).toFixed(1);
        this.startByRating.oneStar =
          (this.startByRating.oneStar /
            this.universitydetailsService.universityDetails.review.length) *
          100;
        this.startByRating.twoStar =
          (this.startByRating.twoStar /
            this.universitydetailsService.universityDetails.review.length) *
          100;
        this.startByRating.threeStart =
          (this.startByRating.threeStart /
            this.universitydetailsService.universityDetails.review.length) *
          100;
        this.startByRating.fourStar =
          (this.startByRating.fourStar /
            this.universitydetailsService.universityDetails.review.length) *
          100;
        this.startByRating.fiveStar =
          (this.startByRating.fiveStar /
            this.universitydetailsService.universityDetails.review.length) *
          100;
      }
    }
  }

  // ngAfterViewInit(): void {
  // this.sd.scrolled()
  //   .pipe(filter(scrollable => this.cdkVirtualScrollViewPort === scrollable))
  //   .subscribe(() => {
  //     let needUpdate = false;

  //     this.options.forEach(option => {
  //       const selected = this.selected.includes(option.value);

  //       if (selected && !option.selected) {
  //         option.select();
  //         needUpdate = true;
  //       } else if (!selected && option.selected) {
  //         option.deselect();
  //         needUpdate = true;
  //       }
  //     });

  //     if (needUpdate) {
  //       this.cd.detectChanges();
  //     }
  //   });

  // }

  //   foropen() {
  //     this.cdkVirtualScrollViewPort.scrollToIndex(5);
  //   }

  //   openChange($event: boolean) {
  //     if ($event) {
  //       this.foropen();
  //       this.cdkVirtualScrollViewPort.scrollToIndex(0);
  //       this.cdkVirtualScrollViewPort.checkViewportSize();
  //     }
  //   }

  //   onSelectionChange(change): void {
  //     if (!change.isUserInput) {
  //       return;
  //     }
  //     console.log(change.source.value);
  //     const value = change.source.value;
  //     const idx = this.selected.indexOf(change.source.value);

  //     if (idx > -1) {
  //       this.selected.splice(idx, 1);
  //     } else {
  //       this.selected.push(value);
  //     }
  //   }

  selectuniversityCourse(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    this.universityService.selectedFileName = event.target.files[0].name;
    this.universityService.file = event.target.files[0];
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadCourseComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  closeDialog() {
    this.changeDetector.detectChanges();
    // this.dialogRef.close();
  }
}
