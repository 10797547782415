<div id=alert>
    <div>
        <div mat-dialog-content class="textalert" style="padding: 1.5rem 3rem 0px; text-align: center; 
        overflow: hidden !important;">Are you sure you want to delete?</div>

        <div>
            <div class="flex-btn">
                <div>
                    <div mat-dialog-actions>
                        <!-- <button class="btnYes" (click)= "deletedata()">yes</button> -->
                        <button class="btn btn-success btn-width" style="
                    background: #6C63FF;    font-family: 'Poppins SemiBold';
                " (click)="deletedata()">Yes</button>

                    </div>
                </div>

                <div style="margin-left: 9px; ">
                    <div mat-dialog-actions>
                        <!-- <button class="btnNo"   mat-button mat-dialog-close>No</button>-->
                        <button class="btn btn-danger btn-width" style="
                    background: #FFC107;   font-family: 'Poppins SemiBold';
                " (click)=" deletedataNo()">No</button>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>