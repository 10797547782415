import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { from } from 'rxjs';

import { UniversityAdminComponent } from './university-admin/university-admin.component';
import { UniversityProfilesComponent } from './university-profiles/university-profiles.component';
import { ProfielDetailComponent } from './profiel-detail/profiel-detail.component';
import { LeadsComponent } from './leads/leads.component';
import { LoginComponent } from './login/login.component';
import { Signup1Component } from './register/signup1/signup1.component';
import { Signup2Component } from './register/signup2/signup2.component';
import { Signup3Component } from './register/signup3/signup3.component';
import { Signup4Component } from './register/signup4/signup4.component';
import { Signup5Component } from './register/signup5/signup5.component';
import { SportdataComponent } from './sportdata/sportdata.component';
import { SportdilogComponent } from './sportdilog/sportdilog.component';
import { EditSportComponent } from './edit-sport/edit-sport.component';
import { EditUniversityDetailsComponent} from './edit-universityDetails/edit-universityDetails.component';
import { ResetpasswordComponent } from './login/resetpassword/resetpassword.component';

const routes: Routes = [
  { path: '', component: UniversityAdminComponent },
  { path: 'home', component: UniversityAdminComponent },
  { path: 'interested_Student_Athletes', component: UniversityProfilesComponent },
  { path: 'university_Profile', component: ProfielDetailComponent },
  { path: 'leads', component: LeadsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup1', component: Signup1Component },
  { path: 'signup2', component: Signup2Component },
  { path: 'signup3', component: Signup3Component },
  { path: 'signup4', component: Signup4Component },
  { path: 'signup5', component: Signup5Component },
  { path: 'sportdata', component: SportdataComponent },
  { path: 'addSportData', component: SportdilogComponent },
  { path: 'editSport', component: EditSportComponent },
  { path: 'editUniversityDetails', component: EditUniversityDetailsComponent },
  { path: 'resetPassword', component: ResetpasswordComponent },

  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
