<div id="login">

    <div class="flexBlock">
        <div class="width1">
            <!-- <aside> -->
            <figure>
                <a (click)="gotoHome()" routerLink="/login" [routerLinkActive]="['activeheader']">
                    <img src="assets/img/SportfolioLoginlogo.svg" width="149" height="42" alt="">
                </a>
            </figure>

            <div class="step-text1 poppins-medium" style="margin-top: 2.5rem;">Step 1 of 4 </div>
            <div class="step-text2 poppinsregular">Enter your account details</div>

            <!-- <form [formGroup]="signUpForm" (ngSubmit)="onNext()"> -->

            <div class="form-group" style="margin-top: 2.5rem;">
                <input id="signupEmail" class=" poppins-medium fontBlack borderinput" placeholder=" Email" [(ngModel)]="authService.registerAccountDetails.email">
            </div>
            <div class="form-group">
                <div class="row linepassword">
                    <div class="col-lg-11 co-sm-11 col-md-11 col-11" style=" padding: 0">
                        <input [type]="_passwordType" id="signupPassword" class="poppins-medium fontBlack inputpassword required" placeholder="Password" [(ngModel)]="authService.registerAccountDetails.password">
                    </div>
                    <div class="col-lg-1 co-sm-1 col-md-1 col-1  " style=" padding: 0; ">
                        <div *ngIf="showPassword">
                            <i class="fa fa-eye fontBlack" (click)="showPassword = false; _passwordType ='password' "></i>
                        </div>
                        <div *ngIf="!showPassword">
                            <i class="fa fa-eye-slash fontBlack" (click)="showPassword = true; _passwordType ='text' "></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row linepassword">
                    <div class="col-lg-11 co-sm-11 col-md-11 col-11" style=" padding: 0">
                         
                        <input type="text" [type]="_confirmPasswordType" id="signupConfirmPasswordPassword" class="poppins-medium fontBlack inputpassword required"
                            placeholder="Confirm Password" [(ngModel)]="confirnPassword">
                    </div>
                    <div class="col-lg-1 co-sm-1 col-md-1 col-1  " style=" padding: 0; ">
                            <div *ngIf="showConfirmPassword">
                                <i class="fa fa-eye fontBlack" (click)="showConfirmPassword = false; _confirmPasswordType ='password' "></i>
                            </div>
                            <div *ngIf="!showConfirmPassword">
                                <i class="fa fa-eye-slash fontBlack" (click)="showConfirmPassword = true; _confirmPasswordType ='text' "></i>
                            </div>
                    </div>
                </div>

                <!-- <small><a href="#0"> <span class="textb poppins-medium">Forgot password?</span></a></small> -->

            </div>

            <div style="display: flex;   margin-top: 2.5rem;">
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" style="  margin-top: 4px;" (change)='acceptTermsAndCondition($event)'>
                <label for="vehicle1" class="textcheck Montserrat-regular" style="margin-left: 10px;"> By clicking you accept Terms and Conditions</label>
                <br>
            </div>

            <button href="#0" type="submit" class="btn_1 rounded full-width add_top_60" style="margin-top:1rem;" (click)="onNext()">Next</button>
            <!-- </form> -->


            <!-- <div class="divider"><span class="lineOr poppinsregular">Or</span></div>

            <ul class="social_teacher">
                <li><a href="#"><i style="color: #1976D2;" class="icon-facebook"></i></a></li>
                <li><a href="#"><img src="../assets/img/g.png" style="width: 20px; height: 20px;"></a></li>
                <li><a href="#"><i style="color: #1976D2;" class="icon-linkedin"></i></a></li>
            </ul> -->





            <!-- </aside> -->
        </div>
        <app-login-slider></app-login-slider>
    </div>


</div>