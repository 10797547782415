<div id="login">

    <div class="flexBlock">
        <div class="width1">
            <!-- <aside> -->
            <figure>
                <span>
                    <i class="fa fa-arrow-left backArrow" aria-hidden="true" (click)="gotoBack()"></i>
                </span>
                <a (click)="gotoHome()" routerLink="/login" [routerLinkActive]="['activeheader']">
                    <img src="assets/img/SportfolioLoginlogo.svg" width="149" height="42" alt="">
                </a>
            </figure>

            <div class="step-text1 poppins-medium" style="margin-top: 2.5rem;">Step 4 of 4</div>
            <div class="step-text2 poppinsregular">Tell us a bit about Students</div>


            <div class="fontHadColor poppinsregular" style="margin-top: 2.5rem; margin-bottom: 8px;">Female Students</div>
            <input type="number" id="Female_students" oninput="validity.valid||(value='');" name="Female_students" class="form-control fontBlack style-input required"
                placeholder="Female Students" [(ngModel)]="authService.registerAccountDetails.female_students" (keyup)="onKey($event)">

            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Male Students</div>
            <input type="number" id="male_students" oninput="validity.valid||(value='');" name="male_students" class="form-control fontBlack style-input required"
                placeholder="Male Students" [(ngModel)]="authService.registerAccountDetails.male_students" (keyup)="onKey($event)">

            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Other Students</div>
            <input type="number" id="other_students" oninput="validity.valid||(value='');" name="other_students" class="form-control fontBlack style-input required"
                placeholder="Other Students" [(ngModel)]="authService.registerAccountDetails.other_students" (keyup)="onKey($event)">

            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Total Students</div>
            <input type="number" id="total_students" oninput="validity.valid||(value='');" name="total_students" class="form-control fontBlack style-input required"
                placeholder="Total Students" [(ngModel)]="authService.registerAccountDetails.total_students" readonly>


            <button type="submit" class="btn_1 rounded full-width add_top_60" style=" margin-bottom: 3rem;  margin-top: 3rem;" (click)="onSubmit()">Create Account</button>



        </div>
        <app-login-slider></app-login-slider>
    </div>


</div>