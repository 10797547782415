import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UniversityService } from '../services/university.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  constructor(public universityService: UniversityService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AlertComponent>) {
  }

  ngOnInit(): void {
  }

  deletedata() {

    switch (this.data.page) {

      case "deletesport":
        this.universityService.deleteSportdata();
        this.dialogRef.close();
        break;

      case "deleteCourse":
        this.universityService.deleteCourseData();
        this.dialogRef.close();
        break;

      case "deleteScholarship":
        this.universityService.deleteScholarshipData();
        this.dialogRef.close();
        break;

      case "deleteUniversityNoticeBoard":
        this.universityService.deleteUniversityNoticeBoardData();
        this.dialogRef.close();
        break;

      case "deleteNotableAlumini":
        this.universityService.deleteNotableAluminiData();
        this.dialogRef.close();
        break;

      //club details
      case "deleteNoticeBoard":
        this.universityService.deleteNoticeBoardData();
        this.dialogRef.close();
        break;

      case "deleteRecommendation":
        this.universityService.deleteRecommendationData();
        this.dialogRef.close();
        break;

      case "deleteCommitee":
        this.universityService.deleteCommiteData();
        this.dialogRef.close();
        break;

      case "deleteClubNotable":
        this.universityService.deleteClubNotableAluminiData();
        this.dialogRef.close();
        break;

      case "deleteFaq":
        this.universityService.deleteFAQData();
        this.dialogRef.close();
        break;

      case "deleteSportNews":
        this.universityService.deleteClubSportNewseData();
        this.dialogRef.close();
        break;

      case "deleteCoach":
        this.universityService.deleteClubCoachData();
        this.dialogRef.close();
        break;

      default:
        break;
    }
  }

  deletedataNo() {
    this.dialogRef.close();

  }
}
