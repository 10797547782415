import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from "@angular/router";
import { DataService } from '../../services/data.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-signup1',
  templateUrl: './signup1.component.html',
  styleUrls: ['./signup1.component.scss']
})
export class Signup1Component implements OnInit {
  atertmessage: any = [];
  signUpForm: FormGroup;

  tearmAndConditionAccepted = false;
  confirnPassword = '';
  showPassword = false;
  showConfirmPassword = false;
  _passwordType = 'password';
  _confirmPasswordType = 'password';

  upasswordPattern = "^[a-z0-9_-]{8,15}$";

  param = { limit: 10, offset: 0 };
  valiDateEmailAPI = 'validate-email';
  valiDateEmailURL = `${this.commonService.configurl}${this.valiDateEmailAPI}`;


  constructor(private formBuilder: FormBuilder, public authService: AuthService, private commonService: CommonService, private router: Router, public data: DataService) {
    this.signUpForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    let user = localStorage.getItem('USER_DETAILS') as string;
    let currentUser = JSON.parse(user);
    if (currentUser) {
      localStorage.removeItem('USER_DETAILS');
      localStorage.removeItem('ACCESS_TOKEN');
      this.commonService.currentUser = '';
      this.commonService.signup1();

    }
  }
  gotoHome() {
    // this.authService.logout();
    this.commonService.currentUser = '';
    this.router.navigate(['/login']);
  }

  onNext(): void {
    this.atertmessage = [];

    console.log(this.authService.registerAccountDetails)
    if (this.authService.registerAccountDetails.email === '') {
      this.atertmessage.push('Email');
    }
    if (this.authService.registerAccountDetails.password === '') {
      this.atertmessage.push(' Password');
    }

    if (this.authService.registerAccountDetails.email === '' || this.authService.registerAccountDetails.password === '') {
      // console.log(this.atertmessage);
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;

      }
      this.data.showError(msg);
    } else {
      const regexpEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      const regEmailtest = regexpEmail.test(this.authService.registerAccountDetails.email);
      const errorEamil = 'Plaese Enter Valid Email Address!'
      if (regEmailtest) {
        const error = "Paaaword Must be Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
        const regexpForRegPass = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
        const testForRegPass = regexpForRegPass.test(this.authService.registerAccountDetails.password);
        const testForConfirmRegPass = regexpForRegPass.test(this.confirnPassword);

        if (testForRegPass) {
          if (testForConfirmRegPass) {
            if (this.confirnPassword === this.authService.registerAccountDetails.password) {
              if (!this.tearmAndConditionAccepted) {
                this.data.showError(`Please Accept the Terms And Conditions!`);
              } else {
                this.commonService.singupOneComplated = true;
                this.router.navigate(['/signup2']);
              }
            } else {
              this.data.showError(`Passwords do not match!`);
            }
          } else {
            this.data.showError(error);
          }
        } else {
          this.data.showError(error);
        }
      } else {
        this.data.showError(errorEamil);
      }
    }

  }

  acceptTermsAndCondition(event: any) {
    if (event.target.checked) {
      this.tearmAndConditionAccepted = true;
    } else {
      this.tearmAndConditionAccepted = false;
    }

  }



  valiDateEmail() {

    this.commonService.addLoader();
    const formData = new FormData();
    formData.append('email', this.authService.registerAccountDetails.email);
    if (this.authService.registerAccountDetails.email) {
     
      this.data.datatoPostvalidemail(this.valiDateEmailURL, formData, this.data.setHeaders(this.param)).subscribe(
        (res: any) => {

          this.commonService.removeLoader();

          this.router.navigate(['/signup2']);
        });
        
    }
    else {
      this.data.showError('Email already exist');
    }
  }

}
