import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { UniversitydetailsService } from '../../services/universitydetails.service';
import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-signup2',
  templateUrl: './signup2.component.html',
  styleUrls: ['./signup2.component.scss']
})
export class Signup2Component implements OnInit {
  @ViewChild('bday') bday: any
  birthdate: NgbDateStruct | any;
  date: any;
  atertmessage: any = [];
  param = { limit: 10, offset: 0 };

  linked_inList = [{ value: "Male" }, { value: "Female" }, { value: "Other" }];

  // signUpForm: FormGroup;

  maxDate = new Date();

  constructor(private formBuilder: FormBuilder, public authService: AuthService, private router: Router, public universitylist: UniversitydetailsService, private common: CommonService, public data: DataService) { }

  ngOnInit(): void {

  }

  gotoHome() {
    // this.authService.logout();
    this.common.currentUser = '';
    this.router.navigate(['/login']);
  }

  onNext(): void {

    this.atertmessage = [];

    if (this.authService.registerAccountDetails.correct_name === '') {
      this.atertmessage.push(' University Name');
    }
    // if (this.authService.registerAccountDetails.url === '') {
    //   this.atertmessage.push(' URL');
    // }

    // if (this.authService.registerAccountDetails.linked_in === '') {
    //   this.atertmessage.push(' LinkedIn');
    // }
    // if (this.authService.registerAccountDetails.facebook === '') {
    //   this.atertmessage.push(' Facebook');
    // }
    // if (this.authService.registerAccountDetails.instagram === '') {
    //   this.atertmessage.push(' Instagram');
    // }


    if (this.authService.registerAccountDetails.correct_name === '' ) {

      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {

        msg = `${this.atertmessage.join()} are Required`;

      }
      this.data.showError(msg);
    } else {
      this.atertmessage = [];
      if (this.authService.registerAccountDetails.linked_in) {
        const regLinkedIn = new RegExp(/((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/);
        const regLinkedInTest = regLinkedIn.test(this.authService.registerAccountDetails.linked_in);

        if (!regLinkedInTest) {
          this.atertmessage.push('error');
          this.data.showError(' Plaese Enter Valid LinkedIn Address');
        }
      }

      if (this.authService.registerAccountDetails.instagram) {
        const regInstagram = new RegExp('(https?)?:?(www)?instagram\.com/[a-z].{3}');
        const regInstagramTest = regInstagram.test(this.authService.registerAccountDetails.instagram);

        if (!regInstagramTest) {
          this.atertmessage.push('error');
          this.data.showError(' Plaese Enter Valid Instagram Address');
        }
      }

      if (this.authService.registerAccountDetails.facebook) {
        const regFecbook = new RegExp(/(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/);
        const regFecbookTest = regFecbook.test(this.authService.registerAccountDetails.facebook);

        if (!regFecbookTest) {
          this.atertmessage.push('error');
          this.data.showError('Plaese Enter Valid Facebook Address!');
        }
      }


      if (this.authService.registerAccountDetails.url) {
        const regWebUrl = new RegExp('^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$');
        const regWebUrlTest = regWebUrl.test(this.authService.registerAccountDetails.url);

        if (!regWebUrlTest) {
          this.atertmessage.push('error');
          this.data.showError(' Plaese Enter Valid Web Url');
        }
      }

      // const regFecbook = new RegExp(/(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/);
      // const regFecbookTest = regFecbook.test(this.authService.registerAccountDetails.facebook);

      // const regLinkedIn = new RegExp(/((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/);
      // const regLinkedInTest = regLinkedIn.test(this.authService.registerAccountDetails.linked_in);

      // const regInstagram = new RegExp('(https?)?:?(www)?instagram\.com/[a-z].{3}');
      // const regInstagramTest = regInstagram.test(this.authService.registerAccountDetails.instagram);

      // const regWebUrl = new RegExp('^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$');
      // const regWebUrlTest = regWebUrl.test(this.authService.registerAccountDetails.url);

      // if (!regFecbookTest) {
      //   this.atertmessage.push('Plaese Enter Valid Facebook Address!');
      // }
      // if (!regInstagramTest) {
      //   this.atertmessage.push(' Plaese Enter Valid Instagram Address');
      // }
      // if (!regLinkedInTest) {
      //   this.atertmessage.push(' Plaese Enter Valid LinkedIn Address');
      // }
      // if (!regWebUrlTest) {
      //   this.atertmessage.push(' Plaese Enter Valid Web Url');
      // }


      if ( this.atertmessage.length > 0) {
        // var msg = '';
        // if (this.atertmessage.length == 1) {
        //   msg = `${this.atertmessage} Required!`;
        // } else {
        //   msg = `${this.atertmessage.join()} are Required`;

        // }
        // this.data.showError(msg);
      }
      else {
        this.router.navigate(['/signup3']);
      }

    }
  }

  onChange(sport: any) {
    // this.universitylist.searchbySport(sport);
  }

  changeDateFormate(date: any) {
    let d = `${date.getFullYear()}-${this.prependZero(date.getMonth())}-${this.prependZero(date.getDate())}`;
    return d;
  }

  prependZero(number: any) {
    if (number < 10)
      return "0" + number;
    else
      return number;
  }

  editbirthday() {
    this.birthdate = this.changeDateFormate(this.date);

  }

  gotoBack() {
    this.router.navigate(['/signup1']);
  }
}


