<div id="login">

    <div class="flexBlock">
        <div class="width1">
            <!-- <aside> -->
            <figure>
                <span>
                    <i class="fa fa-arrow-left backArrow" aria-hidden="true" (click)="gotoBack()"></i>
                    <!-- <mat-icon class="backArrow" (click)="gotoBack()">arrow_back</mat-icon> -->
                </span>
                <a (click)="gotoHome()" routerLink="/login" [routerLinkActive]="['activeheader']">
                    <img src="assets/img/SportfolioLoginlogo.svg" width="149" height="42" alt="">
                </a>
            </figure>

            <div class="step-text1 poppins-medium" style="margin-top: 2.5rem;">Step 2 of 4</div>
            <div class="step-text2 Montserrat-regular">Tell us a bit about yourself!</div>

            <div class="step-text2 poppins-medium" style="margin-top: 1rem; margin-bottom: 2.5rem;">Join as a</div>


            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">University Name*</div>
            <input type="text" id="correct_name" name="correct_name" class="form-control style-input required fontBlack" placeholder="University Name"
                [(ngModel)]="authService.registerAccountDetails.correct_name" />

            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Facebook URL</div>
            <div id="inputWithIcon" style="margin-top: 10px;">
                <input type="text" id="facebook" name="facebook" class="form-control style-input fontBlack required" placeholder="Facebook URL"
                    [(ngModel)]="authService.registerAccountDetails.facebook">
                <i class="fa fa-facebook" aria-hidden="true" style="top: 6px !important;"></i>
            </div>

            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">Instagram URL</div>
            <div id="inputWithIcon" style="margin-top: 10px;">
                <input type="text" id="instagram" name="instagram" class="form-control style-input fontBlack  required" placeholder="Instagram URL"
                    [(ngModel)]="authService.registerAccountDetails.instagram">
                <i class="fa fa-instagram" aria-hidden="true" style="top: 6px !important;"></i>
            </div>

            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">LinkedIn URL</div>
            <div id="inputWithIcon" style="margin-top: 10px;">
                <input type="text" id="linked_in" name="linked_in" class="form-control style-input fontBlack  required" placeholder="LinkedIn URL"
                    [(ngModel)]="authService.registerAccountDetails.linked_in">
                <i class="fa fa-linkedin-square" aria-hidden="true" style="top: 6px !important;"></i>
            </div>

            <div class="fontHadColor poppinsregular" style="margin-top: 1.5rem; margin-bottom: 8px;">University URL</div>
            <div id="inputWithIcon" style="margin-top: 10px;">
                <input type="text" id="url" name="url" class="form-control fontBlack style-input required" placeholder="University URL" [(ngModel)]="authService.registerAccountDetails.url">
                <mat-icon style="top: 2px !important;">link</mat-icon>
            </div>



            <button type="submit" class="btn_1 rounded full-width add_top_60" style="margin-top:3rem;" (click)="onNext()">Next</button>
            <!-- <div
                style="display: flex; justify-content: center;align-items: center; margin-top: 3rem; margin-bottom: -2rem;">
                <a href="#" class="textb poppins-medium">Login as University!</a>
            </div> -->


        </div>

        <app-login-slider></app-login-slider>

    </div>