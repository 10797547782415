import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { CommonService } from './common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UniversitydetailsService {
  universityDetailsDataresponse: any = [];
  param = { limit: 10, offset: 0 };

  copyuniversitydata: any = [];
  copyUserDetailsdata: any = [];

  universityDetails: any = [];
  univserityDetailsFetched = new Subject<number>();

  propic = '';

  listOfRegion: any = [];
  listOfLocation: any = [];
  listOfBucsFilter: any = [];
  listOfStudentNumberFilter: any = [];
  listOfUniversityRanking: any = [];
  listOfContrys: any = [];
  filterTypes: any = [];

  regionApi = `${this.commonservice.configurl}get-all-ethnicities`;
  locationApi = `${this.commonservice.configurl}all-locations`;

  filterTypesUrl = `${this.commonservice.configurl}university-filters`;
  contryUrl = `${this.commonservice.configurl}get-all-countries`;

  url = 'university-details/';
  universityUrl = `${this.commonservice.configurl}${this.url}`;

  ethnicitiesApi = `${this.commonservice.configurl}get-all-ethnicities`;
  listOfethnicities: any = [];

  webConfigDetailsApi = 'web-config';
  webConfigDetailsUrl = `${this.commonservice.configurl}${this.webConfigDetailsApi}`;
  webConfigDetailsData: any = [];

  itemcount = 0;

  constructor(
    private commonservice: CommonService,
    private router: Router,
    public data: DataService,
    private toastr: ToastrService
  ) {}

  webConfigDetails() {
    this.data
      .dataJsonLoad(this.webConfigDetailsUrl, this.data.setHeaders(this.param))
      .subscribe((res: any) => {
        console.log(`webConfigDetailsData:`, res);
        this.webConfigDetailsData = res.data;
        this.itemcount = 0;
        if (this.webConfigDetailsData[0].config_value) {
          this.itemcount++;
        }
        if (this.webConfigDetailsData[1].config_value) {
          this.itemcount++;
        }
        if (this.webConfigDetailsData[2].config_value) {
          this.itemcount++;
        }
      });
  }

  userDetails() {
    this.copyUserDetailsdata = JSON.parse(localStorage.getItem('USER_DETAILS'));
    // console.log('UserDetailsdata: ', this.copyUserDetailsdata);
    // this.dataLoad(this.copyUserDetailsdata.id);
    this.dataLoad(this.copyUserDetailsdata.slug);
  }

  dataLoad(id: string | null) {
    this.data
      .dataJsonLoad(
        `${this.universityUrl}${id}`,
        this.data.setHeaders(this.param)
      )
      .subscribe((res: any) => {
        console.log(`universityDetails:`, res);
        this.universityDetails = res.data;
        this.univserityDetailsFetched.next(this.universityDetails.id)
        // this.reviewCOunting();
        this.commonservice.loadedUniData();
        this.commonservice.afterresponse();
        // setTimeout(() => {
        //   this.commonservice.loadScripts();
        // }, 1);
        this.commonservice.removeLoader();
      });
  }

  universityInfo(university: any) {
    this.router.navigate(['/home'], { queryParams: { id: university.id } });
  }

  filtersList() {
    this.commonservice.addLoader();

    this.data
      .dataJsonLoad(this.filterTypesUrl, this.data.setHeaders(this.param))
      .subscribe((res: any) => {
        console.log(`filterTypes:`, res.data);
        // this.listOLocation = res.data[0].location;
        // this.listOfBucsFilter = res.data[0].overall_bucs_ranking;
        // this.listOfStudentNumberFilter = res.data[0].student_number;
        // this.listOfUniversityRanking = res.data[0].university_ranking;

        this.commonservice.removeLoader();
      });
  }

  contyList() {
    this.commonservice.addLoader();

    this.data
      .dataJsonLoad(this.contryUrl, this.data.setHeaders(this.param))
      .subscribe((res: any) => {
        console.log(`list Of Contrys:`, res.data);
        this.listOfContrys = res.data;

        this.commonservice.removeLoader();
      });
  }

  regionList() {
    this.commonservice.addLoader();

    this.data
      .dataJsonLoad(this.regionApi, this.data.setHeaders(this.param))
      .subscribe((res: any) => {
        console.log(`list Of listOfRegion:`, res.data);
        this.listOfRegion = res.data;

        this.commonservice.removeLoader();
      });
  }

  ethnicitiesList() {
    this.data
      .dataJsonLoad(this.ethnicitiesApi, this.data.setHeaders(this.param))
      .subscribe((res: any) => {
        console.log(`list Of ethnicities:`, res.data);
        this.listOfethnicities = res.data;
      });
  }

  location() {
    this.commonservice.addLoader();

    this.data
      .dataJsonLoad(this.locationApi, this.data.setHeaders(this.param))
      .subscribe((res: any) => {
        console.log(`listOfLocation:`, res.data);
        this.listOfLocation = res.data;

        this.commonservice.removeLoader();
      });
  }
}
