<div class="border_shadow" style="margin: 2rem; padding: 2rem">

    <!-- <div style="width: 100%; margin-bottom: 2rem; display: flex; justify-content: center; align-items: center">
        <div style="display: flex;  align-items: center; ">
            <div *ngIf="this.data.user">
                <img class="logouni" src="{{this.data.user.profile_picture}}">
            </div>
            <div class="poppins-medium firstlatterCapital" style="font-size: 32px; color: black;">{{this.data.user.first_name}} {{this.data.user.last_name}} </div>
        </div>
    </div> -->

    <mat-dialog-content class="mat-typography" style=" height: auto;">

        <div class="container ">
            <div class="row">
                <aside class="col-lg-3" id="sidebar">
                    <div class="profile min-content">

                        <figure class="overlyedit" style="display: flex;	justify-content: center;	align-items: center;">

                            <img *ngIf=" !this.clubService.InterestedProfileResponse.profile_picture" src="{{this.universitydetailsService.webConfigDetailsData[5].config_value}}" alt="Teacher" class="rounded-circle borderuploadphoto">

                            <div class="borderuploadphoto" style="background: url({{this.clubService.InterestedProfileResponse.profile_picture}});" *ngIf="this.clubService.InterestedProfileResponse.profile_picture">
                            </div>
                        </figure>

                        <ul class="social_teacher" style="border-top: none">
                            <li>
                                <a href="mailto:{{this.clubService.InterestedProfileResponse.email}}">
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="tel://{{this.clubService.InterestedProfileResponse.phone}}">
                                    <i class="fa fa-phone"></i>
                                </a>
                            </li>
                            <li>
                                <a (click)="onNavigate(this.clubService.InterestedProfileResponse.linked_in)">
                                    <i class="fa fa-linkedin-square"></i>
                                </a>
                            </li>
                        </ul>

                        <ul class="border_shadow">
                            <li class="poppins-medium">First Name
                                <span class="float-right poppinsregular">{{this.clubService.InterestedProfileResponse.first_name}}</span>
                            </li>
                            <li class="poppins-medium">Last Name
                                <span class="float-right poppinsregular">{{this.clubService.InterestedProfileResponse.last_name}}</span>

                            </li>
                            <li class="poppins-medium">Sport
                                <span class="float-right poppinsregular">{{this.clubService.InterestedProfileResponse.sport_name}}</span>
                            </li>
                            <li class="poppins-medium">Nationality
                                <span class="float-right poppinsregular">{{this.clubService.InterestedProfileResponse.country_name}}</span>
                            </li>
                            <li class="poppins-medium">DOB
                                <span class="float-right poppinsregular">{{this.clubService.InterestedProfileResponse.date_of_birth}}</span>
                            </li>
                            <li class="poppins-medium">Planned Entry Date
                                <span class="float-right poppinsregular">{{this.clubService.InterestedProfileResponse.planned_entry_date}}</span>

                            </li>
                            <li class="lbl1 poppins-medium poppins-medium" style="overflow: hidden !important;
                            text-overflow: ellipsis;" *ngIf="this.clubService.InterestedProfileResponse.youtube">
                                <strong style="font-weight: 100; cursor: pointer;" (click)="commonService.openVideo(this.clubService.InterestedProfileResponse.youtube)">{{this.clubService.InterestedProfileResponse.youtube}}</strong>
                            </li>
                        </ul>
                    </div>
                </aside>

                <div class="col-lg-9 ">

                    <!-- //sport -->
                    <div class="border_shadow">

                        <div class="marginBottom">
                            <div class="edit_data" style="align-items: start !important; ">
                                <div>
                                    <div class="lbl2 poppins-medium">About Me </div>
                                    <div class="lbl1 poppins-medium" *ngIf="clubService.InterestedProfileResponse.sport.length === 0">(Dependent on the sport will depend on the info included)
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="clubService.InterestedProfileResponse.sport.length !== 0" style="margin-top:1.5rem;">

                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                        <div class="lbl1 poppins-medium"> Sport</div>
                                        <p class=" profile_p lbl3 poppins-light text-color">
                                            {{clubService.InterestedProfileResponse.sport[0].SportName}}</p>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="clubService.InterestedProfileResponse.sport[0].primaryPositionName">
                                        <div class="lbl1 poppins-medium">
                                            Primary Position </div>
                                        <p class=" profile_p lbl3 poppins-light text-color">
                                            {{clubService.InterestedProfileResponse.sport[0].primaryPositionName}}
                                        </p>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                        <div class="lbl1 poppins-medium"> Weight(Kg)</div>
                                        <p class=" profile_p lbl3 poppins-light text-color">
                                            {{clubService.InterestedProfileResponse.sport[0].weight}} </p>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                        <div class="lbl1 poppins-medium"> Height(cm)</div>
                                        <p class=" profile_p lbl3 poppins-light text-color">
                                            {{clubService.InterestedProfileResponse.sport[0].height}} </p>
                                    </div>
                                </div>

                                <div class="lbl1 poppins-medium">Notable Info E.g. Predicted Grades</div>
                                <div id="descriptionData">
                                    <div class=" profile_p lbl3 poppins-light text-color" innerHtml={{clubService.InterestedProfileResponse.sport[0].notableInfo}}>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- start Education section -->
                    <div class="border_shadow">
                        <div class="marginBottom">

                            <div class="edit_data" style="align-items: start !important;">
                                <div class="lbl2 poppins-medium">Education </div>
                            </div>

                            <div class="lbl1 poppins-medium" *ngIf="clubService.InterestedProfileResponse.education.length === 0" style="margin-top: 1rem">Not Available
                            </div>

                            <div class="row" *ngIf="clubService.InterestedProfileResponse.education.length !== 0" style="margin-top:1.5rem;">
                                <div class="col-md-6" style="
                                margin-bottom: 8px;" *ngFor="let education of clubService.InterestedProfileResponse.education; index as i;">
                                    <ul class="list_3" style="  margin-top: 0px;">
                                        <li class="" style="border: none;padding: 0px 20px;">
                                            <strong *ngIf="editeducation !== i " class="lbl1 poppins-medium">
                                                {{education.start_date}} to {{education.end_date}} - {{education.university_name}}
                                            </strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- start Qualifications section -->
                    <div class="border_shadow">
                        <div class="marginBottom">
                            <div class="edit_data" style="align-items: start !important;">
                                <div>
                                    <div class="lbl2 poppins-medium">Further Qualifications</div>
                                </div>
                            </div>

                            <div class="lbl1 poppins-medium" *ngIf="clubService.InterestedProfileResponse.qualifications.length === 0" style="margin-top: 1rem">Coaching Badges
                            </div>

                            <div class="row" *ngIf="clubService.InterestedProfileResponse.qualifications.length !== 0" style="margin-top:1.5rem;">
                                <div class="col-md-6" style="
                                margin-bottom: 8px;" *ngFor="let qualifications of clubService.InterestedProfileResponse.qualifications; index as i;">
                                    <ul class="list_3" style="margin-top: 0px !important;">
                                        <li class="" style="border: none;padding: 0px 20px;">

                                            <span style="font-family: 'Poppins Medium';">
                                                <strong class="lbl1 poppins-medium">{{qualifications.end_date}} - {{qualifications.institute_name}}
                                                </strong>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--  Teram History section -->
                    <div class="border_shadow">
                        <div class="marginBottom">
                            <div class="edit_data" style="align-items: baseline !important; margin-top: -26px;">
                                <div class="lbl2 poppins-medium" style="margin-top: 2rem;">Team History</div>
                            </div>

                            <div class="lbl1 poppins-medium" *ngIf="clubService.InterestedProfileResponse.teamHistory.length === 0" style="margin-top: 1rem">Not Available
                            </div>

                            <table style="width:100%" *ngIf="clubService.InterestedProfileResponse.teamHistory.length !== 0" style="margin-top:1.5rem;">
                                <tr style="margin-bottom: 1rem">
                                    <th style="width:30% ">
                                        <div class="lbl1 poppins-medium">Team Name</div>
                                    </th>
                                    <th style="width:26% ">
                                        <div class="lbl1 poppins-medium">Level</div>
                                    </th>
                                    <th style="width:22% ">
                                        <div class="lbl1 poppins-medium">Start Date</div>
                                    </th>
                                    <th style="width:22% ">
                                        <div class="lbl1 poppins-medium">End Date </div>
                                    </th>
                                </tr>

                                <tr *ngFor="let team of clubService.InterestedProfileResponse.teamHistory; index as i;" style="margin-bottom: 1rem">
                                    <td>
                                        <p class=" profile_p lbl3 poppins-light text-color">
                                            {{team.teamName}}</p>
                                    </td>
                                    <td>
                                        <p class=" profile_p lbl3 poppins-light text-color">{{team.Level}}
                                        </p>
                                    </td>
                                    <td>
                                        <p class=" profile_p lbl3 poppins-light text-color">
                                            {{team.StartDate}} </p>
                                    </td>
                                    <td>
                                        <p class=" profile_p lbl3 poppins-light text-color">
                                            {{team.EndDate}} </p>
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>

                    <!-- Relevant Documents -->
                    <div class="border_shadow">
                        <div class="marginBottom">
                            <div id="lessons">

                                <div id="accordion_lessons" role="tablist" class="add_bottom_45">
                                    <div class="card" style="border: none !important;">

                                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                            <div>
                                                <div style="display: flex ; ">
                                                    <div class="lbl2 poppins-medium">Relevant Documents</div>
                                                </div>

                                                <div class="lbl1 poppins-medium" style="margin-top:1rem" *ngIf="clubService.InterestedProfileResponse.coachesReport.length === 0">Coaches Report, Personal Statement, CV
                                                </div>

                                                <div class="list_lessons_2" *ngIf="clubService.InterestedProfileResponse.coachesReport.length !== 0" style="margin-top:1.5rem;">
                                                    <ul style="margin-bottom: 0px;">
                                                        <li *ngFor="let Coaches of clubService.InterestedProfileResponse.coachesReport; index as i;">
                                                            <strong class="textcoach" *ngIf="coachDataId !== Coaches.id">
                                                                {{Coaches.name}}
                                                            </strong>
                                                            <span *ngIf="coachDataId !== Coaches.id">
                                                                <a style="color: #6C63FF;" [href]="Coaches.certificate" target="_blank">
                                                                    <i class="icon_download"></i>
                                                                    PDF</a>
                                                            </span>
                                                        </li>
                                                    </ul>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- video -->
                    <div class="border_shadow">

                        <div class="marginBottom">
                            <div class="edit_data">
                                <div style=" margin-bottom: 1rem;" class="lbl2 poppins-medium">
                                    Video Link </div>
                            </div>

                            <div class="lbl1 poppins-medium" *ngIf="clubService.InterestedProfileResponse.videoClips.length === 0" style="margin-top: 1rem">Not Available
                            </div>

                            <div class="row" style="margin-bottom: 1rem;">
                                <aside class="col-lg-6" id="sidebar" *ngFor="let videodata of clubService.InterestedProfileResponse.videoClips ;  index as i;">
                                    <div class="box_detail relative" style="margin-top: 1rem; padding: 0px !important;	background: none !important; border: none !important;">
                                        <figure style="height: 165px !important;width: 90%;overflow: hidden;">

                                            <a class="video">
                                                <mat-icon class="iconplay" (click)="commonService.openVideo(videodata.uploadvideo)">
                                                    play_arrow
                                                </mat-icon>
                                                <img [src]='youtubeVideoThumbnail(videodata.uploadvideo)' alt="" class="img-fluid" style="
                                                    margin-top: -30px;
                                                    height: 228px;
                                                ">
                                            </a>
                                        </figure>
                                    </div>
                                </aside>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>

    </mat-dialog-content>
</div>
