import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../services/common.service';
import { AllsportService } from '../services/allsport.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UniversitydetailsService } from '../services/universitydetails.service';
import { UniversityService } from '../services/university.service';
import { AlertComponent } from '../alert/alert.component';
import { DataService } from '../services/data.service';

import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import {
  LyImageCropper,
  ImgCropperConfig,
  ImgResolution,
} from '@alyle/ui/image-cropper';
import { StyleRenderer, lyl } from '@alyle/ui';
import { DomSanitizer } from '@angular/platform-browser';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

const styles = () => {
  return {
    actions: lyl`{
      display: flex
    }`,
    cropper: lyl`{
      max-width: 1064px
      height: 450px
    }`,
    flex: lyl`{
      flex: 1
    }`,
  };
};

@Component({
  selector: 'app-edit-universityProfile',
  templateUrl: './edit-universityProfile.component.html',
  styleUrls: ['./edit-universityProfile.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StyleRenderer],
})
export class EditUniversityProfileComponent implements OnInit {
  classes = this.sRenderer.renderSheet(styles);
  croppedImage?: string;
  ready: boolean;
  @ViewChild(LyImageCropper, { static: true }) readonly cropper: LyImageCropper;
  result: string;
  myConfig: ImgCropperConfig = {
    width: 1064, // Default `250`
    height: 450, // Default `200`,
    output: {
      width: 1064,
      height: 450,
    },
    responsiveArea: true,
  };

  profileImage: any;

  ////////////////////////////////////////
  /////// Text editor

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // tags: Tag[] = [];
  name = 'Angular 6';
  htmlContent = '';
  value = `www.wreljndkf.com/dfalkjdflkjalkdjflkasdflkjadlk`;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  ///////////////////////////

  constructor(
    public universityService: UniversityService,
    public universitydetailsService: UniversitydetailsService,
    private Activatedroute: ActivatedRoute,
    public commonService: CommonService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    public data: DataService,
    public sportService: AllsportService,
    private router: Router,
    readonly sRenderer: StyleRenderer,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.profileBanner();
    this.commonService.change.subscribe((emitObject) => {
      if (emitObject.dataToEmit === 'afterresponse') {
        this.profileBanner();
        this.changeDetector.detectChanges();
      }
    });
  }

  onCropped(e) {
    this.croppedImage = e.dataURL;
    this.universityService.editProfile_photo = this.croppedImage;
    console.log(e);
  }

  profileBanner() {
    if (!this.universitydetailsService.universityDetails.university_banner) {
      this.profileImage = this.sanitizer.bypassSecurityTrustStyle(
        `url(${this.universitydetailsService.webConfigDetailsData[6].config_value}) !important`
      );
      console.log(`profileImage:`, this.profileImage);
    }

    if (this.universitydetailsService.universityDetails.university_banner) {
      this.profileImage = this.sanitizer.bypassSecurityTrustStyle(
        `url(${this.universitydetailsService.universityDetails.university_banner.url}) !important`
      );
      console.log(`profileImage:`, this.profileImage);
    }
  }

  saveEditedBackground() {
    if (this.cropper.isLoaded) {
      this.cropper.crop();
      this.universityService.editbackground();
    } else {
      this.universityService.editbackground();
    }
    this.universityService.editBackground = false;
  }

  deleteEditedBackground() {
    this.universityService.editBackground = false;
  }
}
