import { Injectable,EventEmitter, Output } from '@angular/core';
import { DataService } from '../services/data.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ClubService {

  param = { limit: 10, offset: 0 };
  universitieDataresponse: any = [];

  universitiesapi = 'admin/get-user-intrested-universities';
  getuniversitiesUrl=`${this.commonservice.configurl}${this.universitiesapi}`;
  @Output() change: EventEmitter<any> = new EventEmitter();
  emitObject = { dataToEmit: '', selectedObjectName: '' };

  InterestedProfileResponse: any = [];
  profileapi = 'admin/user/';
  getuniversitiesProfileUrl=`${this.commonservice.configurl}${this.profileapi}`;


  constructor(private commonservice: CommonService, public dataservices: DataService) { }

  universityDataLoad(){
    this.dataservices.dataJsonLoad(this.getuniversitiesUrl, this.dataservices.setHeaders(this.param)).subscribe(
      (res: any) => {
        console.log(`universitieresponse:`, res);
        this.universitieDataresponse = res.data;
        this.emitObject.dataToEmit = 'universitieDataresponse';
        this.emitObject.selectedObjectName = '';
        this.change.emit(this.emitObject);
        this.commonservice.removeLoader();
      }
    );
  }

  profileDataLoad(data){
    this.commonservice.addLoader();
    this.dataservices.dataJsonLoad(`${this.getuniversitiesProfileUrl}${data}`, this.dataservices.setHeaders(this.param)).subscribe(
      (res: any) => {
        this.commonservice.removeLoader();
        console.log(`InterestedProfileResponse:`, res);
        this.InterestedProfileResponse = res.profile_data;
      }
    );
  }


}
