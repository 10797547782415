<div>
  <section *ngIf="!universityService.edituniversity">
    <div style="display: flex; justify-content: flex-end; align-items: flex-end">
      <mat-icon
        (click)="editdetails(universitydetailsService.universityDetails)"
        class="iconbtn"
        style="font-size: 16px"
      >edit
      </mat-icon>
    </div>
  </section>

  <section *ngIf="universityService.edituniversity">
    <div style="display: flex; justify-content: flex-end; align-items: flex-end">
      <button
        class="iconbtn"
        (click)="
          editadminUniversitydata(universitydetailsService.copyuniversitydata)
        "
      >
        <mat-icon>save</mat-icon>
      </button>

      <button
        class="iconbtn"
        (click)="editCancel()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </section>

  <div
    class="border_shadow"
    *ngIf="universitydetailsService.universityDetails.university_details"
    style="padding: 0rem 20px 20px"
  >
    <section>
      <!-- <div class="border_shadow "> -->
      <div class="marginbottom">
        <div class="add_top_20">
          <div class="row">
            <div
              class="col-xl-6 col-sm-6 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">
                University Name
                <span *ngIf="universityService.edituniversity">*</span>
              </div>
              <div>
                <div
                  class="textdata margintop8px"
                  *ngIf="!universityService.edituniversity"
                >
                  {{ universitydetailsService.universityDetails.first_name }}
                </div>
              </div>
              <input
                *ngIf="universityService.edituniversity"
                class="universitydatainput"
                placeholder="University Name"
                [(ngModel)]="
                  universitydetailsService.copyuniversitydata.first_name
                "
              />
            </div>
            <div
              class="col-xl-6 col-sm-6 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">
                Email
                <span *ngIf="universityService.edituniversity">*</span>
              </div>
              <div>
                <div
                  class="textdata margintop8px"
                  *ngIf="!universityService.edituniversity"
                >
                  {{ universitydetailsService.universityDetails.email }}
                </div>
              </div>
              <div
                id="inputWithIcon"
                *ngIf="universityService.edituniversity"
              >
                <input
                  class="universitydatainput"
                  placeholder="Email"
                  [(ngModel)]="
                    universitydetailsService.copyuniversitydata.email
                  "
                  disabled
                  style="padding-left: 2rem"
                />
                <mat-icon> email</mat-icon>
              </div>
            </div>

            <div
              class="col-xl-6 col-sm-6 col-12"
              style="margin-top: 2rem"
              *ngIf="
                universityService.edituniversity ||
                universitydetailsService.universityDetails.phone
              "
            >
              <div class="textlabel">Telephone Number</div>
              <div>
                <div
                  class="textdata margintop8px"
                  *ngIf="!universityService.edituniversity"
                >
                  {{ universitydetailsService.universityDetails.phone }}
                </div>
              </div>
              <div *ngIf="universityService.edituniversity">
                <form
                  #f="ngForm"
                  [formGroup]="phoneForm"
                  style="margin-top: 0px !important"
                >
                  <div class="wrapper">
                    <ngx-intl-tel-input
                      [cssClass]="'custom'"
                      [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                      ]"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.UnitedKingdom"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      [separateDialCode]="separateDialCode"
                      [numberFormat]="PhoneNumberFormat.National"
                      [(ngModel)]="
                        universitydetailsService.copyuniversitydata.phone
                      "
                      name="phone"
                      formControlName="phone"
                    >
                    </ngx-intl-tel-input>
                  </div>
                  <!-- <div class="wrapper">  <button (click)="f.reset()">Reset</button> </div> -->
                </form>

                <!-- <input type="number"  class="universitydatainput" placeholder="Phone" [(ngModel)]=" universitydetailsService.copyuniversitydata.phone"> -->
              </div>
            </div>

            <!-- <div class="col-xl-6 col-sm-6 col-12" style="margin-top: 2rem">
                            <div class="textlabel">Sportfolio Tag</div>
                            <div>
                                <div class="textdata margintop8px" *ngIf="!universityService.edituniversity">{{universitydetailsService.universityDetails.sportfolio_tag}}</div>
                            </div>
                            <input *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="Sportfolio Tag" [(ngModel)]=" universitydetailsService.copyuniversitydata.sportfolio_tag">
                        </div> -->
          </div>
          <div *ngIf="
              universityService.edituniversity ||
              universitydetailsService.universityDetails.university_details
                .short_note
            ">
            <div
              class="textlabel"
              style="margin-top: 1rem"
            >Description</div>
            <div *ngIf="!universityService.edituniversity">
              <div id="descriptionData">
                <div
                  class="textdata margintop8px poppinsregular"
                  innerHtml="{{
                    universitydetailsService.universityDetails
                      .university_details.short_note
                  }}"
                >
                  <!-- {{universitydetailsService.universityDetails.university_details.short_note}} -->
                </div>
              </div>
            </div>
            <div *ngIf="universityService.edituniversity">
              <angular-editor
                [(ngModel)]="
                  universitydetailsService.universityDetails.university_details
                    .short_note
                "
                [config]="config"
                #ctrl="ngModel"
                class="editDescription"
                (paste)="onPaste($event)"
              >
              </angular-editor>
            </div>

            <!-- <textarea *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="Description" [(ngModel)]=" universitydetailsService.copyuniversitydata.university_details.short_note"></textarea> -->
          </div>
          <!-- <div>
                        <div class="textlabel" style="margin-top: 1rem;">Scholarship Description</div>
                        <div *ngIf="!universityService.edituniversity ">
                            <div class="textdata margintop8px">{{universitydetailsService.universityDetails.university_details.scholarship_description}}</div>
                        </div>
                        <textarea *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="Description" [(ngModel)]=" universitydetailsService.copyuniversitydata.university_details.scholarship_description"></textarea>

                    </div> -->
        </div>
      </div>
      <!-- </div> -->
    </section>

    <!-- Students -->
    <section>
      <div class="marginbottom">
        <div class="poppins-medium lbl2">Students</div>
        <div class="add_top_20">
          <div class="row">
            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">Female Students</div>
              <div>
                <div
                  class="textdata margintop8px"
                  *ngIf="!universityService.edituniversity"
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_details.female_students
                  }}
                </div>
              </div>
              <input
                type="number"
                id="number"
                oninput="validity.valid||(value='');"
                *ngIf="universityService.edituniversity"
                class="universitydatainput"
                placeholder="Female Student"
                [(ngModel)]="
                  universitydetailsService.copyuniversitydata.university_details
                    .female_students
                "
                (keyup)="onKey($event)"
              />
            </div>
            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">Male Students</div>
              <div>
                <div
                  class="textdata margintop8px"
                  *ngIf="!universityService.edituniversity"
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_details.male_students
                  }}
                </div>
              </div>
              <input
                type="number"
                oninput="validity.valid||(value='');"
                *ngIf="universityService.edituniversity"
                class="universitydatainput"
                placeholder="Male Student"
                [(ngModel)]="
                  universitydetailsService.copyuniversitydata.university_details
                    .male_students
                "
                (keyup)="onKey($event)"
              />
            </div>
            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">Other Students</div>
              <div>
                <div
                  class="textdata margintop8px"
                  *ngIf="!universityService.edituniversity"
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_details.other_students
                  }}
                </div>
              </div>
              <input
                type="number"
                oninput="validity.valid||(value='');"
                *ngIf="universityService.edituniversity"
                class="universitydatainput"
                placeholder="Other Students"
                [(ngModel)]="
                  universitydetailsService.copyuniversitydata.university_details
                    .other_students
                "
                (keyup)="onKey($event)"
              />
            </div>
            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">Total Students</div>
              <div>
                <div
                  class="textdata margintop8px"
                  *ngIf="!universityService.edituniversity"
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_details.total_students
                  }}
                </div>
              </div>
              <input
                type="number"
                oninput="validity.valid||(value='');"
                *ngIf="universityService.edituniversity"
                class="universitydatainput"
                placeholder="Total Students"
                [(ngModel)]="
                  universitydetailsService.copyuniversitydata.university_details
                    .total_students
                "
                readonly
              />
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </section>

    <!-- Social link -->
    <section>
      <!-- <div class="border_shadow "> -->
      <div class="marginbottom">
        <div class="poppins-medium lbl2">Social links</div>
        <div
          *ngIf="
            !universitydetailsService.universityDetails.university_details
              .facebook &&
            !universitydetailsService.universityDetails.university_details
              .instagram &&
            !universitydetailsService.universityDetails.university_details
              .linked_in &&
            !universitydetailsService.universityDetails.university_details
              .video &&
            !universitydetailsService.universityDetails.university_details.url
          "
          class="margintop8px poppins-medium"
        >
          Not Available
        </div>
        <div class="add_top_20">
          <div class="row">
            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
              *ngIf="
                universitydetailsService.universityDetails.university_details
                  .facebook || universityService.edituniversity
              "
            >
              <div class="textlabel">Facebook</div>
              <div>
                <div
                  class="textdatalink margintop8px"
                  *ngIf="!universityService.edituniversity"
                  (click)="
                    onNavigate(
                      universitydetailsService.universityDetails
                        .university_details.facebook
                    )
                  "
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_details.facebook
                  }}
                </div>
              </div>
              <div
                id="inputWithIcon"
                *ngIf="universityService.edituniversity"
              >
                <input
                  class="universitydatainput"
                  placeholder="Facebook"
                  [(ngModel)]="
                    universitydetailsService.copyuniversitydata
                      .university_details.facebook
                  "
                  style="padding-left: 1rem"
                />
                <i
                  class="fa fa-facebook"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
              *ngIf="
                universitydetailsService.universityDetails.university_details
                  .instagram || universityService.edituniversity
              "
            >
              <div class="textlabel">Instagram</div>
              <div>
                <div
                  class="textdatalink margintop8px"
                  *ngIf="!universityService.edituniversity"
                  (click)="
                    onNavigate(
                      universitydetailsService.universityDetails
                        .university_details.instagram
                    )
                  "
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_details.instagram
                  }}
                </div>
              </div>
              <div
                id="inputWithIcon"
                *ngIf="universityService.edituniversity"
              >
                <input
                  class="universitydatainput"
                  placeholder="Instagram"
                  [(ngModel)]="
                    universitydetailsService.copyuniversitydata
                      .university_details.instagram
                  "
                  style="padding-left: 1rem"
                />
                <i
                  class="fa fa-instagram"
                  aria-hidden="true"
                ></i>
              </div>

              <!-- <input *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="Instagram" [(ngModel)]=" universitydetailsService.copyuniversitydata.university_details.instagram"> -->
            </div>
            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
              *ngIf="
                universitydetailsService.universityDetails.university_details
                  .linked_in || universityService.edituniversity
              "
            >
              <div class="textlabel">LinkedIn</div>
              <div>
                <div
                  class="textdatalink margintop8px"
                  *ngIf="!universityService.edituniversity"
                  (click)="
                    onNavigate(
                      universitydetailsService.universityDetails
                        .university_details.linked_in
                    )
                  "
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_details.linked_in
                  }}
                </div>
              </div>
              <div
                id="inputWithIcon"
                *ngIf="universityService.edituniversity"
              >
                <input
                  *ngIf="universityService.edituniversity"
                  class="universitydatainput"
                  placeholder="LinkedIn "
                  [(ngModel)]="
                    universitydetailsService.copyuniversitydata
                      .university_details.linked_in
                  "
                  style="padding-left: 1rem"
                />
                <i
                  class="fa fa-linkedin-square"
                  aria-hidden="true"
                ></i>
              </div>
              <!-- <input *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="LinkedIn " [(ngModel)]=" universitydetailsService.copyuniversitydata.university_details.linked_in"> -->
            </div>
            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
              *ngIf="
                universitydetailsService.universityDetails.university_details
                  .video || universityService.edituniversity
              "
            >
              <div class="textlabel">Video Url</div>
              <div>
                <div
                  class="textdatalink margintop8px"
                  *ngIf="!universityService.edituniversity"
                  (click)="
                    commonService.openVideo(
                      universitydetailsService.universityDetails
                        .university_details.video
                    )
                  "
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_details.video
                  }}
                </div>
              </div>
              <div
                id="inputWithIcon"
                *ngIf="universityService.edituniversity"
              >
                <input
                  *ngIf="universityService.edituniversity"
                  class="universitydatainput"
                  placeholder="Video Url"
                  [(ngModel)]="
                    universitydetailsService.copyuniversitydata
                      .university_details.video
                  "
                  style="padding-left: 1rem"
                />
                <i
                  class="fa fa-youtube-play"
                  aria-hidden="true"
                ></i>
              </div>

              <!-- <input *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="Video Url" [(ngModel)]=" universitydetailsService.copyuniversitydata.university_details.video"> -->
            </div>

            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
              *ngIf="
                universitydetailsService.universityDetails.university_details
                  .url || universityService.edituniversity
              "
            >
              <div class="textlabel">University Website</div>
              <div>
                <div
                  class="textdatalink margintop8px"
                  *ngIf="!universityService.edituniversity"
                  (click)="
                    onNavigate(
                      universitydetailsService.universityDetails
                        .university_details.url
                    )
                  "
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_details.url
                  }}
                </div>
              </div>
              <div
                id="inputWithIcon"
                *ngIf="universityService.edituniversity"
              >
                <input
                  *ngIf="universityService.edituniversity"
                  class="universitydatainput"
                  placeholder="URL"
                  [(ngModel)]="
                    universitydetailsService.copyuniversitydata
                      .university_details.url
                  "
                  style="padding-left: 1rem"
                />
                <mat-icon>link</mat-icon>
              </div>

              <!-- <input *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="URL" [(ngModel)]=" universitydetailsService.copyuniversitydata.university_details.url"> -->
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </section>

    <!-- Address -->
    <section>
      <!-- <div class="border_shadow "> -->
      <div class="marginbottom">
        <div class="poppins-medium lbl2">Address</div>
        <div class="add_top_20">
          <div
            class="textdata margintop8px"
            *ngIf="!universityService.edituniversity"
            style="margin-top: 1rem"
          >
            <span *ngIf="universitydetailsService.universityDetails.country">
              {{ universitydetailsService.universityDetails.country.name }}
            </span>
            <span *ngIf="universitydetailsService.universityDetails.locations">
              , {{ universitydetailsService.universityDetails.locations.name }}
            </span>
            <span *ngIf="
                universitydetailsService.universityDetails.university_details
                  .zip_code !== null
              ">
              ,
              {{
              universitydetailsService.universityDetails.university_details
              .zip_code
              }},
            </span>

            <br />
            <span *ngIf="
                universitydetailsService.universityDetails.address !== null
              ">
              {{ universitydetailsService.universityDetails.address }}
            </span>

            <span *ngIf="
                universitydetailsService.universityDetails.address_line_1 !==
                null
              ">
              , {{ universitydetailsService.universityDetails.address_line_1 }}
            </span>

            <span *ngIf="
                universitydetailsService.universityDetails.address_line_2 !==
                null
              ">
              , {{ universitydetailsService.universityDetails.address_line_2 }}
            </span>
          </div>

          <div
            class="row"
            *ngIf="universityService.edituniversity"
            style="margin-top: 1rem"
          >
            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">Address</div>
              <input
                class="universitydatainput"
                placeholder="Address "
                [(ngModel)]="
                  universitydetailsService.copyuniversitydata.address
                "
              />
            </div>

            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">Address line 1</div>
              <input
                class="universitydatainput"
                placeholder="Address line 1"
                [(ngModel)]="
                  universitydetailsService.copyuniversitydata.address_line_1
                "
              />
            </div>

            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">Address line 2</div>
              <input
                class="universitydatainput"
                placeholder="Address line 2"
                [(ngModel)]="
                  universitydetailsService.copyuniversitydata.address_line_2
                "
              />
            </div>

            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">Zip Code</div>

              <input
                class="universitydatainput"
                placeholder="Zip Code"
                [(ngModel)]="
                  universitydetailsService.copyuniversitydata.university_details
                    .zip_code
                "
                style="margin-top: 19px"
              />
            </div>

            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">Location</div>
              <div>
                <!-- <select class="universitydatainput" [(ngModel)]="universitydetailsService.copyuniversitydata.university_details.location_id"
                                    id="ethnicity">
                                    <option value="" disabled selected class="poppins-medium">Location</option>
                                    <option *ngFor="let location of universitydetailsService.listOfRegion; index as i;" [value]="location.id">{{location.name}}
                                    </option>
                                </select> -->
                <div id="backgroudwhite">
                  <ng-select
                    [items]="universitydetailsService.listOfLocation"
                    bindLabel="name"
                    bindValue="id"
                    [(ngModel)]="
                      universitydetailsService.copyuniversitydata.location_id
                    "
                    placeholder="Select Location"
                  >
                  </ng-select>
                </div>
              </div>
            </div>

            <div
              class="col-xl-4 col-sm-4 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">
                Country
                <span *ngIf="universityService.edituniversity">*</span>
              </div>
              <!-- <select class="universitydatainput " [(ngModel)]="universitydetailsService.copyuniversitydata.country.id" id="listOfContrys">
                                <option value="" disabled selected class="poppins-medium">Country</option>
                                <option class="poppins-medium" *ngFor="let region of universitydetailsService.listOfContrys; index as i;" [value]="region.id">
                                    {{region.name}}</option>
                            </select> -->
              <div id="backgroudwhite">
                <ng-select
                  [items]="universitydetailsService.listOfContrys"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="
                    universitydetailsService.copyuniversitydata.country.id
                  "
                  placeholder="Select Country"
                >
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </section>

    <!-- Ranking -->
    <section *ngIf="!universityService.edituniversity">
      <div style="margin-bottom: 1rem">
        <div class="poppins-medium lbl2">Ranking</div>
        <div>
          <div class="row">
            <div
              class="col-xl-6 col-sm-6 col-12"
              style="margin-top: 2rem"
            >
              <div class="textlabel">Bucs Ranking</div>
              <div *ngIf="!universityService.edituniversity">
                <div
                  class="textdata margintop8px"
                  *ngIf="
                    universitydetailsService.universityDetails
                      .university_details.bucs_overall_rating
                  "
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_details.bucs_overall_rating
                  }}
                </div>
                <div
                  class="textdata margintop8px"
                  *ngIf="
                    !universitydetailsService.universityDetails
                      .university_details.bucs_overall_rating
                  "
                >
                  0
                </div>
              </div>
              <!-- <input *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="Ranking" [(ngModel)]="universitydetailsService.copyuniversitydata.university_details.bucs_overall_rating"> -->
            </div>
            <div
              class="col-xl-6 col-sm-6 col-12"
              style="margin-top: 2rem"
            >
              <div
                *ngIf="!universityService.edituniversity"
                class="textlabel"
              >
                University Ranking
              </div>
              <div *ngIf="!universityService.edituniversity">
                <div
                  class="textdata margintop8px"
                  *ngIf="
                    universitydetailsService.universityDetails
                      .university_ranking
                  "
                >
                  {{
                  universitydetailsService.universityDetails
                  .university_ranking.institution_ranking
                  }}
                </div>
                <div
                  class="textdata margintop8px"
                  *ngIf="
                    !universitydetailsService.universityDetails
                      .university_ranking
                  "
                >
                  0
                </div>
              </div>
              <!-- <div *ngIf="universityService.edituniversity">
                                <input class="universitydatainput" placeholder="Ranking" 
                                [(ngModel)]="universitydetailsService.copyuniversitydata.university_ranking.institution_ranking">
                            </div> -->
            </div>
            <!-- <div class="col-xl-4 col-sm-4 col-12" style="margin-top: 2rem">
                            <div class="textlabel">Average Rating</div>
                            <div *ngIf="!universityService.edituniversity">
                                <ngb-rating [max]="5" [(rate)]="universitydetailsService.universityDetails.university_details.average_rating" [readonly]="true">
                                    <ng-template let-fill="fill">
                                        <span class="star" [class.filled]="fill === 100">&#9733;</span>
                                    </ng-template>
                                </ngb-rating>
                                <div class="textdata margintop8px" *ngIf="!universityService.edituniversity">{{universitydetailsService.universityDetails.university_details.average_rating}}</div>
                            </div>
                            <div *ngIf="universityService.edituniversity">
                                <ngb-rating [(rate)]="universitydetailsService.copyuniversitydata.university_details.average_rating" [max]="5">
                                    <ng-template let-fill="fill">
                                        <span class="star" [ngClass]="'color'+reviewRating" [class.filled]="fill === 100">&#9733;</span>
                                    </ng-template>
                                </ngb-rating>
                            </div>
                            <input type="number" *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="Average Rating " [(ngModel)]=" universitydetailsService.copyuniversitydata.university_details.average_rating">

                        </div>
                        <div class="col-xl-4 col-sm-4 col-12" style="margin-top: 2rem">
                            <div class="textlabel">Bucs men Rating</div>
                            <div *ngIf="!universityService.edituniversity">
                                <ngb-rating [max]="5" [(rate)]="universitydetailsService.universityDetails.university_details.bucs_men_rating" [readonly]="true">
                                    <ng-template let-fill="fill">
                                        <span class="star" [class.filled]="fill === 100">&#9733;</span>
                                    </ng-template>
                                </ngb-rating>
                                <div class="textdata margintop8px" *ngIf="!universityService.edituniversity">{{universitydetailsService.universityDetails.university_details.bucs_men_rating}}</div>
                            </div>
                            <div *ngIf="universityService.edituniversity">
                                <ngb-rating [(rate)]="universitydetailsService.copyuniversitydata.university_details.bucs_men_rating" [max]="5">
                                    <ng-template let-fill="fill">
                                        <span class="star" [ngClass]="'color'+reviewRating" [class.filled]="fill === 100">&#9733;</span>
                                    </ng-template>
                                </ngb-rating>
                            </div>
                            <input type="number" *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="Bucs men Rating" [(ngModel)]=" universitydetailsService.copyuniversitydata.university_details.bucs_men_rating">

                        </div>
                        <div class="col-xl-4 col-sm-4 col-12" style="margin-top: 2rem">
                            <div class="textlabel">Bucs Overall Rating</div>
                            <div *ngIf="!universityService.edituniversity">
                                <ngb-rating [max]="5" [(rate)]="universitydetailsService.universityDetails.university_details.bucs_overall_rating" [readonly]="true">
                                    <ng-template let-fill="fill">
                                        <span class="star" [class.filled]="fill === 100">&#9733;</span>
                                    </ng-template>
                                </ngb-rating>
                                <div class="textdata margintop8px" *ngIf="!universityService.edituniversity">{{universitydetailsService.universityDetails.university_details.bucs_overall_rating}}</div>
                            </div>
                            <div *ngIf="universityService.edituniversity">
                                <ngb-rating [(rate)]="universitydetailsService.copyuniversitydata.university_details.bucs_overall_rating" [max]="5">
                                    <ng-template let-fill="fill">
                                        <span class="star" [ngClass]="'color'+reviewRating" [class.filled]="fill === 100">&#9733;</span>
                                    </ng-template>
                                </ngb-rating>
                            </div>
                            <input type="number" *ngIf="universityService.edituniversity" class="universitydatainput" placeholder="Bucs Overall Rating"
                                    [(ngModel)]=" universitydetailsService.copyuniversitydata.university_details.bucs_overall_rating">

                        </div>
                        <div class="col-xl-4 col-sm-4 col-12" style="margin-top: 2rem">
                            <div class="textlabel">Bucs Women Rating</div>
                            <div *ngIf="!universityService.edituniversity">
                                <ngb-rating [max]="5" [(rate)]="universitydetailsService.universityDetails.university_details.bucs_women_rating" [readonly]="true">
                                    <ng-template let-fill="fill">
                                        <span class="star" [class.filled]="fill === 100">&#9733;</span>
                                    </ng-template>
                                </ngb-rating>
                                <div class="textdata margintop8px" *ngIf="!universityService.edituniversity">{{universitydetailsService.universityDetails.university_details.bucs_women_rating}}</div>
                            </div>
                            <div *ngIf="universityService.edituniversity">
                                <ngb-rating [(rate)]="universitydetailsService.copyuniversitydata.university_details.bucs_women_rating" [max]="5">
                                    <ng-template let-fill="fill">
                                        <span class="star" [ngClass]="'color'+reviewRating" [class.filled]="fill === 100">&#9733;</span>
                                    </ng-template>
                                </ngb-rating>
                            </div>
                        
                        </div> -->
          </div>
        </div>
      </div>
      <!-- </div> -->
    </section>
  </div>
</div>