import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { AfterViewInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';


// import { SocialAuthService } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  authForm: FormGroup;
  isSubmitted = false;
  showPassword = false;

  atertmessage: any = [];


  forgetUseEmail = '';
  param = { limit: 10, offset: 0 };

  forgetpasswordData = false;
  forgetPasswordAPI = 'forgot-password';
  forgetPasswordURL = `${this.commonService.configurl}${this.forgetPasswordAPI}`;


  SocialLoginAPI = 'social-login';
  SocialLoginURL = `${this.commonService.configurl}${this.SocialLoginAPI}`;

  constructor(private commonService: CommonService, private authService: AuthService, public data: DataService, private formBuilder: FormBuilder, 
    private router: Router) {
    this.authForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.commonService.isUserLoggedIn = this.authService.isLoggedIn();
    if (this.commonService.isUserLoggedIn) {
      this.router.navigate(['/home']);
    }
    this.commonService.removePreLoader();

    window.scroll(0,0);
  }

  signIn(): any {


    this.atertmessage = [];


    this.isSubmitted = true;

    if (!this.authForm.value.email) {
      this.atertmessage.push(' Email');
    }
    if (!this.authForm.value.password) {
      this.atertmessage.push(' Password');
    }

    if (!this.authForm.value.email || !this.authForm.value.password) {
      var msg = '';
      if (this.atertmessage.length == 1) {
        msg = `${this.atertmessage} Required!`;
      } else {
        msg = `${this.atertmessage.join()} are Required`;

      }
      this.data.showError(msg);
    }


    if (this.authForm.invalid) {
      return;
    }

    this.authService.signIn(this.authForm.value, 'university');

  }

  ngAfterViewInit() {
    this.commonService.loadScripts();
  }

  gotoHome() {
    this.forgetpasswordData = false;
  }

  forgetPassword() {
    this.commonService.addLoader();
    const formData = new FormData();
    formData.append('email', this.forgetUseEmail);
    formData.append('user_type', 'university');

    const regexpEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const regEmailtest = regexpEmail.test(this.forgetUseEmail);
    const errorEamil = 'Plaese Enter Valid Email Address!'

    if (regEmailtest) {
      this.data.datatoPostEmail(this.forgetPasswordURL, formData, this.data.setHeaders(this.param)).subscribe(
        (res: any) => {
          console.log(`SportClubdata:`, res.data);

          this.commonService.removeLoader();
          this.forgetpasswordData = false;

          this.router.navigate(['/resetPassword']);
          this.data.SuccessToastr('Link sent to email provided.');

        });

    }
    else {
      this.data.showError(errorEamil);
    }


  }

  // signInWithGoogle(): void {
  //   let ref = this;
  //   this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {

  //     console.log(x)

  //     let userDetailsGoogle = {
  //       address: '',
  //       address_line_1: '',
  //       address_line_2: '',
  //       country_id: '',
  //       course_id: '',
  //       course_preference: '',
  //       created_at: '',
  //       date_of_birth: '',
  //       email: x.email,
  //       email_verified_at: '',
  //       ethnicites_id: '',
  //       facebook_id: '',
  //       first_name: x.firstName,
  //       gender: '',
  //       google_id: '',
  //       graduation_status: '',
  //       id: x.id,
  //       last_name: x.lastName,
  //       linked_in: '',
  //       linkedin_id: '',
  //       location_id: '',
  //       marketing_preference: '',
  //       phone: '',
  //       planned_entry_date: '',
  //       preferred_location: '',
  //       profile_picture: '',
  //       school_attended: '',
  //       sport_id: '',
  //       sportfolio_tag: '',
  //       type: '',
  //       updated_at: '',
  //       verify_token: '',
  //       youtube: '',
  //     }

  //     let accessToken = x.authToken;

  //     this.commonService.addLoader();
  //     const formData = new FormData();
  //     formData.append('auth', x.id);
  //     formData.append('provider', x.provider);


  //     this.data.datatoPost(this.SocialLoginURL, formData, this.data.setHeaders(this.param)).subscribe(
  //       (res: any) => {
  //         this.commonService.removeLoader();

  //         this.authService.GoogleSignIn(userDetailsGoogle, accessToken);

  //       });
  //   });
  // }
}
