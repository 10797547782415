<div style="padding: 15px;margin: 30px;text-align: center;">
    <div>
        <button type="button" class="  uploadbtn" (click)="universityService.courseUploadFile = true">                       
            <label for="upload-photo" class="" style="margin-bottom: 0rem;">
                <i class="fa fa-upload" style="margin-left: 3px">&nbsp;Upload File </i>              
            </label>
            <input type="file" name="photo" id="upload-photo" accept="application/vnd.ms-excel,application/msexcel,application/.xls,.xlsx"
                (change)="selectuniversityCourse($event)" style="display: none;">
        </button>

        <div class="file-name graycolorfront" style="
        margin-top: 13px;
    ">&nbsp;{{universityService.selectedFileName}}</div>
        <mat-dialog-actions style="align-items: flex-end;">
            <div style=" display: flex;">
                <div>
                    <button mat-button [mat-dialog-close]="true" class=" btnAA " (click)="universityService.saveCourseUploadFile()" cdkFocusInitial>Save</button>
                </div>
                <div style="margin-left: 8px;">
                    <button class="btnBb" mat-button mat-dialog-close (click)="universityService.deleteCourseUploadcancelFile()">Cancel</button>
                </div>
            </div>
        </mat-dialog-actions>
    </div>





</div>