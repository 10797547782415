import { Component, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { pairwise } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UniversitydetailsService } from './services/universitydetails.service';
import { UniversityService } from './services/university.service';
import { AllsportService } from './services/allsport.service';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  islogedin = false;
  name = 0;
  root: any;
  currentUser: any;

  @BlockUI()
  blockUI!: NgBlockUI;

  title = 'sportfolio-admin';

  constructor(
    private router: Router,
    public common: CommonService,
    private changeDetectorRef: ChangeDetectorRef,
    public universitydetailsService: UniversitydetailsService,
    public universityService: UniversityService,
    public allsportService: AllsportService
  ) {
    let user = localStorage.getItem('USER_DETAILS') as string;
    this.currentUser = JSON.parse(user);

    if (this.currentUser) {
      this.islogedin = true;
    } else {
      this.islogedin = false;
      this.router.navigate(['/login']);
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-Y2Y51E1WV8', {
          page_path: event.urlAfterRedirects,
          page_title: event.urlAfterRedirects.replace('/', ''),
        });
      }
    });

    this.router.events.subscribe((event) => {
      let user = localStorage.getItem('USER_DETAILS') as string;
      this.currentUser = JSON.parse(user);

      if (this.currentUser) {
        this.islogedin = true;
      } else {
        this.islogedin = false;
        // this.router.navigate(['/login']);
      }
      this.root = event;
      let url = this.root[1]?.url;

      // this.setNav(url);
    });
  }

  setNav(url: any) {
    if (url === '/') {
      this.islogedin = false;
    }

    if (!url) {
      return;
    }
    const queIndex = url.indexOf('?');
    let updatedUrl = url;
    if (queIndex !== -1) {
      updatedUrl = url.substring(0, queIndex);
    }
    // switch (updatedUrl) {
    //   case '/home':
    //   case '/about':
    //   case '/howitwork':
    //   case '/contact':
    //     this.islogedin = false;
    //     break;
    //   case '/profile':
    //   case '/university':
    //   case '/description':
    //     this.islogedin = true;
    //     break;

    // }
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    // this.common.loadScripts();
    // this.loaddata();
    this.common.change.subscribe((emitObject) => {
      if (emitObject.dataToEmit === 'login') {
        this.islogedin = true;
        this.router.navigateByUrl('/login');
      }
      if (emitObject.dataToEmit === 'signup1') {
        this.islogedin = false;
      }
      if (emitObject.dataToEmit === 'startLoader') {
        this.addLodaer();
      } else if (emitObject.dataToEmit === 'stopLoader') {
        this.removeLodaer();
      }
    });

    if (this.universityService.courseData.length === 0) {
      this.universityService.courserId();
    }

    if (this.universitydetailsService.listOfRegion.length === 0) {
      this.universitydetailsService.regionList();
    }

    if (this.universitydetailsService.listOfContrys.length === 0) {
      this.universitydetailsService.contyList();
    }

    if (this.universitydetailsService.listOfLocation.length === 0) {
      this.universitydetailsService.location();
    }

    if (this.universitydetailsService.webConfigDetailsData.length === 0) {
      this.universitydetailsService.webConfigDetails();
    }

    if (this.allsportService.listOfSports.length === 0) {
      this.allsportService.dataLoad();
    }
  }

  addLodaer(): void {
    this.blockUI.start('Loading...');
  }

  removeLodaer(): void {
    this.blockUI.stop();
  }

  ngAfterViewInit() {
    this.common.loadScripts();
  }
}
