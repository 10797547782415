<div class="content-wrapper">
  <div class="container-fluid top">
    <!-- Breadcrumbs-->
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <!-- <a class="poppins-light" routerLink="/home" routerLinkActive="active">Home ></a> -->
        <a class="poppins-light">Home ></a>
      </li>
    </ol>

    <!-- Icon Cards-->
    <div class="row">
      <div
        class="col-xl-3 col-sm-6 mb-3"
        *ngIf="universitydetailsService.universityDetails.id === 28 || universitydetailsService.universityDetails.id === 73"
      >
        <div class="card dashboard text-white bg-primary o-hidden h-100">
          <div class="card-body">
            <!-- <div class="card-body-icon">
                                    <i class="fa fa-fw fa-calendar-check-o"></i>
                                </div> -->
            <div class="mr-5">
              <h5
                class="poppinsemibold"
                *ngIf="universitydetailsService.universityDetails.review"
              >
                {{ clubService.universitieDataresponse.length }} Registered
                interested users
              </h5>
            </div>
          </div>
          <a
            class="card-footer text-white clearfix small z-1"
            (click)="commonservice.gotoScroll('registeredInterestedUsers')"
            routerLink="/interested_Student_Athletes"
          >
            <span class="float-left poppinsemibold">View Details</span>
            <span class="float-right">
              <i class="fa fa-angle-right"></i>
            </span>
          </a>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-3">
        <div class="card dashboard text-white bg-warning o-hidden h-100">
          <div class="card-body">
            <!-- <div class="card-body-icon">
                                            <i class="fa fa-fw fa-envelope-open"></i>
                                        </div> -->
            <div class="mr-5">
              <h5
                class="poppinsemibold"
                *ngIf="
                  universitydetailsService.universityDetails.university_club
                "
              >
                {{
                universitydetailsService.universityDetails.university_club
                .length
                }}
                Sports Club
              </h5>
            </div>
          </div>
          <!-- (click)="universityInfo(UserDetailsdata.id)" -->
          <a
            class="card-footer text-white clearfix small z-1"
            (click)="commonservice.gotoScroll('sport1')"
            routerLink="/university_Profile"
          >
            <span class="float-left poppinsemibold">View Details</span>
            <span class="float-right">
              <i class="fa fa-angle-right"></i>
            </span>
          </a>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-3">
        <div class="card dashboard text-white bg-success o-hidden h-100">
          <div class="card-body">
            <!-- <div class="card-body-icon">
                                    <i class="fa fa-fw fa-heart"></i>
                                </div> -->
            <div class="mr-5">
              <h5
                class="poppinsemibold"
                *ngIf="
                  universitydetailsService.universityDetails.university_course
                "
              >
                {{
                universitydetailsService.universityDetails.university_course
                .length
                }}
                Courses
              </h5>
            </div>
          </div>
          <a
            class="card-footer text-white clearfix small z-1"
            (click)="commonservice.gotoScroll('Course')"
            routerLink="/university_Profile"
          >
            <span class="float-left poppinsemibold">View Details</span>
            <span class="float-right">
              <i class="fa fa-angle-right"></i>
            </span>
          </a>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-3">
        <div class="card dashboard text-white bg-danger o-hidden h-100">
          <div class="card-body">
            <!-- <div class="card-body-icon">
                                    <i class="fa fa-fw fa-star"></i>
                                </div> -->
            <div class="mr-5">
              <h5
                class="poppinsemibold"
                *ngIf="
                  universitydetailsService.universityDetails.notable_alumni
                "
              >
                {{
                universitydetailsService.universityDetails.notable_alumni
                .length
                }}
                Notable Alumni
              </h5>
            </div>
          </div>
          <a
            class="card-footer text-white clearfix small z-1"
            (click)="commonservice.gotoScroll('Notable')"
            routerLink="/university_Profile"
          >
            <span class="float-left poppinsemibold">View Details</span>
            <span class="float-right">
              <i class="fa fa-angle-right"></i>
            </span>
          </a>
        </div>
      </div>
    </div>
    <!-- /cards -->
    <h2></h2>
    <div class="backgroundbox"></div>
    <div
      class="box_general boxheight"
      style="
        height: auto !important;
        margin-bottom: 0px !important;
        border-radius: 0px !important;
        padding-bottom: 3rem;
      "
    >
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div *ngIf="
              universitydetailsService.universityDetails.profile_picture !==
              null
            ">
            <!-- <img class="img1" src="../assets/img/MaskGroup.png"> -->
            <img
              class="img1"
              src="{{
                universitydetailsService.universityDetails.profile_picture
              }}"
            />
          </div>
          <div *ngIf="
              universitydetailsService.universityDetails.profile_picture ===
              null
            ">
            <img
              class="img1"
              src="{{
                this.universitydetailsService.webConfigDetailsData[7]
                  .config_value
              }}"
            />
          </div>
          <div style="margin-left: 2rem">
            <div class="text1 poppins-light">Welcome</div>
            <div
              class="text2 poppins-medium firstlatterCapital"
              *ngIf="
                universitydetailsService.universityDetails.university_details
              "
            >
              {{
              universitydetailsService.universityDetails.university_details
              .correct_name
              }}
            </div>

            <!-- <div class="text1 poppins-medium" style="margin: 1rem 0 1rem 0;" *ngIf="universitydetailsService.universityDetails.university_details">{{universitydetailsService.universityDetails.university_details.short_note}}</div> -->
          </div>
        </div>
        <div>
          <!-- <h4
            *ngIf="
              universitydetailsService.universityDetails.is_performance_sport
            "
            style="font-weight: bold; color: #21287f !important"
          >
            Performance Sport
            <span
              matTooltip="A Performance Sport Team is aimed at high performing athletes. These sports are taken seriously and typically receive more resources, funding and support."
            >
              (i)
            </span>
          </h4> -->
        </div>
      </div>

      <!-- Notice Board -->
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="border_shadow add_top_20 marginbottom seperate-data">
            <div class="">
              <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                ">
                <h2
                  class="poppins-medium"
                  style="margin-bottom: -8px"
                >
                  Notice Board
                  <span *ngIf="!universityService.adduniversityNoticeBoard">
                    <i
                      class="material-icons icon-class"
                      (click)="
                        universityService.adduniversityNoticeBoard = true
                      "
                    >add</i>
                  </span>
                </h2>

                <div>
                  <div *ngIf="universityService.adduniversityNoticeBoard">
                    <div style="display: flex">
                      <button
                        class="iconbtn"
                        (click)="saveAddNoticeBoard()"
                      >
                        <mat-icon>save</mat-icon>
                      </button>

                      <button
                        class="iconbtn"
                        (click)="saveCancelNoticeBoard()"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="textDesc add_top_20"
                *ngIf="universityService.adduniversityNoticeBoard"
              >
                <div class="textlabel">Title</div>
                <input
                  class="universitydatainput"
                  placeholder=" Notice Title"
                  [(ngModel)]="
                    universityService.universityNoticeBoard.notice_title
                  "
                />

                <div
                  class="textlabel"
                  style="margin-bottom: 8px"
                >
                  Description
                </div>
                <angular-editor
                  [(ngModel)]="
                    universityService.universityNoticeBoard.notice_board
                  "
                  [config]="config"
                  #ctrl="ngModel"
                  class="editDescription"
                >
                </angular-editor>

                <!-- <textarea class="universitydatainput" placeholder="Notice Board" [(ngModel)]="universityService.universityNoticeBoard.notice_board"></textarea> -->
              </div>

              <div class="textDesc add_top_20">
                <div
                  *ngFor="
                    let noticeBoard of universityService.UniversityNoticeBoardData;
                    index as i
                  "
                  style="margin-top: 1.5rem"
                >
                  <div *ngIf="noticeBoardId !== noticeBoard.id">
                    <div class="clubRelative">
                      <div class="clubAbsolute">
                        <mat-icon
                          (click)="editNoticeBoardData(noticeBoard)"
                          class="iconbtn"
                          style="font-size: 16px"
                        >edit
                        </mat-icon>
                      </div>
                    </div>
                    <div>
                      <div style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          width: 91%;
                        ">
                        <div style="display: flex">
                          <div class="blackbox"></div>
                          <div
                            class="select1 poppins-Bold firstlatterCapital"
                            style="margin-left: 6px"
                          >
                            {{ noticeBoard.title }}
                          </div>
                        </div>

                        <div class="datetext poppins-Bold">
                          {{ noticeBoard.updated_at | date : "shortDate" }}
                        </div>
                      </div>

                      <div id="descriptionData">
                        <div
                          class="text3 poppinsregular"
                          innerHtml="{{ noticeBoard.description }}"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="noticeBoardId === noticeBoard.id">
                    <div class="clubRelative">
                      <div
                        class="clubAbsolute"
                        style="display: flex"
                      >
                        <button
                          class="iconbtn"
                          (click)="
                            saveEditNoticeBoard(
                              universityService
                                .copyuniversityNoticeBoardResponse[i]
                            )
                          "
                        >
                          <mat-icon>save</mat-icon>
                        </button>

                        <button
                          class="iconbtn"
                          (click)="saveDeleteNoticeBoard(i)"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>

                        <button
                          class="iconbtn"
                          (click)="editCancleNoticeBoard(i)"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div style="width: 61%">
                        <div class="textlabel">Title</div>
                        <input
                          class="universitydatainput"
                          placeholder="Notice Board"
                          [(ngModel)]="
                            universityService.copyuniversityNoticeBoardResponse[
                              i
                            ].title
                          "
                        />
                      </div>
                      <div>
                        <div
                          class="textlabel"
                          style="margin-bottom: 8px"
                        >
                          Description
                        </div>
                        <angular-editor
                          [(ngModel)]="
                            universityService.copyuniversityNoticeBoardResponse[
                              i
                            ].description
                          "
                          [config]="config"
                          #ctrl="ngModel"
                          class="editDescription"
                        >
                        </angular-editor>
                        <!-- <textarea class="universitydatainput" placeholder="Notice Board" [(ngModel)]="universityService.copyuniversityNoticeBoardResponse[i].description"></textarea> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12"></div>
      </div>
    </div>
  </div>
  <!-- /.container-fluid-->
</div>