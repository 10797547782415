<div class="content-wrapper">
  <div
    class="border_shadow"
    style="margin: 100px 60px 60px 60px"
    *ngIf="!isLoading()"
  >
    <div style="width: 100%; text-align: center; margin-bottom: 2rem">
      <h1 class="poppins-medium">Edit Sports Club</h1>
    </div>

    <section
      class="add_top_20 backgroudphoto"
      style="
        display: flex;
        justify-content: center;
        text-align: center;
        height: 315px;
        background-color: transparent;
      "
    >
      <div style="overflow-x: scroll; overflow-y: hidden">
        <div
          style="
            width: 1064px;
            height: 315px;
            background-repeat: no-repeat !important;
            background-size: cover !important;
          "
          [style.background-image]="profileImage"
        >
          <!-- <ly-img-cropper [withClass]="classes.cropper" [config]="myConfig" (cropped)="onCropped($event)" (ready)="ready = true" (cleaned)="ready = false" style="width: 1064;  height: 315px;   color: #6C63FF;
                    font-family: 'Poppins Medium'; font-size: 23px; cursor: pointer ; background: transparent !important;">
                        <span style="cursor: pointer !important;
                        border: 1px solid;
                        padding: 10px;
                        border-radius: 11px;">Select Profile Photo</span>
                    </ly-img-cropper> -->

          <!-- style="width: 1064;  height: 315px;   color: #6C63FF;
                    font-family: 'Poppins Medium'; font-size: 23px; cursor: pointer ;" -->
          <!-- style="width: 1064;  height: 315px;   color: #6C63FF;
                    font-family: 'Poppins Medium'; font-size: 23px; cursor: pointer ; background-image: url({{universityService.clubDetails.banner[0].url}}) !important; background-repeat: no-repeat !important; background-size: cover !important;" -->

          <!-- <div style="display: flex; justify-content: flex-end; align-items: flex-end; margin-top: 1rem; ">
                        <button *ngIf="cropper.isLoaded" color="accent" (click)="cropper.crop()" class="doneProfile" ly-button>
                            <ly-icon>crop</ly-icon>SAVE
                        </button>
                    </div> -->
          <!-- <div style="display: block">
                        <img *ngIf="cropper.isCropped" class=" logouni" [src]="croppedImage">
                    </div> -->
        </div>

        <!-- <div *ngIf="cropper.isLoaded" style="margin-top:-72px; ">
                    <button class="ly-btn1" (click)="cropper.zoomIn()" ly-button appearance="icon">
                        <ly-icon>zoom_in</ly-icon>
                    </button>
                    <button class="ly-btn1" (click)="cropper.zoomOut()" ly-button appearance="icon">
                        <ly-icon>zoom_out</ly-icon>
                    </button>
                    <button class="ly-btn1" (click)="cropper.center()" ly-button appearance="icon">
                        <ly-icon>filter_center_focus</ly-icon>
                    </button>
                    <button class="ly-btn1" (click)="cropper.rotate(-90)" ly-button appearance="icon">
                        <ly-icon>rotate_90_degrees_ccw</ly-icon>
                    </button>
                    <button class="ly-btn1" (click)="cropper.fit()" style=" font-family: 'Poppins Medium'; top:-7px; position: relative;" ly-button>Fit</button>
                    <button class="ly-btn1" (click)="cropper.fitToScreen()" style=" font-family: 'Poppins Medium'; top:-7px; position: relative;" ly-button>Fit to screen</button>
                    <button class="ly-btn1" (click)="cropper.clean()" style=" font-family: 'Poppins Medium'; top:-7px; position: relative;" ly-button>Clean</button>
                </div> -->
      </div>
    </section>

    <div class="paddingDIV">
      <section class="add_top_20 seperate-data">
        <div style="display: flex">
          <div *ngIf="!universityService.clubDetails.logo">
            <img
              [src]="this.updateclublogoimage"
              style="height: 60px"
            />
            <!-- <button type="button" class=" logouni" style="background: url({{this.updateclublogoimage}})">
                            <label for="upload-logo" class="upload_btn" style="margin-bottom: 0px; margin-top: 5px;"> -->
            <!-- <mat-icon class="iconbtn" style="font-size: 39px !important;">camera_alt</mat-icon> -->
            <!-- <i class="fa fa-camera" style="font-size:24px"></i>
                            </label>
                            <input type="file"  accept="image/png, image/jpg, image/jpeg" name="photo" id="upload-logo" (change)="selectLogo($event)" style="display: none;">
                        </button> -->
          </div>

          <div *ngIf="universityService.clubDetails.logo">
            <div *ngIf="universityService.clubDetails.logo.url">
              <img
                [src]="universityService.clubDetails.logo.url"
                style="height: 60px"
              />
              <!-- <button type="button" class=" logouni" style="background: url({{universityService.clubDetails.logo.url}})">
                                <label for="upload-logo" class="upload_btn" style="margin-bottom: 0px;
                                                            margin-top: 5px;"> -->
              <!-- <mat-icon class="iconbtn" style="font-size: 39px !important;">camera_alt</mat-icon> -->
              <!-- <i class="fa fa-camera" style="font-size:24px"></i>
                                </label>
                                <input type="file"  accept="image/png, image/jpg, image/jpeg" name="photo" id="upload-logo" (change)="selectLogo($event)" style="display: none;">
                            </button>-->
            </div>
          </div>

          <div style="margin-left: 2rem; width: 100%">
            <div class="textlabel">Club Name*</div>
            <div
              class="poppins-medium"
              style="font-size: 32px !important"
            >
              <input
                class="universitydatainput"
                placeholder="Club Name "
                [(ngModel)]="universityService.clubDetails.name"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Sport*</div>
            <div
              *ngIf="universityService.clubDetails.sport"
              id="backgroudwhite"
            >
              <ng-select
                [items]="sportService.listOfSports"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="universityService.clubDetails.sport.id"
                placeholder="Select Sport"
              >
              </ng-select>
            </div>
          </div>
          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Email</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder="Email"
                [(ngModel)]="universityService.clubDetails.email"
                style="padding-left: 1.3rem"
              />
              <mat-icon>email</mat-icon>
            </div>
          </div>
          <!-- <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="margin-top: 10px;">

                        <div class="textlabel">Nationality*</div>
                        <div *ngIf="universityService.clubDetails.country === null " id="backgroudwhite">
                            <ng-select [items]="universitydetailsService.listOfContrys" bindLabel="name" bindValue="id" [(ngModel)]="universityService.clubDetails.country"
                                placeholder="Select Nationality">
                            </ng-select>
                        </div>

                        <div *ngIf="universityService.clubDetails.country">
                            <div *ngIf="universityService.clubDetails.country !== null " id="backgroudwhite">
                                <ng-select [items]="universitydetailsService.listOfContrys" bindLabel="nationality" bindValue="id" [(ngModel)]="universityService.clubDetails.country.id"
                                    placeholder="Select Nationality">
                                </ng-select>
                            </div>
                        </div>
                    </div> -->
          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Club Url</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder=" Club Url"
                [(ngModel)]="universityService.clubDetails.website"
                style="padding-left: 1.3rem"
              />
              <mat-icon>link</mat-icon>
            </div>
          </div>

          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Video Url</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder=" Video Url"
                [(ngModel)]="universityService.clubDetails.video_link"
                style="padding-left: 1.3rem"
              />
              <i
                class="fa fa-youtube-play"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </section>

      <section class="add_top_20 marginbottom seperate-data">
        <div class="poppins-medium lbl2">Social Links</div>

        <div class="row">
          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Facebook</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder=" Facebook"
                [(ngModel)]="universityService.clubDetails.facebook"
                style="padding-left: 1.3rem"
              />
              <i
                class="fa fa-facebook"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Twitter</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder=" Twitter"
                [(ngModel)]="universityService.clubDetails.twitter"
                style="padding-left: 1.3rem"
              />
              <i
                class="fa fa-twitter"
                aria-hidden="true"
              ></i>
            </div>
          </div>

          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <div class="textlabel">Instagram</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder="Instagram"
                [(ngModel)]="universityService.clubDetails.instagram"
                style="padding-left: 1.3rem"
              />
              <i
                class="fa fa-instagram"
                aria-hidden="true"
              ></i>
            </div>
          </div>

          <div
            class="col-xl-6 col-md-6 col-sm-6 col-12"
            style="margin-top: 10px"
          >
            <label class="textlabel"> Is this a performance sport? </label>
            <div id="inputWithIcon">
              <mat-slide-toggle
                color="primary"
                [(ngModel)]="universityService.clubDetails.is_performance_sport"
              >
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </section>

      <!-- Overview -->
      <section class="add_top_20 marginbottom seperate-data">
        <div class="poppins-medium lbl2">Overview</div>

        <div class="textDesc add_top_20">
          <div
            class="textlabel"
            style="margin-bottom: 8px"
          >Description</div>
          <angular-editor
            [(ngModel)]="universityService.clubDetails.description"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
            (paste)="onPaste($event)"
          >
          </angular-editor>

          <!-- <textarea class="universitydatainput" placeholder=" Overview" [(ngModel)]="universityService.clubDetails.description"></textarea> -->
        </div>
      </section>

      <!-- Basic Information -->
      <section class="add_top_20 marginbottom seperate-data">
        <div class="poppins-medium lbl2">Basic Information</div>

        <div class="textDesc add_top_20">
          <div
            class="textlabel"
            style="margin-bottom: 8px"
          >Description</div>
          <angular-editor
            [(ngModel)]="universityService.clubDetails.basic_information"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
            (paste)="onPaste($event)"
          >
          </angular-editor>

          <!-- <textarea class="universitydatainput" placeholder=" Description" [(ngModel)]="universityService.clubDetails.basic_information"></textarea> -->
        </div>
      </section>

      <!-- Training Times -->
      <section class="add_top_20 marginbottom seperate-data">
        <div class="poppins-medium lbl2">Training Times</div>

        <div class="textDesc add_top_20">
          <div
            class="textlabel"
            style="margin-bottom: 8px"
          >Description</div>
          <angular-editor
            [(ngModel)]="universityService.clubDetails.training_times"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
            (paste)="onPaste($event)"
          >
          </angular-editor>
          <!-- <textarea class="universitydatainput" placeholder=" Description" [(ngModel)]="universityService.clubDetails.training_times"></textarea> -->
        </div>
      </section>

      <!-- Performance Overview -->
      <section class="add_top_20 marginbottom seperate-data">
        <div class="poppins-medium lbl2">Performance Overview</div>

        <div class="textDesc add_top_20">
          <div
            class="textlabel"
            style="margin-bottom: 8px"
          >Description</div>
          <angular-editor
            [(ngModel)]="universityService.clubDetails.performance_information"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
            (paste)="onPaste($event)"
          >
          </angular-editor>
          <!-- <textarea class="universitydatainput" placeholder=" Performance Overview" [(ngModel)]="universityService.clubDetails.performance_information"></textarea> -->
        </div>
      </section>

      <!-- Membership Price  -->
      <section class="add_top_20 marginbottom seperate-data">
        <div class="poppins-medium lbl2">Membership Price</div>

        <div class="textDesc add_top_20">
          <div class="textlabel">Membership Price</div>
          <input
            class="universitydatainput"
            placeholder="£"
            [(ngModel)]="universityService.clubDetails.membership_price"
          />
        </div>
      </section>

      <!-- Gallery -->
      <section class="add_top_20">
        <!-- <div class="border_shadow "> -->
        <div class="marginbottom">
          <div class="poppins-medium lbl2">Gallery</div>
          <div class="add_top_20">
            <div>
              <button
                type="button "
                class="gallery"
              >
                <label
                  for="upload-photo"
                  class="upload_btn"
                  style="margin-top: 5px"
                >Add Image
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  name="photo"
                  id="upload-photo"
                  #uploadphoto
                  multiple
                  (change)="selecteditGallery($event)"
                  style="display: none"
                />
              </button>
              <!-- <input type="file"  accept="image/png, image/jpg, image/jpeg" multiple (change)="selecteditGallery($event)"> -->

              <div
                class="row add_top_20"
                style="margin-bottom: 1.5rem"
              >
                <div
                  class="col-xl-4 col-md-4 col-sm-4 col-12"
                  style="margin-top: 1rem"
                  *ngFor="
                    let gallery of this.universityService.Clubgallery;
                    index as i
                  "
                >
                  <img
                    class="imgA"
                    [src]="gallery"
                  />
                </div>
              </div>
              <!-- <div *ngIf="universityService.Clubgallery.length !== 0">
                                    <div style="border-bottom: 2px solid #888181;"></div>
                            </div> -->
            </div>
            <div class="row add_top_20">
              <div
                class="col-lg-4 col-sm-4 col-12 mb-4"
                *ngFor="
                  let clubgallaryimg of universityService.clubDetails.gallery;
                  index as i
                "
              >
                <div style="position: relative">
                  <img
                    class="imgA"
                    src="{{ clubgallaryimg.url }}"
                    alt=""
                  />

                  <div style="position: absolute; top: 0; right: 0">
                    <div style="
                        height: 26px;
                        width: 26px;
                        border: 1px solid black;
                        background: white;
                      ">
                      <button
                        class="iconbtn"
                        (click)="deleteGallery(clubgallaryimg.id)"
                        style="margin-left: -5px"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </section>

      <div style="
          display: flex;
          justify-content: flex-end;
          align-items: end;
          margin: 2rem 0 -5px 0;
        ">
        <div>
          <button
            class="btn btn-success btn-width"
            style="
              background: #6c63ff;
              font-family: 'Poppins SemiBold';
              width: 90px;
              color: white;
            "
            (click)="saveEditedSport(universityService.clubDetails)"
          >
            SAVE
          </button>

          <!-- <button class="btn btn-danger btn-width" style="background: #FFC107;   font-family: 'Poppins SemiBold'; margin-left: 8px; width: 90px; color: black;"
                        (click)="editCancelSportdata()">CANCEL</button> -->
        </div>
      </div>
    </div>
  </div>

  <div
    class="border_shadow"
    style="margin: 100px 60px 60px 60px"
  >
    <div
      class="paddingDIV"
      style="padding-top: 0px"
    >
      <!-- Coaching Team  -->
      <section class="add_top_20 marginbottom seperate-data">
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <div class="poppins-medium lbl2">
            Coaching Team
            <span *ngIf="!universityService.addCoaching">
              <i
                class="material-icons icon-class"
                (click)="universityService.addCoaching = true"
              >add</i>
            </span>
          </div>
          <div>
            <div *ngIf="universityService.addCoaching">
              <div style="display: flex">
                <button
                  class="iconbtn"
                  (click)="saveAddCoach(universityService.clubDetails.id)"
                >
                  <mat-icon>save</mat-icon>
                </button>

                <button
                  class="iconbtn"
                  (click)="saveCancelCoach()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="textDesc add_top_20"
          *ngIf="universityService.addCoaching"
        >
          <div class="textlabel">Coach Name</div>
          <input
            class="universitydatainput"
            placeholder=" Coach Name"
            [(ngModel)]="universityService.universitySport.coach_name"
          />

          <div class="textlabel">Coach Type</div>
          <input
            class="universitydatainput"
            placeholder=" Coach Type"
            [(ngModel)]="universityService.universitySport.coach_type"
          />

          <div
            class="textlabel"
            style="margin-bottom: 8px"
          >Description</div>
          <angular-editor
            [(ngModel)]="universityService.universitySport.coach_information"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
          >
          </angular-editor>

          <!-- <textarea class="universitydatainput" placeholder="Coach Information" [(ngModel)]="universityService.universitySport.coach_information"></textarea> -->
        </div>

        <div class="textDesc add_top_20">
          <div
            *ngFor="let coach of universityService.ClubCoachDetails; index as i"
            style="margin-top: 1rem"
          >
            <div *ngIf="coachingId !== coach.id">
              <div class="clubRelative">
                <div class="clubAbsolute">
                  <mat-icon
                    (click)="editCoachData(coach)"
                    class="iconbtn"
                    style="font-size: 16px"
                  >edit
                  </mat-icon>
                </div>
              </div>
              <div>
                <div class="select1 poppins-medium firstlatterCapital">
                  {{ coach.coach_name }}
                </div>
                <div class="select1 poppins-medium firstlatterCapital">
                  {{ coach.coach_type }}
                </div>
                <div id="descriptionData">
                  <div
                    class="text3 poppinsregular"
                    innerHtml="{{ coach.coach_information }}"
                  ></div>
                </div>

                <!-- <div class="text3 poppinsregular">{{coach.coach_information}}</div> -->
              </div>
            </div>

            <div *ngIf="coachingId === coach.id">
              <div class="clubRelative">
                <div
                  class="clubAbsolute"
                  style="display: flex"
                >
                  <button
                    class="iconbtn"
                    (click)="
                      saveEditCoach(universityService.copyClubCoachDetails[i])
                    "
                  >
                    <mat-icon>save</mat-icon>
                  </button>

                  <button
                    class="iconbtn"
                    (click)="saveDeleteCoach(i)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>

                  <button
                    class="iconbtn"
                    (click)="editCancleCoach(i)"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
              <div style="width: 80%">
                <div>
                  <div class="textlabel">Coach Name</div>
                  <input
                    class="universitydatainput"
                    placeholder="Coach Name"
                    [(ngModel)]="
                      universityService.copyClubCoachDetails[i].coach_name
                    "
                  />
                </div>
                <div>
                  <div class="textlabel">Coach Type</div>
                  <input
                    class="universitydatainput"
                    placeholder="Coach Type"
                    [(ngModel)]="
                      universityService.copyClubCoachDetails[i].coach_type
                    "
                  />
                </div>
                <div>
                  <div
                    class="textlabel"
                    style="margin-bottom: 8px"
                  >
                    Description
                  </div>
                  <angular-editor
                    [(ngModel)]="
                      universityService.copyClubCoachDetails[i]
                        .coach_information
                    "
                    [config]="config"
                    #ctrl="ngModel"
                    class="editDescription"
                  >
                  </angular-editor>

                  <!-- <textarea class="universitydatainput" placeholder="Coach Information" [(ngModel)]="universityService.copyClubCoachDetails[i].coach_information"></textarea> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--Notable Alumni-->
      <section class="add_top_20 marginbottom seperate-data">
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <div class="poppins-medium lbl2">
            Notable Alumni
            <span *ngIf="!universityService.addNotableAlumini">
              <i
                class="material-icons icon-class"
                (click)="universityService.addNotableAlumini = true"
              >add</i>
            </span>
          </div>

          <div>
            <div *ngIf="universityService.addNotableAlumini">
              <div style="display: flex">
                <button
                  class="iconbtn"
                  (click)="saveNotable_club(universityService.clubDetails.id)"
                >
                  <mat-icon>save</mat-icon>
                </button>

                <button
                  class="iconbtn"
                  (click)="saveCancelNotable_club()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="add_top_20"
          *ngIf="universityService.addNotableAlumini"
        >
          <div class="row">
            <div
              class="col-xl-2 col-md-2 col-sm-2 col-12"
              style="margin-top: 10px"
            >
              <button
                type="button"
                class="logouni"
                style="background: url({{ addNotable_imagePic }})"
                (click)="imageRef.click()"
              >
                <label
                  class="upload_btn"
                  style="
                    margin-bottom: 0px;
                    margin-left: -1rem;
                    margin-top: 5px;
                  "
                >
                  <mat-icon
                    class="iconbtn"
                    style="font-size: 39px !important"
                  >camera_alt</mat-icon>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  name="photo"
                  id="upload-Notable"
                  (change)="selectAddNotable($event)"
                  #imageRef
                  hidden
                />
              </button>
            </div>
            <div
              class="col-xl-5 col-md-5 col-sm-5 col-12"
              style="margin-top: 10px"
            >
              <div class="textlabel">First Name</div>
              <input
                class="universitydatainput"
                placeholder=" First Name"
                [(ngModel)]="
                  universityService.clubNotableAlumini
                    .notable_alumini_first_name
                "
              />
            </div>
            <div
              class="col-xl-5 col-md-5 col-sm-5 col-12"
              style="margin-top: 10px"
            >
              <div class="textlabel">Last Name</div>
              <input
                class="universitydatainput"
                placeholder=" Last Name"
                [(ngModel)]="
                  universityService.clubNotableAlumini.notable_alumini_last_name
                "
              />
            </div>
          </div>
          <!-- <div class="row"> -->
          <!-- <div
              class="col-xl-6 col-md-6 col-sm-6 col-12"
              style="margin-top: 10px"
              id="backgroudwhite"
            >
              <ng-select
                [items]="universityService.courseData"
                [virtualScroll]="true"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="universityService.clubNotableAlumini.course_id"
                placeholder="Select Course"
                loadingText="Loading..."
              >
              </ng-select>
            </div> -->
          <!-- <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="margin-top: 10px;">
                            <div id="backgroudwhite">
                                <ng-select [items]="yeardllService.nbgAllYears" [virtualScroll]="true" placeholder="Select Year" [(ngModel)]="universityService.clubNotableAlumini.notable_alumini_year">
                                </ng-select>
                            </div>
                        </div> -->
          <!-- </div> -->

          <!-- <div class="textlabel">LinkedIn</div>
                    <div id="inputWithIcon">
                        <input class="universitydatainput" placeholder=" LinkedIn " [(ngModel)]="universityService.clubNotableAlumini.notable_alumini_linked_in" style="margin-top: 10px; padding-left:  1rem;">
                        <i class="fa fa-linkedin-square" aria-hidden="true" style="
                        top: 10px !important;"></i>
                    </div> -->

          <div class="textlabel mt-2">Achievement</div>
          <input
            class="universitydatainput"
            placeholder=" Achievement"
            [(ngModel)]="
              universityService.clubNotableAlumini.notable_alumini_achievement
            "
            style="margin-top: 10px"
          />
        </div>

        <div
          class="row add_top_20 add_bottom_30"
          *ngIf="universityService.ClubNotableAluminieDetails.length !== 0"
        >
          <div
            class="col-xl-6 col-sm-12"
            *ngFor="
              let notableData of universityService.ClubNotableAluminieDetails;
              index as i
            "
          >
            <div *ngIf="Notable_clubeId !== notableData.id">
              <div class="clubRelative">
                <div class="clubAbsolute">
                  <mat-icon
                    (click)="editNotable_clubeData(notableData, i)"
                    class="iconbtn"
                    style="font-size: 16px"
                  >edit
                  </mat-icon>
                </div>
              </div>
              <div
                class="row"
                style="width: 95%; margin-top: 1rem"
              >
                <div class="col-xl-2 col-md-2 col-sm-2 col-12">
                  <div
                    class="boxBule1"
                    style="background-image: url({{
                      notableData.profile_image
                    }}); background-size:cover; height: 55px;
                                                        width: 55px;  background-position:center"
                  ></div>
                </div>
                <div class="col-xl-10 col-md-10 col-sm-10 col-12 paddingBlock">
                  <div class="text3 poppinsemibold firstlatterCapital">
                    {{ notableData.first_name }} {{ notableData.last_name }}
                  </div>
                  <!-- <div class="textdatalink Montserrat-regular">
                    {{ notableData.linked_in }}
                  </div> -->
                  <div class="textDesc Montserrat-regular">
                    {{ notableData.achievement }} {{ notableData.year }}
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="Notable_clubeId === notableData.id"
              style="margin-top: 1rem"
            >
              <div class="clubRelative">
                <div class="clubAbsolute">
                  <button
                    class="iconbtn"
                    (click)="
                      saveEditNotable_clube(
                        universityService.copyClubNotableAluminiResponse[i]
                      )
                    "
                  >
                    <mat-icon>save</mat-icon>
                  </button>

                  <button
                    class="iconbtn"
                    (click)="saveDeleteNotable_clube(i)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>

                  <button
                    class="iconbtn"
                    (click)="saveEditCancleNotable_clube(i,notableData.id)"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  class="logouni"
                  style="background:url({{
                    notableData.profile_image
                  }})"
                  (click)="editImageRef.click()"
                >
                  <label
                    class="upload_btn"
                    style="margin-bottom: 0px; margin-top: 5px"
                  >
                    <i
                      class="fa fa-camera"
                      style="font-size: 24px"
                    ></i>
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    name="photo"
                    id="upload-editNotable"
                    (change)="selecteditNotable($event, notableData.id)"
                    #editImageRef
                    hidden
                  />
                </button>
              </div>

              <div class="textlabel">First Name</div>
              <input
                class="universitydatainput firstlatterCapital"
                placeholder=" First Name"
                [(ngModel)]="
                  universityService.copyClubNotableAluminiResponse[i].first_name
                "
              />

              <div class="textlabel">Last Name</div>
              <input
                class="universitydatainput firstlatterCapital"
                placeholder=" Last Name"
                [(ngModel)]="
                  universityService.copyClubNotableAluminiResponse[i].last_name
                "
              />

              <!-- <div class="textlabel">LinkedIn</div>
              <div id="inputWithIcon">
                <input
                  class="universitydatainput"
                  placeholder=" LinkedIn "
                  [(ngModel)]="
                    universityService.copyClubNotableAluminiResponse[i]
                      .linked_in
                  "
                  style="margin-top: 10px; padding-left: 1rem"
                />
                <i
                  class="fa fa-linkedin-square"
                  aria-hidden="true"
                  style="top: 8px !important"
                ></i>
              </div> -->

              <div class="textlabel">Achievement</div>
              <input
                class="universitydatainput"
                placeholder=" Achievement"
                [(ngModel)]="
                  universityService.copyClubNotableAluminiResponse[i]
                    .achievement
                "
                style="margin-top: 10px"
              />

              <!-- <div id="backgroudwhite">
                <ng-select
                  [items]="universityService.courseData"
                  [virtualScroll]="true"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="
                    universityService.copyClubNotableAluminiResponse[i].club_id
                  "
                  placeholder="Select Course"
                  loadingText="Loading..."
                >
                </ng-select>
              </div> -->

              <!-- <div id="backgroudwhite">
                <ng-select
                  [items]="yeardllService.nbgAllYears"
                  [virtualScroll]="true"
                  placeholder="Select Year"
                  [(ngModel)]="
                    universityService.copyClubNotableAluminiResponse[i].year
                  "
                >
                </ng-select>
              </div> -->
              <!-- <div id="yearcal">
                                <mat-form-field style=" width: 100%; ">
                                    <input matInput #d1 [matDatepicker]="dp" [formControl]="date" placeholder="Year" readonly [(ngModel)]="date">
                                    <mat-datepicker-toggle matSuffix [for]="dp">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandlerEdit($event, dp ,i)" panelClass="example-month-picker">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div> -->
            </div>
          </div>
        </div>
      </section>

      <!-- Notice Board -->
      <section class="add_top_20 marginbottom seperate-data">
        <div class="">
          <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
            <div class="poppins-medium lbl2">
              Notice Board
              <span *ngIf="!universityService.addNoticeBoard">
                <i
                  class="material-icons icon-class"
                  (click)="universityService.addNoticeBoard = true"
                >add</i>
              </span>
            </div>

            <div>
              <div *ngIf="universityService.addNoticeBoard">
                <div style="display: flex">
                  <button
                    class="iconbtn"
                    (click)="
                      saveAddNoticeBoard(universityService.clubDetails.id)
                    "
                  >
                    <mat-icon>save</mat-icon>
                  </button>

                  <button
                    class="iconbtn"
                    (click)="saveCancelNoticeBoard()"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="textDesc add_top_20"
            *ngIf="universityService.addNoticeBoard"
          >
            <div class="textlabel">Title</div>
            <input
              class="universitydatainput"
              placeholder=" Notice Title"
              [(ngModel)]="universityService.universitySport.notice_title"
            />

            <div
              class="textlabel"
              style="margin-bottom: 8px"
            >Description</div>
            <angular-editor
              [(ngModel)]="universityService.universitySport.notice_board"
              [config]="config"
              #ctrl="ngModel"
              class="editDescription"
            >
            </angular-editor>

            <!-- <textarea class="universitydatainput" placeholder="Notice Description" [(ngModel)]="universityService.universitySport.notice_board"></textarea> -->
          </div>

          <div class="textDesc add_top_20">
            <div
              *ngFor="
                let club of universityService.clubDetails.notice_board;
                index as i
              "
              style="margin-top: 1rem"
            >
              <div *ngIf="noticeBoardId !== club.id">
                <div class="clubRelative">
                  <div class="clubAbsolute">
                    <mat-icon
                      (click)="editNoticeBoardData(club)"
                      class="iconbtn"
                      style="font-size: 16px"
                    >edit
                    </mat-icon>
                  </div>
                </div>
                <div>
                  <div class="select1 poppins-medium firstlatterCapital">
                    {{ club.title }}
                  </div>
                  <div id="descriptionData">
                    <div
                      class="text3 poppinsregular"
                      innerHtml="{{ club.description }}"
                    ></div>
                  </div>

                  <!-- <div class="text3 poppinsregular">{{club.description}}</div> -->
                </div>
              </div>

              <div *ngIf="noticeBoardId === club.id">
                <div class="clubRelative">
                  <div
                    class="clubAbsolute"
                    style="display: flex"
                  >
                    <button
                      class="iconbtn"
                      (click)="
                        saveEditNoticeBoard(
                          universityService.copyClubNoticeBoardResponse[i]
                        )
                      "
                    >
                      <mat-icon>save</mat-icon>
                    </button>

                    <button
                      class="iconbtn"
                      (click)="saveDeleteNoticeBoard(i)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>

                    <button
                      class="iconbtn"
                      (click)="editCancleNoticeBoard(i)"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
                <div style="width: 81%">
                  <div>
                    <div class="textlabel">Title*</div>
                    <input
                      class="universitydatainput firstlatterCapital"
                      placeholder="Title"
                      [(ngModel)]="
                        universityService.copyClubNoticeBoardResponse[i].title
                      "
                    />
                  </div>
                  <div>
                    <div
                      class="textlabel"
                      style="margin-bottom: 8px"
                    >
                      Description*
                    </div>
                    <angular-editor
                      [(ngModel)]="
                        universityService.copyClubNoticeBoardResponse[i]
                          .description
                      "
                      [config]="config"
                      #ctrl="ngModel"
                      class="editDescription"
                    >
                    </angular-editor>

                    <!-- <textarea class="universitydatainput" placeholder="Description" [(ngModel)]="universityService.copyClubNoticeBoardResponse[i].description"></textarea> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Club  Commitee-->
      <section class="add_top_20 marginbottom seperate-data">
        <div class="">
          <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
            <div class="poppins-medium lbl2">
              Club Commitee
              <span *ngIf="!universityService.addCommite">
                <i
                  class="material-icons icon-class"
                  (click)="universityService.addCommite = true"
                >add</i>
              </span>
            </div>

            <div>
              <div *ngIf="universityService.addCommite">
                <div style="display: flex">
                  <button
                    class="iconbtn"
                    (click)="saveAddCommite(universityService.clubDetails.id)"
                  >
                    <mat-icon>save</mat-icon>
                  </button>

                  <button
                    class="iconbtn"
                    (click)="saveCancelCommite()"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="add_top_20"
            *ngIf="universityService.addCommite"
          >
            <div class="row">
              <!-- <div class="col-xl-2 col-md-2 col-sm-2 col-12" style="margin-top: 10px;">
                                <button type="button" class=" logouni" style="background: url({{addCommities_imagePic}})">
                                    <label for="upload-Club" class="upload_btn" style="margin-bottom: 0px;
                                                margin-left: -1rem; margin-top: 5px;">
                                        <mat-icon class="iconbtn" style="font-size: 39px !important;">camera_alt</mat-icon>
                                    </label>
                                    <input type="file"  accept="image/png, image/jpg, image/jpeg"name="photo" id="upload-Club" (change)="selectAddClub($event)"  style="display: none;">
                                </button>
                            </div> -->
              <div
                class="col-xl-5 col-md-5 col-sm-5 col-12"
                style="margin-top: 10px"
              >
                <div class="textlabel">First Name*</div>
                <input
                  class="universitydatainput"
                  placeholder=" First Name"
                  [(ngModel)]="
                    universityService.universitySport.commities_fname
                  "
                />
              </div>
              <div
                class="col-xl-5 col-md-5 col-sm-5 col-12"
                style="margin-top: 10px"
              >
                <div class="textlabel">Last Name*</div>
                <input
                  class="universitydatainput"
                  placeholder=" Last Name"
                  [(ngModel)]="
                    universityService.universitySport.commities_lname
                  "
                />
              </div>
            </div>

            <div class="textlabel">Email*</div>
            <div id="inputWithIcon">
              <input
                class="universitydatainput"
                placeholder=" Email"
                [(ngModel)]="universityService.universitySport.commities_email"
                style="margin-top: 10px; padding-left: 1.3rem"
              />
              <mat-icon style="top: 8px !important">email</mat-icon>
            </div>

            <div
              class="textlabel"
              style="margin-bottom: 8px"
            >Description*</div>
            <angular-editor
              [(ngModel)]="
                universityService.universitySport.commities_description
              "
              [config]="config"
              #ctrl="ngModel"
              class="editDescription"
            >
            </angular-editor>

            <!-- <textarea class="universitydatainput" placeholder="Description" [(ngModel)]="universityService.universitySport.commities_description"
                            style="margin-top: 10px;"></textarea> -->
          </div>

          <div class="row add_top_20 add_bottom_30">
            <div
              class="col-xl-6 col-sm-12"
              *ngFor="
                let clubcommities of universityService.clubDetails.commities;
                index as i
              "
            >
              <div *ngIf="CommiteeId !== clubcommities.id">
                <div class="clubRelative">
                  <div class="clubAbsolute">
                    <mat-icon
                      (click)="editCommiteeData(clubcommities)"
                      class="iconbtn"
                      style="font-size: 16px"
                    >edit
                    </mat-icon>
                  </div>
                </div>
                <div
                  class="row"
                  style="width: 95%"
                >
                  <!-- <div class="col-xl-2 col-md-2 col-sm-2 col-12 ">
                                        <div class="boxBule1" style="background-image: url({{clubcommities.image}}); background-size:cover; height: 55px;
                                                width: 55px;  background-position:center">
                                        </div>
                                    </div> -->
                  <div class="col-xl-10 col-md-10 col-sm-10 col-12">
                    <div class="text3 poppinsemibold firstlatterCapital">
                      {{ clubcommities.first_name }}
                      {{ clubcommities.last_name }}
                    </div>
                    <div class="textDesc Montserrat-regular">
                      {{ clubcommities.email }}
                    </div>
                    <div id="descriptionData">
                      <div
                        class="text3 poppinsregular"
                        innerHtml="{{ clubcommities.description }}"
                      ></div>
                    </div>

                    <!-- <div class="textDesc Montserrat-regular">
                                            {{clubcommities.description}}
                                        </div> -->
                  </div>
                </div>
              </div>

              <div
                *ngIf="CommiteeId === clubcommities.id"
                style="margin-top: 1rem"
              >
                <div class="clubRelative">
                  <div class="clubAbsolute">
                    <button
                      class="iconbtn"
                      (click)="
                        saveEditCommitee(
                          universityService.copyClubCommittieResponse[i]
                        )
                      "
                    >
                      <mat-icon>save</mat-icon>
                    </button>

                    <button
                      class="iconbtn"
                      (click)="saveDeleteCommitee(i)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>

                    <button
                      class="iconbtn"
                      (click)="saveEditCancleCommitee(i)"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
                <!-- <div>
                                    <button type="button" class=" logouni" style="background: url({{universityService.copyClubCommittieResponse[i].image}})">
                                        <label for="upload-Club" class="upload_btn" style="margin-bottom: 0px;
                                                                         margin-top: 5px;">
                                            <i class="fa fa-camera" style="font-size:24px"></i>
                                        </label>
                                        <input type="file"  accept="image/png, image/jpg, image/jpeg" name="photo" id="upload-Club" (change)="selectClub($event , i)" style="display: none;">
                                    </button>
                                </div> -->

                <div class="textlabel">First Name</div>
                <input
                  class="universitydatainput firstlatterCapital"
                  placeholder=" First Name"
                  [(ngModel)]="
                    universityService.copyClubCommittieResponse[i].first_name
                  "
                />

                <div class="textlabel">Last Name</div>
                <input
                  class="universitydatainput firstlatterCapital"
                  placeholder=" Last Name"
                  [(ngModel)]="
                    universityService.copyClubCommittieResponse[i].last_name
                  "
                />

                <div class="textlabel">Email</div>
                <div id="inputWithIcon">
                  <input
                    class="universitydatainput"
                    placeholder=" Email"
                    [(ngModel)]="
                      universityService.copyClubCommittieResponse[i].email
                    "
                    style="margin-top: 10px; padding-left: 1.3rem"
                  />
                  <mat-icon style="top: 8px !important">email</mat-icon>
                </div>

                <div
                  class="textlabel"
                  style="margin-bottom: 8px"
                >
                  Description
                </div>
                <angular-editor
                  [(ngModel)]="
                    universityService.copyClubCommittieResponse[i].description
                  "
                  [config]="config"
                  #ctrl="ngModel"
                  class="editDescription"
                >
                </angular-editor>

                <!-- <textarea class="universitydatainput" placeholder="Description" [(ngModel)]="universityService.copyClubCommittieResponse[i].description"
                                    style="margin-top: 10px;"></textarea> -->
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Sportfolio News  -->
      <!-- <section class="add_top_20 seperate-data">
                        <div class="poppins-medium lbl2">Sportfolio News
                            <span *ngIf="!universityService.addSportNews">
                                <i class="material-icons icon-class" (click)="universityService.addSportNews = true">add</i>
                            </span>
                        </div>
                        <div>
                            <div *ngIf="universityService.addSportNews">
                                <div style="display: flex;">
                                    <button class="iconbtn" (click)="saveAddSportNews(universityService.clubDetails.id)">
                                        <mat-icon>save</mat-icon>
                                    </button>

                                    <button class="iconbtn" (click)="saveCancelSportNews()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class=" add_top_20" *ngIf="universityService.addSportNews">

                            <input class="universitydatainput" placeholder=" Title" [(ngModel)]="universityService.universitySport.sportNewsTitle" style="margin-top: 10px;">

                            <textarea class="universitydatainput" placeholder="Description" [(ngModel)]="universityService.universitySport.sportNewsdescription"
                                style="margin-top: 10px;"></textarea>
                        </div>

                        <div class="row add_top_20 add_bottom_30">
                            <div class="col-xl-6 col-sm-12">
                                <div>
                                    <div class="clubRelative">
                                        <div class="clubAbsolute">
                                            <mat-icon (click)="editSportNewsData()" class="iconbtn" style="font-size: 16px;">edit
                                            </mat-icon>
                                        </div>
                                    </div>
                                    <div style="width: 95%;  margin-top: 1rem">
                                        <div class="text3 poppinsemibold">sdgvs dbhsbd
                                        </div>
                                        <div class="textDesc Montserrat-regular">
                                            dsjkdhjks jfhdjsf jfhdsjf fhjdsf
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="CommiteeId === clubcommities.id" style="
                            margin-top: 1rem;">
                                    <div class="clubRelative">
                                        <div class="clubAbsolute">
                                            <button class="iconbtn" (click)="saveEditSportNews(universityService.copyClubCommittieResponse[i])">
                                                <mat-icon>save</mat-icon>
                                            </button>

                                            <button class="iconbtn" (click)="saveDeleteSportNews(i)">
                                                <mat-icon>delete</mat-icon>
                                            </button>

                                            <button class="iconbtn" (click)="editCancleSportNews(i)">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </div>
                                    </div>

                                    <input class="universitydatainput" placeholder=" Title" [(ngModel)]="universityService.copyClubCommittieResponse[i].email"
                                        style="margin-top: 10px;">

                                    <textarea class="universitydatainput" placeholder="Description" [(ngModel)]="universityService.copyClubCommittieResponse[i].description"
                                        style="margin-top: 10px;"></textarea>

                                </div>

                            </div>
                        </div>

            </section> -->

      <!-- FAQ's-->
      <section
        class="add_top_20 marginbottom seperate-data"
        style="margin-top: -1rem"
      >
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <div class="poppins-medium lbl2">
            FAQ's
            <span *ngIf="!universityService.addFaq">
              <i
                class="material-icons icon-class"
                (click)="universityService.addFaq = true"
              >add</i>
            </span>
          </div>

          <div>
            <div *ngIf="universityService.addFaq">
              <div style="display: flex">
                <button
                  class="iconbtn"
                  (click)="saveAddFaq(universityService.clubDetails.id)"
                >
                  <mat-icon>save</mat-icon>
                </button>

                <button
                  class="iconbtn"
                  (click)="saveCancelFaq()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="add_top_20"
          *ngIf="universityService.addFaq"
        >
          <div class="textlabel">Question</div>
          <input
            class="universitydatainput"
            placeholder=" Question"
            [(ngModel)]="universityService.universitySport.faqTitle"
            style="margin-top: 10px"
          />

          <div
            class="textlabel"
            style="margin-bottom: 8px"
          >Description</div>
          <angular-editor
            [(ngModel)]="universityService.universitySport.faqdescription"
            [config]="config"
            #ctrl="ngModel"
            class="editDescription"
          >
          </angular-editor>

          <!-- <textarea class="universitydatainput" placeholder="Answer" [(ngModel)]="universityService.universitySport.faqdescription"
                        style="margin-top: 10px;"></textarea> -->
        </div>

        <div
          class="add_top_20 add_bottom_30"
          *ngIf="universityService.ClubFaqDetails.length !== 0"
        >
          <div *ngFor="let faq of universityService.ClubFaqDetails; index as i">
            <div
              *ngIf="faq_clubeId !== faq.id"
              style="margin-top: 1rem"
            >
              <div class="clubRelative">
                <div class="clubAbsolute">
                  <mat-icon
                    (click)="editFaqData(faq)"
                    class="iconbtn"
                    style="font-size: 16px"
                  >edit
                  </mat-icon>
                </div>
              </div>
              <div class="text3 poppinsemibold">{{ faq.question }}</div>
              <div id="descriptionData">
                <div
                  class="text3 poppinsregular"
                  innerHtml="{{ faq.answer }}"
                ></div>
              </div>

              <!-- <div class="textDesc Montserrat-regular ">{{faq.answer}}
                            </div> -->
            </div>
            <div *ngIf="faq_clubeId === faq.id">
              <div class="clubRelative">
                <div class="clubAbsolute">
                  <button
                    class="iconbtn"
                    (click)="
                      saveEditFaq(universityService.copyClubFaqResponse[i])
                    "
                  >
                    <mat-icon>save</mat-icon>
                  </button>

                  <button
                    class="iconbtn"
                    (click)="saveDeleteFaq(i)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>

                  <button
                    class="iconbtn"
                    (click)="editCancleFaq(i)"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
              <div style="width: 75%">
                <div class="textlabel">Question</div>
                <input
                  class="universitydatainput"
                  placeholder=" Question"
                  [(ngModel)]="
                    universityService.copyClubFaqResponse[i].question
                  "
                  style="margin-top: 10px"
                />

                <div style="margin-top: 10px">
                  <div
                    class="textlabel"
                    style="margin-bottom: 8px"
                  >
                    Description
                  </div>
                  <angular-editor
                    [(ngModel)]="
                      universityService.copyClubFaqResponse[i].answer
                    "
                    [config]="config"
                    #ctrl="ngModel"
                    class="editDescription"
                  >
                  </angular-editor>
                </div>

                <!-- <textarea class="universitydatainput" placeholder="Answer" [(ngModel)]="universityService.copyClubFaqResponse[i].answer"
                                    style="margin-top: 10px;"></textarea> -->
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Membership Price  -->
      <!-- <section class="add_top_20 marginbottom seperate-data">
                <div class="poppins-medium lbl2">Membership Price</div>

                <div class="textDesc add_top_20">
                    <input type="number" class="universitydatainput" placeholder="Price" [(ngModel)]="universityService.clubDetails.membership_price">
                </div>

            </section> -->

      <div style="
          display: flex;
          justify-content: flex-end;
          align-items: end;
          margin: 2rem 0 -4px;
        ">
        <div>
          <!-- <button class="btn btn-success btn-width" style="  background: #6C63FF;    font-family: 'Poppins SemiBold'; width: 90px; color: white;"
                        (click)="saveEditedSport(universityService.clubDetails)">SAVE</button> -->

          <button
            class="btn btn-danger btn-width"
            style="
              background: #ffc107;
              font-family: 'Poppins SemiBold';
              margin-left: 8px;
              width: 90px;
              color: black;
            "
            (click)="editCancelSportdata()"
          >
            BACK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>