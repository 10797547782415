<div class="bgbule width2">
    <div id="demo" class="carousel slide" data-ride="carousel">

        <ul class="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" class="active"></li>
            <li data-target="#demo" data-slide-to="1"></li>
            <li data-target="#demo" data-slide-to="2"></li>
        </ul>


        <div class="carousel-inner">
            <div class="carousel-item active">
                <div>
                    <div style="display: flex; justify-content: center; align-items: center; ">
                        <div><img src="../assets/img/Grouplogin.png" style="margin-top: 6rem; height: 270px; ">
                        </div>
                    </div>
                    <div class="textC poppinsemibold">Create your Sportfolio</div>
                    <div class="text_slider Montserrat-regular">Sign up and create your sporting profile specific to the sport you play, including information such as your height, team history, notable achievements and education.</div>
                </div>
            </div>
            <div class="carousel-item">
                <div>
                    <div style="display: flex; justify-content: center; align-items: center; ">
                        <div><img src="../assets/img/Grouplogin-2.png" style="margin-top: 6rem; height: 270px; ">
                        </div>
                    </div>
                    <div class="textC poppinsemibold">Find the right opportunity for you</div>
                    <div class="text_slider Montserrat-regular">Using past league scores and rankings, Sportfolio will help you discover and suggest the best fit universities for you and your sport.</div>
                </div>
            </div>
            <div class="carousel-item">
                <div>
                    <div style="display: flex; justify-content: center; align-items: center; ">
                        <div><img src="../assets/img/Grouplogin-3.png" style="margin-top: 6rem; height: 270px; ">
                        </div>
                    </div>
                    <div class="textC poppinsemibold">Register Interest</div>
                    <div class="text_slider Montserrat-regular">Once you have selected your choice of your universites, register interest using the status that applies to you to keep the university up to date with your decision.</div>
                </div>
            </div>
            <div class="carousel-item">
                <div>
                    <div style="display: flex; justify-content: center; align-items: center; ">
                        <div><img src="../assets/img/Grouplogin.png" style="margin-top: 6rem; height: 270px; ">
                        </div>
                    </div>
                    <div class="textC poppinsemibold">Create your Sportfolio</div>
                    <div class="text_slider Montserrat-regular">Sign up and create your sporting profile specific to the sport you play, including information such as your height, team history, notable achievments and education.</div>
                </div>
            </div>
        </div>

    </div>

</div>